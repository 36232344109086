import PropTypes from 'prop-types';

const TopBar = ({ leftContent, centerContent, rightContent }) => {
  return (
    <div className="topbar flex-container align-center justify-between">
      {leftContent ? (
        <div className="flex-container align-center left-content">{leftContent}</div>
      ) : null}
      {centerContent ? (
        <div className="flex-container align-center center-content">{centerContent}</div>
      ) : null}
      {rightContent ? (
        <div className="flex-container align-center right-content">{rightContent}</div>
      ) : null}
    </div>
  );
};

TopBar.propTypes = {
  centerContent: PropTypes.any,
  leftContent: PropTypes.any,
  rightContent: PropTypes.any,
};

export default TopBar;
