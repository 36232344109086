import styled from 'styled-components/macro';
import { Icon, Tooltip } from '@zylo/orchestra';
import { DefaultCell } from '@components/tables/cells';
import { CellProps, RowData } from '@typings';

const CellContainer = styled.div`
  .zylo-icon {
    margin-right: 0.25em;
  }
`;

export type DefaultCellWithErrorProps = CellProps & {
  data: string | string[];
  rowData: RowData & { results?: { errors?: string[]; warnings?: string[] } };
  truncate?: number;
};

export function DefaultCellWithError({ data, rowData }: DefaultCellWithErrorProps) {
  const errorText = rowData?.results?.errors?.join('\n');
  const warningText = rowData?.results?.warnings?.join('\n');

  const hasError = !!errorText;
  const hasWarning = !!warningText;

  return (
    <CellContainer>
      {hasError || hasWarning ? (
        <Tooltip text={errorText || warningText}>
          <Icon
            color={hasError ? 'red' : 'yellow'}
            icon="IconAlertTriangle"
            size={1.25}
            relativeSize
          />
          <DefaultCell data={data as string} />
        </Tooltip>
      ) : (
        <DefaultCell data={data as string} />
      )}
    </CellContainer>
  );
}
