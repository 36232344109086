import PropTypes from 'prop-types';
import Select from 'react-select-plus';
import classNames from 'clsx';
import { reduxObject } from '@utilities/propTypes';

import 'react-select-plus/dist/react-select-plus.css';

function ReactSelect({
  children,
  className,
  clearable = false,
  disabled,
  label,
  labelKey = 'label',
  multi,
  name,
  onChange,
  options,
  placeholder = 'Select One',
  readOnly,
  searchable,
  value,
  valueKey = 'value',
}) {
  function handleChange(selection) {
    if (selection !== value) {
      const updatedValue = multi
        ? selection.map((val) => {
            return val[valueKey];
          })
        : selection
        ? selection[valueKey]
        : null;
      if (onChange) {
        onChange(updatedValue, selection);
      }
    }
  }

  function handleClear() {
    handleChange({ value: null });
  }

  const select = (
    <Select
      className={classNames(readOnly && !value ? 'empty-select' : '', className)}
      clearable={false}
      disabled={disabled}
      labelKey={labelKey}
      multi={multi}
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={readOnly && !value ? '-' : disabled ? '' : placeholder}
      readOnly={readOnly}
      searchable={multi || searchable || false}
      value={typeof value === 'boolean' ? value.toString() : value}
      valueKey={valueKey}
    />
  );
  const clearSelect =
    value && value !== '' && clearable ? (
      <span className="clear-select" onClick={handleClear}>
        <span className="clear-x">&times;</span>
        <span>Clear</span>
      </span>
    ) : null;

  return label ? (
    <div className={classNames('zylo-form-field select-container', readOnly && 'read-only')}>
      <label className="zylo-form-label" htmlFor={name}>
        {label}
      </label>
      {select}
      {clearSelect}
      {children}
    </div>
  ) : (
    <div className={classNames('select-container', readOnly && 'read-only')}>
      {select}
      {clearSelect}
      {children}
    </div>
  );
}

ReactSelect.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  multi: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(reduxObject).isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  searchable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  valueKey: PropTypes.string,
};

export default ReactSelect;
