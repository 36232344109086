import { Button, Tooltip, useTableConfigState } from '@zylo/orchestra';
import { useState } from 'react';
import { useEventTracking } from '@hooks';
import { useZAPState } from '../ZAPContext';
import ZAPBulkEditDrawer from './ZAPBulkEditDrawer';

export function ZAPBulkEditButton() {
  const { selectionState } = useTableConfigState();
  const { eventLocation } = useZAPState();
  const selectionMade =
    selectionState && (selectionState.selectAll || selectionState.selectedRows.length > 0);
  const [showBulkEditDrawer, setShowBulkEditDrawer] = useState<boolean>(false);
  const sendEvent = useEventTracking();

  function handleBulkEditClick() {
    setShowBulkEditDrawer(true);
    sendEvent({ eventName: 'BULK_EDIT', data: { page: eventLocation } });
  }

  return (
    <div className="action">
      <Tooltip
        disabled={selectionMade}
        text="To bulk edit, make a selection from the rows of the table below."
      >
        <div>
          <Button
            disabled={!selectionMade}
            handleClick={handleBulkEditClick}
            icon="IconListCheck"
            text="Bulk Edit"
            type="primary"
          />
        </div>
      </Tooltip>
      {showBulkEditDrawer && (
        <ZAPBulkEditDrawer
          handleClose={() => setShowBulkEditDrawer(false)}
          tableSelectionState={selectionState}
        />
      )}
    </div>
  );
}
