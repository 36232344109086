import styled, { css } from 'styled-components/macro';
import {
  Checkbox,
  ZyloCurrencyField,
  FormField,
  FormFieldTextarea,
  FieldWrapper,
  ItemList,
  Select,
  Switch,
  ComboboxFormField,
  MultiComboboxFormField,
} from '@zylo/orchestra';
import { ControllerRenderProps, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import { ReactElement } from 'react';
import DatePicker from '@components/datepickers/DatePicker';
import type {
  CheckboxConfig,
  ComboboxConfig,
  CurrencyFieldConfig,
  DatePickerConfig,
  FormContentCommonProps,
  FieldConfig,
  FormFieldConfig,
  FormFieldTextareaConfig,
  ItemListFieldConfig,
  MultiComboboxConfig,
  OmitUnPassedProps,
  SelectConfig,
  SwitchConfig,
  UnifiedControlProps,
} from '../types';
import { errorMessageHandler, handleChangeHelper } from '../formHelpers';

const SwitchFieldWrapper = styled(FieldWrapper)`
  min-height: 2rem;
`;

export type FieldProps = FormContentCommonProps & {
  controlProps: UnifiedControlProps;
  fieldConfig: FieldConfig;
};

/**
 * The Field Input with Label handles rendering the actual Input for a given Field.
 * Because some of our Orchestra components (Select, Checkbox, Combobox, etc) render their own labels to allow for
 * correct a11y properties, we keep consistent here by always rendering the Label and Input at this level.
 * */
export function FieldInputWithLabel({
  editing,
  controlProps,
  fieldConfig,
  methods,
  readOnly,
}: FieldProps): ReactElement {
  // The Fields component will be called without the 'methods'
  // prop during 'loading' and 'error' states. The 'idle',
  // 'success' and default cases will not be reached without
  // the methods prop being provided. This is the reason for
  // the use of the non-null assertion (!) on the next line.
  const {
    formState: { errors },
  } = methods!;
  const [field, { name, ...props }] = fieldConfig;
  const disabled = !editing || props.readOnly || readOnly;
  const { field: fieldControlProps, fieldState, formState } = controlProps;
  const fieldErrors = errorMessageHandler(errors, name);

  switch (field) {
    case 'CustomFormField': {
      const [, , { customFormFieldComponent: CustomFormField }] = fieldConfig;

      return (
        <CustomFormField
          disabled={disabled}
          editing={editing}
          errors={fieldErrors}
          field={fieldControlProps as ControllerRenderProps<FieldValues, string>}
          fieldState={fieldState}
          formState={formState}
          label={props.label}
          readOnly={readOnly}
        />
      );
    }
    case 'Checkbox': {
      return (
        <Checkbox
          {...(props as OmitUnPassedProps<CheckboxConfig>)}
          {...(fieldControlProps as UseFormRegisterReturn<string>)}
          disabled={disabled}
        />
      );
    }
    case 'Combobox': {
      const {
        className,
        instructions,
        onChange: changeHandler,
        placeholder,
        ...rest
      } = props as OmitUnPassedProps<ComboboxConfig>;
      const { onChange, value } = fieldControlProps as ControllerRenderProps<FieldValues, string>;
      return (
        <ComboboxFormField
          {...rest}
          className={className}
          disabled={disabled}
          displayOnly={disabled ?? readOnly}
          errors={Boolean(fieldErrors)}
          helperText={fieldErrors ?? instructions}
          name={name}
          onChange={(option) => handleChangeHelper(option, onChange, changeHandler)}
          placeholder={editing ? 'Type to search' : placeholder}
          value={value}
        />
      );
    }
    case 'CurrencyField': {
      const {
        instructions,
        onChange: changeHandler,
        ...rest
      } = props as OmitUnPassedProps<CurrencyFieldConfig>;
      const { onChange, value } = fieldControlProps as ControllerRenderProps<FieldValues, string>;

      return (
        <ZyloCurrencyField
          {...rest}
          disabled={disabled}
          displayOnly={disabled ?? readOnly}
          error={Boolean(fieldErrors)}
          helperText={fieldErrors ?? instructions}
          name={name}
          onChange={(option) => handleChangeHelper(option, onChange, changeHandler)}
          value={value}
        />
      );
    }
    case 'DatePicker': {
      const {
        className,
        instructions,
        onChange: changeHandler,
        label,
        ...rest
      } = props as OmitUnPassedProps<DatePickerConfig>;
      const { onChange, value } = fieldControlProps as ControllerRenderProps<FieldValues, string>;

      return (
        <FieldWrapper
          className={className}
          errors={fieldErrors}
          instructions={instructions}
          label={label}
          name={name}
        >
          <DatePicker
            {...rest}
            css={css`
              width: unset;

              & > span > span {
                display: unset !important;
              }
            `}
            disabled={disabled}
            name={name}
            onChange={(option) => handleChangeHelper(option, onChange, changeHandler)}
            value={value}
          />
        </FieldWrapper>
      );
    }
    case 'FormField': {
      return (
        <FormField
          {...(props as OmitUnPassedProps<FormFieldConfig>)}
          disabled={disabled}
          displayOnly={disabled ?? readOnly}
          errors={fieldErrors}
          {...(fieldControlProps as UseFormRegisterReturn<string>)}
        />
      );
    }
    case 'FormFieldTextarea': {
      return (
        <FormFieldTextarea
          {...(props as OmitUnPassedProps<FormFieldTextareaConfig>)}
          disabled={disabled}
          displayOnly={disabled ?? readOnly}
          errors={fieldErrors}
          {...(fieldControlProps as UseFormRegisterReturn<string>)}
        />
      );
    }
    case 'ItemListField': {
      const {
        className,
        instructions,
        onChange: changeHandler,
        label,
        ...rest
      } = props as OmitUnPassedProps<ItemListFieldConfig>;
      const { onChange, value } = fieldControlProps as ControllerRenderProps<FieldValues, string>;

      return (
        <FieldWrapper
          className={className}
          errors={fieldErrors}
          instructions={instructions}
          label={label}
          name={name}
        >
          <ItemList
            {...rest}
            disabled={disabled}
            onChange={(option) => handleChangeHelper(option, onChange, changeHandler)}
            value={value}
          />
        </FieldWrapper>
      );
    }
    case 'MultiCombobox': {
      const {
        className,
        instructions,
        onChange: changeHandler,
        placeholder,
        ...rest
      } = props as OmitUnPassedProps<MultiComboboxConfig>;
      const { onChange, value } = fieldControlProps as ControllerRenderProps<FieldValues, string>;

      return (
        <MultiComboboxFormField
          {...rest}
          className={className}
          disabled={disabled}
          displayOnly={disabled ?? readOnly}
          errors={Boolean(fieldErrors)}
          helperText={fieldErrors ?? instructions}
          name={name}
          onChange={(option) => handleChangeHelper(option, onChange, changeHandler)}
          placeholder={placeholder ?? 'Type to search'}
          value={value}
        />
      );
    }
    case 'Select': {
      const {
        className,
        instructions,
        onChange: changeHandler,
        placeholder,
        ...rest
      } = props as OmitUnPassedProps<SelectConfig>;
      const { onChange, value } = fieldControlProps as ControllerRenderProps<FieldValues, string>;

      return (
        <Select
          {...rest}
          className={className}
          disabled={disabled}
          displayOnly={disabled ?? readOnly}
          error={Boolean(fieldErrors)}
          helperText={fieldErrors ?? instructions}
          name={name}
          onChange={(option) => handleChangeHelper(option, onChange, changeHandler)}
          placeholder={editing ? 'Select one' : placeholder}
          value={value}
        />
      );
    }
    case 'Switch': {
      const { className, label, ...rest } = props as OmitUnPassedProps<SwitchConfig>;

      return (
        <SwitchFieldWrapper className={className}>
          <Switch
            {...rest}
            disabled={disabled}
            {...(fieldControlProps as UseFormRegisterReturn<string>)}
            rightLabel={label}
          />
        </SwitchFieldWrapper>
      );
    }
    default:
      return <></>;
  }
}
