import PropTypes from 'prop-types';
import classNames from 'clsx';

function TabLabel({ handleClick, isActive, label, tabId, tabIndex }) {
  function handleTabClick(event) {
    event.preventDefault();
    handleClick(tabId);
  }

  return (
    <li
      className={classNames(
        'flex-container justify-center align-center tab-label',
        isActive && 'active',
      )}
      onClick={handleTabClick}
      onKeyPress={handleTabClick}
      role="presentation"
      tabIndex={tabIndex}
    >
      {label}
    </li>
  );
}

TabLabel.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tabId: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
};

export default TabLabel;
