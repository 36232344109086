/*
 * This is not export by Waterworks, but should be kept
 * up to date with `api/helpers/contants/SAVED_FILTER_TYPES`
 * to the best of our ability.
 */
export const SAVED_FILTERS_TYPES = {
  AUTOMATION: 'automation',
  APP_CATALOG_APPS: 'app_catalog_apps',
  APP_COMPARE: 'app_compare',
  APP_USERS: 'user_apps',
  COMPANY_APP_BUDGETS: 'company_app_budgets',
  COMPANY_APPS: 'company_apps',
  CONNECTIONS: 'connections',
  CONTRACTS: 'contracts',
  JOB_HISTORY: 'batch_job_statuses',
  MANUAL_UPLOADS: 'manual_uploads',
  MULTIORG: (integrationType) => `multiorg_${integrationType}`,
  PO_LINES: 'po_lines',
  PURCHASE_ORDERS: 'purchase_orders',
  SAVINGS_CENTER: 'savings_center',
  SOFTWARE_SPEND: 'software_spend',
  TEAMS_USERS: 'teams_users',
  USER_GROUPS: 'user_groups',
  ZYLO_AUTH: 'zylo_auth',
};
