import { get } from 'lodash-es';
import { UseMutateFunction } from 'react-query';
import { usePermissions } from '@hooks';
import { RowData } from '@typings';
import useUserIdFromEmail from '@hooks/useUserIdFromEmail';

type OnClickParams = { rowData: RowData; userId?: string };

type HighlightCellOwnProps = {
  data?: string;
  metadataDisplayProperty?: string;
  onClick: (onClickParams: OnClickParams) => void;
  rowData: RowData;
};

type HighlightCellDataProps = {
  authCanAccessUsers?: boolean;
  getUserIdMutation: UseMutateFunction<string, unknown, string, unknown>;
};

export type HighlightCellDisplayProps = HighlightCellOwnProps & HighlightCellDataProps;

export function HighlightCell(ownProps: HighlightCellOwnProps) {
  return <HighlightCell_DisplayLayer {...ownProps} {...useDataLayer()} />;
}

export function HighlightCell_DisplayLayer({
  authCanAccessUsers,
  data,
  getUserIdMutation,
  metadataDisplayProperty = '',
  onClick = () => {},
  rowData,
}: HighlightCellDisplayProps) {
  function handleClick() {
    if (authCanAccessUsers && data && data === rowData.email) {
      getUserIdMutation(data, {
        onSuccess: (userId: string) => onClick({ rowData, userId }),
      });
    } else if (authCanAccessUsers && data) {
      onClick({ rowData });
    }
  }

  const displayValue = data ? data : get(rowData, `metadata.${metadataDisplayProperty}`) || '-';

  return (
    <div className={`${data && authCanAccessUsers ? 'highlight-cell' : ''}`} onClick={handleClick}>
      {displayValue}
    </div>
  );
}

function useDataLayer(): HighlightCellDataProps {
  const { authCanAccessUsers } = usePermissions('users');
  const { mutate: getUserIdMutation } = useUserIdFromEmail();

  return {
    authCanAccessUsers,
    getUserIdMutation,
  };
}
