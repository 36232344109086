import { useCallback } from 'react';
import styled from 'styled-components/macro';
import { PortalDrawer, Button, Colors, defaultborder } from '@zylo/orchestra';
import { usePortalTarget, useSidebarVisibilitySensor } from '@hooks';
import { CategoriesInfo } from '@typings';
import { useSidebarState, useSidebarUpdate } from '@contexts/sidebarContext';
import TabPane from '../layout/TabPane';
import Tabs from '../layout/Tabs';
import AppUsersChooseFields from './AppUsersChooseFields';
import ChooseFields from './ChooseFields';
import SortFields from './SortFields';

const ColumnPickerSidebar = styled(PortalDrawer)`
  padding: 0;

  .column-picker {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
    width: 560px;

    .column-picker-close-button {
      color: ${Colors.white};
      width: 75px;
    }
    .column-picker-controls {
      border-top: ${defaultborder};
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }

    .column-picker-tabs {
      flex: 1;
      overflow: hidden;
      padding: 1.5rem 0 0;

      .tabs-content {
        flex: 1;
        overflow: auto;
      }

      & > .topbar {
        align-items: end;
        min-height: auto;
        margin: 0;
        padding: 0 21px;
        border-bottom: ${defaultborder};
        border-top: none;

        .tab-label:focus {
          outline: none;
          color: ${Colors.navy} !important;
        }

        & > div {
          ul {
            padding: 0;

            li {
              height: inherit !important;
              margin: 0 !important;
              padding: 0 16px 9px !important;
            }
          }
        }
      }
    }
  }
`;

/** The default icon and copy for categories in the Column Picker and assigned to fields based on their data type. this has been standardized in ⚡ZAP™. */
export const defaultCategoriesInfo: CategoriesInfo = {
  appCatalog: {
    category: 'App Catalog',
    icon: 'IconVersions',
    tooltipText: 'Fields derived from your company’s App Catalog.',
  },
  budget: {
    category: 'Budget',
    icon: 'IconReportMoney',
    tooltipText: 'Fields specific to your company’s budget information.',
  },
  custom: {
    category: 'Custom Fields',
    icon: 'IconCircleLetterZ',
    tooltipText: 'Custom fields added and maintained by Zylo Admins.',
  },
  events: {
    category: 'Events',
    icon: 'IconCalendarEvent',
    tooltipText: 'Fields for both custom and Zylo created savings events.',
  },
  file: {
    category: 'Primary Contract',
    icon: 'IconFile',
    tooltipText: 'Fields from Primary Contract in Subscription Details.',
  },
  financial: {
    category: 'Financial',
    icon: 'IconCurrencyDollar',
    tooltipText:
      'SaaS spend metrics calculated from connected financial integrations or uploaded financial data.',
  },
  fiscal: {
    category: 'Fiscal Year',
    icon: 'IconCurrencyDollar',
    tooltipText: "SaaS spend metrics specific to your company's fiscal year settings.",
  },
  input: {
    category: 'User Input',
    icon: 'IconPencil',
    tooltipText: 'Fields manually input by Zylo users.',
  },
  metric: {
    category: 'Metric',
    icon: 'IconCircleLetterZ',
    tooltipText: 'Details about user activities, assigned from integration information.',
  },
  owner: {
    category: 'Owners',
    icon: 'IconUsers',
    tooltipText: 'Owner fields manually input by Zylo users',
  },
  purchaseOrder: {
    category: 'Purchase Orders',
    icon: 'IconJumpRope',
    tooltipText: 'Fields from the Purchase Orders related to the Subscription.',
  },
  security: {
    category: 'Security',
    icon: 'IconShieldLock',
    tooltipText: 'Risk and Compliance fields automatically populated within the Zylo platform.',
  },
  singleSignOn: {
    category: 'Single Sign-On',
    icon: 'IconCloudLock',
    tooltipText: 'Fields from applications from your Single Sign-On integration.',
  },
  usage: {
    category: 'Usage',
    icon: 'IconChartBar',
    tooltipText:
      'User activity metrics calculated from connected SSO or Direct Utilization integrations',
  },
  user: {
    category: 'User',
    icon: 'IconCircleLetterZ',
    tooltipText: 'Details about users, assigned from integration information.',
  },
  zylo: {
    category: 'Zylo',
    icon: 'IconCircleLetterZ',
    tooltipText: 'Fields automatically tracked by Zylo’s platform.',
  },
};

export type ColumnPickerProps = {
  /** Default standardized values are provided (based on the ⚡ZAP™ Framework) and
   * should rarely need to be overriden. When providing overrides only values that
   * you wish to override need to be provided they will be merged with the default
   * values. Icons will only appear in the Column Picker if the associated data
   * types are present in the metadata.
   */
  categoriesInfo?: CategoriesInfo | never;
  implementationName: string;
};

export default function ColumnPicker(props: ColumnPickerProps) {
  const { implementationName } = props;
  const { closeColumnPicker, isSidebarActive, isSidebarVisible, ...displayLayerProps } =
    useDataLayer({
      implementationName,
    });
  const portalTarget = usePortalTarget();

  const supportButton: HTMLElement | null = document.getElementById('launcher');

  if (supportButton) {
    supportButton.style.display = isSidebarVisible ? 'none' : 'block';
  }

  return (
    <ColumnPickerSidebar
      handleClose={closeColumnPicker}
      isActive={isSidebarActive}
      portalTarget={portalTarget}
      clickOutsideToClose
      hideCloseButton
    >
      {isSidebarVisible && (
        <ColumnPicker_DisplayLayer
          {...props}
          {...displayLayerProps}
          closeColumnPicker={closeColumnPicker}
        />
      )}
    </ColumnPickerSidebar>
  );
}

export type ColumnPickerDisplayLayerProps = ColumnPickerProps &
  Omit<UseDataLayerValues, 'isSidebarActive' | 'isSidebarVisible'>;

export function ColumnPicker_DisplayLayer({
  closeColumnPicker,
  categoriesInfo = {},
  implementationName,
}: ColumnPickerDisplayLayerProps) {
  return (
    <section className="column-picker">
      <Tabs className="column-picker-tabs" selected="sortFields" isDetails>
        <TabPane label="Sort Fields" tabId="sortFields">
          <SortFields />
        </TabPane>

        <TabPane label="Choose Fields" tabId="chooseFields">
          {implementationName === 'Users_COLUMN_PICKER' ? (
            <AppUsersChooseFields />
          ) : (
            <ChooseFields categoriesInfo={{ ...defaultCategoriesInfo, ...categoriesInfo }} />
          )}
        </TabPane>
      </Tabs>
      <div className="column-picker-controls">
        <Button
          className="column-picker-close-button"
          handleClick={closeColumnPicker}
          text="Close"
          type="primary"
        />
      </div>
    </section>
  );
}

type UseDataLayerValues = {
  closeColumnPicker(): void;
  isSidebarActive: boolean;
  isSidebarVisible: boolean;
};

function useDataLayer({ implementationName }: { implementationName: string }): UseDataLayerValues {
  const activeSidebars = useSidebarState();
  const { hideSidebar } = useSidebarUpdate();

  const isSidebarVisible = useSidebarVisibilitySensor({
    sidebarName: implementationName,
  });

  const closeColumnPicker = useCallback(() => {
    hideSidebar(implementationName);
  }, [hideSidebar, implementationName]);

  return {
    closeColumnPicker,
    isSidebarActive: activeSidebars.includes(implementationName),
    isSidebarVisible,
  };
}
