import { navigate } from '@reach/router';
import { useAppDetailsCompanyAppId } from './useAppDetailsCompanyAppId';

/* If the click thru originates in app details (either full or quick view), the
   companyAppId prop is not necessary.  If the click thru originates outside of app details,
   then the companyAppId prop is required */

export type AppDetailsClickThru = (clickThru: string, companyAppId?: string) => void;

export function useAppDetailsClickThru(): AppDetailsClickThru {
  const appDetailsCompanyAppId = useAppDetailsCompanyAppId();

  return (clickThru, companyAppId) => {
    const originatesFromQuickView = !!document.querySelector('.app-details-quick-view');

    // click originates outside of app details
    if (!appDetailsCompanyAppId) {
      navigate(`/subscriptions/${companyAppId}/${clickThru}`);
      // click originates in app details quick view
    } else if (originatesFromQuickView) {
      window.open(`/subscriptions/${appDetailsCompanyAppId}/${clickThru}`, '_blank');
      // click originates in app details full view
    } else {
      navigate(`/subscriptions/${appDetailsCompanyAppId}/${clickThru}`);
    }
  };
}
