import { useCallback } from 'react';
import { useFiltersState } from '../../../contexts/filtersContext';
import { useSavedFiltersListUpdate } from '../../../contexts/savedFilterContexts/savedFiltersListContext';
import { useAppDetailsCompanyAppId, useAppMessaging, useEventTracking } from '../../../hooks';
import { putData } from '../../../utilities/xhr';

export default function useChangeDefaultSavedFilter() {
  const { apiRequestFilters } = useFiltersState();
  const { fetchSavedFilters } = useSavedFiltersListUpdate();
  const showMessage = useAppMessaging();
  const sendEvent = useEventTracking();
  const companyAppId = useAppDetailsCompanyAppId();

  const changeDefaultSavedFilter = useCallback(
    (defaultSavedFilter) => {
      const {
        columnsOrder,
        defaultFilter,
        filters,
        filterName,
        id: savedFilterId,
        tableSort,
        teamFilter,
      } = defaultSavedFilter;

      putData({
        path: `companies/$companyId/savedFilter/${savedFilterId}`,
        params: {
          apiRequestFilters,
          columnsOrder,
          companyAppId,
          defaultFilter: !defaultFilter,
          displayFilters: JSON.stringify(filters),
          filterName,
          isUpdatingDefault: true,
          tableSort,
          teamFilter,
        },
      })
        .then(() => {
          fetchSavedFilters();

          showMessage({ message: 'Your default table view has been updated.' });

          sendEvent({
            eventName: 'DEFAULT_SAVED_FILTER_CHANGE',
            data: { defaultSavedFilter },
          });
        })
        .catch(({ message }) => {
          showMessage({ message, isError: true });
        });
    },
    [apiRequestFilters, companyAppId, fetchSavedFilters, sendEvent, showMessage],
  );

  return changeDefaultSavedFilter;
}
