import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { intersection } from 'lodash-es';
import { Tooltip, ZyloTag } from '@zylo/orchestra';
import { useFiltersState, useFiltersUpdate } from '@contexts/filtersContext';
import {
  STATIC_FILTER_OPTIONS,
  STATIC_FILTER_NULL_OPTIONS,
} from '@utilities/filterUtils/filterConstants';
import humanizeFilterValue from '@utilities/filterUtils/humanizeFilterValue';
import { useFiscalData } from '@hooks';
import { NullUXValues } from '@typings';

function FiltersDisplay({ frozenKeys, readOnly }) {
  const { filterMap, selectedFilters } = useFiltersState();
  const [filterPills, setFilterPills] = useState([]);
  const includeFiscal = useFiscalData();
  const { handleFilterChange } = useFiltersUpdate();

  useEffect(() => {
    if (!filterMap || !selectedFilters) return;

    function wrapInTooltip(markup, hasMultipleValues, index) {
      return (
        <Tooltip
          key={`filter-tooltip-${index}`}
          text={`${
            hasMultipleValues ? 'These filter values are' : 'This filter value is'
          } invalid. Please remove ${
            hasMultipleValues ? 'them' : 'it'
          } and, if necessary, attempt to reselect ${
            hasMultipleValues ? 'them' : 'it'
          } in the Filters menu.`}
        >
          {markup}
        </Tooltip>
      );
    }

    function renderFilterValue(key, type, subtype, value, isInvalid) {
      return (
        <ZyloTag
          className={classNames('flex-container filters-display-value', isInvalid && 'is-invalid')}
          color="primary"
          data-testid="filters-display-value"
          key={`${key}-${value}`}
          /* subtype has been deprecated when using the ⚡ZAP™. If this is the case type is passed 
                 as the first and second argument of humanizeFilterValue. Currently, some filters may 
                 still use subtype, so we check to see if it exists. 05/19/2022 */
          label={humanizeFilterValue(type, subtype ? subtype : type, value)}
          onDelete={
            readOnly || frozenKeys?.includes(key) ? undefined : () => removeFilterValue(key, value)
          }
        />
      );
    }

    function removeFilterValue(filterKey, filterValue) {
      const currentFilters = selectedFilters[filterKey];
      let filterUpdate = [];
      if (Array.isArray(currentFilters) && currentFilters.length > 1) {
        currentFilters.splice(currentFilters.indexOf(filterValue), 1);
        filterUpdate = currentFilters;
      }
      handleFilterChange(filterKey, filterUpdate);
    }

    function validateDateValue(val) {
      const fiscalFilterValues = [
        ...STATIC_FILTER_OPTIONS.fiscalQuarters.map((fq) => fq.value),
        ...STATIC_FILTER_OPTIONS.fiscalYears.map((fy) => fy.value),
      ];
      if (!includeFiscal && fiscalFilterValues.includes(val)) {
        return false;
      }

      if (isNullValue(val)) return true;

      return typeof val === 'string' && val.split(':').length >= 2;
    }

    function validateNumberValue(val) {
      if (isNullValue(val)) return true;

      return typeof val === 'string' && val.split(':').length === 4;
    }

    function isNullValue(val) {
      const nullValues = STATIC_FILTER_NULL_OPTIONS;
      return nullValues.includes(val);
    }

    const filtersMarkup = Object.entries(selectedFilters).map(
      ([filterType, filterValues], index) => {
        const associatedFilter = filterMap[filterType];
        const {
          displayKey = 'Invalid Filter',
          key = filterType,
          subtype,
          type = 'string',
        } = associatedFilter || {};
        const filterValuesIsArray = Array.isArray(filterValues);
        const filterHasMultipleValues = filterValuesIsArray && filterValues.length > 1;
        const allowedBooleanValues = ['Yes', 'No', NullUXValues.NULL, NullUXValues.EXISTS];
        let hasInvalidFilterValues = false;

        if (type === 'boolean') {
          const intersectedValues = intersection(allowedBooleanValues, filterValues);

          if (!filterValuesIsArray || filterValues.length > intersectedValues.length) {
            hasInvalidFilterValues = true;
          }
        } else if (type === 'date') {
          if (filterValuesIsArray) {
            filterValues
              .filter(
                (val) =>
                  !Object.values(STATIC_FILTER_OPTIONS.dateRangeBuckets)
                    .map(({ value }) => value)
                    .includes(val),
              )
              .forEach((val) => {
                hasInvalidFilterValues = !validateDateValue(val);
              });
          } else {
            hasInvalidFilterValues = !validateDateValue(filterValues);
          }
        } else if (type === 'number') {
          hasInvalidFilterValues = !validateNumberValue(filterValues);
        } else if (type === 'string') {
          if (!Array.isArray(filterValues)) {
            hasInvalidFilterValues = true;
          }
        }

        const filtersDisplayGroup = (
          <div
            className={classNames(
              'flex-container align-center filters-display-group',
              (!associatedFilter || hasInvalidFilterValues) && 'invalid-filter',
            )}
            key={key}
          >
            {index > 0 && <span className="filters-display-separator">and</span>}
            <span className="filters-display-type">{`${
              hasInvalidFilterValues
                ? displayKey === 'Invalid Filter'
                  ? displayKey
                  : 'Invalid Filter - ' + displayKey
                : displayKey
            }:`}</span>
            <div className="flex-container wrap-normal filters-display-values-container">
              {filterValuesIsArray
                ? filterValues.map((value) =>
                    renderFilterValue(
                      key,
                      type,
                      subtype,
                      value,
                      !associatedFilter || hasInvalidFilterValues,
                    ),
                  )
                : renderFilterValue(
                    key,
                    type,
                    subtype,
                    filterValues,
                    !associatedFilter || hasInvalidFilterValues,
                  )}
            </div>
          </div>
        );

        return associatedFilter && !hasInvalidFilterValues
          ? filtersDisplayGroup
          : wrapInTooltip(filtersDisplayGroup, filterHasMultipleValues, index);
      },
    );

    setFilterPills(filtersMarkup);
  }, [filterMap, handleFilterChange, readOnly, selectedFilters, frozenKeys, includeFiscal]);

  return (
    <div
      className={classNames(
        'filters-display',
        selectedFilters && !Object.keys(selectedFilters).length && 'no-filters',
      )}
      data-testid="filters-display"
    >
      <div className="flex-container wrap-normal filters-display-transition-container">
        {filterMap && selectedFilters && filterPills}
      </div>
    </div>
  );
}

FiltersDisplay.propTypes = {
  frozenKeys: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
};

export default FiltersDisplay;
