import { useMutation, useQueryClient } from 'react-query';
import { useAppMessaging } from '@hooks';
import { putData } from '@src/utilities/xhr';
import { useInvalidateZapQueries } from '@frameworks/zap';
import { NewOrUpdatedSavingsEvent } from '@pages/insightsWorkbench/savingsEvents/types';

export type UpdateSavingsEventArgs = {
  event: NewOrUpdatedSavingsEvent;
  eventId?: string;
};

async function updateSavingsEvent({ event, eventId }: UpdateSavingsEventArgs) {
  const response = await putData({
    path: `savingsCenter/companies/$companyId/events/${eventId}`,
    params: event,
  });
  return response;
}

export function useUpdateSavingsEvent() {
  const showMessage = useAppMessaging();
  const invalidateZapQueries = useInvalidateZapQueries();
  const queryClient = useQueryClient();

  return useMutation(
    ({ event, eventId }: UpdateSavingsEventArgs) => updateSavingsEvent({ event, eventId }),
    {
      onSuccess: async ({ body }) => {
        const { id: eventId } = body;
        invalidateZapQueries('savingsCenter');
        await queryClient.invalidateQueries(['savings-center', 'events', 'getEvent', eventId]);
        await queryClient.refetchQueries(['savings-center', 'events', 'getEvent', eventId]);
        showMessage({ message: body.message });
      },
      onError: () =>
        showMessage({
          message: 'There was an error updating the event. Please try again.',
          isError: true,
        }),
    },
  );
}
