import { get } from 'lodash-es';

// returns a new array of sorted objects
export default function sortArrayOfObjects(sort, array) {
  if (!sort || !array || sort.length <= 0 || array.length <= 0) {
    return array || null;
  }

  const property = sort.slice(1);
  const value = get(array[0], property);
  if (value === null || value === undefined) {
    return array;
  }

  const isDescending = sort.charAt(0) === '-';
  const sortFunc = (a, b) => {
    const aValue = get(a, property);
    const bValue = get(b, property);

    if (aValue > bValue) {
      return isDescending ? -1 : 1;
    }
    if (aValue < bValue) {
      return isDescending ? 1 : -1;
    }
    return 0;
  };

  return [...array].sort(sortFunc);
}
