import PropTypes from 'prop-types';
import classNames from 'clsx';

function InputGroup({ prepend, postpend, children, className, disabled = false }) {
  if (!prepend && !postpend) {
    return children;
  }

  return (
    <div className={classNames('input-group', className, disabled && 'disabled')}>
      {prepend && (
        <div className="input-group-prepend">
          <div className="input-group-content">{prepend}</div>
        </div>
      )}
      <div className="input-group-children">{children}</div>
      {postpend && (
        <div className="input-group-postpend">
          <div className="input-group-content">{postpend}</div>
        </div>
      )}
    </div>
  );
}

InputGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  postpend: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  prepend: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default InputGroup;
