import { useState } from 'react';
import styled from 'styled-components/macro';
import DynamicCombobox from '@components/forms/inputs/DynamicCombobox';
import useDynamicSearchOption, { IDynamicFilterOptions } from '@hooks/useDynamicSearchOption';

const StyledDynamicCombobox = styled(DynamicCombobox)`
  /* this is here because of the Cancel/Confirm buttons, if removed the user can click apply and
      it won't apply because the options from the dropdown shift the buttons upwards */
  .dropdown-container {
    min-height: 242px;
  }
`;

export type SearchFilterOptionsResponse = {
  body: IDynamicFilterOptions;
};

type SearchFilterOptionsProps = {
  path: string;
  pathKey: string;
  onChange: (values: string[]) => void;
  selectedValues: string[];
};

export function SearchFilterOptions(props: SearchFilterOptionsProps) {
  /* This wrapping component is provided to ensure `SearchFilterOptions` is unmounted when switching between filters. */
  return props.pathKey ? <SearchFilterOptions_DisplayLayer {...props} /> : null;
}

function SearchFilterOptions_DisplayLayer({
  path,
  pathKey,
  onChange,
  selectedValues,
}: SearchFilterOptionsProps) {
  const [search, setSearch] = useState('');
  /* When `getIntialOptions` is set false this ensures that the minimum search length is honored. */
  const [isIntialFetchEnabled, setIsIntialFetchEnabled] = useState(true);

  const { data, needMoreInput, isLoading } = useDynamicSearchOption({
    enabled: isIntialFetchEnabled || search.length >= 2,
    params: {
      search,
    },
    path: `${path}/${pathKey}`,
    onSuccess: () => setIsIntialFetchEnabled(false),
    responseHandler: (response: SearchFilterOptionsResponse) => response.body,
  });

  const options = data?.options || [];
  const hasMore = data?.hasMore || false;

  return (
    <StyledDynamicCombobox
      handleSelection={onChange}
      hasMore={hasMore}
      isLoading={isLoading}
      needMoreInput={needMoreInput}
      options={options}
      selectedValues={selectedValues}
      updateSearchString={setSearch}
    />
  );
}
