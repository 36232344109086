import { get } from 'lodash-es';
import { useFiltersState } from '@contexts/filtersContext';
import DateFilter from '@components/filters/filterTypes/DateFilter';
import DateRangeBucketsFilter from '@components/filters/filterTypes/DateRangeBucketsFilter';
import FiltersMenu from '@components/filters/FiltersMenu';
import MultiSelect from '@components/forms/inputs/MultiSelect';
import NumberFilter from '@components/filters/filterTypes/NumberFilter';
import { FilterTypes, IJsonFilters, IMetadataField } from '@typings';
import { STATIC_FILTER_OPTIONS } from '@utilities/filterUtils';
import { SearchFilterOptions } from './SearchFilterOptions';

export type ZAPFiltersMenuProps = {
  filterApplyEventName?: string;
  /** Temporary prop for backwards compatiblity for non ⚡️ZAP™ consumers of the component. */
  path: string;
};
/** This component is intended for internal use in the ZAP component. Do not use this component directly. */
export function ZAPFiltersMenu({ filterApplyEventName, path }: ZAPFiltersMenuProps) {
  return (
    <ZAPFiltersMenu_DisplayLayer
      filterApplyEventName={filterApplyEventName}
      {...useDataLayer(path)}
    />
  );
}

type ZAPFiltersMenuDisplayLayerProps = UseDataLayerValues &
  Pick<ZAPFiltersMenuProps, 'filterApplyEventName'>;

export function ZAPFiltersMenu_DisplayLayer({
  filterApplyEventName,
  searchFilterOptionsPath,
  selectedFilters,
}: ZAPFiltersMenuDisplayLayerProps) {
  return (
    <FiltersMenu filterApplyEventName={filterApplyEventName}>
      {({ reportFilterChange, selectedFilterType, stagedFilterValue }) => {
        const { dataType, editProperties, key, type } = selectedFilterType as IMetadataField;
        const selectedValue = get(selectedFilters, key);

        function handleFilterOptionChange(value: string[]) {
          reportFilterChange(value);
        }

        if (type === FilterTypes.boolean) {
          return (
            <MultiSelect
              name={key}
              // @ts-ignore MultiSelect needs to be properly typed
              onChange={handleFilterOptionChange}
              options={STATIC_FILTER_OPTIONS.boolean}
              // @ts-ignore MultiSelect needs to be properly typed
              value={selectedValue}
            />
          );
        }
        if (type === FilterTypes.date) {
          return key === 'lastActivityDate' ? (
            <DateRangeBucketsFilter
              name={key}
              onChange={handleFilterOptionChange}
              // @ts-ignore DateRangeBucketsFilter needs to be properly typed
              value={selectedValue}
            />
          ) : (
            <DateFilter
              name={key}
              onChange={handleFilterOptionChange}
              // @ts-ignore DateRangeBucketsFilter needs to be properly typed
              value={selectedValue}
            />
          );
        }

        if (
          type === FilterTypes.currency ||
          type === FilterTypes.number ||
          type === FilterTypes.percentage
        ) {
          return (
            <NumberFilter
              name={key}
              onChange={handleFilterOptionChange}
              // subtype is not availble in the metadata when using ⚡ZAP™. So the type property is passed here.
              subtype={type}
              // @ts-ignore NumberFilter needs to be properly typed
              value={selectedValue}
            />
          );
        }
        // This case is for custom boolean fields, as these are returned as type: "string" since the values are stored as strings in the db. It is also for custom dropdown fields, since we need to provide those options from the metadata.
        if (
          type === FilterTypes.string &&
          dataType === 'custom' &&
          editProperties?.inputType === 'select'
        ) {
          return (
            <MultiSelect
              name={key}
              // @ts-ignore MultiSelect needs to be properly typed
              onChange={handleFilterOptionChange}
              options={mapSelectOptions(editProperties?.selectOptions)}
              // @ts-ignore MultiSelect needs to be properly typed
              value={selectedValue}
            />
          );
        }
        return (
          <SearchFilterOptions
            onChange={handleFilterOptionChange}
            path={searchFilterOptionsPath}
            pathKey={key}
            selectedValues={stagedFilterValue || selectedValue}
          />
        );
      }}
    </FiltersMenu>
  );
}

type UseDataLayerValues = {
  searchFilterOptionsPath: string;
  selectedFilters: IJsonFilters;
};

function useDataLayer(path: string): UseDataLayerValues {
  const { selectedFilters } = useFiltersState();

  return {
    searchFilterOptionsPath: path,
    selectedFilters,
  };
}

function mapSelectOptions(options?: any[]) {
  if (!options) {
    return;
  }
  return options.map((option) => ({ label: option.label, name: option.value }));
}
