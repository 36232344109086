import { useState } from 'react';
import useDynamicSearchOption, { IDynamicFilterOptions } from '@hooks/useDynamicSearchOption';

export type UseBulkEditSearchOptionsArgs = {
  addBlank?: boolean;
  editSlug: string;
  minSearchLength?: number;
  ZAPName?: string;
};

export function useBulkEditSearchOptions({
  addBlank = false,
  editSlug,
  minSearchLength = 3,
  ZAPName,
}: UseBulkEditSearchOptionsArgs) {
  const [search, setSearch] = useState('');

  const { data, isLoading } = useDynamicSearchOption({
    enabled: !!ZAPName && search.length >= minSearchLength,
    params: { search, limit: 10 },
    path: `companies/$companyId/zap/${ZAPName}/editOptions/${editSlug}`,
    responseHandler: (response: { body: IDynamicFilterOptions }) => response.body,
  });

  function handleSearch(term: string) {
    setSearch(term);
  }

  const { options = [] } = data ?? {};
  const adjustedOptions = options;
  const blankOption = { label: 'Blank', value: 'blank' };
  if (addBlank && !adjustedOptions.some((option) => option.label === 'Blank')) {
    adjustedOptions.unshift(blankOption);
  }

  return { handleSearch, isLoading, options: adjustedOptions };
}
