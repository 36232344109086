import { updateValue } from '@utilities/localStorageHelpers';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

// Exporting the query client to give areas outside of the providers direct access to the cache.
export const mgQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
    },
  },
});

export function clearCurrentSessionData() {
  mgQueryClient.clear();
  updateValue('confirmNavigation', undefined);
}

export function AppQueryClient({ children }: { children: ReactNode }) {
  return <QueryClientProvider client={mgQueryClient}>{children}</QueryClientProvider>;
}
