const minimumCoreAccessRoles = [
  'apps',
  'apps-read-only',
  'charges',
  'charges-read-only',
  'users',
  'users-read-only',
];

const rolesLabelMapping = {
  // legacy roles
  user: 'User',
  'read-only': 'Read-Only',
  admin: 'Admin',

  // advanced roles
  apps: 'Subscriptions',
  'apps-read-only': 'Subscriptions View-Only',
  charges: 'Payments',
  'charges-read-only': 'Payments View-Only',
  contracts: 'Contracts',
  'contracts-read-only': 'Contracts',
  users: 'Team',
  'users-read-only': 'Team View-Only',
  integrations: 'Integrations',
  'integrations-read-only': 'Integrations View-Only',
  'admin-panel': 'Admin',
  'app-catalog-admin': 'App Catalog Admin',
  uploads: 'Payment Upload',
  // super-admin role
  'super-admin': 'Super Admin',
};

const rolesSummaryMapping = {
  apps: 'Subscriptions',
  'apps-read-only': 'Subscriptions',
  charges: 'Payments',
  'charges-read-only': 'Payments',
  contracts: 'Contracts',
  'contracts-read-only': 'Contracts',
  users: 'Team',
  'users-read-only': 'Team',
  integrations: 'Integrations',
  'integrations-read-only': 'Integrations',
  'admin-panel': 'Admin',
  'app-catalog-admin': 'App Catalog Admin',
  uploads: 'Payment Upload',
};

// converts an array of roles from advanced to legacy values for display purposes
function convertRoles(roles: string[] = []) {
  let result = ['user'];

  if (roles.includes('admin-panel')) {
    result = ['admin'];
  } else if (roles.includes('apps-read-only')) {
    result = ['read-only'];
  }

  return result;
}

export { minimumCoreAccessRoles, rolesLabelMapping, rolesSummaryMapping, convertRoles };
