import { useEffect, useRef } from 'react';
import { useLocation, globalHistory, useNavigate } from '@reach/router';
import { useEventTracking } from '@hooks';
import { getValue, storeValue, updateValue } from '@utilities/localStorageHelpers';

// This is a temporary measure. There are plans to update 'auth'. The key
// 'confirmNavigation' must be kept in sync with the key in 'auth.js'.
const key = 'confirmNavigation';

export type ConfirmNavigationVariables = {
  cancelEventName?: string;
  confirmationEventName?: string;
  initialState?: boolean;
};

/**
 * Provides a function to set (`true` | `false`) whether a confirmation alert should be shown
 * when navigating away from the current path. `initialState` can be set to confirm navigation
 * change on initial load.
 * */
export function useConfirmNavigation({
  cancelEventName,
  confirmationEventName,
  initialState,
}: ConfirmNavigationVariables = {}) {
  const curLocation = useLocation();
  const navigate = useNavigate();
  const sendEvent = useEventTracking();
  const confirmNavigationRef = useRef<boolean>();

  function setShouldConfirmNavigation(value: boolean) {
    if (value && confirmNavigationRef.current !== value) {
      storeValue(key, value);
      confirmNavigationRef.current = value;
    }
    // If no initial value is provided this will always run on first load.
    // This is important to ensure that any previous value is removed from
    // local storage. This can happen when the tab/window is closed instead
    // of navigating away from the page.
    if (!value && confirmNavigationRef.current !== value) {
      updateValue(key, undefined);
      confirmNavigationRef.current = value;
    }
  }

  // protects against refresh, external page load, closing
  window.onbeforeunload = function () {
    if (getValue(key)) {
      return '';
    }
  };

  useEffect(() => {
    setShouldConfirmNavigation(Boolean(initialState));

    return globalHistory.listen(({ action, location }) => {
      if (
        getValue(key) &&
        ((action === 'PUSH' && !location.pathname.includes(curLocation.pathname)) ||
          action === 'POP')
      ) {
        if (
          !window.confirm(
            "You're about to leave this page and any updates you've made to this template will be lost.",
          )
        ) {
          navigate(curLocation.pathname + curLocation.search);

          if (cancelEventName) {
            sendEvent({ eventName: cancelEventName });
          }
        } else {
          setShouldConfirmNavigation(false);

          if (confirmationEventName) {
            sendEvent({ eventName: confirmationEventName });
          }
        }
      }
    });
  }, [
    cancelEventName,
    confirmationEventName,
    curLocation.pathname,
    curLocation.search,
    initialState,
    navigate,
    sendEvent,
  ]);

  return { setShouldConfirmNavigation };
}
