import { Fragment, useState } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '@zylo/orchestra';
import { CategoriesInfo, FieldDataTypes } from '@typings';
import SearchBar from '../forms/SearchBar';
import { useColumnsState, useColumnsUpdate } from '../../contexts/columnsContext';
import ChooseFieldsCategoryField from './ChooseFieldsCategoryFields';
import ChooseFieldsCategoryTitle from './ChooseFieldsCategoryTitle';
import { ColumnPickerField, ColumnPickerFieldArray, ColumnsState } from './typings/types';

export const ChooseFieldsStyles = styled.div`
  width: 100%;
  padding: 0;
  background: ${Colors.white};

  .choose-fields-search-bar {
    padding: 1.5rem;

    .search-filter-container {
      min-width: 100px;
      width: 100%;
    }
  }

  .choose-fields-scroll-container {
    background: transparent;
    overflow: auto;
    position: relative;
  }
`;

type ChooseFieldsProps = { categoriesInfo: CategoriesInfo };

export default function ChooseFields(props: ChooseFieldsProps) {
  return <ChooseFields_DisplayLayer {...props} {...useDataLayer()} />;
}

type FieldUpdateKeys = ColumnPickerField['fieldUpdateKeys'];
export type ChooseFieldsDisplayLayerProps = ChooseFieldsProps & UseDataLayerValues;

export function ChooseFields_DisplayLayer({
  categoriesInfo,
  columns,
  frozenColumns,
  setColumns,
}: ChooseFieldsDisplayLayerProps) {
  const [fields, setFields] = useState(() => buildFieldsObject(columns, frozenColumns));

  const [searchValue, setSearchValue] = useState('');

  function handleClearSearch() {
    setFields(buildFieldsObject(columns, frozenColumns));
  }

  function handleSearch(searchTerm: string) {
    const { activeColumns, freezeColumns, inactiveColumns = [] } = columns;

    setSearchValue(searchTerm);

    setFields(
      buildFieldsObject(
        {
          freezeColumns,
          activeColumns: activeColumns.filter(({ displayName }: ColumnPickerField) =>
            displayName.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
          inactiveColumns: inactiveColumns.filter(({ displayName }: ColumnPickerField) =>
            displayName.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        },
        frozenColumns,
      ),
    );
  }

  function handleChooseField(fieldUpdateKeys: FieldUpdateKeys, index: number) {
    const { activeColumns, freezeColumns, inactiveColumns = [] } = columns;
    const [list] = fieldUpdateKeys;
    const field = fields[list][index];
    const updatedField = { ...field, isFrozen: false, isInactive: !field.isInactive };

    setFields((previousState) => {
      previousState[list].splice(index, 1, updatedField);
      return previousState;
    });

    if (field.isInactive) {
      const columnsUpdate = {
        freezeColumns,
        activeColumns: [...activeColumns, updatedField],
        inactiveColumns: inactiveColumns.filter(
          (column: ColumnPickerField) => !(field.displayName === column.displayName),
        ),
      };

      setColumns(columnsUpdate);
    }

    if (!field.isInactive) {
      const columnsUpdate = {
        activeColumns: activeColumns.filter(
          (column: ColumnPickerField) => !(field.displayName === column.displayName),
        ),
        freezeColumns: freezeColumns.filter(
          (column: ColumnPickerField) => !(field.displayName === column.displayName),
        ),
        inactiveColumns: [...inactiveColumns, updatedField],
      };

      setColumns(columnsUpdate);
    }
  }

  return (
    <ChooseFieldsStyles>
      <div className="choose-fields-search-bar">
        <SearchBar
          clearSearch={handleClearSearch}
          handleSearch={handleSearch}
          placeholder="Search Fields"
          searchOnChange={true}
          searchValue={searchValue}
        />
      </div>

      <div className="choose-fields-scroll-container">
        {Object.entries(fields).map(([dataType, fields]) => {
          const categoryInfo = categoriesInfo[dataType as FieldDataTypes];
          return (
            <Fragment key={dataType}>
              <ChooseFieldsCategoryTitle
                category={categoryInfo?.category ?? ''}
                icon={categoryInfo?.icon}
                tooltipText={categoryInfo?.tooltipText}
              />
              <ChooseFieldsCategoryField fields={fields} onChooseFields={handleChooseField} />
            </Fragment>
          );
        })}
      </div>
    </ChooseFieldsStyles>
  );
}

type UseDataLayerValues = {
  columns: ColumnsState;
  frozenColumns: string[];
  setColumns(columns: ColumnsState): void;
};

function useDataLayer(): UseDataLayerValues {
  const { columns, frozenColumns } = useColumnsState();
  const { setColumns } = useColumnsUpdate();

  return {
    columns,
    frozenColumns,
    setColumns,
  };
}

function buildFieldsObject(
  { activeColumns, inactiveColumns = [] }: ColumnsState,
  frozenColumns: string[],
) {
  return [
    ...activeColumns.map((column) => ({ ...column, isInactive: false })),
    ...inactiveColumns.map((column) => ({ ...column, isInactive: true })),
  ]
    .map((column: ColumnPickerField) =>
      frozenColumns.includes(column.columnName)
        ? { ...column, isFrozen: true }
        : { ...column, isFrozen: false },
    )
    .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
    .reduce(handleBuildFieldsObject, {});
}

function handleBuildFieldsObject(
  previousValue: { [key: string]: ColumnPickerFieldArray },
  currentValue: ColumnPickerField,
): { [key: string]: ColumnPickerFieldArray } {
  return {
    ...previousValue,
    [currentValue.dataType]: [
      ...(previousValue[currentValue.dataType] ?? []),
      { ...currentValue, fieldUpdateKeys: [currentValue.dataType] },
    ],
  };
}
