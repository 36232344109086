import { useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { getData } from '../utilities/xhr';

type FetchCompanyAppsParams = {
  search?: string;
  sort?: string;
  limit?: number;
};

async function fetchCompanyApps(params: FetchCompanyAppsParams) {
  const { body } = await getData({
    path: 'companies/$companyId/zap/companyApps',
    params,
  });

  return body;
}

export type UseCompanyAppsSearchValues = {
  searchCompanyApps: (search: string) => void;
  queryData: UseQueryResult<any, unknown>;
};

export function useCompanyAppsSearch({
  limit = 50,
  sort = '+appLabel',
}: {
  limit?: number;
  sort?: string;
}): UseCompanyAppsSearchValues {
  const [search, setSearch] = useState('');
  const queryData = useQuery(['fetchCompanyApps', search], () =>
    fetchCompanyApps({ search, sort, limit }),
  );

  return { searchCompanyApps: setSearch, queryData };
}
