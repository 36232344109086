import PropTypes from 'prop-types';
import { useImperativeHandle } from 'react';
import { ActiveSavedFilterProvider } from '../../contexts/savedFilterContexts/activeSavedFilterContext';
import { DefaultTableViewProvider } from '../../contexts/savedFilterContexts/defaultTableViewContext';
import { SavedFilterModificationProvider } from '../../contexts/savedFilterContexts/savedFilterModificationContext';
import { SavedFiltersTypeProvider } from '../../contexts/savedFilterContexts/savedFiltersTypeContext';
import { SavedFiltersListProvider } from '../../contexts/savedFilterContexts/savedFiltersListContext';
import { SAVED_FILTERS_TYPES } from './utilities/savedFiltersConstants';
import { useUpdateSavedFilter } from './hooks';

function SavedFilterUpdater({ children, savedFilterStateRef }) {
  const updateSavedFilter = useUpdateSavedFilter();

  useImperativeHandle(
    savedFilterStateRef,
    () => ({
      updateSavedFilter,
    }),
    [updateSavedFilter],
  );

  return children;
}

export default function SavedFiltersProviders({
  children,
  companyAppId,
  savedFilterStateRef,
  savedFiltersType,
}) {
  return (
    <SavedFiltersTypeProvider savedFiltersType={savedFiltersType}>
      <SavedFiltersListProvider companyAppId={companyAppId}>
        <DefaultTableViewProvider>
          <ActiveSavedFilterProvider savedFilterStateRef={savedFilterStateRef}>
            <SavedFilterModificationProvider savedFilterStateRef={savedFilterStateRef}>
              <SavedFilterUpdater savedFilterStateRef={savedFilterStateRef}>
                {children}
              </SavedFilterUpdater>
            </SavedFilterModificationProvider>
          </ActiveSavedFilterProvider>
        </DefaultTableViewProvider>
      </SavedFiltersListProvider>
    </SavedFiltersTypeProvider>
  );
}

SavedFiltersProviders.propTypes = {
  children: PropTypes.any,
  companyAppId: PropTypes.string,
  savedFiltersType: PropTypes.oneOf(Object.values(SAVED_FILTERS_TYPES)).isRequired,
};
