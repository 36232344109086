import { useLocalStorage } from '@hooks';

export function useChartToggle(key: string, defaultValue: boolean) {
  const [isVisible, toggle] = useLocalStorage(`show${key}Chart`, defaultValue);

  function toggleChart() {
    toggle((previousState) => !previousState);
  }

  return [isVisible, toggleChart] as const;
}
