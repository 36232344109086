import { capitalize } from 'lodash-es';
import styled from 'styled-components/macro';
import { Colors } from '@zylo/orchestra';

export type StatusColorMap = Record<string, keyof typeof Colors>;

export type ColoredEnumTextCellProps = {
  data: string;
  statusColorMap?: StatusColorMap;
};

const ColoredEnumTextCellColorizer = styled.span<{ textColor?: keyof typeof Colors }>`
  color: ${({ textColor = 'black' }) => Colors[textColor]};
`;

export function ColoredEnumTextCell({ data, statusColorMap }: ColoredEnumTextCellProps) {
  const textColor = statusColorMap && statusColorMap[data];

  return data ? (
    <div className="flex-container align-center">
      <ColoredEnumTextCellColorizer textColor={textColor}>
        {capitalize(data)}
      </ColoredEnumTextCellColorizer>
    </div>
  ) : (
    <>{'-'}</>
  );
}
