import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Icon } from '@zylo/orchestra';
import MultiSelect from '../../forms/inputs/MultiSelect';
import { STATIC_FILTER_OPTIONS } from '../../../utilities/filterUtils';
import DateFilter from './DateFilter';

function DateRangeBucketsFilter({ name, onChange, value = [] }) {
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [customDateRangeValue, setCustomDateRangeValue] = useState(':::');

  useEffect(() => {
    const possibleCustomRange = value[0] || '';

    if (possibleCustomRange.includes(':gte') || possibleCustomRange.includes(':lte')) {
      setCustomDateRangeValue(possibleCustomRange);
    }
  }, [value]);

  function toggleCustomDateRangeDisplay() {
    setShowCustomDateRange(!showCustomDateRange);
  }

  function handleCustomDateRangeChange(customDateRange) {
    setCustomDateRangeValue(customDateRange);
    onChange([customDateRange]);
  }

  function handleBucketChange(values) {
    onChange(values.filter((val) => !val.includes(':')));
  }

  return (
    <div
      className={classNames(
        'flex-container date-range-buckets-filter',
        showCustomDateRange && 'show-custom-date-range',
      )}
    >
      <div className="flex-container vertical date-range-buckets-options">
        <MultiSelect
          name={name}
          onChange={handleBucketChange}
          options={STATIC_FILTER_OPTIONS.dateRangeBuckets}
          optionsKey="value"
          value={value}
        />
        <div
          className="flex-container justify-between align-center filters-menu-type custom-date-range-filter-type"
          onClick={toggleCustomDateRangeDisplay}
        >
          Custom Date Range
          <Icon icon="IconChevronRight" size={1.4} relativeSize />
        </div>
      </div>
      <div className="custom-date-range-container">
        <div
          className="flex-container align-center hide-custom-date-range"
          onClick={toggleCustomDateRangeDisplay}
        >
          <Icon color="blue" icon="IconChevronLeft" size={1.4} relativeSize />
          Back to Checkboxes
        </div>
        <DateFilter
          name="customDateRange"
          onChange={handleCustomDateRangeChange}
          value={customDateRangeValue}
        />
      </div>
    </div>
  );
}

DateRangeBucketsFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default DateRangeBucketsFilter;
