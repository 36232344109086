import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { TimeZone } from '@typings';
import { useFeatureFlags } from '@hooks';

type AllTimeZones = TimeZone[];

type GetTimeZonesResponse = {
  body: {
    timezones: AllTimeZones;
  };
};

export function useGetAllTimeZones() {
  const { isAutomationCenterEnabled } = useFeatureFlags('automation-center');
  return useQuery(
    ['timeZones'],
    async () => {
      const response: GetTimeZonesResponse = await getData({
        path: `timezones`,
      });
      return response.body;
    },
    {
      enabled: isAutomationCenterEnabled,
    },
  );
}
