import { useQueryClient, useMutation } from 'react-query';
import { postData } from '@utilities/xhr';
import { useAppMessaging } from '@hooks';
import { Contract } from '@typings';

type NewContractRequest = {
  data: Contract;
};
export function useCreateContract() {
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ data }: NewContractRequest) =>
      postData({
        path: `companies/$companyId/contracts`,
        params: { ...data },
      }),
    {
      onSuccess: async () => {
        showMessage({
          message: 'Contract has been successfully saved.',
        });
        await queryClient.invalidateQueries(['companies/$companyId/zap/contracts']);
        await queryClient.invalidateQueries(['companyApp', 'contracts']);
      },
      onError: () => {
        showMessage({
          message: 'There was an error saving the contract.',
          isError: true,
        });
      },
    },
  );
}
