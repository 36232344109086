import { useCallback } from 'react';
import { get } from 'lodash-es';
import {
  useActiveSavedFilterState,
  useActiveSavedFilterUpdate,
} from '../../../contexts/savedFilterContexts/activeSavedFilterContext';
import { useFiltersState } from '../../../contexts/filtersContext';
import { useSavedFilterModificationState } from '../../../contexts/savedFilterContexts/savedFilterModificationContext';
import { useSavedFiltersListUpdate } from '../../../contexts/savedFilterContexts/savedFiltersListContext';
import { useAppMessaging } from '../../../hooks';
import { putData } from '../../../utilities/xhr';

export default function useUpdateInsightFilter() {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { setActiveSavedFilter } = useActiveSavedFilterUpdate();
  const { apiRequestFilters } = useFiltersState();
  const { currentConfiguration } = useSavedFilterModificationState();
  const { fetchInsightFilters } = useSavedFiltersListUpdate();
  const showMessage = useAppMessaging();
  const activeSavedFilterId = get(activeSavedFilter, 'id');

  const updateInsightFilter = useCallback(
    ({ betaFilter, description, displayForAppId, filterName, onError, onSuccess }) => {
      const { columnsOrder, filters, tableSort, frozenColumns } = currentConfiguration;

      putData({
        path: `insightFilters/${activeSavedFilterId}`,
        params: {
          apiRequestFilters,
          betaFilter,
          columnsOrder,
          description,
          displayFilters: filters,
          filterName,
          tableSort,
          frozenColumns,
          displayForAppId,
        },
      })
        .then(({ body }) => {
          showMessage({ message: `${filterName} Insight Filter has been updated.` });

          setActiveSavedFilter({
            apiRequestFilters,
            betaFilter,
            columnsOrder,
            description,
            filterName,
            filters: JSON.parse(filters),
            id: body[0].id,
            isInsight: true,
            tableSort,
            frozenColumns,
            displayForAppId,
          });

          setTimeout(fetchInsightFilters, 2000);

          onSuccess();
        })
        .catch(({ message }) => {
          onError(message);
        });
    },
    [
      activeSavedFilterId,
      apiRequestFilters,
      currentConfiguration,
      fetchInsightFilters,
      setActiveSavedFilter,
      showMessage,
    ],
  );

  return updateInsightFilter;
}
