import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';

class ZyloCheckbox extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isBubble: PropTypes.bool,
    isErrored: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    size: PropTypes.oneOf(['', 'large']),
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string, // value can initially be an empty string from redux-form
    ]),
  };

  static defaultProps = { className: '', size: '' };

  constructor(props) {
    super(props);
    this.state = {
      cachedValue: props.value,
      isChecked: props.value || false,
      isBubble: props.value || false,
    };
  }

  static getDerivedStateFromProps({ value }, { cachedValue, isChecked }) {
    const stateUpdate = {};
    const shouldBeChecked = value || false;

    if (value !== cachedValue) {
      stateUpdate.cachedValue = value;

      if (value !== undefined && shouldBeChecked !== isChecked) {
        stateUpdate.isChecked = shouldBeChecked;
      }
    }

    return stateUpdate;
  }

  handleChange = () => {
    const { onChange, name, value } = this.props;
    let newValue;

    this.setState(
      ({ isChecked }) => {
        const shouldBeChecked = !isChecked;
        newValue = shouldBeChecked ? value || true : false;

        return { isChecked: shouldBeChecked };
      },
      () => {
        onChange(newValue, name);
      },
    );
  };

  render() {
    const { name, label, className, disabled, isErrored, size, readOnly, isBubble } = this.props;
    const { isChecked } = this.state;

    return (
      <div
        className={classNames(
          'zylo-checkbox-wrapper',
          size,
          className,
          isErrored && 'errored',
          !label && 'no-label',
        )}
      >
        <input
          checked={isChecked}
          className="zylo-checkbox"
          data-testid="zylo-checkbox"
          disabled={readOnly || disabled}
          id={name}
          name={name}
          onChange={this.handleChange}
          type="checkbox"
        />
        <label className="zylo-checkbox-label" htmlFor={name}>
          <span className={classNames('zylo-check', isBubble && 'zylo-check-bubble')} />
          {label}
        </label>
      </div>
    );
  }
}

export default ZyloCheckbox;
