import { FormattedCurrencyNumber } from '../../FormattedCurrencyNumber';

export function CurrencyCell({
  data = '',
  callback,
  rowData,
  decimals = 0,
}: {
  data: number | string;
  callback?: (data: any) => void;
  rowData: any;
  decimals?: 0 | 1 | 2;
}) {
  const isClickable = callback && rowData.isUnclassified && Number(data) > 0;
  const handleClick = (e: { stopPropagation: () => void }) => {
    if (isClickable) {
      e.stopPropagation();
      callback(rowData);
    }
  };

  // Number.isNaN coerces empty string to zero so it returns false, added === '' for this condition.
  const amount =
    data === null || data === undefined || Number.isNaN(data) || data === '' ? (
      '-'
    ) : (
      <FormattedCurrencyNumber
        decimals={decimals}
        isAbbreviated={false}
        value={Number(data)}
        isCurrency
      />
    );

  return (
    <div className={'numerical-cell'}>
      <span className={`${isClickable ? 'clickable' : ''}`} onClick={handleClick}>
        {amount}
      </span>
    </div>
  );
}
