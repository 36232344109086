import {
  FontStyles,
  Icon,
  PortalModal,
  TableHorizontalAlignment,
  ZyloButton,
  ZyloTable,
  ZyloTableColumnProps,
} from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { Cell } from 'react-table';
import AppLogo from '@components/AppLogo';
import TabPane from '@components/layout/TabPane';
import {
  useChangeDefaultSavedFilter,
  useDeleteInsightFilter,
  useDeleteSavedFilter,
} from '@components/savedFilters/hooks';
import {
  ActionConfirmationCell,
  DateCell,
  DefaultCell,
  NameCell,
  SwitchCell,
  ZyloInsightCell,
} from '@components/tables/cells';
import { SendEventFunction, useEventTracking, usePermissions, usePortalTarget } from '@hooks';
import { useSavedFiltersListState } from '@contexts/savedFilterContexts/savedFiltersListContext';
import { useActiveSavedFilterUpdate } from '@contexts/savedFilterContexts/activeSavedFilterContext';
import { useAppDetailsCompanyAppId, useGetCompanyApp } from '@hooks';
import { RowData } from '@typings';
import { ZyloTabs } from '@components/layout/ZyloTabs';
import { InsightFilter } from '@pages/insightsWorkbench/typings/insights';
import { definitions } from '@typings/api';

type SavedFiltersTablesDisplayLayerProps = {
  appDomain?: string;
  appLabel?: string;
  authCanAccessAdmin?: boolean;
  authCanAccessProductAdmin?: boolean;
  changeDefaultSavedFilter: (r: RowData, cv: boolean) => void;
  closeModal: () => void;
  deleteInsightFilter: (() => void) | undefined;
  deleteSavedFilter: (() => void) | undefined;
  handleInsightClick: (insight: InsightFilter) => void;
  handleSavedFilterClick: (savedFilter: definitions['SavedFilter']) => void;
  insightFilters: InsightFilter[];
  savedFilters: {
    myFilters: definitions['SavedFilter'][];
    teamFilters: definitions['SavedFilter'][];
  };
  savedFiltersApplyEventName?: string;
  sendEvent: SendEventFunction;
};

export default function SavedFiltersTables({
  handleClose,
  savedFiltersApplyEventName,
}: {
  handleClose: SavedFiltersTablesDisplayLayerProps['closeModal'];
  savedFiltersApplyEventName: SavedFiltersTablesDisplayLayerProps['savedFiltersApplyEventName'];
}) {
  const dataLayer = useDataLayer(handleClose);
  const portalTarget = usePortalTarget();
  const { closeModal, ...displayLayerProps } = dataLayer;

  return (
    <PortalModal handleClose={closeModal} portalTarget={portalTarget} clickOutsideToClose isOpen>
      <SavedFiltersTables_DisplayLayer
        {...{ closeModal, savedFiltersApplyEventName, ...displayLayerProps }}
      />
    </PortalModal>
  );
}

export const StyledSavedFiltersModalHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;

  h2 {
    display: inline-flex;

    > * {
      margin-right: 0.5rem;
    }
  }
`;

const StyledSavedFiltersModal = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 70vw;
`;

export function SharedActionCell({
  row,
  handleConfirmation,
}: {
  row: any;
  handleConfirmation: (() => void) | undefined;
}) {
  return (
    <ActionConfirmationCell
      confirmationButtonText={['Yes, delete']}
      elements={[
        <Icon
          color="error"
          hoverColor="teal5"
          icon="IconCircleMinus"
          key="delete-icon"
          size={18}
          testId="icon-delete"
        />,
      ]}
      handleConfirmation={handleConfirmation}
      rowData={row.original}
    />
  );
}

export function SavedFiltersTables_DisplayLayer({
  appDomain,
  appLabel,
  authCanAccessAdmin,
  authCanAccessProductAdmin,
  changeDefaultSavedFilter,
  closeModal,
  deleteInsightFilter,
  deleteSavedFilter,
  handleInsightClick,
  handleSavedFilterClick,
  insightFilters,
  savedFilters,
  savedFiltersApplyEventName,
  sendEvent,
}: SavedFiltersTablesDisplayLayerProps) {
  const { myFilters = [], teamFilters = [] } = savedFilters;
  const insightsColumns: ZyloTableColumnProps<InsightFilter>[] = [
    {
      accessor: 'filterName',
      Header: 'Name',
      Cell: ({ value, row }: any) => {
        return <ZyloInsightCell data={value} rowData={row.original} />;
      },
    },
    {
      accessor: 'description',
      Header: 'Description',
      Cell: ({ row, value }: any) => {
        return <ZyloInsightCell data={value} rowData={row.original} />;
      },
    },
  ];

  const insightActionColumn: ZyloTableColumnProps<InsightFilter> = {
    accessor: 'id',
    Header: '',
    Cell: ({ row }) => <SharedActionCell handleConfirmation={deleteInsightFilter} row={row} />,
    ignoreRowClick: true,
    horizontalAlignment: TableHorizontalAlignment.right,
    columnWidth: 200,
  };

  const columns: ZyloTableColumnProps<definitions['SavedFilter']>[] = [
    {
      accessor: 'filterName',
      Header: 'Name',
      Cell: ({ value }) => {
        return <DefaultCell data={value} />;
      },
    },
    {
      accessor: 'modifiedAt',
      Header: 'Last Modified',
      Cell: ({ row, value }) => {
        return <DateCell data={value ?? ''} fallbackProperty="createdAt" rowData={row.original} />;
      },
    },
    {
      accessor: 'firstName',
      Header: 'Created By',
      Cell: ({ row }) => {
        return <NameCell rowData={row.original} employeeIcon />;
      },
    },
  ];

  const actionColumn: ZyloTableColumnProps<definitions['SavedFilter']> = {
    accessor: 'id',
    Header: '',
    Cell: ({ row }) => <SharedActionCell handleConfirmation={deleteSavedFilter} row={row} />,
    ignoreRowClick: true,
    horizontalAlignment: TableHorizontalAlignment.right,
    columnWidth: 200,
  };

  const myFiltersColumns: ZyloTableColumnProps<definitions['SavedFilter']>[] = [
    ...columns,
    {
      Header: 'Set As Default',
      Cell: ({ row }: Cell<definitions['SavedFilter']>) => {
        return (
          <SwitchCell
            data={Boolean(row.original['defaultFilter'])}
            onChange={changeDefaultSavedFilter}
            rowData={row.original}
          />
        );
      },
    },
    actionColumn,
  ];
  const teamFiltersColumns = authCanAccessAdmin ? [...columns, actionColumn] : columns;

  const insightFiltersColumns = authCanAccessProductAdmin
    ? [...insightsColumns, insightActionColumn]
    : insightsColumns;

  function sendFilterApplyEvent(savedFilterType: string, filterName?: string) {
    if (savedFiltersApplyEventName) {
      sendEvent({
        eventName: savedFiltersApplyEventName,
        data: { filterName, filterType: savedFilterType },
      });
    }
  }

  return (
    <StyledSavedFiltersModal className="flex-container vertical">
      <StyledSavedFiltersModalHeader>
        <h2 className="saved-filters__heading">
          {appDomain && <AppLogo filename={appDomain} />}
          <span>Saved Filters</span>
        </h2>
        <ZyloButton icon="IconX" onClick={closeModal} testId="icon-close" variant="text" />
      </StyledSavedFiltersModalHeader>

      {appLabel && (
        <p className="company-app-saved-filters-explanation">
          The Saved Filters in{' '}
          <span style={{ fontWeight: FontStyles.weight[700] }}>My Filters</span> and{' '}
          <span style={{ fontWeight: FontStyles.weight[700] }}>Team Filters</span> are specific to{' '}
          <span style={{ fontWeight: FontStyles.weight[700] }}>{appLabel}</span>.
        </p>
      )}
      <ZyloTabs contentPadding={'1.25rem 0 0 0'} headerPadding={0}>
        {insightFilters.length ? (
          <TabPane label="Zylo Insights" tabId="zyloInsights">
            <ZyloTable
              columns={insightFiltersColumns}
              data={insightFilters}
              minHeight={350}
              onRowClick={(row: InsightFilter) => {
                handleInsightClick(row);
                sendFilterApplyEvent('Zylo Insights', row.filterName);
              }}
            />
          </TabPane>
        ) : (
          <></>
        )}

        <TabPane label="My Filters" tabId="myFilters">
          <ZyloTable
            columns={myFiltersColumns}
            data={myFilters}
            minHeight={350}
            noDataText="No Saved Filters to display"
            onRowClick={(row: definitions['SavedFilter']) => {
              handleSavedFilterClick(row);
              sendFilterApplyEvent('My Filters', row.filterName);
            }}
          />
        </TabPane>

        <TabPane label="Team Filters" tabId="teamFilters">
          <ZyloTable
            columns={teamFiltersColumns}
            data={teamFilters}
            minHeight={350}
            noDataText="No Team Filters to display"
            onRowClick={(row: definitions['SavedFilter']) => {
              handleSavedFilterClick(row);
              sendFilterApplyEvent('Team Filters', row.filterName);
            }}
          />
        </TabPane>
      </ZyloTabs>
    </StyledSavedFiltersModal>
  );
}

function useDataLayer(handleClose: SavedFiltersTablesDisplayLayerProps['closeModal']) {
  const { insightFilters, savedFilters } = useSavedFiltersListState();
  const { setActiveSavedFilter } = useActiveSavedFilterUpdate();
  const changeDefaultSavedFilter = useChangeDefaultSavedFilter();
  const deleteInsightFilter = useDeleteInsightFilter();
  const deleteSavedFilter = useDeleteSavedFilter();
  const sendEvent = useEventTracking();
  const { authCanAccessAdmin, authCanAccessProductAdmin } = usePermissions('admin', 'productAdmin');
  const companyAppId = useAppDetailsCompanyAppId();
  const { data } = useGetCompanyApp(companyAppId);
  const { appDomain, appLabel } = data ?? {};

  function handleInsightClick(insight: InsightFilter) {
    setActiveSavedFilter({ ...insight, isInsight: true });
    handleClose();
  }

  function handleSavedFilterClick(savedFilter: definitions['SavedFilter']) {
    setActiveSavedFilter(savedFilter);
    handleClose();
  }

  return {
    appDomain,
    appLabel,
    authCanAccessAdmin,
    authCanAccessProductAdmin,
    changeDefaultSavedFilter,
    closeModal: handleClose,
    deleteInsightFilter,
    deleteSavedFilter,
    handleInsightClick,
    handleSavedFilterClick,
    insightFilters,
    savedFilters,
    sendEvent,
  };
}
