import { useQuery, UseQueryResult } from 'react-query';
import { useFeatureFlags } from '@hooks';
import { getData } from '@utilities/xhr';

type MultiOrgAppsResponse = {
  body: {
    types: IMultiOrgApp[];
  };
};

export interface IMultiOrgApp {
  type: string;
  provisionedUserAppCount: number;
  activeUserAppCount: number;
  inactiveUserAppCount: number;
  appDomain?: string;
  domain?: string;
  launchUrl?: string;
}

async function fetchMultiOrgApps(): Promise<IMultiOrgApp[]> {
  const { body: multiOrgAppsList }: MultiOrgAppsResponse = await getData({
    path: `companies/$companyId/multiorg`,
  });
  return multiOrgAppsList?.types;
}

export function useMultiOrgApps(): UseQueryResult<IMultiOrgApp[] | undefined> {
  const { isMultiOrgEnabled } = useFeatureFlags('multi-org');
  return useQuery(['multiOrg'], () => fetchMultiOrgApps(), {
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    enabled: isMultiOrgEnabled,
  });
}
