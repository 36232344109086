import { useCompanyData } from '@hooks/company';
import { useGetCompanyApp } from '@hooks';

/**
 * Determines if a company has an active integration associated with a given app using
 * the company's app id.
 * @param companyAppId The `id` associated with an app, separate from the app's `appId`.
 */
export function useAppHasActiveIntegration(companyAppId: string) {
  const { data } = useGetCompanyApp(companyAppId);
  const { companyIntegrations } = useCompanyData().companyData;
  /**
   * `data.id` is the same as the function parameter `companyAppId` and potentially
   * may be the same as the shadowed `companyAppId` (if the integration is active).
   * the primary need for `useGetCompanyApp` here is to ensure we have access to `data.appId`.
   */
  if (data?.id) {
    return companyIntegrations.some(({ companyAppId }) => {
      return companyAppId === data.id;
    });
  }
}
