import { useQuery } from 'react-query';
import { sortBy } from 'lodash-es';
import { HttpResponse } from '@src/typings';
import { getData } from '../utilities/xhr';

export type CompanySupplier = {
  id: string;
  supplierId: string;
  name: string;
  domain: string;
  description?: string;
};

export type CompanySupplierDataResponse = {
  data: CompanySupplier[];
};
export function useCompanySupplierData(searchValue: string, limit?: number) {
  return useQuery(['fetchCompanySuppliers', searchValue, limit], async () => {
    const response: HttpResponse<CompanySupplier[]> = await getData({
      path: 'companies/$companyId/companySuppliers',
      params: {
        search: searchValue,
        limit,
      },
    });

    return sortBy(response.body, ['name']);
  });
}
