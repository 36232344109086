import logo from '@assets/img/zylo_logo.png';
import { usePageTitle } from '@hooks';
import '@styles/pages/browser-warning-page.scss';

function BrowserWarningPage() {
  usePageTitle('Browser Warning');
  return (
    <div className="browser-warning-page">
      <img alt="Zylo logo" className="zylo-logo" src={logo} />
      <p>Internet Explorer is not a supported browser for Zylo.</p>
      <p>
        We recommend that you use the latest version of Chrome, Firefox, Microsoft Edge or Safari.
      </p>
    </div>
  );
}

export default BrowserWarningPage;
