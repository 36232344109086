import { MouseEvent as ReactMouseEvent, useState } from 'react';
import classNames from 'clsx';
import 'styled-components/macro';
import { Colors, ColorsUnion, Icon, IconProps, Tooltip, TooltipProps } from '@zylo/orchestra';

type LinkProps = {
  'data-cy': string;
  download?: string;
  href?: string;
  target?: string;
};

export type OptionData = string | { [key: string]: any };

type PopoverMenuItemActionProps =
  | { action: (a?: OptionData) => void; optionData?: never }
  | { action: (a: OptionData) => void; optionData: OptionData };

export type PopoverMenuItemProps = Pick<IconProps, 'color' | 'hoverColor' | 'icon'> &
  PopoverMenuItemActionProps & {
    // action: (a: OptionData) => void;
    closeMenu?: () => void;
    isDisabled?: boolean;
    linkProps?: LinkProps;
    // optionData: OptionData;
    optionText: string;
    textColor?: keyof typeof Colors;
    tooltipText?: string;
    tooltipMinWidth?: number;
    tooltipVerticalDisplay?: TooltipProps['verticalDisplay'];
  };

export default function PopoverMenuItem({
  action,
  closeMenu = () => {},
  color = 'darkgray',
  hoverColor = 'blue',
  icon,
  isDisabled,
  linkProps,
  textColor = 'black',
  tooltipText,
  tooltipMinWidth,
  tooltipVerticalDisplay,
  optionData,
  optionText,
}: PopoverMenuItemProps) {
  const [iconColor, setIconColor] = useState<ColorsUnion>(color);

  type MenuItemClickEvent =
    | ReactMouseEvent<HTMLAnchorElement, MouseEvent>
    | ReactMouseEvent<HTMLSpanElement, MouseEvent>;

  function handleClick(e: MenuItemClickEvent) {
    e.stopPropagation();

    if (!isDisabled) {
      closeMenu();
      action(optionData!);
    }
  }

  function handleMouseEnter() {
    setIconColor(hoverColor);
  }

  function handleMouseLeave() {
    setIconColor(color);
  }

  const className = classNames(
    `popover-menu-action${isDisabled ? ' disabled' : ''}`,
    !linkProps ? 'flex-container align-center' : null,
  );

  return linkProps ? (
    <a
      className={className}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...linkProps}
    >
      <Tooltip
        bodyStyle={{ minWidth: tooltipMinWidth }}
        disabled={!Boolean(tooltipText)}
        text={tooltipText}
        verticalDisplay={tooltipVerticalDisplay}
      >
        <span
          className="flex-container align-center"
          style={{
            color: Colors[textColor],
          }}
        >
          <Icon color={iconColor as ColorsUnion} icon={icon} size={1} relativeSize />
          {optionText}
        </span>
      </Tooltip>
    </a>
  ) : (
    <Tooltip
      bodyStyle={{ minWidth: tooltipMinWidth }}
      disabled={!Boolean(tooltipText)}
      text={tooltipText}
      verticalDisplay={tooltipVerticalDisplay}
    >
      <span
        className={className}
        onClick={handleClick}
        style={{
          color: Colors[textColor],
        }}
      >
        <Icon color={color} hoverColor={hoverColor} icon={icon} size={1} relativeSize />
        {optionText}
      </span>
    </Tooltip>
  );
}
