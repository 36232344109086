import { useMutation, UseMutationResult } from 'react-query';
import { getData } from '@utilities/xhr';
import { useAppMessaging } from '@hooks';

type IUserIdFromEmail = {
  userId: string;
};

type UserIdFromEmailResponse = {
  body: IUserIdFromEmail;
};

export default function useUserIdFromEmail(): UseMutationResult<string, unknown, string> {
  const showMessage = useAppMessaging();
  return useMutation(
    async (email: string) => {
      const response = await getData({
        path: `companies/$companyId/users/byEmail/${email}`,
      }).then((data: UserIdFromEmailResponse) => {
        const {
          body: { userId },
        } = data;
        return userId;
      });
      return response;
    },
    {
      onError: () => {
        showMessage({
          message: 'Sorry, that email is not associated with a user.',
          isError: true,
        });
      },
    },
  );
}
