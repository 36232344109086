import { Controller } from 'react-hook-form';
import type { FormMethods, ReactHookFormInputControllerRenderChild, Field } from '../types';

type FieldControllerWrapperProps = {
  formMethods: FormMethods;
  defaultValue: unknown;
  field: Field;
  name: string;
  rules?: any;
  children?: ReactHookFormInputControllerRenderChild;
};

const inputsNeedingController: Field[] = [
  'CustomFormField',
  'Combobox',
  'CurrencyField',
  'DatePicker',
  'ItemListField',
  'MultiCombobox',
  'Select',
];

/**
 * The Field Control Wrapper handles the connection of any Field Input to the `react-hook-form` library.
 * The library uses different registration methods for controlled and uncontrolled components (`register` and `Controller`)
 * This component hides that fact and calls whichever is relevant for a given input type, and passes through a
 * unified set of handlers to the Field Input itself.
 * */
export default function FieldControllerWrapper({
  children,
  formMethods,
  field,
  name,
  rules,
  // Controller only prop
  defaultValue,
}: FieldControllerWrapperProps) {
  if (!children) {
    return <></>;
  }

  if (inputsNeedingController.includes(field)) {
    const { control } = formMethods;
    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={children}
        rules={rules}
      />
    );
  }

  const { register, formState, getFieldState } = formMethods;
  const inputRegsitrationProps = register(name, rules);
  return children({
    field: inputRegsitrationProps,
    fieldState: getFieldState(name),
    formState: formState,
  });
}
