import { ReactNode } from 'react';

export type TabPaneProps = {
  children: ReactNode;
  label: string;
  tabId: string;
};

export default function TabPane({ children }: TabPaneProps) {
  return <div className="tab-pane">{children}</div>;
}
