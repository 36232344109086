import { useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  QuickViewDrawer,
  defaultborder,
  Colors,
  FontStyles,
  ZyloTableSelectionState,
  Borders,
  ConfirmationModal,
  ZyloButton,
  formatAbbreviateNumber,
} from '@zylo/orchestra';
import { FormState } from 'react-hook-form';
import {
  MetadataResponse,
  useAppMessaging,
  useCalculateQuickViewDuration,
  useEventTracking,
  useGetTableTotalCount,
  usePortalTarget,
  useToggleState,
} from '@hooks';
import { FormValues } from '@frameworks/form-fns';
import { useFiltersState } from '@contexts/filtersContext';
import { makeItPlural } from '@utilities/util';
import { useSearchState } from '@contexts/searchContext';
import { APIRequestFilters } from '@typings';
import { useZAPState } from '../ZAPContext';
import BulkEditDrawerFormPage from './BulkEditDrawerFormPage';
import BulkEditDrawerConfirmationPage from './BulkEditDrawerConfirmationPage';
import { useZAPBulkEditSubmit } from './useBulkEditSubmit';
import { BulkEditApiChanges, BulkEditApiPatchField } from './types';
import { mapFormValuesToApiChanges } from './mapFormValuesToApiChanges';

const BulkEditTitle = styled.div`
  font-size: ${FontStyles.size['16px']};
  font-weight: ${FontStyles.weight[500]};
  color: ${Colors.teal5};
`;

const BulkEditDrawerWrapper = styled.div<{ hideForm: boolean }>`
  padding: 0;
  width: 100%;

  .bulk-edit {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__content {
      flex: 1 1 auto;
      overflow-y: auto;
      margin: 1rem;
      border: ${defaultborder};
      border-radius: ${Borders.radius.large};
    }

    &__appcount {
      color: ${Colors.sienna7};
      font-size: ${FontStyles.size['16px']};
      font-weight: ${FontStyles.weight[500]};
      padding: 1rem 20px 0;
    }

    &__footer {
      align-items: center;
      background: ${Colors.white};
      display: flex;
      flex: 0 0 4rem;
      height: 4rem;
      justify-content: space-between;
      padding: 0 1.5rem;
      width: 100%;

      & > *:only-child {
        margin-left: auto;
      }
    }
  }

  ${({ hideForm }) => {
    if (hideForm) {
      return css`
        .bulk-edit__form-container {
          display: none;
        }
      `;
    }
  }}
`;

export type BulkEditDrawerProps = {
  handleClose: () => void;
  tableSelectionState?: ZyloTableSelectionState<any>;
};

export default function ZAPBulkEditDrawer(props: BulkEditDrawerProps) {
  return (
    <ZAPBulkEditDrawer_DisplayLayer
      {...useDataLayer(props.handleClose, props.tableSelectionState)}
      {...props}
    />
  );
}

type BulkEditDrawerDisplayProps = BulkEditDrawerProps & BulkEditDrawerDataLayerValues;

export function ZAPBulkEditDrawer_DisplayLayer({
  apiRequestFilters,
  eventLocation,
  handleClose,
  isBulkEditSaving,
  portalTarget,
  search,
  submitBulkEditForm,
  tableSelectionState,
  totalCount,
  ZAPMetadata,
}: BulkEditDrawerDisplayProps) {
  const formApiChanges = useRef<BulkEditApiChanges[]>([]);
  const formIsValid = useRef<boolean>(true);
  const [showConfirmationPage, toggleShowConfirmationPage] = useToggleState(false);
  const [showConfirmationModal, toggleConfirmationModal] = useToggleState(false);
  const rowCount = useMemo(() => {
    if (!totalCount || !tableSelectionState) {
      return undefined;
    }
    const isAllSelected = tableSelectionState?.selectAll;

    if (isAllSelected) {
      const numberDeselected = tableSelectionState?.deselectedRows.length || 0;

      return totalCount - numberDeselected;
    }

    return tableSelectionState?.selectedRows.length || 0;
  }, [totalCount, tableSelectionState]);
  const showMessage = useAppMessaging();
  const sendEvent = useEventTracking();
  useCalculateQuickViewDuration('BULK_EDIT_DURATION', { page: eventLocation });

  function onValueChange(formValues: FormValues, { isValid }: FormState<FormValues>) {
    // This function gets called with {} and `undefined` when the page first render,
    // don't reset things when this happens.
    if (!formValues || Object.keys(formValues).length === 0) {
      return;
    }

    formIsValid.current = isValid;
    formApiChanges.current = mapFormValuesToApiChanges(formValues, ZAPMetadata?.fields ?? []);
  }

  function handleContinue() {
    if (formIsValid.current && formApiChanges.current?.length > 0) {
      toggleShowConfirmationPage();
    } else if (formApiChanges.current?.length === 0) {
      showMessage({ message: 'Please make at least one update before continuing.', isError: true });
    } else {
      showMessage({ message: 'Please address form errors before continuing.', isError: true });
    }
  }

  function handleBack() {
    toggleShowConfirmationPage();
    sendEvent({ eventName: 'BULK_EDIT_BACK', data: { page: eventLocation } });
  }

  function handleCancel() {
    toggleConfirmationModal();
    sendEvent({ eventName: 'BULK_EDIT_CANCEL', data: { page: eventLocation } });
  }

  function sendSubmitEvent() {
    const fieldsEdited = formApiChanges.current.map((fieldInfo) => fieldInfo.field);
    const selectedRows =
      tableSelectionState?.selectAll === false
        ? tableSelectionState?.selectedRows.map((row) => row.appLabel)
        : 'none';
    const deselectedRows =
      tableSelectionState?.selectAll === true
        ? tableSelectionState?.deselectedRows.map((row) => row.appLabel)
        : 'none';
    sendEvent({
      eventName: 'BULK_EDIT_SUBMIT',
      data: {
        page: eventLocation,
        fieldsEdited,
        selectAll: tableSelectionState?.selectAll,
        selectedRows,
        deselectedRows,
        search,
        apiRequestFilters,
      },
    });
  }

  function confirmationModalSubmit() {
    submitBulkEditForm(formApiChanges.current);
    sendSubmitEvent();
  }

  return (
    <QuickViewDrawer
      backgroundColor={Colors.white}
      clickOutsideToClose={false}
      expanded={false}
      handleClose={handleClose}
      portalTarget={portalTarget}
      title={<BulkEditTitle>Bulk Edit</BulkEditTitle>}
      isActive
      showOverLay
    >
      <BulkEditDrawerWrapper hideForm={showConfirmationPage}>
        <section className="bulk-edit">
          <div>
            <div className="bulk-edit__appcount">
              {rowCount
                ? `${formatAbbreviateNumber({ value: rowCount }).formattedNumber} Row${makeItPlural(
                    rowCount,
                  )} Selected`
                : ''}
            </div>
          </div>
          <div className="bulk-edit__content">
            <div className="bulk-edit__form-container">
              <BulkEditDrawerFormPage
                ZAPMetadata={ZAPMetadata}
                handleSubmit={toggleConfirmationModal}
                onValueChange={onValueChange}
              />
            </div>

            {showConfirmationPage && (
              <BulkEditDrawerConfirmationPage formApiChanges={formApiChanges.current} />
            )}
          </div>
          <div className="bulk-edit__footer">
            {showConfirmationPage ? (
              <>
                <ZyloButton onClick={handleBack} variant="outlined">
                  Back
                </ZyloButton>
                <ZyloButton onClick={toggleConfirmationModal}>Submit</ZyloButton>
              </>
            ) : (
              <ZyloButton className="bulk-edit__continue-button" onClick={handleContinue}>
                Continue
              </ZyloButton>
            )}
          </div>
        </section>
      </BulkEditDrawerWrapper>
      <ConfirmationModal
        contentText="It may take a few minutes to see these updates reflected throughout Zylo."
        handleDismissClick={handleCancel}
        handlePrimaryClick={confirmationModalSubmit}
        headerText="Confirm Bulk Edits"
        isDisabled={isBulkEditSaving}
        isOpen={showConfirmationModal}
        primaryButtonText="Confirm"
        width={330}
      />
    </QuickViewDrawer>
  );
}

type BulkEditDrawerDataLayerValues = {
  apiRequestFilters: APIRequestFilters;
  eventLocation?: string;
  isBulkEditSaving: boolean;
  portalTarget: HTMLElement;
  search?: string;
  submitBulkEditForm: (formApiChanges: BulkEditApiChanges[]) => void;
  totalCount: number | undefined;
  ZAPMetadata?: MetadataResponse['body'];
};

export function useDataLayer(
  handleClose: () => void,
  tableSelectionState?: ZyloTableSelectionState<any>,
): BulkEditDrawerDataLayerValues {
  const portalTarget = usePortalTarget();
  const { endpoints, eventLocation, ZAPMetadata } = useZAPState();
  const { apiRequestFilters } = useFiltersState();
  const search = useSearchState();
  const { isLoading: isBulkEditSaving, mutate: submitPatch } = useZAPBulkEditSubmit(
    endpoints?.update,
  );
  const { data: tableCountData } = useGetTableTotalCount({
    path: endpoints?.tableData,
  });
  const totalCount = tableCountData?.count || undefined;

  const submitBulkEditForm = useCallback(
    (formApiChanges: BulkEditApiChanges[]) => {
      const apiPatches: BulkEditApiPatchField[] = formApiChanges.map(
        ({ fieldLabel: _fieldLabel, displayValue: _displayValue, ...patchData }) => patchData,
      );
      return submitPatch(
        {
          filters: JSON.parse(apiRequestFilters),
          fields: apiPatches,
          rows: {
            selectAll: tableSelectionState!.selectAll,
            selectedRows: tableSelectionState!.selectedRows,
            deselectedRows: tableSelectionState!.deselectedRows,
          },
          search: search || '',
        },
        {
          onSuccess: handleClose,
        },
      );
    },
    [submitPatch, apiRequestFilters, tableSelectionState, handleClose, search],
  );

  return {
    apiRequestFilters,
    eventLocation,
    isBulkEditSaving,
    portalTarget,
    search,
    submitBulkEditForm,
    totalCount,
    ZAPMetadata,
  };
}
