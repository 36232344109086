import { NullUXValues } from '@typings';

const API_REQUEST_FILTER_TYPES = {
  BOOL: 'bool',
  NUMERIC_RANGE: 'numeric_range',
  ONEOF: 'oneOf',
  RANGE: 'range',
  TEXT: 'text',
};

const FILTER_TYPES = {
  ARRAY: 'array',
  BOOLEAN: 'boolean',
  CURRENCY: 'currency',
  DATE: 'date',
  NUMBER: 'number',
  PERCENTAGE: 'percentage',
  STRING: 'string',
};

const STATIC_FILTER_NULL_OPTIONS = ['NULL', 'EXISTS'];

const STATIC_FILTER_OPTIONS = {
  boolean: [
    { name: 'Yes', label: 'Yes' },
    { name: 'No', label: 'No' },
    { name: NullUXValues.NULL, label: NullUXValues.NULL },
    { name: NullUXValues.EXISTS, label: NullUXValues.EXISTS },
  ],
  customFieldBoolean: [
    { name: 'Yes', label: 'Yes' },
    { name: 'No', label: 'No' },
    { name: 'Not Specified (-)', label: NullUXValues.NULL },
  ],
  nullOptions: [
    { label: NullUXValues.NULL, value: STATIC_FILTER_NULL_OPTIONS[0] },
    { label: NullUXValues.EXISTS, value: STATIC_FILTER_NULL_OPTIONS[1] },
  ],
  numberOperators: [
    { label: 'is between', value: 'between' },
    { label: 'is equal to', value: 'equals' },
    { label: 'is less than', value: 'lt' },
    { label: 'is greater than', value: 'gt' },
    { label: 'is less than or equal to', value: 'lte' },
    { label: 'is greater than or equal to', value: 'gte' },
  ],
  dateRangeBuckets: [
    { label: '0 - 30 days', value: 'zeroToThirty' },
    { label: '31 - 60 days', value: 'thirtyOneToSixty' },
    { label: '61 - 90 days', value: 'sixtyOneToNinety' },
    { label: '91+ days', value: 'ninetyOnePlus' },
    { label: 'No Activity', value: 'neverLoggedIn' },
    { label: 'Has Activity', value: 'hasLoggedIn' },
  ],
  fiscalQuarters: [
    { label: 'Next Fiscal Quarter', value: 'future:+:1:fiscal_quarter' },
    { label: 'Next 2 Fiscal Quarters', value: 'future:+:2:fiscal_quarter' },
    { label: 'Last Fiscal Quarter', value: 'past:-:1:fiscal_quarter' },
    { label: 'Last 2 Fiscal Quarters', value: 'past:-:2:fiscal_quarter' },
    { label: 'Current Fiscal Quarter', value: 'current:+:0:fiscal_quarter' },
    { label: 'Current & Next Fiscal Quarters', value: 'current:+:1:fiscal_quarter' },
    { label: 'Current & Next 2 Fiscal Quarters', value: 'current:+:2:fiscal_quarter' },
    { label: 'Current & Last Fiscal Quarter', value: 'current:-:1:fiscal_quarter' },
    { label: 'Current & Last 2 Fiscal Quarters', value: 'current:-:2:fiscal_quarter' },
  ],
  fiscalYears: [
    { label: 'Next Fiscal Year', value: 'future:+:1:fiscal_year' },
    { label: 'Last Fiscal Year', value: 'past:-:1:fiscal_year' },
    { label: 'Last 2 Fiscal Years', value: 'past:-:2:fiscal_year' },
    { label: 'Current Fiscal Year', value: 'current:+:0:fiscal_year' },
    { label: 'Current & Next Fiscal Year', value: 'current:+:1:fiscal_year' },
    { label: 'Current & Last Fiscal Year', value: 'current:-:1:fiscal_year' },
    { label: 'Current & Last 2 Fiscal Years', value: 'current:-:2:fiscal_year' },
  ],
};

export {
  API_REQUEST_FILTER_TYPES,
  FILTER_TYPES,
  STATIC_FILTER_NULL_OPTIONS,
  STATIC_FILTER_OPTIONS,
};
