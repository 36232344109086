import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { checkForProvider } from '@zylo/orchestra';

const PageCacheStateContext = createContext();
const PageCacheUpdateContext = createContext();

/* pageCacheContext provides the ability to cache page-specific information. To use this context,
   wrap your page markup with <PageCacheProvider>. An example use case is caching the
   selected sort of various feeds that are implemented in tabs on a page. When a tab remounts, we can check
   the page cache to apply the previously selected sort. The cache only persists while the page is mounted. */

function PageCacheProvider({ children }) {
  const [pageCache, setPageCache] = useState({});

  const updatePageCache = useCallback((key, update) => {
    setPageCache((currentVal) => ({ ...currentVal, [key]: update }));
  }, []);

  return (
    <PageCacheStateContext.Provider value={pageCache}>
      <PageCacheUpdateContext.Provider value={updatePageCache}>
        {children}
      </PageCacheUpdateContext.Provider>
    </PageCacheStateContext.Provider>
  );
}

PageCacheProvider.propTypes = {
  children: PropTypes.node,
};

function usePageCacheState() {
  const context = useContext(PageCacheStateContext);

  checkForProvider(context, 'usePageCacheState', 'PageCacheProvider');

  return context;
}

function usePageCacheUpdate() {
  const context = useContext(PageCacheUpdateContext);

  checkForProvider(context, 'usePageCacheUpdate', 'PageCacheProvider');

  return context;
}

export { PageCacheProvider, usePageCacheState, usePageCacheUpdate };
