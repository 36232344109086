import { useQuery, UseQueryResult } from 'react-query';
import { getData } from '@utilities/xhr';
import { SelectOption } from '@typings';

export type UseDynamicFilterOptionResult = UseQueryResult<SelectOption | undefined>;
export interface IDynamicFilterOptions {
  options: SelectOption[];
  hasMore: boolean;
}

export type ResponseHandler<ExpectedResponse> = (
  response: ExpectedResponse,
) => IDynamicFilterOptions;

async function fetchDynamicFilterOptions<ExpectedResponse>(
  path: string,
  handleResponse: ResponseHandler<ExpectedResponse>,
  params: SearchAPIParams,
) {
  const response: ExpectedResponse = await getData({
    path,
    params,
  });

  return handleResponse(response);
}

type SearchAPIParams = {
  search: string;
} & any;

export type UseDynamicSearchProps<ExpectedResponse> = {
  /** If using `enabled` this is the only value used to determine if the database should be queried, `minSearchLength` will be ignored.
   * If a minimum search length is still needed it must be handled when determining this value. */
  enabled?: boolean;
  path: string;
  responseHandler: ResponseHandler<ExpectedResponse>;
  params: SearchAPIParams;
  /** The minimum search string length necessary to enable a request to query the database. This will be ignored if `enabled` is set. */
  minSearchLength?: number;
  /** Will be called after a successful request with the resulting data returned from the `responseHandler`. */
  onSuccess?: (d: IDynamicFilterOptions) => void;
};

/**
 * Some of our Filter Menu options support a large and dynamic list of options
 * that cannot be loaded into a single Typeahead component in a reasonable manner.
 *
 * For these, we are providing the user a prompt to begin a search in the Filter Menu Typeahead,
 * querying the database with that string, and providing only a list of options that matched their
 * search, with an upper limit of returned options.
 *
 * This hook should be used generically by these search otions to provide the route the options will be queried at
 * and a response handler to map the returned data to a valid Search Option.
 */

export default function useDynamicSearchOption<ExpectedResponse>({
  enabled: enabledOverride,
  path,
  responseHandler,
  params,
  minSearchLength = 3,
  onSuccess,
}: UseDynamicSearchProps<ExpectedResponse>): UseQueryResult<IDynamicFilterOptions | undefined> & {
  needMoreInput: boolean;
} {
  const searchString = params.search;
  const searchStringLongEnough = Boolean(searchString && searchString.length >= minSearchLength);
  const enabled = enabledOverride === undefined ? searchStringLongEnough : enabledOverride;
  const queryResult = useQuery(
    ['dynamicSearchOption', path, params],
    () => fetchDynamicFilterOptions<ExpectedResponse>(path, responseHandler, params),
    {
      enabled,
      onSuccess,
    },
  );
  return {
    needMoreInput: !searchStringLongEnough,
    ...queryResult,
  };
}
