import { createAction, handleActions } from 'redux-actions';
import { navigate } from '@reach/router';
import moment from 'moment';
import { get } from 'lodash-es';
import WaterWorksAPI from '@src/WaterWorksAPI';
import { OFFSET } from '@src/config/config';
import { wrapAsync, handleNetworkError, emptyStringToNull } from '@utilities/util';
import { calculateHasMore } from '@utilities/moduleUtils';
import { mgQueryClient } from '@lib/components/AppQueryClient';
import { actions as zyloFormActions } from './zyloForm';
import { actions as statusBarActions } from './statusBar';

const { showZyloErrorMessage } = statusBarActions;
const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants
// ------------------------------------
const TEMPLATE = {
  id: null,
  appId: null,
  appLabel: '',
  spend: null,
  userCount: null,
  utilization: null,
  category: '',
  renewalDate: null,
  primaryCostCenter: {},
};
const DEFAULT_SORT_COLUMN = 'appLabel';
const DEFAULT_SORT = `+${DEFAULT_SORT_COLUMN}`;

const DEFAULT_COMPANY_APPS_STATE = {
  allCompanyApps: [],
  list: [],
  listWithoutViewsApplied: [],
  listSort: [DEFAULT_SORT],
  error: null,
  hasMore: true,
  isLoading: false,
  searchTerm: '',
  addResponse: null,
  newAppCount: 0,
  functionalities: [],
  isLoadingFunctionalities: false,
  functionalitiesError: null,
  isEditingFunctionalities: false,
};

function formatFunctions(functions) {
  const activeFunctions = [];
  const inactiveFunctions = [];
  functions.forEach((functionObject) =>
    functionObject.isActive
      ? activeFunctions.push(functionObject)
      : inactiveFunctions.push(functionObject),
  );

  return [
    ...activeFunctions.sort((a, b) => (a.name > b.name ? 1 : -1)),
    ...inactiveFunctions.sort((a, b) => (a.name > b.name ? 1 : -1)),
  ];
}

export const FETCH_COMPANY_APPS_START = 'FETCH_COMPANY_APPS_START';
export const FETCH_COMPANY_APPS_SUCCESS = 'FETCH_COMPANY_APPS_SUCCESS';
export const FETCH_COMPANY_APPS_FAIL = 'FETCH_COMPANY_APPS_FAIL';

export const EDIT_VIEW_SEARCH_APPS_SUCCESS = 'EDIT_VIEW_SEARCH_APPS_SUCCESS';
export const EDIT_VIEW_FILTER_APPS_SUCCESS = 'EDIT_VIEW_FILTER_APPS_SUCCESS';

export const SORT_COMPANY_APPS = 'SORT_COMPANY_APPS';

export const FETCH_ALL_COMPANY_APPS_SUCCESS = 'FETCH_ALL_COMPANY_APPS_SUCCESS';

export const SEARCH_COMPANY_APPS_START = 'SEARCH_COMPANY_APPS_START';
export const CLEAR_COMPANY_APPS_SEARCH = 'CLEAR_COMPANY_APPS_SEARCH';

export const ADD_APPS_SUCCESS = 'ADD_APPS_SUCCESS';

export const RESET_COMPANY_APPS_LIST_START = 'RESET_COMPANY_APPS_START';
export const RESET_COMPANY_APP_DETAILS_START = 'RESET_COMPANY_APP_DETAILS_START';

export const FETCH_COMPANY_APP_FUNCTIONALITIES_START = 'FETCH_COMPANY_APP_FUNCTIONALITIES_START';
export const FETCH_COMPANY_APP_FUNCTIONALITIES_SUCCESS =
  'FETCH_COMPANY_APP_FUNCTIONALITIES_SUCCESS';
export const FETCH_COMPANY_APP_FUNCTIONALITIES_FAIL = 'FETCH_COMPANY_APP_FUNCTIONALITIES_FAIL';

export const EDIT_COMPANY_APP_FUNCTIONALITIES_START = 'EDIT_COMPANY_APP_FUNCTIONALITIES_START';
export const EDIT_COMPANY_APP_FUNCTIONALITIES_SUCCESS = 'EDIT_COMPANY_APP_FUNCTIONALITIES_SUCCESS';
export const EDIT_COMPANY_APP_FUNCTIONALITIES_FAIL = 'EDIT_COMPANY_APP_FUNCTIONALITIES_FAIL';
// ------------------------------------
// Actions
// ------------------------------------

export const fetchCompanyAppsStart = createAction(FETCH_COMPANY_APPS_START);
export const fetchCompanyAppsSuccess = createAction(FETCH_COMPANY_APPS_SUCCESS);
export const fetchCompanyAppsFail = createAction(FETCH_COMPANY_APPS_FAIL);

export const editViewSearchAppsSuccess = createAction(EDIT_VIEW_SEARCH_APPS_SUCCESS);
export const editViewFilterAppsSuccess = createAction(EDIT_VIEW_FILTER_APPS_SUCCESS);

export const sortCompanyApps = createAction(SORT_COMPANY_APPS);

export const fetchAllCompanyAppsSuccess = createAction(FETCH_ALL_COMPANY_APPS_SUCCESS);

export const searchCompanyAppsStart = createAction(SEARCH_COMPANY_APPS_START);
export const clearCompanyAppsSearch = createAction(CLEAR_COMPANY_APPS_SEARCH);

export const addAppsSuccess = createAction(ADD_APPS_SUCCESS);

export const resetCompanyAppsListStart = createAction(RESET_COMPANY_APPS_LIST_START);

export const fetchCompanyAppFunctionalitiesStart = createAction(
  FETCH_COMPANY_APP_FUNCTIONALITIES_START,
);
export const fetchCompanyAppFunctionalitiesSuccess = createAction(
  FETCH_COMPANY_APP_FUNCTIONALITIES_SUCCESS,
);
export const fetchCompanyAppFunctionalitiesFail = createAction(
  FETCH_COMPANY_APP_FUNCTIONALITIES_FAIL,
);

export const editCompanyAppFunctionalitiesStart = createAction(
  EDIT_COMPANY_APP_FUNCTIONALITIES_START,
);
export const editCompanyAppFunctionalitiesSuccess = createAction(
  EDIT_COMPANY_APP_FUNCTIONALITIES_SUCCESS,
);
export const editCompanyAppFunctionalitiesFail = createAction(
  EDIT_COMPANY_APP_FUNCTIONALITIES_FAIL,
);

async function fetchAllCompanyApplications(_, dispatch, getState) {
  const { token, companyId } = getState().auth;
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, ['apps'], {
      limit: 1000,
      offset: 0,
      sort: ['+appLabel'],
    });
  } catch (e) {
    handleNetworkError('fetchAllCompanyApplications', e, dispatch, null, { companyId });

    return;
  }

  dispatch(fetchAllCompanyAppsSuccess(result));
}

async function fetchCompanyApplications(
  { offset, limit, all = false, source, suppliers },
  dispatch,
  getState,
) {
  const { auth, companyApps } = getState();
  const { token, companyId } = auth;

  if (!companyApps.isLoading) {
    dispatch(fetchCompanyAppsStart());

    const { list, listWithoutViewsApplied, listSort, hasMore, searchTerm } = companyApps;
    const listLength = all ? listWithoutViewsApplied.length : list.length;
    const calculatedOffset = hasMore ? listLength : 0;
    const offsetValue = Number.isInteger(offset) ? offset : calculatedOffset;
    const requestParams = {
      limit: limit || OFFSET,
      offset: offsetValue,
      sort: listSort,
      search: searchTerm,
      suppliers,
    };
    let result;

    if (!searchTerm) {
      requestParams.status = ['active', 'expired'];
    }

    try {
      const path = all ? 'all/apps' : 'apps';
      result = await WaterWorksAPI.fetchCompanyData(token, companyId, [path], requestParams);
    } catch (e) {
      dispatch(showZyloErrorMessage(get(e, 'response.body.message', e.message)));
      handleNetworkError('fetchCompanyApplications', e, dispatch, fetchCompanyAppsFail, {
        companyId,
        offset,
      });

      return;
    }

    const payload = { ...result };
    if (all) {
      payload.companyApps = result.map((listObj) => {
        return {
          ...TEMPLATE,
          ...listObj,
        };
      });
    } else {
      payload.companyApps = result.companyApps.map((listObj) => {
        return {
          ...TEMPLATE,
          ...listObj,
        };
      });
    }

    if (source === 'editViewSearchApps' || source === 'editViewFilterApps') {
      const segmentPayload = {
        appCount: get(result, 'summary.count', 0),
        apps: result.map(({ appName }) => {
          return appName;
        }),
      };

      if (source === 'editViewSearchApps') {
        dispatch(editViewSearchAppsSuccess({ ...segmentPayload, searchTerm }));
      } else if (source === 'editViewFilterApps') {
        dispatch(editViewFilterAppsSuccess({ ...segmentPayload, filters: searchTerm }));
      }
    }

    dispatch(
      fetchCompanyAppsSuccess({ data: payload, offset: offsetValue, limit: limit || OFFSET, all }),
    );
  }
}

async function searchCompanyApps(
  { search, limit, all = false, source, suppliers },
  dispatch,
  getState,
) {
  if (search && search !== '') {
    dispatch(searchCompanyAppsStart(search));
  } else {
    dispatch(clearCompanyAppsSearch());
  }

  fetchCompanyApplications({ offset: 0, limit, all, source, suppliers }, dispatch, getState);
}

function clearSearch() {
  return (dispatch, getState) => {
    dispatch(clearCompanyAppsSearch());
    fetchCompanyApplications({ offset: 0 }, dispatch, getState);
  };
}

function sortCompanyApplications({ sortBy }, dispatch, getState) {
  dispatch(sortCompanyApps(sortBy));
  fetchCompanyApplications({ offset: 0 }, dispatch, getState);
}

async function addApps({ apps }, dispatch, getState) {
  const { token, companyId } = getState().auth;
  const appsToAdd = apps.map((app) => {
    return {
      appId: app.id,
      status: 'active',
    };
  });
  let result;

  try {
    result = await WaterWorksAPI.addCompanyApps(token, companyId, appsToAdd);
  } catch (e) {
    handleNetworkError('addApps', e, dispatch, saveZyloFormError, { companyId, appsToAdd });

    return;
  }

  const numAdded = result.message.match(/\d+/)[0];
  const message = `${numAdded} Subscription${numAdded === '1' ? '' : 's'} Added`;
  const today = moment().format('MM/DD/YYYY');

  // setTimeout is to allow date filter to find newly created company app
  setTimeout(
    () =>
      navigate(
        // not using formatFiltersClickThru here bc it creates dependency cycle with formatCurrency
        `/subscriptions?filters=${encodeURIComponent(
          JSON.stringify({ createdAt: `${today}:gte` }),
        )}`,
      ),
    1000,
  );

  mgQueryClient.invalidateQueries(['companyData']);
  dispatch(addAppsSuccess(result));
  dispatch(saveZyloFormSuccess(message));
}

function resetCompanyAppsList() {
  return (dispatch) => {
    dispatch(resetCompanyAppsListStart());
  };
}

async function fetchCompanyAppFunctionalities({ companyAppId }, dispatch, getState) {
  dispatch(fetchCompanyAppFunctionalitiesStart());

  const { token, companyId } = getState().auth;
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, [
      'companyApps',
      companyAppId,
      'functionalities',
    ]);
  } catch (e) {
    handleNetworkError(
      'fetchCompanyAppFunctionalities',
      e,
      dispatch,
      fetchCompanyAppFunctionalitiesFail,
      { companyId, companyAppId },
    );

    return;
  }

  dispatch(fetchCompanyAppFunctionalitiesSuccess(result));
}

async function editCompanyAppFunctionalities(
  { companyAppFunctionalities, companyAppId },
  dispatch,
  getState,
) {
  dispatch(editCompanyAppFunctionalitiesStart());

  const { auth } = getState();
  const { token, companyId } = auth;

  try {
    await WaterWorksAPI.updateCompanyData(
      token,
      companyId,
      ['companyApps', companyAppId, 'functionalities'],
      companyAppFunctionalities,
    );
  } catch (e) {
    handleNetworkError(
      'editCompanyAppFunctionalities',
      e,
      dispatch,
      editCompanyAppFunctionalitiesFail,
      { companyId, companyAppId },
    );
  }
  dispatch(editCompanyAppFunctionalitiesSuccess(companyAppFunctionalities));
}

export const actions = {
  addCompanyApps: wrapAsync(addApps),
  fetchApplications: wrapAsync(fetchCompanyApplications),
  fetchAllApplications: wrapAsync(fetchAllCompanyApplications),
  sortApplications: wrapAsync(sortCompanyApplications),
  searchCompanyApps: wrapAsync(searchCompanyApps),
  clearCompanyAppsSearch: clearSearch,
  resetCompanyAppsList,
  fetchFunctionalities: wrapAsync(fetchCompanyAppFunctionalities),
  editCompanyAppFunctionalities: wrapAsync(editCompanyAppFunctionalities),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [FETCH_COMPANY_APPS_START]: (state) => {
      return { ...state, isLoading: true };
    },
    [FETCH_COMPANY_APPS_SUCCESS]: (state, { payload }) => {
      const { data, offset, all, limit } = payload;
      const { companyApps, summary, newAppCount } = data;
      const searchSummary = offset === 0 ? emptyStringToNull(summary) : state.searchSummary;
      const hasMore = calculateHasMore(companyApps.length, limit);

      // eslint-disable-next-line prefer-const
      let { list, listWithoutViewsApplied } = state;
      if (all) {
        listWithoutViewsApplied =
          offset === 0 ? companyApps : state.listWithoutViewsApplied.concat(companyApps);
      } else {
        list = offset === 0 ? companyApps : state.list.concat(companyApps);
      }

      return {
        ...state,
        list,
        listWithoutViewsApplied,
        hasMore,
        error: null,
        isLoading: false,
        searchSummary,
        newAppCount,
      };
    },
    [FETCH_COMPANY_APPS_FAIL]: (state, { payload }) => {
      return { ...state, error: payload, isLoading: false, hasMore: false };
    },
    [SORT_COMPANY_APPS]: (state, { payload }) => {
      const listSort =
        payload.indexOf(DEFAULT_SORT_COLUMN) > -1 ? [payload] : [payload, DEFAULT_SORT];

      return { ...state, listSort };
    },

    [FETCH_ALL_COMPANY_APPS_SUCCESS]: (state, { payload }) => {
      const { companyApps } = payload;
      return {
        ...state,
        allCompanyApps: companyApps,
      };
    },

    [SEARCH_COMPANY_APPS_START]: (state, { payload }) => {
      return { ...state, isSearching: true, searchTerm: payload };
    },
    [CLEAR_COMPANY_APPS_SEARCH]: (state) => {
      const list = [];
      const listWithoutViewsApplied = [];

      return {
        ...state,
        list,
        listWithoutViewsApplied,
        isLoading: false,
        error: null,
        hasMore: true,
        isSearching: false,
        searchTerm: '',
        searchSummary: null,
      };
    },

    [RESET_COMPANY_APPS_LIST_START]: () => {
      return { ...DEFAULT_COMPANY_APPS_STATE };
    },
    [FETCH_COMPANY_APP_FUNCTIONALITIES_START]: (state) => {
      return { ...state, isLoadingFunctionalities: true };
    },
    [FETCH_COMPANY_APP_FUNCTIONALITIES_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        functionalities: formatFunctions(payload),
        isLoadingFunctionalities: false,
        functionalitesError: null,
      };
    },
    [FETCH_COMPANY_APP_FUNCTIONALITIES_FAIL]: (state, { payload }) => {
      return {
        ...state,
        isLoadingFunctionalities: false,
        functionalitiesError: payload,
      };
    },
    [EDIT_COMPANY_APP_FUNCTIONALITIES_START]: (state) => {
      return { ...state, isEditingFunctionalities: true };
    },
    [EDIT_COMPANY_APP_FUNCTIONALITIES_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        functionalities: formatFunctions(payload),
        isEditingFunctionalities: false,
        functionalitesError: null,
      };
    },
    [EDIT_COMPANY_APP_FUNCTIONALITIES_FAIL]: (state, { payload }) => {
      return {
        ...state,
        isEditingFunctionalities: false,
        functionalitiesError: payload,
      };
    },
  },
  DEFAULT_COMPANY_APPS_STATE,
);
