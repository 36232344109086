import moment from 'moment';

export default function convertDateStringToMoment(date?: string | null) {
  let momentDate = null;

  // There was a customer issue where `includes` was not a function, suggesting that a non-string was passed
  // in, likely from a non TS source. The additional validation is here to keep this from ever happening.
  if (date && typeof date === 'string' && date.length > 0) {
    // if date string includes time zone, use moment.utc
    momentDate = date.includes('T') ? moment.utc(date) : moment(date);
  }

  return momentDate;
}
