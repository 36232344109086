import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { Contract, HttpResponse } from '@typings';

export function useContractData(contractId: string) {
  return useQuery(['contractData', contractId], async () => {
    const response: HttpResponse<Contract> = await getData({
      path: `companies/$companyId/contracts/${contractId}`,
    });

    return response.body;
  });
}
