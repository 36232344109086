import { Icons, TableHorizontalAlignment } from '@zylo/orchestra';
import { FunctionComponent, ComponentClass } from 'react';

/**
 * Used to consistently render the same text and render
 * the same text for our "null" and "not null" UX values.
 */
export enum NullUXValues {
  NULL = '(Blank)',
  EXISTS = '(Not Blank)',
}

/** The expected metadata types when using the ⚡ZAP™. */
export enum FilterTypes {
  array = 'array',
  boolean = 'boolean',
  currency = 'currency',
  date = 'date',
  number = 'number',
  percentage = 'percentage',
  string = 'string',
}
type FilterTypeUnion = keyof typeof FilterTypes;

/** The expected metadata data types when using ⚡ZAP™. */
export enum FieldDataTypes {
  /** IconVersions icon */
  appCatalog = 'appCatalog',
  /** BUDGET icon */
  budget = 'budget',
  /** IconCircleLetterZ icon */
  custom = 'custom',
  /** EVENTS icon */
  events = 'events',
  /** IconFile icon */
  file = 'file',
  /** IconCurrencyDollar icon */
  financial = 'financial',
  /** IconCurrencyDollar icon */
  fiscal = 'fiscal',
  /** USERS_INPUT icon */
  input = 'input',
  /** IconCircleLetterZ icon */
  metric = 'metric',
  /** IconUsers icon */
  owner = 'owner',
  /** IconJumpRope icon */
  purchaseOrder = 'purchaseOrder',
  /** SHIELD_LOCK icon */
  security = 'security',
  /** CLOUD_LOCK icon */
  singleSignOn = 'singleSignOn',
  /** IconChartBar icon */
  usage = 'usage',
  /** IconCircleLetterZ icon */
  user = 'user',
  /** IconCircleLetterZ icon */
  zylo = 'zylo',
}
type FieldDataTypeUnion = keyof typeof FieldDataTypes;

/** A configuration object used in `ColumnPicker` to determine the category title, icon and tooltip
 * for the categories displayed in `ChooseFields`. Categories are derived from the `dataType` property
 * of `IMetadataField`. This does not currently apply to the users table in app details.
 */
export type CategoriesInfo = {
  [Property in FieldDataTypeUnion]?: {
    category: string;
    icon: Extract<
      Icons,
      | 'IconJumpRope'
      | 'IconFile'
      | 'IconCurrencyDollar'
      | 'IconChartBar'
      | 'IconUser'
      | 'IconPencil'
      | 'IconCircleLetterZ'
      | 'IconReportMoney'
      | 'IconShieldLock'
      | 'IconCloudLock'
      | 'IconUsers'
      | 'IconVersions'
      | 'IconCalendarEvent'
    >;
    tooltipText: string | JSX.Element;
  };
};

export type EditableInputType =
  | 'array'
  | 'boolean'
  | 'comboBox'
  | 'currency'
  | 'date'
  | 'input'
  | 'number'
  | 'select';

export type EditProperties = {
  inputType?: EditableInputType;
  selectOptions?: FilterOption[];
};

/** Metadata associated with a given field. Metadata is transformed into:
 *
 * 1. Columns to be displayed in a table
 * 2. Filters to filter a table and accompanying stats and charts
 * 3. Sort map to enable sorting of a table
 *
 * The way column data is displayed, filtered and sorted as well as how a column/field is grouped in the column picker, the icon and tooltip
 *  text are derived from the values of `IMetadataField`. */
export interface IMetadataField {
  /** Determines if a filter has search options. If `canAutocomplete` is `true` filter values can be searched for using the API. */
  canAutocomplete: boolean;
  /** Determines if a filed can be used to filter the table. If `canFilter` is set `false` the field cannot be used to filter. */
  canFilter: boolean;
  /** Overrides horizontal alignment for this field in the table column. */
  columnAlignment?: TableHorizontalAlignment;
  /** The source of the data being presented. Data from an integration will have that integration's name.
   * Data generated by Zylo or input by users will be 'zylo'. */
  dataSource: string;
  /** Used to dertermine the category the column is grouped in, and the icon and tooltip text. */
  dataType: FieldDataTypeUnion;
  displayIndex?: number;
  /** The properly formatted name of the column displayed to the user. */
  displayKey: string;
  /** Returned if isEditable is true, for use with bulk edit */
  editProperties?: EditProperties;
  /** The filter param value sent to the API that indicates how the requested data should be filtered (typically `displayName` snake cased). */
  filterOn: string;
  /** If `filterOnly` is true the field will not be visible in the table or the column picker, but can still be used for filtering. */
  filterOnly?: boolean;
  /** Determines if a column is "active" (visable to the user) or "inactive" (hidden from the user) by default. If `isDefaultHidden` is `true` it
   * will be inactive, but can be set to active by the user.
   */
  isDefaultHidden: boolean;
  /**
   * Whether or not the user has the ability to edit this field.
   */
  isEditable: boolean;
  /** A frozen column will be position on the left side of the table and will not scroll horizontally. There may or may not be default
   * frozen columns for a given table. However, any column can be frozen or unfrozen by the user up to a set limit. A frozen column
   * can be hidden by a user if `isLocked` is set to `false`. */
  isFrozen: boolean;
  /** Determines if the user is able to hide the given column. If `isLocked` is `true` the user can not hide the column. */
  isLocked: boolean;
  /** This value is currently not returned from the API but an equivalent will be added as `isLockedFilter`. Determines if the
   * user is able to remove the an applied and displayed filter. This is similar to the use of `isLocked` but for filters. */
  freezeFilterValue?: boolean;
  /** The name of the column (typically `displayName` camel cased) used as an accessor for column. */
  key: string;
  /** Determines if a column can be used to search the table. If `searchable` is set `false` the column cannot be used to search. */
  searchable?: boolean;
  /** Determines if a column can be used to sort the table. If `sortable` is set `false` the column cannot be used to sort. */
  sortable?: boolean;
  /** The sort param value sent to the API that indicates how the requested data should be sorted (typically `displayName` snake cased). */
  sortOn: string;
  /** @deprecated  The `type` property should be used. `subtype` is no longer returned when using ⚡ZAP™. */
  subtype?: FILTER_NUMBER_SUBTYPES;
  /** Tooltips to be displayed when hovering over a column's header. */
  tooltipText: { text: string; link: string; linkText: string };
  /** The type of value being presented to the user. Determines the way the data is presented in the table as well as the kind
   * of interaction (search, check boxes, date ranges etc.) a user has with the value when filtering. */
  type: FilterTypeUnion;
}

/** The transformed `IMetadataField` value passed to the columns context and used by the table and the column picker. */
export type MetaDataColumn = Pick<
  IMetadataField,
  'dataSource' | 'dataType' | 'filterOn' | 'isFrozen' | 'isLocked' | 'subtype'
> & {
  allowTooltipBodyHover: boolean;
  columnName: string;
  columnAlignment?: TableHorizontalAlignment;
  component?: React.ReactNode;
  customComponent?: FunctionComponent<any> | ComponentClass<any, any>;
  customComponentProps?: object;
  dataIsString?: boolean;
  disableColumnSort?: boolean;
  disableClick?: boolean;
  displayName: string;
  headerIcon?: string;
  idProp?: string;
  ignoreRowClick?: boolean;
  isInactive: boolean;
  isNumeric?: boolean;
  tooltipText?: JSX.Element | string;
  truncate?: number;
  width?: number;
};

/** Filters chosen by the user, formatted to be used as an API param. */
export type APIRequestFilters = string | (string | string[])[];
export interface IJsonFilters {
  [key: string]: string | string[];
}

/** @deprecated These values have been moved to `FilterTypes` in ⚡ZAP™. `FILTER_NUMBER_SUBTYPES`
 * is being provided here temporarily while it is still in use. */
export enum FILTER_NUMBER_SUBTYPES {
  NUMBER = 'number',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export interface IFilterComponentProps {
  customComponent: FunctionComponent<any> | ComponentClass<any, any>;
  allowMultiple?: boolean;
  allowCustomValue?: boolean;
  showMenuByDefault?: boolean;
  placeholder?: string;
  noResultsMessage?: string;
  keepMenuOpen?: boolean;
  showFiscalRanges?: boolean;
  /** @deprecated  The `type` property should be used. `subtype` is no longer returned when using ⚡ZAP™. */
  subtype?: FILTER_NUMBER_SUBTYPES | string;
  options?: unknown[];
}

export interface IFilterMenuOption {
  displayKey: string;
  key: string;
  type: FilterTypeUnion;
  filterComponentProps?: IFilterComponentProps;
}

export type FilterOption = { label: string; value: string };
export interface IFilterOptions {
  options: FilterOption[];
}
export type FilterOptionMap = { [key: string]: FilterOption[] };
