import { useMutation } from 'react-query';
import { useAppMessaging } from '@hooks';
import { deleteData } from '@utilities/xhr';

export function useDeleteCompanyApp(companyAppId: string | undefined) {
  const showMessage = useAppMessaging();

  return useMutation(
    async (blacklist: boolean = true) =>
      await deleteData({
        path: `companies/$companyId/apps/${companyAppId}`,
        params: { blacklist },
      }),
    {
      onSuccess: ({ body }) => showMessage({ message: body.message }),
      onError: () =>
        showMessage({
          message: 'There was an error deleting your subscription. Please try again.',
          isError: true,
        }),
    },
  );
}
