import { useEffect } from 'react';

/**
 * calls function on click outside of component
 * @param ref - React ref to component to listen for clicks outside of
 * @param handler {function} - called on click outside
 * @param disable {boolean} - disable tracking of click events
 */
export function useHandleClickOutside(ref, handler, disable = false) {
  useEffect(() => {
    if (!handler || disable) {
      return;
    }
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, disable]);
}
