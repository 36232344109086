import { Component } from 'react';

/* This HOC is used in class-based components that need event tracking.
   Please use the useEventTracking custom hook in functional components. */

function eventTrackingHOC(Komponent) {
  class extendedComponent extends Component {
    render() {
      return (
        <Komponent
          {...this.props}
          sendEvent={({ eventName, data }) => window.analytics.track(eventName, data)}
        />
      );
    }
  }

  return extendedComponent;
}

export default eventTrackingHOC;
