import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { useFeatureFlags } from '@hooks';
import { SavingsEvent } from '@pages/insightsWorkbench/savingsEvents/types';

type GetSavingsEventResponse = {
  body: SavingsEvent;
};

export function useGetSavingsEvent(eventId?: string) {
  const { isInitiativesEventsEnabled } = useFeatureFlags('initiatives-events');
  return useQuery(
    ['savings-center', 'events', 'getEvent', eventId],
    async () => {
      const response: GetSavingsEventResponse = await getData({
        path: `savingsCenter/companies/$companyId/events/${eventId}`,
      });

      return response.body;
    },
    {
      enabled: isInitiativesEventsEnabled && !!eventId,
    },
  );
}
