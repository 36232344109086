import moment from 'moment';
import { DownloadButton, DownloadButtonProps } from '@zylo/orchestra';
import { stringify } from 'query-string';
import { useColumnsState } from '@contexts/columnsContext';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';
import { useSortState } from '@contexts/sortContext';
import { SendEventFunction, useEventTracking, useFeatureFlags, useFiscalData } from '@hooks';
import { APIRequestFilters, IJsonFilters } from '@typings';
import { useCompanyData } from '@hooks/company';

type TableExportConfigProps = Pick<
  ComposeDownloadUri,
  'appName' | 'endpoint' | 'gridName' | 'status' | 'urlParams'
> & {
  hiddenFilters?: string[];
};
type ButtonStyleProps = Pick<DownloadButtonProps, 'buttonColor' | 'className'>;
type TableExportButtonProps = ButtonStyleProps & TableExportConfigProps;

/** This export button should be used going forward for any tables that
 * connect to the filters, columns and sort contexts. In additions to accessing many of the needed params
 * for downloads (companyName, fields, filters, listFilter, listSort, search, appName), the `downloads`
 * feature flag will be checked and the button will not render if it is not enabled.
 *
 * ```
 *  <TableExportButton endpoint="companies/$companyId/search/users" gridName="Team" />
 * ```
 */
export default function TableExportButton({
  buttonColor,
  className,
  ...props
}: TableExportButtonProps) {
  return (
    <TableExportButton_DisplayLayer
      buttonColor={buttonColor}
      className={className}
      {...useDataLayer(props)}
    />
  );
}

type TableExportButtonDisplayLayerProps = UseDataLayerValues & ButtonStyleProps;

export function TableExportButton_DisplayLayer({
  buttonColor,
  className,
  downLoadUri,
  gridName,
  isDownloadsEnabled,
  sendEvent,
}: TableExportButtonDisplayLayerProps) {
  return isDownloadsEnabled ? (
    <DownloadButton
      buttonColor={buttonColor}
      className={className}
      collapseMediaQueryWidth={1100}
      downloadUri={downLoadUri}
      handleClick={() =>
        sendEvent({
          eventName: 'EXPORT_GRID',
          data: { 'Grid Exported': gridName },
        })
      }
      type="secondary"
    />
  ) : null;
}

type UseDataLayerValues = {
  downLoadUri(): string;
  gridName: string;
  isDownloadsEnabled: boolean;
  sendEvent: SendEventFunction;
};

function useDataLayer({
  appName,
  endpoint,
  gridName,
  status,
  urlParams,
}: TableExportConfigProps): UseDataLayerValues {
  const { isDownloadsEnabled } = useFeatureFlags('downloads');
  const { columnsOrder } = useColumnsState();
  const { apiRequestFilters = '[]' } = useFiltersState();
  const { currentSort = '', sortApiRequestParam = '' } = useSortState();
  const includeFiscal = useFiscalData();
  const { id: companyId, companyName } = useCompanyData().companyData;

  const search = useSearchState() ?? '';
  const sendEvent = useEventTracking();

  if (!urlParams) {
    urlParams = {};
  }

  if (includeFiscal) {
    urlParams['useFiscalData'] = 'true';
  }

  function downLoadUri() {
    return composeDownloadUri({
      companyName,
      fields: columnsOrder,
      listFilter: JSON.parse(apiRequestFilters),
      listSort: [sortApiRequestParam ? sortApiRequestParam : (currentSort as string)],
      search,
      appName,
      endpoint: endpoint.replace('$companyId', companyId),
      gridName,
      status,
      urlParams,
    });
  }

  return {
    downLoadUri,
    gridName,
    isDownloadsEnabled,
    sendEvent,
  };
}

export type ComposeDownloadUri = {
  appName?: string;
  companyName: string;
  endpoint: string;
  fields: string[];
  filters?: IJsonFilters;
  gridName: string;
  listFilter: APIRequestFilters;
  listSort: [string];
  search: string;
  status?: string;
  urlParams?: { [key: string]: string };
};

export function composeDownloadUri({
  appName,
  companyName,
  endpoint,
  fields,
  filters,
  gridName,
  listFilter,
  listSort,
  search,
  status,
  urlParams = {},
}: ComposeDownloadUri): string {
  const { API_URI } = process.env;

  const scrubbedCompanyName = escape(companyName.replace(/\s/g, ''));
  const scrubbedGridName = escape(gridName.replace(/\s/g, '_'));
  const date = moment().format('MM-DD-YYYY');
  let fileName;
  if (appName) {
    const scrubbedAppName = escape(appName.replace(/\s/g, ''));
    fileName = `${scrubbedCompanyName}_${scrubbedGridName}_${scrubbedAppName}_Export_${date}`;
  } else {
    fileName = `${scrubbedCompanyName}_${scrubbedGridName}_Export_${date}`;
  }

  const licenseParams = filters
    ? Object.entries(filters)
        .map(([filterKey, filterValue]) => `&licenseType=${filterKey}&licenseName=${filterValue}`)
        .join('')
    : '';

  const params = stringify(
    {
      fields: Array.isArray(fields) ? fields : undefined,
      fileName,
      format: 'csv',
      sort: Array.isArray(listSort) ? listSort : undefined,
      search: search && search !== '' ? search : undefined,
      status,
      filters: Array.isArray(listFilter)
        ? encodeURIComponent(JSON.stringify(listFilter))
        : undefined,
      ...urlParams,
    },
    { arrayFormat: 'comma', encode: false },
  );

  return `${API_URI}/${endpoint}?${params}${licenseParams}`;
}
