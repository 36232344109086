/**
 * returns an array of indexes of the highest values in the arr arg
 * @param {number[]} numArr
 * @param {number} numResults desired length of returned array
 * @returns {number[]} array of indices of highest values
 */
function findMaxIndices(numArr, numResults) {
  const maxIndices = [];
  for (let i = 0; i < numArr.length; i += 1) {
    maxIndices.push(i);

    if (maxIndices.length > numResults) {
      maxIndices.sort((a, b) => {
        return numArr[b] - numArr[a];
      });
      maxIndices.pop();
    }
  }
  return maxIndices;
}

export default findMaxIndices;
