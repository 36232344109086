import styled from 'styled-components/macro';
import { FormState } from 'react-hook-form';
import { mapMetadataToFields } from '@frameworks/zap/bulkEditDrawer/mapMetadataToFields';
import { useZAPState } from '@frameworks/zap/ZAPContext';
import { ZAPProps } from '@frameworks/zap/ZAP';
import { FormValues, ZyloForm } from '@frameworks/form-fns';
import { useCompanyData } from '@hooks/company';
import { MetadataResponse } from '@hooks';

const BulkEditDrawerFormContainer = styled.div`
  padding: 20px;

  .bulk-edit-form {
    fieldset {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 1.25rem;
      padding-bottom: 12px;

      legend {
        width: 100%;
      }

      label {
        height: 25px;
      }
    }
  }
`;

type BulkEditDrawerFormPageProps = {
  handleSubmit: (formValues: FormValues) => void;
  onValueChange: (formValues: FormValues, formState: FormState<FormValues>) => void;
  savedValues?: FormValues;
  ZAPMetadata: MetadataResponse['body'] | undefined;
};

type BulkEditDrawerFormPageDataProps = Pick<ZAPProps<{}>, 'customBulkEditForm' | 'ZAPName'> & {
  currency: string;
};

type BulkEditDrawerFormPageDisplayProps = BulkEditDrawerFormPageProps &
  BulkEditDrawerFormPageDataProps;

export default function BulkEditDrawerFormPage(props: BulkEditDrawerFormPageProps) {
  return <BulkEditDrawerFormPage_DisplayLayer {...props} {...useDataLayer()} />;
}

export function BulkEditDrawerFormPage_DisplayLayer({
  customBulkEditForm: CustomBulkEditForm,
  currency,
  handleSubmit,
  onValueChange,
  ZAPMetadata,
  ZAPName,
}: BulkEditDrawerFormPageDisplayProps) {
  if (CustomBulkEditForm) {
    return (
      <BulkEditDrawerFormContainer>
        <CustomBulkEditForm
          id="bulk-edit-form"
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
        />
      </BulkEditDrawerFormContainer>
    );
  }

  return (
    <BulkEditDrawerFormContainer>
      {ZAPMetadata && (
        <ZyloForm
          className="bulk-edit-form"
          disabledStyle="widget"
          editMode="enabled"
          error={!ZAPMetadata}
          errorMessage="Sorry, there was a problem retrieving bulk edit options."
          fields={mapMetadataToFields({
            currency,
            ZAPMetadata,
            ZAPName,
          })}
          id="bulk-edit-form"
          layout="oneColumn"
          mode="onChange"
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
        />
      )}
    </BulkEditDrawerFormContainer>
  );
}

function useDataLayer() {
  const { chargeCurrency } = useCompanyData().companyData.internalPreferences;
  const { customBulkEditForm, ZAPName } = useZAPState();

  return {
    currency: chargeCurrency,
    customBulkEditForm,
    ZAPName,
  };
}
