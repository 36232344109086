import { ConfirmationModal, Icon, Icons } from '@zylo/orchestra';
import { useState } from 'react';
import styled from 'styled-components/macro';
import PopoverMenu from '@components/PopoverMenu';
import PopoverMenuItem, { OptionData } from '@components/PopoverMenuItem';
import { CellProps } from '@typings';

const AssignmentCellContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CustomValueWrapper = styled.div`
  width: 100%;
`;

export type AssignmentCellOption = OptionData & {
  icon: Icons;
  isDisabled: boolean;
  optionText: string;
  value?: string | boolean;
};

export type AssignmentCellProps = {
  data: string;
  options: AssignmentCellOption[];
  confirmationModal?: {
    contentText: string;
    headerText: string;
  };
  onChange: (item: OptionData, rowData: CellProps['rowData']) => void;
  customValue?: any;
} & Omit<CellProps, 'data'>;

export default function AssignmentCell({
  data,
  options,
  confirmationModal,
  onChange,
  customValue,
  rowData,
}: AssignmentCellProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({} as OptionData);

  function toggleConfirmationModal() {
    setIsConfirmationModalOpen((previousState) => !previousState);
  }

  function verifyAction(item: OptionData) {
    setSelectedOption(item);
    toggleConfirmationModal();
  }

  function confirmModalAction() {
    onChange(selectedOption, rowData);
    toggleConfirmationModal();
  }

  function confirmPopoverAction(item: OptionData) {
    onChange(item, rowData);
  }

  return (
    <AssignmentCellContainer>
      <CustomValueWrapper>{customValue || data}</CustomValueWrapper>
      <PopoverMenu menuButton={<Icon hoverColor="blue" icon="IconDots" size={1} relativeSize />}>
        {options.map((option) => {
          return (
            <PopoverMenuItem
              action={confirmationModal ? verifyAction : confirmPopoverAction}
              icon={option.icon}
              isDisabled={option.isDisabled || false}
              key={option.optionText}
              optionData={option}
              optionText={option.optionText}
            />
          );
        })}
      </PopoverMenu>
      {confirmationModal && (
        <ConfirmationModal
          contentText={confirmationModal.contentText}
          handleDismissClick={toggleConfirmationModal}
          handlePrimaryClick={confirmModalAction}
          headerText={confirmationModal.headerText}
          isOpen={isConfirmationModalOpen}
          width={330}
        />
      )}
    </AssignmentCellContainer>
  );
}
