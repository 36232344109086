import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, defaultborder, FontStyles, Icon } from '@zylo/orchestra';
import styled, { css } from 'styled-components/macro';
import { useTheme } from '@mui/material/styles';
import { useFiltersState } from '@contexts/filtersContext';
import {
  useActiveSavedFilterState,
  useActiveSavedFilterUpdate,
} from '@contexts/savedFilterContexts/activeSavedFilterContext';
import { useSavedFilterModificationState } from '@contexts/savedFilterContexts/savedFilterModificationContext';
import { usePermissions } from '@hooks';
import SavedFiltersForm from './modals/SavedFiltersForm';
import { useUpdateSavedFilter } from './hooks';

const StyledSavedFiltersControls = styled.div`
  margin-bottom: 8px;

  ${({ hasActiveSavedFilter }) => {
    return hasActiveSavedFilter
      ? css`
          border-bottom: ${defaultborder};
          padding-bottom: 0.5rem;
        `
      : undefined;
  }}

  .filters-name-container {
    h2 {
      color: ${() => {
        const { palette } = useTheme();

        return palette.primary.main;
      }};
      font-size: ${FontStyles.size['20px']};
      font-weight: ${FontStyles.weight[500]};
      margin: 0;
    }
  }
`;

export default function SavedFiltersControls() {
  return <SavedFiltersControls_DisplayLayer {...useDataLayer()} />;
}

export function SavedFiltersControls_DisplayLayer({
  activeSavedFilter,
  authCanAccessAdmin,
  authCanAccessProductAdmin,
  closeModal,
  defaultViewHasBeenModified,
  hasAvailableFilters,
  launchInsightFilterForm,
  launchSavedFiltersForm,
  returnToDefaultView,
  savedFilterHasBeenModified,
  showFiltersForm,
  showInsightsForm,
  updateSavedFilter,
}) {
  const { filterName, isInsight, teamFilter } = activeSavedFilter || {};
  const permissionsAllowUpdateFilters = !teamFilter || (teamFilter && authCanAccessAdmin);

  return hasAvailableFilters ? (
    <StyledSavedFiltersControls
      className="flex-container justify-between align-center saved-filters-controls-first-row"
      hasActiveSavedFilter={Boolean(activeSavedFilter)}
    >
      <div className="flex-container filters-name-container">
        {isInsight && <Icon color="darkgray" icon="IconBulb" size={1.5} relativeSize />}
        <h2>{filterName}</h2>
      </div>

      <div className="flex-container filter-controls">
        {defaultViewHasBeenModified && (
          <Button
            buttonColor="gray"
            handleClick={returnToDefaultView}
            text="Return to Default View"
            type="link"
          />
        )}

        {savedFilterHasBeenModified && permissionsAllowUpdateFilters ? (
          <Button handleClick={updateSavedFilter} text="Update Filter" type="link" />
        ) : null}

        {authCanAccessProductAdmin && isInsight ? (
          <>
            <Button handleClick={launchInsightFilterForm} text="Update Insight" type="link" />
            {showInsightsForm && <SavedFiltersForm handleClose={closeModal} isInsight />}
          </>
        ) : null}

        <Button handleClick={launchSavedFiltersForm} text="Save as New Filter" type="link" />
        {showFiltersForm && <SavedFiltersForm handleClose={closeModal} />}
      </div>
    </StyledSavedFiltersControls>
  ) : null;
}

SavedFiltersControls_DisplayLayer.propTypes = {
  activeSavedFilter: PropTypes.oneOfType([
    PropTypes.shape({
      // columnsOrder is an empty string on Saved Filters that were created before columnsOrder column
      columnsOrder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      filters: PropTypes.shape({}),
      tableSort: PropTypes.string,
    }),
    PropTypes.bool, // activeSavedFilter can be false
  ]),
  authCanAccessAdmin: PropTypes.bool,
  authCanAccessProductAdmin: PropTypes.bool,
  closeModal: PropTypes.func,
  defaultViewHasBeenModified: PropTypes.bool,
  hasAvailableFilters: PropTypes.bool,
  launchInsightFilterForm: PropTypes.func.isRequired,
  launchSavedFiltersForm: PropTypes.func.isRequired,
  returnToDefaultView: PropTypes.func.isRequired,
  savedFilterHasBeenModified: PropTypes.bool,
  showFiltersForm: PropTypes.bool,
  showInsightsForm: PropTypes.bool,
};

function useDataLayer() {
  const [showFiltersForm, setShowFiltersForm] = useState(false);
  const [showInsightsForm, setShowInsightsForm] = useState(false);
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { returnToDefaultView } = useActiveSavedFilterUpdate();
  const { filters: availableFilters } = useFiltersState();
  const { defaultViewHasBeenModified, savedFilterHasBeenModified } =
    useSavedFilterModificationState();
  const { authCanAccessAdmin, authCanAccessProductAdmin } = usePermissions('admin', 'productAdmin');
  const updateSavedFilter = useUpdateSavedFilter();

  return {
    activeSavedFilter,
    authCanAccessAdmin,
    authCanAccessProductAdmin,
    closeModal: () => {
      setShowFiltersForm(false);
      setShowInsightsForm(false);
    },
    defaultViewHasBeenModified,
    hasAvailableFilters: !!availableFilters,
    launchInsightFilterForm: () => setShowInsightsForm(true),
    launchSavedFiltersForm: () => setShowFiltersForm(true),
    returnToDefaultView,
    savedFilterHasBeenModified,
    showFiltersForm,
    showInsightsForm,
    updateSavedFilter,
  };
}
