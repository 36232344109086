import { Company, IGenericMapObject } from '@typings';
import { cleanPayloadObject, nullToEmptyString } from '@utilities/util';

type CleanAppDetailsPayloadDataProps = {
  appDetailsPreferences: Company['preferences']['appDetails'];
  payloadData: IGenericMapObject<any>;
};

export default function cleanAppDetailsPayloadData({
  appDetailsPreferences,
  payloadData,
}: CleanAppDetailsPayloadDataProps) {
  const allowedFields = [
    'appId',
    'status',
    'appSpecificFields',
    'category',
    'subcategory',
    ...appDetailsPreferences.application.map(({ slug }: { slug: string }) => slug),
    ...appDetailsPreferences.general.map(({ slug }: { slug: string }) => slug),
    ...appDetailsPreferences.subscription.map(({ slug }: { slug: string }) => slug),
  ];
  const payloadFields = cleanPayloadObject(allowedFields, payloadData);
  const {
    lastMonthlyPaymentDate,
    purchasedLicenseCount,
    monthlyAmount,
    renewalDate,
    totalContractValue,
    startDate,
    userId,
  } = payloadData;

  return {
    ...payloadFields,
    lastMonthlyPaymentDate: nullToEmptyString(lastMonthlyPaymentDate),
    purchasedLicenseCount: parseFloat(purchasedLicenseCount) || 0,
    monthlyAmount: parseFloat(monthlyAmount) || 0,
    renewalDate: nullToEmptyString(renewalDate),
    totalContractValue: parseFloat(totalContractValue) || 0,
    startDate: nullToEmptyString(startDate),
    userId: nullToEmptyString(userId),
  };
}
