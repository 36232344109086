import { Tooltip } from '@zylo/orchestra';
import { startCase } from 'lodash-es';
import { DefaultCellContainer } from './DefaultCell';

type TransformedStringCellProps = {
  data?: string;
  transformFunction: (str: string) => string;
  truncate?: number;
};

export function TransformedStringCell({ data, truncate }: TransformedStringCellProps) {
  const transformedData = data ? startCase(data) : '-';

  if (truncate && transformedData.length > truncate) {
    return (
      <DefaultCellContainer>
        <Tooltip text={transformedData}> {transformedData.substring(0, truncate)}...</Tooltip>
      </DefaultCellContainer>
    );
  }
  return <DefaultCellContainer>{transformedData}</DefaultCellContainer>;
}
