import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getData } from '@utilities/xhr';
import { Company } from '@typings';
import { actions as featureFlagActions } from '@modules/featureFlags';

const defaultCompanyValues = {
  companyBranding: [],
  companyIntegrations: [],
  companyName: '',
  companySlug: '',
  createdAt: '',
  emailDomain: [],
  features: [],
  id: '',
  insights: {},
  internalPreferences: {
    chargeCurrency: 'USD',
    companyAccountType: '',
    companyShowNonCloud: false,
    currentFiscalYearStartDate: '',
    customCategoriesEnabled: false,
    fiscalYearSettings: {
      fiscalYearBasis: undefined,
      fiscalYearStartMonth: undefined,
      fiscalYearType: undefined,
    },
    timezone: '',
  },
  lastStatsRun: undefined,
  monthlyInsights: {},
  preferences: {
    appDetails: {
      application: [],
      general: [],
      subscription: [],
    },
  },
  primaryUserStore: '',
  primaryUserStoreCompanyAppId: '',
  primaryUserStoreIntegrationId: '',
  stats: {
    appCount: 0,
    appSpend: 0,
    currentSpend: 0,
    userAppAvgSpend: 0,
    userAppCount: 0,
    userCount: 0,
    spendType: [
      {
        amount: 0,
        type: '',
      },
    ],
    spendCostCenter: [
      {
        amount: 0,
        account: '',
      },
    ],
    totalSpend: 0,
    pendingTransactions: 0,
  },
  timeMachineDate: '',
};

function massageCompanyData(data: Company) {
  return {
    ...defaultCompanyValues,
    ...data,
    stats: { ...defaultCompanyValues.stats, ...data.stats },
  };
}

export async function fetchCompanyData() {
  const { body } = await getData({
    path: 'companies/$companyId/',
  });

  return massageCompanyData(body);
}

export function useCompanyData() {
  const dispatch = useDispatch();

  const { data, isLoading, status } = useQuery(['companyData'], fetchCompanyData, {
    onSuccess: ({ companySlug, internalPreferences }) => {
      dispatch(
        featureFlagActions.initializeLaunchDarklyClient({ companySlug, internalPreferences }),
      );
    },
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
  });

  return { companyData: data || defaultCompanyValues, isLoading, status };
}
