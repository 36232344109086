import { useMutation, useQueryClient } from 'react-query';
import { putData } from '@utilities/xhr';
import { useAppMessaging } from '@hooks/useAppMessaging';
import { useCompanyData } from '@hooks/company';
import { cleanPayloadObject, nullToEmptyString } from '@utilities/util';
import { Company } from '@typings';

function cleanCompanyPayloadData(mergedCompanyData: Company) {
  const { timeMachineDate } = mergedCompanyData;
  const payload = cleanPayloadObject(
    [
      'catalogSupportContact',
      'companyName',
      'defaultContact',
      'emailDomain',
      'preferences',
      'primaryUserStore',
      'primaryUserStoreIntegrationId',
      'showProvisionedOnAppCat',
      'timeMachineDate',
    ],
    mergedCompanyData,
  ) as Company;

  payload.timeMachineDate = nullToEmptyString(timeMachineDate);

  return payload;
}

export async function updateCompany(
  currentCompanyData: Company,
  companyUpdates: Record<string, unknown>,
) {
  const response = await putData({
    path: `companies/$companyId`,
    params: cleanCompanyPayloadData({
      ...currentCompanyData,
      ...companyUpdates,
      preferences: {
        ...currentCompanyData.preferences,
        ...(companyUpdates?.preferences || {}),
      },
    }),
  });
  return response;
}

export function useUpdateCompany() {
  const currentCompanyData = useCompanyData().companyData;
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();

  return useMutation(
    async (companyUpdates: Record<string, unknown>) =>
      updateCompany(currentCompanyData, companyUpdates),
    {
      onSuccess: async ({ body }) => {
        showMessage({
          message: `The "${body.companyName}" company settings have been successfully updated.`,
        });
        await queryClient.invalidateQueries(['companyData']);
      },
      onError: () => {
        showMessage({
          message: 'There was an error updating your company. Please try again.',
          isError: true,
        });
      },
    },
  );
}
