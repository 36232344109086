import classNames from 'clsx';
import { ZyloUser } from '@typings';

export type NameCellProps<T extends object> = {
  className?: string;
  employeeIcon?: boolean;
  rowData: T;
  truncate?: number;
};

export function NameCell<T extends object>({
  className,
  employeeIcon,
  rowData,
  truncate,
}: NameCellProps<T>) {
  const { firstName, lastName } = rowData as ZyloUser;
  const displayName = firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '-';

  return (
    <div className="name-cell">
      {employeeIcon && (
        <span
          className={classNames(
            'employee-icon flex-container align-center justify-center',
            className,
          )}
        >
          {`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
        </span>
      )}
      <span className="name-cell-name">
        {truncate && displayName.length > truncate
          ? `${displayName.substring(0, truncate)}...`
          : displayName}
      </span>
    </div>
  );
}
