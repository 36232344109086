import styled from 'styled-components/macro';
import { Colors, Divider, FontStyles, Icon, ZyloTag } from '@zylo/orchestra';
import { ReactNode } from 'react';
import { BulkEditApiChanges, BulkEditOperation } from './types';

const RedDisplayValue = styled.span`
  color: ${Colors.error};
`;

const BulkEditDrawerConfirmationContainer = styled.div`
  .bulk-edit__confirmation {
    overflow-wrap: break-word;
    padding: 1.5rem;
    width: 100%;

    .title {
      font-size: ${FontStyles.size['16px']};
      font-weight: ${FontStyles.weight[500]};
      color: ${Colors.teal5};
    }

    .callout-text {
      color: ${Colors.sienna7};
      font-weight: ${FontStyles.weight[500]};
      margin-bottom: 0.5rem;
    }

    .field-display {
      margin: 5px 0;

      .field-display-name {
        margin-right: 5px;
        color: ${Colors.teal5};
        font-weight: ${FontStyles.weight[500]};
      }

      .field-form-value {
        font-weight: ${FontStyles.weight[400]};
        > * {
          margin: 2px 5px 2px 0;
        }
      }
    }

    .warning-text {
      display: flex;
      color: ${Colors.error};
      font-weight: ${FontStyles.weight[400]};
      margin-bottom: 1.5rem;

      .icon-container {
        margin-right: 5px;
        margin-top: -1px;
      }
    }

    label {
      padding: 0 1.5rem;
    }
  }
`;
type BulkEditDrawerConfirmationPageProps = {
  formApiChanges: BulkEditApiChanges[];
};

const OperationToLabelMap: Record<BulkEditOperation, string> = {
  append: 'Add',
  // eslint-disable-next-line camelcase
  array_remove: 'Remove',
  remove: '',
  replace: 'Replace All',
};

function formatFieldLabel({ fieldLabel, displayValue, operation }: BulkEditApiChanges) {
  if (Array.isArray(displayValue)) {
    return `${OperationToLabelMap[operation]} ${fieldLabel}:`;
  }
  return `${fieldLabel}:`;
}

function formatFieldDisplayValue({
  field,
  displayValue,
  operation,
}: BulkEditApiChanges): ReactNode {
  if (Array.isArray(displayValue)) {
    if (operation === 'remove') {
      return <RedDisplayValue>{'Clear All'}</RedDisplayValue>;
    }
    return (
      <>
        {displayValue.map((singleValue) => {
          return (
            <ZyloTag color="primary" key={`${field}-value-${singleValue}`} label={singleValue} />
          );
        })}
      </>
    );
  }

  if (operation === 'remove') {
    return <RedDisplayValue>{'Blank'}</RedDisplayValue>;
  }

  return displayValue;
}

export default function BulkEditDrawerConfirmationPage({
  formApiChanges = [],
}: BulkEditDrawerConfirmationPageProps) {
  return (
    <BulkEditDrawerConfirmationContainer>
      <div className="bulk-edit__confirmation">
        <div className="title">Bulk Edit Details</div>
        <Divider margin="1rem" />
        <div className="warning-text">
          <div className="icon-container">
            <Icon color="error" icon="IconInfoCircle" size={18} />
          </div>
          <div>
            Bulk edit changes cannot be reverted. Please review the edited data fields before
            submitting changes.
          </div>
        </div>

        <div className="callout-text">Edited Fields</div>
        <ul className="edited-field-list">
          {formApiChanges.map((apiChange: BulkEditApiChanges) => (
            <li className="field-display" key={`${apiChange.field}-display`}>
              <span className="field-display-name">{formatFieldLabel(apiChange)}</span>
              <span className="field-form-value">{formatFieldDisplayValue(apiChange)}</span>
            </li>
          ))}
        </ul>
      </div>
    </BulkEditDrawerConfirmationContainer>
  );
}
