import PropTypes from 'prop-types';
import { Children, cloneElement, PureComponent } from 'react';
import classNames from 'clsx';
import { debounce, get, isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { actions as zyloFormActions } from '../../modules/zyloForm';
import { reduxObject } from '../../utilities/propTypes';

class AutosaveForm extends PureComponent {
  static propTypes = {
    additionalOnChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    className: PropTypes.string,
    data: reduxObject,
    form: PropTypes.string,
    formDisabled: PropTypes.bool,
    formStore: reduxObject,
    formSubmissionError: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    saveMethod: PropTypes.func.isRequired,
    saveZyloForm: PropTypes.func,
    savedData: reduxObject,
  };

  constructor() {
    super();

    this.saveForm = debounce(this.saveForm, 3500);
  }

  componentDidMount() {
    const { initialize, data } = this.props;
    initialize(data);
  }

  componentDidUpdate(prevProps) {
    const { data, savedData, formSubmissionError, initialize, form } = this.props;

    if (get(savedData, form) && savedData !== prevProps.savedData) {
      initialize(savedData[form]);
      // this seems crazy to reinit every time data changes
    } else if (formSubmissionError || !isEqual(data, prevProps.data)) {
      initialize(data);
    }
  }

  handleFieldChange = (field, onChange, val) => {
    const { additionalOnChange } = this.props;

    onChange(val);
    this.saveForm();

    if (additionalOnChange) {
      additionalOnChange(field, val);
    }
  };

  handleKeyPress = (e) => {
    const { key, target } = e;

    if (key === 'Enter' && target.type !== 'textarea') {
      e.preventDefault();
    }
  };

  saveForm = () => {
    const { saveZyloForm, saveMethod, form, formStore } = this.props;
    const currentForm = formStore[form];
    const { values, syncErrors } = currentForm;

    if (!syncErrors) {
      saveZyloForm({ formName: form, method: saveMethod, autosaveValues: values });
    }
  };

  // The convention here is that the form will exhaust the DOM tree looking for fields as long as it keeps hitting divs or fieldsets.
  formatForm = (markup) => {
    return Children.map(markup, (element) => {
      let content;

      if (element) {
        const { type, props = {}, key } = element;
        const { className: elementClassName } = props;

        if (props.component) {
          content = cloneElement(element, {
            key: `form-field-${get(element, 'props.name', '')}`,
            handleChange: this.handleFieldChange,
            disabled: this.props.formDisabled,
            autosave: true,
          });
        } else if (type === 'fieldset' || type === 'div') {
          content = (
            <element.type
              className={classNames(elementClassName, 'zylo-form-fieldset')}
              key={`form-fieldset-${key}`}
            >
              {this.formatForm(props.children)}
            </element.type>
          );
        } else {
          content = element;
        }
      }

      return content;
    });
  };

  render() {
    const { className, children } = this.props;

    return (
      // Form is Autosaved, Enter can be suppressed
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <form
        className={classNames('zylo-form autosave-form', className)}
        onKeyPress={this.handleKeyPress}
      >
        {this.formatForm(children)}
      </form>
    );
  }
}

const mapStateToProps = ({ zyloForm, form }, { data }) => {
  const { savedData, formSubmissionError } = zyloForm;
  const initialValues = { ...data };

  return { savedData, formSubmissionError, initialValues, formStore: form };
};

export default connect(mapStateToProps, { ...zyloFormActions })(reduxForm({})(AutosaveForm));
