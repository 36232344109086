import { ReactNode } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { getDroppableStyle } from '../../utilities/dndUtil';

type DroppableSortFieldsCategoryItemsProps =
  | { categoryLimit: number; numberOfItemsCurrentlyInCategory: number }
  | { categoryLimit?: undefined; numberOfItemsCurrentlyInCategory?: undefined };

type DroppableSortFieldsCategoryProps = DroppableSortFieldsCategoryItemsProps & {
  children: ReactNode[];
  id: 'activeColumns' | 'freezeColumns';
};

export default function DroppableSortFieldsCategory({
  children,
  categoryLimit,
  id,
  numberOfItemsCurrentlyInCategory,
}: DroppableSortFieldsCategoryProps) {
  return (
    <Droppable droppableId={id} type="field">
      {(provided, snapshot) => {
        const noDrop =
          categoryLimit === numberOfItemsCurrentlyInCategory &&
          !snapshot.draggingFromThisWith &&
          id === 'freezeColumns';

        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              ...getDroppableStyle(snapshot.isDraggingOver),
              cursor: noDrop ? 'no-drop' : undefined,
              minHeight: '30px',
            }}
          >
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}
