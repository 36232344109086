import PropTypes from 'prop-types';
import { Icon } from '@zylo/orchestra';

export function SidebarTriggerCell({ data, rowData, handleSidebarOpen }) {
  const handleClick = () => {
    handleSidebarOpen(rowData);
  };

  return (
    <div className="sidebar-trigger-cell">
      <Icon hoverColor="blue" icon="IconList" onClick={handleClick} size={20} />
      <p>{data}</p>
    </div>
  );
}

SidebarTriggerCell.propTypes = {
  data: PropTypes.string.isRequired,
  handleSidebarOpen: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  rowData: PropTypes.object.isRequired,
};
