import classNames from 'clsx';
import moment from 'moment';
import { get } from 'lodash-es';
import { convertDateStringToMoment } from '@utilities/util';

export type DateCellProps = {
  className?: string;
  data: string;
  fallbackProperty?: string;
  rowData?: Record<string, any>;
  showTime?: boolean;
};

export function DateCell({
  className,
  data,
  fallbackProperty,
  rowData,
  showTime = false,
}: DateCellProps) {
  function formatDateDisplay() {
    const rawDate = data || get(rowData, fallbackProperty ?? '');

    if (!rawDate) return '-';

    const time = showTime ? ` ${moment.utc(rawDate).format('LT')}` : '';

    return `${convertDateStringToMoment(rawDate)?.format('L')}${time}`;
  }

  return <div className={classNames('numerical-cell', className)}>{formatDateDisplay()}</div>;
}
