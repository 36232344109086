import PropTypes from 'prop-types';

export function PercentageCell({ data, isIntegerPercent = false }) {
  const parsedData = parseFloat(data);
  const formattedData = isIntegerPercent ? parsedData : Math.round(parsedData * 100);
  return <div className="numerical-cell">{isNaN(parsedData) ? '-' : `${formattedData}%`}</div>;
}

PercentageCell.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isIntegerPercent: PropTypes.bool,
};
