/**
 * @param {string} word
 */
export default function pluralize(word) {
  if (typeof word === 'string' && word) {
    const lastLetterIndex = word.length - 1;
    if (word[lastLetterIndex] === 'y') {
      return `${word.slice(0, lastLetterIndex)}ies`;
    }
    return `${word}s`;
  }
  return word;
}
