import create from 'zustand';

type CurrentAppDetailsTabStateType = {
  currentTab: string | '';
};

export type CurrentAppDetailsTabActionsType = {
  changeTab: (tabName: string) => void;
};

export const useGetAppDetailsTab = create<
  CurrentAppDetailsTabStateType & CurrentAppDetailsTabActionsType
>()((set) => ({
  currentTab: '',
  changeTab: (tabName: string) => set(() => ({ currentTab: tabName })),
}));
