import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import NumberInput from '@components/forms/inputs/NumberInput';
import ReactSelect from '@components/forms/inputs/ReactSelect';
import DatePicker from '@components/datepickers/DatePicker';
import { boundsFromValue, STATIC_FILTER_OPTIONS } from '@utilities/filterUtils';
import { useFiscalData } from '@hooks';

function DateFilter({ name, onChange, value = ':::', showFiscalRanges = false }) {
  const [startDate, setStartDate] = useState(boundsFromValue(value)[0]);
  const [endDate, setEndDate] = useState(boundsFromValue(value)[1]);
  const [operator, setOperator] = useState();
  const [customOperator, setCustomOperator] = useState('-');
  const [customOperatorA, setCustomOperatorA] = useState('-');
  const [customOperatorB, setCustomOperatorB] = useState('-');
  const [customOperatorValue, setCustomOperatorValue] = useState();
  const [customOperatorValueA, setCustomOperatorValueA] = useState();
  const [customOperatorValueB, setCustomOperatorValueB] = useState();
  const [customOperatorUnit, setCustomOperatorUnit] = useState('days');
  const includeFiscal = useFiscalData();
  const valueRef = useRef(value);
  const selectedOperatorRef = useRef();
  const operatorOptions = [
    {
      optionType: 'standardOperator',
      options: [
        { label: 'is between', value: 'between' },
        { label: 'is equal to', value: 'equals' },
        { label: 'is before', value: 'lt' },
        { label: 'is after', value: 'gt' },
        { label: 'is on or before', value: 'lte' },
        { label: 'is on or after', value: 'gte' },
      ],
    },
    {
      optionType: 'preformattedRange',
      options: [
        { label: 'Next Calendar Month', value: 'month:+:1:months' },
        { label: 'Next 30 Days', value: 'today:+:30:days' },
        { label: 'Next 60 Days', value: 'today:+:60:days' },
        { label: 'Next 90 Days', value: 'today:+:90:days' },
        { label: 'Next 120 Days', value: 'today:+:120:days' },
      ],
    },
    {
      optionType: 'preformattedRange',
      options: [
        { label: 'Last Calendar Month', value: 'month:-:1:months' },
        { label: 'Last 30 Days', value: 'today:-:30:days' },
        { label: 'Last 60 Days', value: 'today:-:60:days' },
        { label: 'Last 90 Days', value: 'today:-:90:days' },
        { label: 'Last 120 Days', value: 'today:-:120:days' },
      ],
    },
  ];

  if (includeFiscal && showFiscalRanges) {
    operatorOptions.push(
      {
        optionType: 'preformattedRange',
        options: STATIC_FILTER_OPTIONS.fiscalQuarters,
      },
      {
        optionType: 'preformattedRange',
        options: STATIC_FILTER_OPTIONS.fiscalYears,
      },
    );
  }

  operatorOptions.push(
    {
      optionType: 'preformattedRange',
      options: STATIC_FILTER_OPTIONS.nullOptions,
    },
    {
      optionType: 'customRange',
      options: [
        { label: 'Custom Range', value: 'customDaysRange' },
        { label: 'Custom Range from Today', value: 'custom' },
      ],
    },
  );

  const preformattedRangeValues = useRef(
    operatorOptions
      .filter(({ optionType }) => optionType === 'preformattedRange')
      .reduce(
        (arr, { options }) => [
          ...arr,
          ...options.reduce((groupArr, option) => [...groupArr, option.value], []),
        ],
        [],
      ),
  );

  useEffect(() => {
    if (!operator) {
      if (value === ':::') {
        selectedOperatorRef.current = 'standardOperator';
        setOperator('between');
      } else if (preformattedRangeValues.current.includes(value)) {
        selectedOperatorRef.current = 'preformattedRange';
        setOperator(value);
      } else {
        const [val1, oper1, val2, oper2, , oper3, val3, oper4] = value.split(':');
        let initialOperator = `${oper1}`;

        if (oper2) {
          if (val1 === 'today') {
            selectedOperatorRef.current = 'customRange';
            initialOperator = 'custom';
            setCustomOperator(oper1);
            setCustomOperatorValue(val2);
            setCustomOperatorUnit(oper2);
          } else if (val1 === 'custom') {
            selectedOperatorRef.current = 'customRange';
            initialOperator = 'customDaysRange';
            setCustomOperatorA(oper1);
            setCustomOperatorB(oper3);
            setCustomOperatorValueA(val2);
            setCustomOperatorValueB(val3);
            setCustomOperatorUnit(oper4);
          } else {
            selectedOperatorRef.current = 'standardOperator';
            initialOperator = val1 === val2 ? 'equals' : 'between';
          }
        } else {
          selectedOperatorRef.current = 'standardOperator';
        }

        setOperator(initialOperator);
      }
    }
  }, [operator, value]);

  function isStartDateTenseType() {
    return startDate === 'past' || startDate === 'current' || startDate === 'future';
  }

  useEffect(() => {
    let formattedValue;

    if (!operator || operator === valueRef.current) return;

    if (preformattedRangeValues.current.includes(operator)) {
      valueRef.current = operator;
      onChange(operator);
      return;
    }

    const cleanStartDate = moment(startDate).format('MM/DD/YYYY');
    const cleanEndDate = moment(endDate, moment.localeData().longDateFormat('L')).format(
      'MM/DD/YYYY',
    );

    switch (operator) {
      case 'equals':
        formattedValue = startDate ? `${cleanStartDate}:gte:${cleanStartDate}:lte` : ':::';

        break;
      case 'between': {
        if (startDate && endDate) {
          formattedValue = `${cleanStartDate}:gte:${cleanEndDate}:lte`;
        } else if (startDate) {
          formattedValue = `${cleanStartDate}:gte::`;
        } else if (endDate) {
          formattedValue = `${cleanEndDate}:lte::`;
        } else {
          formattedValue = ':::';
        }

        break;
      }
      case 'custom': {
        if (customOperatorValue !== undefined) {
          formattedValue = `today:${customOperator}:${customOperatorValue}:${customOperatorUnit}`;
        }

        break;
      }
      case 'customDaysRange': {
        if (customOperatorValueA !== undefined && customOperatorValueB !== undefined) {
          formattedValue = `custom:${customOperatorA}:${customOperatorValueA}:${customOperatorUnit}:gte:${customOperatorB}:${customOperatorValueB}:${customOperatorUnit}:lte`;
        }

        break;
      }
      default:
        formattedValue = startDate ? `${cleanStartDate}:${operator}::` : null;
    }

    if (formattedValue !== undefined && formattedValue !== valueRef.current) {
      valueRef.current = formattedValue;
      onChange(formattedValue);
    }
  }, [
    customOperator,
    customOperatorA,
    customOperatorB,
    customOperatorUnit,
    customOperatorValue,
    customOperatorValueA,
    customOperatorValueB,
    endDate,
    onChange,
    operator,
    startDate,
  ]);

  function handleOperatorChange(optionValue, selectedOption) {
    if (selectedOption) {
      selectedOperatorRef.current = selectedOption.group.optionType;
      if (isStartDateTenseType()) {
        setStartDate('');
        setEndDate('');
      }
      setOperator(optionValue);
    }
  }

  return (
    <div className="date-filter">
      <ReactSelect
        className="filter-select"
        onChange={handleOperatorChange}
        options={operatorOptions}
        searchable={true}
        value={operator}
      />

      {selectedOperatorRef.current === 'customRange' && operator === 'custom' && (
        <div className="flex-container date-filter-custom-range">
          <ReactSelect
            onChange={setCustomOperator}
            options={[
              { label: 'Last', value: '-' },
              { label: 'Next', value: '+' },
            ]}
            value={customOperator}
          />
          <NumberInput onChange={setCustomOperatorValue} value={customOperatorValue} />
          <ReactSelect
            onChange={setCustomOperatorUnit}
            options={[
              { label: 'Days', value: 'days' },
              { label: 'Weeks', value: 'weeks' },
              { label: 'Months', value: 'months' },
              { label: 'Years', value: 'years' },
            ]}
            value={customOperatorUnit}
          />
        </div>
      )}

      {selectedOperatorRef.current === 'customRange' && operator === 'customDaysRange' && (
        <div className="date-filter-custom-range-days">
          <div className="flex-container align-center">
            <ReactSelect
              onChange={setCustomOperatorA}
              options={[
                { label: 'Last', value: '-' },
                { label: 'Next', value: '+' },
              ]}
              value={customOperatorA}
            />
            <NumberInput onChange={setCustomOperatorValueA} value={customOperatorValueA} />
            <div className="text-container flex-container justify-center">to</div>
          </div>
          <div className="flex-container">
            <ReactSelect
              onChange={setCustomOperatorB}
              options={[
                { label: 'Last', value: '-' },
                { label: 'Next', value: '+' },
              ]}
              value={customOperatorB}
            />
            <NumberInput onChange={setCustomOperatorValueB} value={customOperatorValueB} />
            <ReactSelect
              onChange={setCustomOperatorUnit}
              options={[
                { label: 'Days', value: 'days' },
                { label: 'Weeks', value: 'weeks' },
                { label: 'Months', value: 'months' },
                { label: 'Years', value: 'years' },
              ]}
              value={customOperatorUnit}
            />
          </div>
        </div>
      )}

      {selectedOperatorRef.current === 'standardOperator' && (
        <div className="flex-container align-center date-filter-inputs">
          <DatePicker
            disableFuture={!!endDate}
            name={`${name}-start-date`}
            onChange={setStartDate}
            outsideRange={endDate}
            placeholder="Start Date"
            value={startDate}
          />

          {operator === 'between' && (
            <>
              <DatePicker
                disablePast={!!startDate}
                name={`${name}-end-date`}
                onChange={setEndDate}
                outsideRange={startDate}
                placeholder="End Date"
                value={endDate}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

DateFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default DateFilter;
