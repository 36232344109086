import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { debounce, isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { ConfirmationModal, Icon } from '@zylo/orchestra';
import { useDispatch, useSelector } from 'react-redux';
import ApplicationAssignmentChangeWarning from '@pages/spend/spendTabs/ApplicationAssignmentChangeWarning';
import PopoverMenu from '@components/PopoverMenu';
import PopoverMenuItem from '@components/PopoverMenuItem';
import SubscriptionOption from '@components/forms/inputs/SubscriptionOption';
import TypeAhead from '@components/forms/inputs/TypeAhead';
import { useEventTracking, useFeatureFlags } from '@hooks';
import { actions as chargeActions } from '@modules/companyCharges';
import { getData } from '@utilities/xhr';
import { reduxObject } from '@utilities/propTypes';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';
import { useSortState } from '@contexts/sortContext';

export function CompanyAppSelectorCell(props) {
  return <CompanyAppSelectorCell_DisplayLayer {...props} {...useDataLayer()} />;
}

CompanyAppSelectorCell.propTypes = {
  data: PropTypes.string,
  index: PropTypes.number,
  isSupplierPayments: PropTypes.bool,
  queryKey: PropTypes.string,
  rowData: reduxObject,
};

export function CompanyAppSelectorCell_DisplayLayer({
  additionalOnChange = () => {},
  modifiedChargesAppCache,
  data,
  editCompanyCharge,
  handleSearchChange,
  index,
  isSupplierPayments,
  rowData,
  queryKey,
  searchOptions,
  sendEvent,
}) {
  const [isConfirmingModal, setIsConfirmingModal] = useState(false);
  const [isAssignmentChangeWarningModal, setIsAssignmentChangeWarningModal] = useState(false);
  const [reassigning, setReassigning] = useState(false);
  const { isPurchaseOrdersEnabled } = useFeatureFlags('purchase-orders');

  const { apiRequestFilters = '[]' } = useFiltersState();
  const { sortApiRequestParam } = useSortState();
  const searchValue = useSearchState() ?? '';
  const path = queryKey ?? `companies/$companyId/softwareSpendCharges`;
  const queryClient = useQueryClient();

  function updateSoftwareSpendTable(result, queryKey, appLabel, chargeId) {
    const unassignCharge = (oldPagesArray) => {
      return oldPagesArray?.filter((charge) => charge.id !== chargeId);
    };

    const reassignCharge = (oldPagesArray) => {
      return oldPagesArray?.map((charge) => {
        return charge.id === result.softwareSpendChargeResponse.id
          ? result.softwareSpendChargeResponse
          : charge;
      });
    };

    const mapFunc = appLabel === '' ? unassignCharge : reassignCharge;

    //manually updates the cached data for the current queryKey values for /softwareSpendCharges so the UI updates without refetching
    queryClient.setQueryData(queryKey, (data) => ({
      pages: data.pages.map(mapFunc),
      pageParams: data.pageParams,
    }));

    //invalidates other cached queries for /softwareSpendCharges or the `queryKey` so they will be re-fetched with the fresh data
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey[0] === path && !isEqual(query.queryKey, queryKey);
      },
    });
  }

  function handleBlur(event) {
    if (event?.target?.id === 'companyApp') {
      document.querySelector('#companyApp').focus();
    } else {
      setReassigning(false);
    }
  }

  function handleChange(_, option) {
    const { id, transactionId } = rowData;

    if (option) {
      editCompanyCharge({
        chargeId: id,
        appData: option,
        chargeIndex: index,
        queryKey: [path, apiRequestFilters, sortApiRequestParam, searchValue],
        updateSoftwareSpendTable,
      });
      additionalOnChange();
      setReassigning(false);
      sendEvent({
        eventName: 'REASSIGNED_PAYMENT',
        data: {
          'Charge Id': id,
          'Transaction Id': transactionId,
          'Company App Id': option.id,
        },
      });
    }
  }

  function handleReassign() {
    handleSearchChange('');
    setReassigning(true);
    sendEvent({
      eventName: 'REASSIGN_PAYMENT_CLICK',
    });
  }

  function checkForPoData() {
    if (isPurchaseOrdersEnabled && rowData.poNumberPoLineItemNumber) {
      setIsAssignmentChangeWarningModal(true);
    } else {
      handleReassign();
    }
  }

  function handleReassignConfirm() {
    handleReassignClose();
    handleReassign();
  }

  function handleReassignClose() {
    setIsAssignmentChangeWarningModal(false);
  }

  function handleUnassign() {
    const { companyAppId, id, subscriptionCompanyAppId, transactionId } = rowData;

    setIsConfirmingModal(false);
    editCompanyCharge({
      chargeId: id,
      appData: { appDomain: '', appLabel: '', id: '' },
      chargeIndex: index,
      queryKey: [path, apiRequestFilters, sortApiRequestParam, searchValue],
      updateSoftwareSpendTable,
    });
    sendEvent({
      eventName: 'UNASSIGNED_PAYMENT',
      data: {
        'Charge Id': id,
        'Transaction Id': transactionId,
        'Company App Id': companyAppId || subscriptionCompanyAppId,
      },
    });
  }

  function handleUnassignModalOpen() {
    setIsConfirmingModal(true);
    sendEvent({
      eventName: 'UNASSIGN_PAYMENT_CLICK',
    });
  }

  function handleUnassignModalClose() {
    setIsConfirmingModal(false);
  }

  function renderSelectedApp() {
    const selectedOption = modifiedChargesAppCache[data] || rowData;

    return (
      <SubscriptionOption option={selectedOption}>{selectedOption.appLabel}</SubscriptionOption>
    );
  }

  return (
    <div className="flex-container align-center justify-end company-app-selector-cell">
      {(isSupplierPayments || reassigning) && (
        <TypeAhead
          allowCustomValue={false}
          allowMultiple={false}
          customOption={SubscriptionOption}
          customValueRenderer={renderSelectedApp}
          handleSearchChange={debounce(handleSearchChange, 300)}
          labelKey="appLabel"
          matchPos="any"
          name="companyApp"
          onBlur={handleBlur}
          onChange={handleChange}
          options={searchOptions}
          value={data}
          valueKey="id"
          showMenuByDefault
        />
      )}

      {!isSupplierPayments && !reassigning && (
        <div
          className={classNames(
            'selected-option-read-only flex-container justify-between align-center',
            data ? '' : 'color-gray',
          )}
        >
          {data ? renderSelectedApp() : 'Unassigned'}
          <PopoverMenu
            menuButton={<Icon hoverColor="blue" icon="IconDots" size={1} relativeSize />}
          >
            <PopoverMenuItem action={checkForPoData} icon="IconSwitch3" optionText="Reassign" />
            <PopoverMenuItem
              action={handleUnassignModalOpen}
              icon="IconUnlink"
              isDisabled={!data}
              optionText="Unassign"
            />
          </PopoverMenu>
        </div>
      )}

      {isConfirmingModal && (
        <ConfirmationModal
          contentText="Unassigning this transaction will remove it from your Zylo account. This is a permanent action and cannot be undone."
          handleDismissClick={handleUnassignModalClose}
          handlePrimaryClick={handleUnassign}
          headerText="Unassign Payment"
          isOpen={isConfirmingModal}
          width={330}
        />
      )}

      {isAssignmentChangeWarningModal && (
        <ApplicationAssignmentChangeWarning
          handleCancel={handleReassignClose}
          handleConfirm={handleReassignConfirm}
        />
      )}
    </div>
  );
}

CompanyAppSelectorCell_DisplayLayer.propTypes = {
  additionalOnChange: PropTypes.func,
  data: PropTypes.string,
  editCompanyCharge: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isSupplierPayments: PropTypes.bool,
  modifiedChargesAppCache: reduxObject,
  queryKey: PropTypes.string,
  rowData: reduxObject,
  searchOptions: PropTypes.arrayOf(reduxObject),
  sendEvent: PropTypes.func.isRequired,
};

function useDataLayer() {
  const { modifiedChargesAppCache } = useSelector(({ companyCharges }) => companyCharges);
  const [searchOptions, setSearchOptions] = useState([]);
  const dispatch = useDispatch();
  const sendEvent = useEventTracking();

  function editCompanyCharge(params) {
    dispatch(chargeActions.editCompanyCharge(params));
  }

  function handleSearchChange(search) {
    getData({
      path: 'companies/$companyId/apps/typeAheadOptions',
      params: { limit: 6, search, sort: '+app_label' },
    }).then(({ body }) => {
      setSearchOptions(body.companyApps);
    });
  }

  return {
    modifiedChargesAppCache,
    editCompanyCharge,
    handleSearchChange,
    searchOptions,
    sendEvent,
  };
}
