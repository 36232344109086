import { ZyloTableSelectionState } from '@zylo/orchestra';
import { RowData } from '@src/typings';

export default function getSelectedTableRows({
  selectionKey,
  selectionState,
  tableData = [],
}: {
  selectionKey: string;
  selectionState: ZyloTableSelectionState<any>;
  tableData: RowData[] | undefined;
}) {
  const { deselectedRows, selectAll, selectedRows } = selectionState;
  const deselectedRowsKeys = deselectedRows.map((deselectedRow) => deselectedRow[selectionKey]);
  let selections = selectedRows as RowData[];

  if (selectAll) {
    selections = tableData.filter((row) => !deselectedRowsKeys.includes(row[selectionKey]));
  }

  return selections;
}
