import { capitalize } from 'lodash-es';
import { NullUXValues } from '@typings';

function parseFullName(name: string) {
  const suffixes = ['sr', 'sr.', 'jr', 'jr.', 'iii', 'iv'];
  const splitArr = name.split(' ');
  const lastIndex = splitArr.length - 1;
  let lastNameIndex = lastIndex;
  if (suffixes.indexOf(splitArr[lastIndex].toLowerCase()) !== -1) {
    lastNameIndex = lastIndex - 1;
  }
  let firstName = '';
  let lastName = '';
  for (let i = 0; i < splitArr.length; i += 1) {
    if (i < lastNameIndex) {
      firstName = `${firstName} ${splitArr[i]}`;
    } else {
      lastName = `${lastName} ${splitArr[i]}`;
    }
  }
  return {
    firstName: firstName.trim(),
    lastName: lastName.trim(),
  };
}

/**
 * Get all field values that are required but have no value
 * @param fields list of all fields on form
 * @param requiredFields array of fields required on form
 * @return field (key) with no value and validation error message (value)
 */
function checkRequired<T extends object>(fields: T, requiredFields: (keyof T)[]) {
  const errors: Partial<{ [k in keyof T]: any }> = {};

  requiredFields.forEach((field) => {
    const value = fields[field];

    if ((!value && !Number.isInteger(value)) || (value as any).length === 0) {
      errors[field] = 'This field is required.';
    }
  });

  return errors;
}

function checkEmail(email?: string) {
  if (!email) return false;

  const emailRegex = new RegExp(
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
  );

  return emailRegex.test(email);
}

function checkArray(array: string[], regex: RegExp) {
  let isValid = true;
  for (let i = 0; i < array.length; i += 1) {
    if (!regex.test(array[i])) {
      isValid = false;
      break;
    }
  }
  return isValid;
}

function checkCertificate(cert: string) {
  return cert && typeof cert === 'string' && cert.length >= 256;
}

/**
 * Check if a domain is valid. Prevents attacks such as SSRF.
 * Internal domains are not allowed(zylo.com and zylo.internal as of 07/2021).
 * Update the regular expression when new internal domain is added.
 *
 * @param domain The domain being checked.
 * @param re Optional regular expression that the domain needs to match.
 * @param checkLength Optional flag to check for length of url
 * @returns If the domain is valid.
 */
function checkDomain(domain: string, re?: RegExp, checkLength = true) {
  if (checkLength) {
    domain = domain.trim().toLowerCase();
    if (!domain || domain.length > 253) {
      return false;
    }
  }

  const validDomainNameRe =
    /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/;
  if (!validDomainNameRe.test(domain)) {
    return false;
  }

  const internalDomainRe = /^.*zylo\.(com|internal)$/;
  if (internalDomainRe.test(domain)) {
    return false;
  }

  const repeatingPeriods = /\b(\.)\1+\b/;
  if (repeatingPeriods.test(domain)) {
    return false;
  }

  if (re && !re.test(domain)) {
    return false;
  }

  return true;
}

function mapUnspecified(rawValue: 'EXISTS' | 'NULL' | string | null) {
  let value;
  let label;
  if (rawValue === 'NULL' || rawValue === null) {
    value = 'NULL';
    label = NullUXValues.NULL;
  } else if (rawValue === 'EXISTS') {
    value = 'EXISTS';
    label = NullUXValues.EXISTS;
  } else {
    value = rawValue;
    label = rawValue;
  }
  return { value, label };
}

function mapReactSelectOptions<T>(options: T, humanizeLabels = true) {
  if (!Array.isArray(options) || options.length === 0) {
    return [];
  }
  return options.map((rawValue) => {
    const { value, label } = mapUnspecified(rawValue);
    return {
      value,
      label:
        humanizeLabels && label !== undefined
          ? label.charAt(0).toUpperCase() + label.slice(1)
          : label,
    };
  });
}

function mapMultiSelectOptions(options = []) {
  if (!Array.isArray(options) || options.length === 0) {
    return [];
  }
  return options.map((rawValue) => {
    const { value, label } = mapUnspecified(rawValue);
    return { value, name: value, label };
  });
}

function mapRadioListOptions(options = []) {
  if (!Array.isArray(options) || options.length === 0) {
    return [];
  }
  return options.map((rawValue) => {
    const { value, label } = mapUnspecified(rawValue);
    return { value, name: capitalize(label) };
  });
}

function calculateUnsavedChanges<T>(initialValues: T, currentValues: T) {
  if (initialValues && currentValues) {
    const initialLength = Object.keys(initialValues).length;
    const currentLength = Object.keys(currentValues).length;
    if (initialLength !== currentLength) return currentLength - initialLength;

    const differentValues: Partial<T> = Object.keys(initialValues).reduce((diff, key) => {
      const k = key as keyof T;
      if (JSON.stringify(initialValues[k]) === JSON.stringify(currentValues[k])) return diff;
      return {
        ...diff,
        [k]: currentValues[k],
      };
    }, {});

    return Object.keys(differentValues).length;
  }
  return null;
}

const passwordRequirements = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?!.*\s).{10,70}$/,
);

const defaultSelectOption = [{ value: '', label: ' - ' }];
const booleanOptions = [
  ...defaultSelectOption,
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export {
  defaultSelectOption,
  booleanOptions,
  parseFullName,
  checkRequired,
  checkEmail,
  checkArray,
  checkCertificate,
  checkDomain,
  mapReactSelectOptions,
  mapMultiSelectOptions,
  mapRadioListOptions,
  calculateUnsavedChanges,
  passwordRequirements,
};
