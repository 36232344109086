import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import ZyloCheckbox from './ZyloCheckbox';

function MultiSelect({
  disabled,
  name,
  onChange = () => {},
  options = [],
  optionsKey = 'name',
  value = [],
}) {
  const stateRef = useRef(
    Array.isArray(value)
      ? value.reduce((arr, item) => {
          const targetIndex = options.findIndex((option) => option[optionsKey] === item);
          const updatedArr = [...arr];

          updatedArr[targetIndex] = item;

          return updatedArr;
        }, [])
      : [],
  );

  function handleCheckboxChange(newStatus, target, index) {
    const parsedName = target.replace(`${name}-`, '');
    const updatedState = [...stateRef.current];

    updatedState[index] = newStatus ? parsedName : null;

    stateRef.current = updatedState;
    onChange(updatedState.filter(Boolean));
  }

  return (
    <div className="multi-select">
      {options.map((option, i) => {
        const optionValue = option[optionsKey];

        function handleChangeWithIndex(val, target) {
          handleCheckboxChange(val, target, i);
        }

        return (
          <ZyloCheckbox
            className={classNames('multi-select-option', option.optionClassName)}
            disabled={disabled || option.disabled}
            key={`checkbox-${option[optionsKey]}`}
            label={option.label || optionValue}
            name={`${name}-${optionValue}`}
            onChange={handleChangeWithIndex}
            value={value.includes(optionValue)}
          />
        );
      })}
    </div>
  );
}

MultiSelect.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      disabled: PropTypes.bool,
      optionClassName: PropTypes.string,
    }),
  ),
  optionsKey: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string, // value can initially be an empty string from redux-form
    PropTypes.array,
  ]),
};

export default MultiSelect;
