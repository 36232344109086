import { Colors, FormFieldWrapper, Label, MiniSelect } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { useController } from 'react-hook-form';
import { FieldConfig, FieldHOCWrapperInputProps } from '@frameworks/form-fns/types';
import { BulkEditOperationOption } from './types';

export const StyledMiniSelect = styled(MiniSelect)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const FieldBulkEditSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const RedText = styled.div`
  align-items: center;
  color: ${Colors.error};
  display: inline-flex;
  min-height: 2.25rem;
`;

function BulkEditBlankableField(props: FieldHOCWrapperInputProps) {
  const { methods, editing, fieldConfig, children, wrapperConfig } = props;
  const { wrapperName, defaultValue: wrapperDefault } = wrapperConfig;
  const calculatedDefault = wrapperDefault || { label: 'Edit', value: 'replace' };

  const {
    field: { onChange, value },
  } = useController({
    name: wrapperName,
    control: methods?.control,
    defaultValue: calculatedDefault,
  });

  const miniSelectOptions: BulkEditOperationOption[] = [
    { label: 'Edit', value: 'replace' },
    { label: 'Blank', value: 'remove' },
  ];

  if (!children) {
    return <>{children}</>;
  }

  const fieldOptions = fieldConfig[1];
  const { name, label } = fieldOptions;

  const newLabel = editing ? (
    <FieldBulkEditSelectWrapper>
      {label ? <Label htmlFor={name}>{label}</Label> : <></>}
      <StyledMiniSelect
        name={wrapperName}
        onChange={onChange}
        options={miniSelectOptions}
        value={value}
      />
    </FieldBulkEditSelectWrapper>
  ) : (
    label
  );
  const referenceBreaker: FieldConfig = [...fieldConfig];
  referenceBreaker[1] = {
    ...fieldConfig[1],
    label: '', // we don't need this label to render
  };

  if (value.value === 'remove') {
    return (
      <FormFieldWrapper>
        <>{newLabel}</>
        <RedText>Blank</RedText>
      </FormFieldWrapper>
    );
  } else {
    return (
      <FormFieldWrapper>
        {newLabel}
        {children({ ...props, fieldConfig: referenceBreaker })}
      </FormFieldWrapper>
    );
  }
}

export default function FieldBulkEditBlankWrapper(props: FieldHOCWrapperInputProps) {
  const { editing, children } = props;
  if (!children) {
    return <></>;
  }
  if (editing) {
    return <BulkEditBlankableField {...props} />;
  }

  return children(props);
}
