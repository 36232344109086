import styled from 'styled-components/macro';
import {
  Colors,
  ComboboxFormField,
  defaultborder,
  FontStyles,
  MultiComboboxFormField,
} from '@zylo/orchestra';
import { MetadataResponse } from '@hooks';
import { defaultCategoriesInfo } from '@components/columnPicker/ColumnPicker';
import { CategoriesInfo, IMetadataField } from '@typings';
import { FieldConfig, FieldsetConfig, validatePositiveWholeNumber } from '@frameworks/form-fns';
import ZAPComboboxWrapper from '../ZAPComboboxWrapper';
import FieldBulkActionTypeWrapper from './FieldBulkActionTypeWrapper';
import FieldBulkEditBlankWrapper from './FieldBulkBlankWrapper';

const BulkEditSectionHeader = styled.header`
  padding: 0 0 8px 0;
  margin-bottom: 4px;
  color: ${Colors.teal5};
  font-size: ${FontStyles.size['14px']};
  font-weight: ${FontStyles.weight[500]};
  border-bottom: ${defaultborder};
`;

type MapMetadataToFieldsArgs = {
  currency: string;
  ZAPMetadata: MetadataResponse['body'];
  ZAPName?: string;
};

type GetFieldPropsArgs = Pick<MapMetadataToFieldsArgs, 'currency' | 'ZAPName'> & {
  field: IMetadataField;
};

export function mapMetadataToFields({ currency, ZAPMetadata, ZAPName }: MapMetadataToFieldsArgs) {
  const editableFields = ZAPMetadata.fields.filter((field) => field.isEditable);
  const sectionsWithEditableFields = ZAPMetadata.settings!.bulkEditingFieldsOrderByDataType.filter(
    (sectionName) => editableFields.some((field) => field.dataType === sectionName),
  );
  const fieldSetSections: FieldsetConfig[] = sectionsWithEditableFields.map((sectionName) => {
    const sectionTitle =
      sectionName === 'zylo'
        ? 'Categorization'
        : defaultCategoriesInfo[sectionName as keyof CategoriesInfo]?.category;
    return [
      'Fieldset',
      {
        className: `${sectionName}-fieldset`,
        name: sectionName,
        legend: <BulkEditSectionHeader>{sectionTitle}</BulkEditSectionHeader>,
      },
      { fields: [] },
    ];
  });

  fieldSetSections.forEach((section) => {
    const matchingSectionFields = editableFields.filter(
      (field) => field.dataType === section[1].name,
    );
    const fieldInfo = matchingSectionFields.map((field) => {
      return getFieldProps({ currency, field, ZAPName });
    });
    section[2].fields = fieldInfo;
  });
  return fieldSetSections;
}

export enum EditableInputTypeMap {
  'array' = 'MultiCombobox',
  'boolean' = 'Select',
  'comboBox' = 'Combobox',
  'currency' = 'CurrencyField',
  'date' = 'DatePicker',
  'input' = 'FormField',
  'number' = 'Number',
  'select' = 'Select',
}
function getFieldProps({ currency, field, ZAPName }: GetFieldPropsArgs): FieldConfig | null {
  const fieldType = EditableInputTypeMap[field.editProperties?.inputType || 'input'];
  const fieldKey = field.key;
  const allFieldsProps = {
    className: 'bulk-edit-field',
    defaultValue: '',
    label: field.displayKey,
    name: fieldKey,
  };
  const wrapperName = `${fieldKey}ZyloOperation`;
  const wrapperConfig = {
    defaultValue: '',
    wrapperName,
  };

  switch (fieldType) {
    case 'MultiCombobox': {
      return [
        'CustomFormField',
        { ...allFieldsProps },
        {
          customFormFieldComponent: ({
            field: { onChange, value },
            editing,
            errors,
            label,
            readOnly,
          }) => {
            const disabled = !editing || readOnly;
            let inputValue = '';
            return (
              <ZAPComboboxWrapper ZAPName={ZAPName} fieldKey={fieldKey} minSearchLength={0}>
                {({ onSearch, options }) => (
                  <MultiComboboxFormField
                    allowCustomOptions={true}
                    autoCompleteProps={{
                      onInputChange: (event, search, reason) => {
                        if (reason !== 'reset' || (event && event.type === 'blur')) {
                          inputValue = search;
                          onSearch(search);
                        }
                      },
                      inputValue: inputValue,
                    }}
                    className="bulk-edit-field"
                    disabled={disabled}
                    errors={errors}
                    label={label}
                    onChange={onChange}
                    options={options}
                    placeholder="Type to search"
                    value={value}
                  />
                )}
              </ZAPComboboxWrapper>
            );
          },
          wrapper:
            fieldKey === 'tags'
              ? {
                  wrapperComponent: FieldBulkActionTypeWrapper,
                  wrapperConfig,
                }
              : undefined,
        },
      ];
    }
    case 'Select': {
      return [
        'Select',
        {
          options: field.editProperties?.selectOptions || [],
          nullable: true,
          ...allFieldsProps,
        },
      ];
    }
    case 'Combobox': {
      return [
        'CustomFormField',
        { ...allFieldsProps },
        {
          customFormFieldComponent: ({ field: { onChange, value }, editing, errors, readOnly }) => {
            const disabled = !editing || readOnly;
            return (
              <ZAPComboboxWrapper
                ZAPName={ZAPName}
                fieldKey={fieldKey}
                minSearchLength={1}
                addBlank
              >
                {({ onSearch, options }) => (
                  <ComboboxFormField
                    className="bulk-edit-field"
                    disabled={disabled}
                    errors={errors}
                    label={field.displayKey}
                    nullable={true}
                    onChange={onChange}
                    onSearch={onSearch}
                    options={options}
                    placeholder="Type to search"
                    value={value}
                  />
                )}
              </ZAPComboboxWrapper>
            );
          },
        },
      ];
    }
    case 'CurrencyField': {
      return [
        'CurrencyField',
        {
          currency,
          ...allFieldsProps,
        },
        {
          wrapper: {
            wrapperComponent: FieldBulkEditBlankWrapper,
            wrapperConfig,
          },
        },
      ];
    }
    case 'DatePicker': {
      return [
        'DatePicker',
        {
          formatToISOString: true,
          ...allFieldsProps,
        },
        {
          wrapper: {
            wrapperComponent: FieldBulkEditBlankWrapper,
            wrapperConfig,
          },
        },
      ];
    }
    case 'Number': {
      return [
        'FormField',
        {
          ...allFieldsProps,
          rules: {
            validate: {
              positiveWholeNumber: validatePositiveWholeNumber,
            },
            valueAsNumber: false,
          },
        },
        {
          wrapper: {
            wrapperComponent: FieldBulkEditBlankWrapper,
            wrapperConfig,
          },
        },
      ];
    }
    case 'FormField': {
      return [
        'FormField',
        {
          ...allFieldsProps,
        },
        {
          wrapper: {
            wrapperComponent: FieldBulkEditBlankWrapper,
            wrapperConfig,
          },
        },
      ];
    }
    default:
      return null;
  }
}
