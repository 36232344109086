import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import { Popover, Colors, FontStyles, ZyloTable, ZyloTableColumnProps } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { useNavigate } from '@reach/router';
import AppLogo from '@components/AppLogo';
import { SubscriptionCell } from '@components/tables/cells/';
import { sortArrayOfObjects } from '@utilities/util';
import { CompanyAppLogoProps } from '@typings';

const MultiLogoContainer = styled.div`
  .logo-container {
    position: relative;
    min-height: 40px;

    & > div {
      position: absolute;
      top: 50%;
      min-height: 40px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .show-secondary-logos {
    margin-left: 12px;
    color: ${Colors.teal5};
    font-size: ${FontStyles.size['14px']};
    cursor: pointer;
  }
`;

type MultiLogoProps = {
  className?: string;
  data?: Array<CompanyAppLogoProps>;
  disableLogoClick?: boolean;
  primaryCutoff?: number;
};

export function MultiLogoCell({
  className,
  data = [],
  disableLogoClick = false,
  primaryCutoff = 10,
}: MultiLogoProps) {
  const [primaryApps, setPrimaryApps] = useState<CompanyAppLogoProps[]>([]);
  const [displayPopover, setDisplayPopover] = useState(false);
  const [alphabetizedData, setAlphabetizedData] = useState<CompanyAppLogoProps[]>(
    sortArrayOfObjects('-appLabel', data),
  );
  const [includePopover, setIncludePopover] = useState(data.length > primaryCutoff);
  const positionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!data.length) return;

    setPrimaryApps([...data].splice(0, primaryCutoff));
    setAlphabetizedData(sortArrayOfObjects('+appLabel', data));
    setIncludePopover(data.length > primaryCutoff);
  }, [data, primaryCutoff]);

  function togglePopover(e?: SyntheticEvent) {
    if (e) e.stopPropagation();

    setDisplayPopover((currentVal) => !currentVal);
  }

  function checkClick(e: SyntheticEvent) {
    if (disableLogoClick) {
      e.stopPropagation();
      return;
    }
  }

  const popoverTableColumns: ZyloTableColumnProps<CompanyAppLogoProps>[] = [
    {
      Header: 'Application Name',
      accessor: 'appLabel',
      Cell: ({ row, value }) => <SubscriptionCell data={value} rowData={row.original} />,
    },
  ];

  return (
    <MultiLogoContainer
      className={classNames('flex-container align-center multi-logo-cell', className)}
      onClick={checkClick}
    >
      <div className="logo-container" style={{ width: 36 + (primaryApps.length - 1) * 24 }}>
        {primaryApps.map(({ companyAppId, launchUrl, appDomain }, i) => {
          return (
            <div
              className="flex-container align-center"
              key={companyAppId}
              onClick={() => navigate(`/subscriptions/${companyAppId}`)}
              style={{ right: i * 24 }}
            >
              <AppLogo
                filename={typeof appDomain !== 'undefined' ? appDomain : launchUrl}
                key={companyAppId}
              />
            </div>
          );
        })}
      </div>
      {includePopover && (
        <div className="show-secondary-logos" onClick={togglePopover} ref={positionRef}>
          {`+${data.length - primaryCutoff} more`}
        </div>
      )}
      <Popover
        closeAction={togglePopover}
        positionRef={positionRef.current!}
        style={{ height: 300, width: 300, border: 'none', borderRadius: 10 }}
        visible={displayPopover}
        clickToOpen
      >
        <ZyloTable columns={popoverTableColumns} data={alphabetizedData} minHeight={300} />
      </Popover>
    </MultiLogoContainer>
  );
}
