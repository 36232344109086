import { createElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { useFiltersState, useFiltersUpdate } from '@contexts/filtersContext';

export function FilterableCell(props) {
  const { component, ...componentProps } = props;
  const { columnName, data } = componentProps;
  const { selectedFilters } = useFiltersState();
  const { handleFilterChange } = useFiltersUpdate();
  const [orderedData, setOrderedData] = useState({ [columnName]: data });

  useEffect(() => {
    if (Array.isArray(data)) {
      const dataToOrder = data;
      const selectedFilterValues = get(selectedFilters, columnName, []);

      setOrderedData({
        [columnName]: [
          ...selectedFilterValues.filter((filterValue) => dataToOrder.includes(filterValue)),
          ...dataToOrder.filter((val) => !selectedFilterValues.includes(val)),
        ],
      });
    }
  }, [columnName, data, selectedFilters]);

  useEffect(() => {
    if (typeof data === 'string' && data.length) {
      setOrderedData({ [columnName]: data });
    }
  }, [columnName, data]);

  function handleValueClick(val) {
    const selectedFiltersValues = get(selectedFilters, columnName, []);

    if (selectedFiltersValues.includes(val)) return;

    handleFilterChange(columnName, [...selectedFiltersValues, val]);
  }

  return orderedData
    ? createElement(component, {
        ...componentProps,
        data: orderedData[columnName],
        handleValueClick,
      })
    : null;
}

FilterableCell.propTypes = {
  columnName: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
