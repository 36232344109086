import { defaultMapCellToColumn } from '@hooks';
import { IMetadataField } from '@typings';
import { CellMappings } from './types';
import { ZAPEndpointsOverrideType } from './ZAPContext';

export const ZAPBaseURL = 'companies/$companyId/zap';

export function buildZAPEndpoint(ZAPName: string, resource?: string, zapId?: string) {
  return `${ZAPBaseURL}/${ZAPName}${resource ? `/${resource}` : ''}${
    zapId ? `?zapId=${zapId}` : ''
  }`;
}

export function buildZAPEndpoints(
  ZAPName: string,
  zapId?: string,
  zapEndpointsOverride?: ZAPEndpointsOverrideType,
) {
  return {
    completions:
      zapEndpointsOverride?.completions || buildZAPEndpoint(ZAPName, 'completions', zapId),
    metadata: zapEndpointsOverride?.metadata || buildZAPEndpoint(ZAPName, 'metadata', zapId),
    stats: zapEndpointsOverride?.stats || buildZAPEndpoint(ZAPName, 'stats', zapId),
    tableData: zapEndpointsOverride?.tableData || buildZAPEndpoint(ZAPName, undefined, zapId),
    update: zapEndpointsOverride?.update || buildZAPEndpoint(ZAPName, 'update', zapId),
  };
}

export function customCellMapping<T extends object>(customCellMappings: CellMappings<T>) {
  return (f: IMetadataField) => {
    const found = customCellMappings.find(([key]) => f.key === key);
    if (found) {
      const [, customMapping] = found;
      return customMapping;
    }
    return defaultMapCellToColumn(f);
  };
}
