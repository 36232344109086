import styled from 'styled-components/macro';
import { Colors, FontStyles, Icon } from '@zylo/orchestra';
import { ReactNode } from 'react';
import { Form } from './Form';
import { FormControlProps, FormProps } from './types';

export const FormError = styled.p`
  display: flex;
  align-content: end;
  margin-top: 1rem;
  color: ${Colors.error};
  font-size: ${FontStyles.size['14px']};
  font-weight: ${FontStyles.weight[500]};

  .form__form-error-exclamation {
    margin-right: 0.25rem;
    padding: 0;
  }
`;

export type FormComponentProps = Omit<FormProps, 'defaultValues' | 'fields' | 'onSubmit'> &
  FormControlProps & {
    children: ReactNode;
    onSubmit?(d: unknown): void;
  };

/**
 * Provides basic form styling, and header with form controls ('Edit', 'Cancel' and 'Save' buttons),
 * and error state. A custom error message may be provided. Fields and other [form elements](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form)
 * should be passed as children.
 */
export function FormComponent({
  className,
  children,
  disabledStyle,
  error,
  errorMessage,
  fieldsContainerClassName,
  footer: Footer,
  header: Header,
  id,
  layout,
  onSubmit,
  ...props
}: FormComponentProps) {
  function getErrorMarkup(errorMessage: FormComponentProps['errorMessage']) {
    return typeof errorMessage === 'string' ? (
      <FormError className="form__form-error">
        <span className="form__form-error-exclamation">
          <Icon color="red" icon="IconInfoCircle" size={16} testId="zylo-form-icon-exclamation" />
        </span>
        {errorMessage ?? 'There was a problem loading the form. Please try refreshing the page.'}
      </FormError>
    ) : (
      errorMessage
    );
  }

  return (
    <>
      <Form
        className={className}
        disabledStyle={disabledStyle}
        id={id}
        layout={layout}
        onSubmit={onSubmit}
      >
        {Header ? <Header {...props} /> : null}
        {error ? getErrorMarkup(errorMessage) : null}
        <div className={fieldsContainerClassName}>{children}</div>
      </Form>
      {Footer ? <Footer {...props} /> : null}
    </>
  );
}
