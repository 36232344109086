import { get } from 'lodash-es';
import moment from 'moment';
import Raven from 'raven-js';
import { createAction } from 'redux-actions';
import { interpolateHcl } from 'd3-interpolate';
import { parse, stringify } from 'query-string';
import convertDateStringToMoment from './convertDateStringToMoment';
import findMaxIndices from './findMaxIndices';
import formatEmptyField from './formatEmptyField';
import getAWSCompanyLogoPath from './getAWSCompanyLogoPath';
import pluralize from './pluralize';
import searchArrayOfObjects from './searchArrayOfObjects';
import sortArrayOfObjects from './sortArrayOfObjects';
import sortList from './sortList';
import verifyThemeValue from './verifyThemeValue';

/**
 * Common utils
 */

/**
 * Helper function to wrap an async thunk function for redux
 * @param fun the function o wrap
 * @returns {function} the wrapped function (format required by redux-thunk)
 */
function wrapAsync(func) {
  return (params) => {
    return (dispatch, getState) => {
      return func(params, dispatch, getState);
    };
  };
}

function cleanPayloadObject(allowedFields, payloadData) {
  const payload = Object.keys(payloadData).reduce((payloadFields, prop) => {
    if (allowedFields.includes(prop)) {
      // eslint-disable-next-line no-param-reassign
      payloadFields[prop] = payloadData[prop];
    }
    return payloadFields;
  }, {});

  return payload;
}

function formatCategory(category) {
  return category.replace(/\s+/g, '-').replace(/&/g, 'and').toLowerCase();
}

// Accepts an array of two colors (hex or rgb) and incrementally adds new colors (rgb) between existing values
function fillInColorScale(scale, newColorsNeeded) {
  if (newColorsNeeded < 1) {
    return scale;
  }

  const spectrum = interpolateHcl(scale[0], scale[1]);
  const expandedScale = [scale[0]];
  const increment = 1 / (newColorsNeeded + 1);
  let currentColor = increment;
  let counter = 0;

  while (counter <= newColorsNeeded) {
    expandedScale.push(spectrum(currentColor));
    currentColor += increment;
    counter += 1;
  }

  return expandedScale;
}

// Takes an array of months as an integer [0, 1, 2] and outputs shortened month name list
function formatMonthList(monthList) {
  return monthList.map((month) => {
    return moment()
      .month(month - 1)
      .format('MMM');
  });
}

function formatNumber(num) {
  return num === null || Number.isNaN(Number(num)) ? '' : num.toLocaleString('en-US');
}

function formatDate(value = '') {
  const date = value;
  const isValidDate = moment(date).isValid();
  let formattedValue;
  if (isValidDate) {
    formattedValue = moment(date).utc().format('l');
  } else {
    formattedValue = value;
  }

  return formattedValue;
}

function normalizeNumber(num) {
  return Number(num.replace(/[^0-9.]+/g, ''));
}

function formatBytes(bytes, round) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1000));

  return `${parseFloat(bytes / 1000 ** i).toFixed(round ? 0 : 2)} ${sizes[i]}`;
}

function formatLocation(location) {
  const { loc, ...queryParams } = parse(location.search);

  if (!loc) {
    return '/';
  }

  const newQueryParams = stringify({ ...queryParams, token: undefined });

  return newQueryParams.length > 0 ? `${loc}?${newQueryParams}` : `${loc}`;
}

function getErrorMessage(error, fallback = 'An unexpected error occurred') {
  return get(error, 'response.body.message', fallback);
}

function handleNetworkError(call, error, dispatch, action, params, preventLogout = false) {
  const { response } = error;
  if (response) {
    const { statusCode } = response;
    const errorMessage = getErrorMessage(error);

    if (statusCode !== 401) {
      if (action) {
        dispatch(action(errorMessage));
      }

      if (statusCode !== 403) {
        Raven.captureException(`NETWORK ERROR - ${call} failed: ${statusCode} - ${errorMessage}`, {
          extra: { params, hasLocalStorageProfile: !!localStorage.getItem('zyloProfile') },
        });
      }
    } else if (statusCode === 401 && !preventLogout) {
      const expiredToken = createAction('EXPIRED_TOKEN');
      dispatch(expiredToken(errorMessage));
    }
  }
}

function GenericError(code = 400) {
  this.response = { statusCode: code };
}

function emptyStringToNull(value) {
  if (typeof value === 'string' && value.trim().length === 0) {
    return null;
  }
  return value;
}

function nullToEmptyString(value) {
  return value === null ? '' : value;
}

/**
 * use for ui elements that cannot use index for key (e.g. items rearranged, deleted)
 * call once in constructor, and again for every new indexed item added
 */
function genKey(keyLength = 5) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomKey = '';

  for (let i = 0; i < keyLength; i += 1) {
    randomKey += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return randomKey;
}

function makeItPlural(count) {
  if (Number.isInteger(count) && count !== 1) {
    return 's';
  }
  return '';
}

export {
  convertDateStringToMoment,
  cleanPayloadObject,
  emptyStringToNull,
  fillInColorScale,
  findMaxIndices,
  formatBytes,
  formatCategory,
  formatDate,
  formatEmptyField,
  formatLocation,
  formatMonthList,
  formatNumber,
  GenericError,
  genKey,
  getAWSCompanyLogoPath,
  getErrorMessage,
  handleNetworkError,
  makeItPlural,
  normalizeNumber,
  nullToEmptyString,
  pluralize,
  searchArrayOfObjects,
  sortArrayOfObjects,
  sortList,
  verifyThemeValue,
  wrapAsync,
};
