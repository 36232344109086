import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';

type SamlConfigType = 'zylo' | 'appcatalog' | 'all';

type SamlInfo = {
  cert: string;
  companyId?: string;
  companyIdentifier?: string;
  companyUrlParam: string;
  createdAt?: string;
  createdBy?: string;
  decryptionCert?: string;
  entryPoint: string;
  id?: string;
  idpUrlParam: string;
  issuer: string;
  modifiedAt?: string;
  modifiedBy?: string;
  signAuthnRequest: boolean;
  signingCert?: string;
  useEncryption: boolean;
  useSaml: boolean;
};

type SamlInfoResponse = {
  body: SamlInfo;
};

async function getSamlInfo(configType: SamlConfigType) {
  const { body }: SamlInfoResponse = await getData({
    path: `companies/$companyId/saml/${configType}`,
  });
  return body;
}

export function useSamlInfo(configType: SamlConfigType) {
  return useQuery('saml', () => getSamlInfo(configType));
}
