import { FunctionComponent } from 'react';
import { reconcileQueryStatuses } from './formHelpers';
import { FormPlaceholder } from './FormPlaceholder';
import { FormBuilderProps, FormProps, FormStatus, FormValues } from './types';

export type FormLoaderProp = {
  /**
   * - Optional
   * - QueryStatus `"idle" | "loading" | "error" | "success"`
   * - Default `['success']`.
   *
   * The status of the form. 'Loading' (waiting on data), 'success' (intialized with
   * the available data) or 'error'. Takes the query status of an API call. The status
   * value from multiple sources may be added to the array, they will be reconciled
   * to a single status.
   *
   * **Note**: 'idle' has been removed in react-query v4 and is treated as 'success' by this component.
   * */
  status?: FormStatus[];
};
type FCP = FunctionComponent<FormBuilderProps>;

/** Provides functionality to handle form presentation through various states ('error', 'loading', 'success'). */
export function formloader(FormComponent: FCP, CustomPlaceholder?: FCP) {
  return <TFormValues extends FormValues>({
    status = ['success'],
    ...props
  }: FormProps<TFormValues> & FormLoaderProp) => {
    const reconiledStatus = reconcileQueryStatuses(...status);
    const Placeholder = CustomPlaceholder ?? FormPlaceholder;

    switch (reconiledStatus) {
      case 'loading':
        return <Placeholder {...props} status={reconiledStatus} />;
      case 'error':
        return <Placeholder {...props} status={reconiledStatus} error />;
      case 'idle':
      case 'success':
        return <FormComponent {...props} status={reconiledStatus} />;
      default:
        return null;
    }
  };
}
