import { capitalize } from 'lodash-es';

export type CapitalizedCellProps = {
  data: string[] | string;
};

export function CapitalizedCell({ data }: CapitalizedCellProps) {
  if (data) {
    return <div>{Array.isArray(data) ? data.map(capitalize).join(' & ') : capitalize(data)}</div>;
  }

  return <div>&#45;</div>;
}
