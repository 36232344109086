import { useReducer } from 'react';

/**
 * State helper that maintains a toggled boolean state and returns a function
 * to toggle between True and False
 * @param startingState - whether the state should start as True or False
 * @return [currentToggleState, ToggleFunction] - Follows the same pattern as useState, returning the current value and toggle function as a tuple.
 */

function reducerToggleDispatch(currentState: boolean) {
  return !currentState;
}

export function useToggleState(startingState = false): [boolean, () => void] {
  return useReducer(reducerToggleDispatch, startingState);
}
