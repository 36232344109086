import { get } from 'lodash-es';
import { useSelector } from 'react-redux';

/**
 * @deprecated
 * DO NOT USE - considered harmful.
 *
 * get current form field values from redux-form state
 * @param formName - string name of form
 * @param fields - array of field names
 * @returns {object} - form values in a k/v pair
 */
export function useFormValues(formName, fields = []) {
  const values = {};
  fields.forEach((fieldName) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    values[fieldName] = useSelector(({ form }) => {
      // This appears to work currently though the React hooks eslint rule complains. Exploring alternative implementations
      return get(form, `${formName}.values.${fieldName}`);
    });
  });
  return values;
}
