import styled from 'styled-components/macro';
import {
  Card,
  TextWithTooltip,
  Colors,
  FontStyles,
  FormatAbbreviateNumberArguments,
  ZyloSkeleton,
} from '@zylo/orchestra';
import { ReactChild, ReactNode } from 'react';
import { FormattedCurrencyNumber } from './FormattedCurrencyNumber';

export const FlexCardsContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

const StatisticCardBody = styled(Card)<{ color?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  padding: 1rem;

  .statistics-card-title {
    color: ${Colors.grey7};
    display: flex;
    font-size: ${FontStyles.size['13px']};
    font-weight: ${FontStyles.weight[500]};
    font-family: ${FontStyles.family.default};
  }

  .statistics-card-value {
    min-height: 40px;
    font-size: ${FontStyles.size['28px']};
    color: ${({ color }) => color};
    font-weight: ${FontStyles.weight[500]};
  }

  .statistics-chart-card-value {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: ${FontStyles.size['24px']};
    color: ${Colors.teal5};
  }

  .statistics-card-no-value {
    font-size: ${FontStyles.size['28px']};
    color: ${Colors.grey2};
  }

  .skeleton {
    height: 3vw;
    padding: 0.5rem 0;
  }
`;

export type StatisticsCardProps = Omit<
  FormatAbbreviateNumberArguments,
  'svgCompatible' | 'value'
> & {
  color?: string;
  label: string;
  isLoading?: boolean;
  isText?: boolean;
  tooltipText?: string;
  hasChart?: boolean;
  children?: ReactNode | ReactNode[];
  suffix?: ReactChild;
  value?: number | string;
};

export default function StatisticsCard({
  color = Colors.teal6,
  isAbbreviated = true,
  isCurrency,
  isLoading,
  isPercent,
  isText,
  label,
  returnFormattedRawValue,
  tooltipText,
  suffix,
  value,
  children,
  className,
}: StatisticsCardProps) {
  const hasData = value !== undefined;
  return (
    <StatisticCardBody className={className} color={color} key={`statistics-card-${label}`}>
      <div className="statistics-card-title">
        <TextWithTooltip iconColor="teal6" text={label} tooltipText={tooltipText} />
      </div>

      <ZyloSkeleton
        className="statistics-card-value skeleton"
        ready={!isLoading}
        rows={1}
        type="text"
      >
        {!hasData && <div className="statistics-card-no-value">-- No results</div>}

        {hasData && !isText ? (
          <div className={`statistics-card-value ${children ? 'statistics-chart-card-value' : ''}`}>
            <FormattedCurrencyNumber
              isAbbreviated={isAbbreviated}
              isCurrency={isCurrency}
              isPercent={isPercent}
              returnFormattedRawValue={returnFormattedRawValue}
              value={Number(value ?? 0)}
            />
            {suffix && ' ' + suffix}
          </div>
        ) : (
          <span className="statistics-card-value">{value}</span>
        )}
      </ZyloSkeleton>
      {children}
    </StatisticCardBody>
  );
}
