import { useQuery, UseQueryResult } from 'react-query';
import { getData } from '@utilities/xhr';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';

export type IPurchaseOrderStats = {
  stats: {
    totalSpendAgainst: number;
    totalApprovedAmount: number;
    totalCount: number;
    percentageSpendAgainst: number;
    uniqueApps: number;
  };
};

export type PurchaseOrderStatsResponse = {
  body: IPurchaseOrderStats;
};

type PurchaseOrderParams = {
  filters?: string;
  search?: string;
  useFiscalData?: boolean;
};

async function fetchPurchaseOrderStats(params: PurchaseOrderParams) {
  const { body: softwareSpendStats }: PurchaseOrderStatsResponse = await getData({
    path: `companies/$companyId/search/purchaseOrders/stats`,
    params,
  });
  return softwareSpendStats;
}

export function usePurchaseOrderStats(): UseQueryResult<IPurchaseOrderStats> {
  const { apiRequestFilters } = useFiltersState();
  const searchValue = useSearchState();

  return useQuery(
    ['PurchaseOrderStats', apiRequestFilters, searchValue],
    () =>
      fetchPurchaseOrderStats({
        filters: apiRequestFilters,
        search: searchValue,
      }),
    {
      staleTime: 1000 * 60 * 60 * 12, // 12 hours,
      refetchOnWindowFocus: false,
    },
  );
}
