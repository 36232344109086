import styled from 'styled-components/macro';
import { fieldBuilder } from '../formHelpers';
import { FormContentCommonProps, FieldConfig, FieldsetConfig, CustomElementConfig } from '../types';
import { Field } from './Field';

const StyledFieldset = styled.fieldset`
  margin-bottom: 20px;

  legend {
    margin-bottom: 20px;
  }
`;

export type FieldsetProps = FormContentCommonProps & {
  fieldsetConfig: FieldsetConfig;
};

export function FieldSet({
  editing,
  fieldsetConfig: [, { className, legend }, { fields }],
  methods,
  readOnly,
  status,
}: FieldsetProps) {
  return (
    <StyledFieldset className={className} disabled={!editing || readOnly}>
      {legend ? <legend>{legend}</legend> : null}
      {fieldBuilder(fields, (f) => {
        const formElementConfig = f as FieldConfig | CustomElementConfig;

        if (formElementConfig[0] === 'CustomElement') {
          const [, { name }, { customElementComponent: CustomElement }] = formElementConfig;
          return <CustomElement key={name} />;
        }

        return (
          <Field
            editing={editing}
            fieldConfig={formElementConfig}
            key={formElementConfig[1].name}
            methods={methods}
            readOnly={readOnly}
            status={status}
          />
        );
      })}
    </StyledFieldset>
  );
}
