import { useQuery } from 'react-query';
import { HttpResponse } from '@typings';
import { getData } from '@utilities/xhr';

export type SignOnMode =
  | 'AUTO_LOGIN'
  | 'BROWSER_PLUGIN'
  | 'OPENID_CONNECT'
  | 'SAML_2_0'
  | 'SECURE_PASSWORD_STORE';

export type SSOApp = {
  appName: string;
  ssoEnforced: boolean;
  userCount: number;
  activeUserCount: number;
  metadata: {
    accessibility: {
      selfService: boolean;
    };
    implicitAssignment: boolean;
  };
  signOnMode: SignOnMode | Omit<string, SignOnMode>;
};

export type SSOStatus = {
  apps: SSOApp[];
};

export function useGetSSOStatus(companyAppId: string | undefined) {
  return useQuery(
    ['companyApp', 'ssoStatus', companyAppId],
    async () => {
      const response: HttpResponse<SSOStatus> = await getData({
        path: `companies/$companyId/apps/${companyAppId}/sso`,
      });

      return response.body;
    },
    {
      enabled: Boolean(companyAppId),
    },
  );
}
