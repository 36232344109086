import { createAction, handleActions } from 'redux-actions';
import { wrapAsync, handleNetworkError, formatMonthList } from '../utilities/util';
import WaterWorksAPI from '../WaterWorksAPI';
import { actions as zyloFormActions } from './zyloForm';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants
// ------------------------------------

const DEFAULT_SORT_COLUMN = 'chargeDate';
const DEFAULT_SORT = `-${DEFAULT_SORT_COLUMN}`;
const DEFAULT_COMPANY_CHARGES_STATE = {
  list: [],
  appChargeHistory: {},
  pendingCount: 0,
  pendingSpend: 0,
  unmodified: [],
  listSort: [DEFAULT_SORT],
  error: null,
  hasMore: true,
  isLoading: false,
  searchTerm: '',
  unassignedSpend: {},
  modifiedChargesAppCache: {},
};

export const FETCH_APP_CHARGE_HISTORY_START = 'FETCH_APP_CHARGE_HISTORY_START';
export const FETCH_APP_CHARGE_HISTORY_SUCCESS = 'FETCH_APP_CHARGE_HISTORY_SUCCESS';
export const FETCH_APP_CHARGE_HISTORY_FAIL = 'FETCH_APP_CHARGE_HISTORY_FAIL';

export const FETCH_UNASSIGNED_SUPPLIER_SPEND_SUCCESS = 'FETCH_UNASSIGNED_SUPPLIER_SPEND_SUCCESS';
export const FETCH_UNASSIGNED_SUPPLIER_SPEND_FAIL = 'FETCH_UNASSIGNED_SUPPLIER_SPEND_FAIL';

export const EDIT_COMPANY_CHARGE_SUCCESS = 'EDIT_COMPANY_CHARGE_SUCCESS';

export const RESET_CHARGES = 'RESET_CHARGES';
export const RESET_CHARGE_HISTORY = 'RESET_CHARGE_HISTORY';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAppChargeHistoryStart = createAction(FETCH_APP_CHARGE_HISTORY_START);
export const fetchAppChargeHistorySuccess = createAction(FETCH_APP_CHARGE_HISTORY_SUCCESS);
export const fetchAppChargeHistoryFail = createAction(FETCH_APP_CHARGE_HISTORY_FAIL);

export const fetchUnassignedSupplierSpendSuccess = createAction(
  FETCH_UNASSIGNED_SUPPLIER_SPEND_SUCCESS,
);
export const fetchUnassignedSupplierSpendFail = createAction(FETCH_UNASSIGNED_SUPPLIER_SPEND_FAIL);

export const editCompanyChargesSuccess = createAction(EDIT_COMPANY_CHARGE_SUCCESS);

export const resetCharges = createAction(RESET_CHARGES);
export const resetChargeHistory = createAction(RESET_CHARGE_HISTORY);

async function fetchAppChargeHistory({ appId }, dispatch, getState) {
  dispatch(fetchAppChargeHistoryStart());
  const { token, companyId } = getState().auth;
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, [
      'reporting',
      'apps',
      appId,
      'spend',
    ]);
  } catch (e) {
    handleNetworkError('fetchAppChargeHistory', e, dispatch, fetchAppChargeHistoryFail, {
      companyId,
      appId,
      endpoint: 'reporting',
    });

    return;
  }

  dispatch(fetchAppChargeHistorySuccess(result));
}

async function fetchUnassignedSupplierSpend(_companyAppId, dispatch, getState) {
  const { token, companyId } = getState().auth;
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, ['searchOptions', 'charge']);
  } catch (e) {
    handleNetworkError(
      'fetchUnassignedSupplierSpend',
      e,
      dispatch,
      fetchUnassignedSupplierSpendFail,
      { companyId },
    );

    return;
  }
  dispatch(fetchUnassignedSupplierSpendSuccess(result));
}

/* if the edit call comes from CompanyAppSelectorCell (currently on place charges are edited),
   it will pass in the index of the edited charge and we can use that to find the list item array that needs replaced.
   if not, then we map through the list to find the item that needs replaced. */
async function editCompanyCharge(
  { chargeId, chargeIndex, appData, queryKey, updateSoftwareSpendTable },
  dispatch,
  getState,
) {
  const { token, companyId } = getState().auth;
  const { id, appLabel } = appData;
  let result;

  try {
    result = await WaterWorksAPI.updateCompanyData(token, companyId, ['charges', chargeId], {
      companyAppId: id,
    });

    if (queryKey && updateSoftwareSpendTable) {
      updateSoftwareSpendTable(result, queryKey, appLabel, chargeId);
    }
  } catch (e) {
    handleNetworkError('editCompanyCharge', e, dispatch, saveZyloFormError, {
      companyId,
      chargeId,
      companyAppId: id,
    });

    return;
  }

  dispatch(
    editCompanyChargesSuccess({ updatedCharge: result.chargeResponse, chargeIndex, appData }),
  );
  dispatch(
    saveZyloFormSuccess(
      appLabel
        ? `Payment has been successfully applied to ${appLabel}`
        : 'Payment successfully unassigned',
    ),
  );
}

function resetCompanyCharges() {
  return (dispatch) => {
    dispatch(resetCharges());
  };
}

function resetAppChargeHistory() {
  return (dispatch) => {
    dispatch(resetChargeHistory());
  };
}

export const actions = {
  fetchAppChargeHistory: wrapAsync(fetchAppChargeHistory),
  fetchUnassignedSupplierSpend: wrapAsync(fetchUnassignedSupplierSpend),
  editCompanyCharge: wrapAsync(editCompanyCharge),
  resetCompanyCharges,
  resetAppChargeHistory,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [FETCH_APP_CHARGE_HISTORY_START]: (state) => {
      return { ...state, isLoadingChartData: true };
    },
    [FETCH_APP_CHARGE_HISTORY_SUCCESS]: (state, { payload }) => {
      const { sources, totalSpend } = payload;

      const paymentData = sources.map((wave) => {
        return {
          data: wave.transactions,
          type: 'currency',
          label: wave.sourceName,
          total: wave.sourceSpend,
        };
      });

      const paymentLabels =
        sources.length > 0
          ? formatMonthList(
              sources[0].transactions.map((payment) => {
                return payment.month;
              }),
            )
          : [];

      return {
        ...state,
        appChargeHistory: { paymentData, paymentLabels, totalSpend },
        isLoadingChartData: false,
      };
    },
    [FETCH_APP_CHARGE_HISTORY_FAIL]: (state, { payload }) => {
      return { ...state, error: payload, isLoadingChartData: false };
    },
    [FETCH_UNASSIGNED_SUPPLIER_SPEND_SUCCESS]: (state, { payload }) => {
      return { ...state, unassignedSpend: payload };
    },
    [FETCH_UNASSIGNED_SUPPLIER_SPEND_FAIL]: (state, { payload }) => {
      return { ...state, error: payload };
    },
    [EDIT_COMPANY_CHARGE_SUCCESS]: (state, { payload }) => {
      const { updatedCharge, chargeIndex, appData } = payload;
      const { appDomain, appLabel, id } = appData;
      const chargesCacheUpdate = { ...state.modifiedChargesAppCache };
      let updatedList = [...state.list];

      if (chargeIndex) {
        updatedList[chargeIndex] = updatedCharge;
      } else {
        updatedList = state.list.map((charge) => {
          return charge.id === updatedCharge.id ? updatedCharge : charge;
        });
      }

      if (id) {
        chargesCacheUpdate[id] = { appDomain, appLabel };
      }

      return {
        ...state,
        list: updatedList,
        modifiedChargesAppCache: chargesCacheUpdate,
      };
    },
    [RESET_CHARGES]: () => {
      return { ...DEFAULT_COMPANY_CHARGES_STATE };
    },
    [RESET_CHARGE_HISTORY]: (state) => {
      return { ...state, appChargeHistory: [] };
    },
  },
  DEFAULT_COMPANY_CHARGES_STATE,
);
