import { useQuery } from 'react-query';
import { useAppMessaging } from '@hooks/useAppMessaging';
import { CompanyApp } from '@typings';
import { getData } from '@utilities/xhr';

export type AppDetailsResponse = {
  body: CompanyApp;
};

export function useGetCompanyApp(companyAppId: string | undefined) {
  const showMessage = useAppMessaging();

  return useQuery(
    ['companyApp', companyAppId],
    async () => {
      const response: AppDetailsResponse = await getData({
        path: `companies/$companyId/apps/${companyAppId}`,
      });

      return response.body;
    },
    {
      enabled: Boolean(companyAppId),
      keepPreviousData: true,
      onError: ({ message }) => showMessage({ message, isError: true }),
    },
  );
}
