import { useCallback } from 'react';
import { useActiveSavedFilterUpdate } from '../../../contexts/savedFilterContexts/activeSavedFilterContext';
import { useFiltersState } from '../../../contexts/filtersContext';
import { useSavedFilterModificationState } from '../../../contexts/savedFilterContexts/savedFilterModificationContext';
import { useSavedFiltersListUpdate } from '../../../contexts/savedFilterContexts/savedFiltersListContext';
import { useSavedFiltersTypeState } from '../../../contexts/savedFilterContexts/savedFiltersTypeContext';
import { useAppMessaging } from '../../../hooks';
import { postData } from '../../../utilities/xhr';

export default function useCreateInsightFilter() {
  const { setActiveSavedFilter } = useActiveSavedFilterUpdate();
  const { apiRequestFilters } = useFiltersState();
  const { currentConfiguration } = useSavedFilterModificationState();
  const { fetchInsightFilters } = useSavedFiltersListUpdate();
  const savedFiltersType = useSavedFiltersTypeState();
  const showMessage = useAppMessaging();

  const createInsightFilter = useCallback(
    ({
      betaFilter,
      description,
      displayForAppId,
      filterName,
      integrationType,
      mappingType,
      onError,
      onSuccess,
    }) => {
      const { columnsOrder, filters, tableSort, frozenColumns } = currentConfiguration;

      postData({
        path: `insightFilters`,
        params: {
          apiRequestFilters,
          betaFilter,
          columnsOrder,
          description,
          displayFilters: filters,
          displayForAppId,
          filterName,
          filterType: `${savedFiltersType}`,
          integrationType,
          mappingType,
          tableSort,
          frozenColumns,
        },
      })
        .then(({ body }) => {
          showMessage({ message: `${filterName} Insight Filter has been created.` });

          setActiveSavedFilter({
            columnsOrder,
            description,
            filterName,
            filters: JSON.parse(filters),
            id: body.id,
            isInsight: true,
            tableSort,
            frozenColumns,
            displayForAppId,
          });

          setTimeout(fetchInsightFilters, 500);

          onSuccess();
        })
        .catch(({ message }) => {
          onError(message);
        });
    },
    [
      apiRequestFilters,
      currentConfiguration,
      fetchInsightFilters,
      savedFiltersType,
      setActiveSavedFilter,
      showMessage,
    ],
  );

  return createInsightFilter;
}
