import { IGenericMapObject } from '../utility';

export type IntegrationType =
  | 'aha!'
  | 'airtable'
  | 'anaplan'
  | 'asana'
  | 'asanav2'
  | 'atlassian'
  | 'box'
  | 'calendly'
  | 'centrify'
  | 'idaptive'
  | 'cloudflare'
  | 'concur'
  | 'concurv2'
  | 'concurendorsed'
  | 'confluence'
  | 'coupa'
  | 'coupacontract'
  | 'docusign'
  | 'domo'
  | 'dropbox'
  | 'expensify'
  | 'figma'
  | 'github'
  | 'azuread'
  | 'msdynamics365'
  | 'genericusage'
  | 'apiusage'
  | 'genericsso'
  | 'googlesso'
  | 'gsuite'
  | 'hrroster'
  | 'hubspot'
  | 'informatica'
  | 'intacct'
  | 'jiracloud'
  | 'liveperson'
  | 'looker'
  | 'miro'
  | 'monday'
  | 'netsuite'
  | 'office365'
  | 'office365v2'
  | 'okta'
  | 'onelogin'
  | 'outreach'
  | 'outreachv2'
  | 'pagerdutyv2'
  | 'pagerduty'
  | 'pingplatform'
  | 'qualtrics'
  | 'salesforce'
  | 'servicenow'
  | 'servicenowv2'
  | 'slack'
  | 'slackutilization'
  | 'smartsheet'
  | 'trello'
  | 'veevacrm'
  | 'veevavault'
  | 'workdayfinancialexpense'
  | 'workdayfinancialap'
  | 'wrike'
  | 'launchdarkly'
  | 'zendesk'
  | 'zoom'
  | 'zylo';

export interface IIntegrationMasterListConfigurationObject {
  active: boolean;
  additionalDescription?: React.ReactNode;
  appName?: string;
  basicAuthFields?: IntegrationListFields;
  beta?: boolean;
  canBePrimaryUserStore?: boolean;
  /** When a new category is introduced, `category` must be updated. */
  category: 'communication' | 'financial' | 'hr' | 'sso' | 'utilization' | 'contracts';
  customOauthConnectButton?: React.ReactNode;
  description: React.ReactNode;
  displayName: string;
  disabledReconnectingFields?: string[];
  fields?: IntegrationListFields;
  formatCustomOauthURL?: (config: GenericIntegrationValues) => string;
  formatIntegrationData?: (config: GenericIntegrationValues) => GenericIntegrationValues;
  formatOauthValidationData?: (config: GenericIntegrationValues) => GenericIntegrationValues;
  fourthOauthURL?: (config: GenericIntegrationValues) => string;
  hasCustomModal?: boolean;
  isCustomOauth?: boolean;
  isAppProvider?: boolean;
  isOauth?: boolean;
  isSuiteIntegration?: boolean;
  isWebhookIntegration?: boolean;
  knowledgeBaseUrl?: string;
  logo: string;
  mappingType?: 'batch-generic-direct' | 'batch-generic-sso' | 'import-api-usage' | 'sso' | 'suite';
  multiOrgSupport?: boolean;
  oauthURL?: string;
  oAuthRequiredFields?: string[];
  oAuthFields?: IntegrationListFields;
  reconnectingDisabledFields?: string[];
  requiredBasicAuthFields?: string[];
  requiredFields?: string[];
  /** This is potentially a non-existent property but appears here for compatiblity with `ConnectedIntegrationCard` */
  sandboxAccount?: boolean;
  sandboxURL?: string;
  secondOauthURL?: (config: GenericIntegrationValues) => string;
  solutionId?: string;
  subtitle?: string;
  systemType?: 'zylo';
  thirdOauthURL?: (config: GenericIntegrationValues) => string;
  /** When a new type is introduced, `IntegrationType` must be updated. */
  type: IntegrationType;
  uriType?: string;
  validate?: (value: any) => boolean | string | undefined;
}

export type DataMappingType = 'array' | 'boolean' | 'currency' | 'date' | 'integer' | 'text';

export type DataMappingField = {
  /** Present on internal/hidden fields only. */
  applyDefault?: string;
  /** Present on internal/hidden fields only. */
  defaultValue?: string;
  /** This is the Source Data Header. */
  from: string;
  /** This is the `displayKey` (Zylo Data Field); converted to snake case for custom fields only. */
  to: string;
  options: {
    type: DataMappingType;
    /** This is the Zylo Data Field; it is not present on internal/hidden fields. */
    displayKey?: string;
    required?: boolean;
    internal?: boolean;
  };
};

export type IntegrationConfigurations = {
  accountHasMetricsAccess?: boolean;
  allApps?: { created: string; id: string; label: string; name: string }[];
  backoffThreshold?: unknown;
  dataMapping?: DataMappingField[];
  domain?: string;
  hasCredentials?: boolean;
  includeUtilizationData?: boolean;
  includeTokenData?: boolean;
  mapping?: IGenericMapObject<string>[];
  org?: { id: string; name: string };
  redirectUri?: string;
  requestCredentials?: boolean;
  savedSearch?: { id: string; name: string };
  scope?: string | string[];
  tokenType?: string;
  useWebhook?: boolean;
};

export type IntegrationStandardCredentials = {
  awsBucket?: { name: string; path: string; region: string };
  isSandbox?: boolean;
  redirectUri?: string;
  sandboxAccount?: boolean;
  scope?: string;
  userName?: string;
};

export enum IntegrationStatus {
  Error = 'Error',
  PendingUserInput = 'Pending User Input',
  Sucess = 'Success',
}

export enum IntegrationStates {
  Inactive = 'Inactive',
  Active = 'Active',
  Pending = 'Pending',
  Error = 'Error',
}

export type Integration = {
  appLabel: string;
  appDomain: string;
  companyAppId: string;
  companyId: string;
  configurations: IntegrationConfigurations;
  connectDate: string;
  createdAt: string;
  createdBy: string;
  errorMessage: string;
  externalOrganizationId: string;
  disconnectDate: string;
  id: string;
  companyIntegrationId?: string; // ZAP query alias for id
  jobCompletionDate: string;
  lastFetchDate: string;
  lastRunStatus: string;
  modifiedAt: string;
  modifiedBy: string;
  name: string;
  nextJobDate: string;
  standardCredentials: IntegrationStandardCredentials;
  status: IntegrationStatus;
  state: IntegrationStates;
  stats: { unmappedAppCount?: number; webhookConnected?: boolean };
  type: IntegrationType;
  step: string;
};

export type GenericIntegrationValues<T = any> = IGenericMapObject<T>;

export type IntegrationListFields =
  | ({
      label?: React.ReactNode;
      name?: string;
      options?: { label: React.ReactNode; name: string; value: string }[];
      value?: boolean | number | string;
      [k: string]: any;
    } | null)[];

export type ConnectedIntegration = Integration & IIntegrationMasterListConfigurationObject;

export type IntegrationNotConnected = {
  communications?: IIntegrationMasterListConfigurationObject[];
  financial?: IIntegrationMasterListConfigurationObject[];
  hr?: IIntegrationMasterListConfigurationObject[];
  sso?: IIntegrationMasterListConfigurationObject[];
  utilization?: IIntegrationMasterListConfigurationObject[];
};

export type IntegrationData = {
  connected: {
    error: ConnectedIntegration[];
    pending: ConnectedIntegration[];
    success: ConnectedIntegration[];
  };
  notConnected: IntegrationNotConnected;
};

/** integration Redux module */
export type Integrations = {
  activeIntegrations: ConnectedIntegration[];
  data: IntegrationData;
  pendingDeleteIntegrations: ConnectedIntegration[];
  redirectParams: IGenericMapObject<unknown>;
  validationFailed: boolean;
};

/** This intersection accurately describes the properties available in `integrationSettings` and is not the same as `ConnectedIntegration`. */
export type IntergrationSettings = IIntegrationMasterListConfigurationObject & Partial<Integration>;

/** The props passed to all integration modal components. */
export type StandardIntegrationModalProps = {
  getListOfCompanyIntegrations(): void;
  handleClose(): void;
  integrationSettings: IntergrationSettings;
  isConnecting: boolean;
  isReconnecting: boolean;
  mappingType: string | undefined;
  refreshModal(): void;
  saveZyloForm(): void;
  submitIntegration(): void;
  validateIntegration(): boolean;
};
