import { useQuery, UseQueryResult } from 'react-query';
import { getData } from '@utilities/xhr';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';
import { operations } from '@typings/api';
import { useFiscalData } from './useFiscalData';

export type ISoftwareSpendStats = operations['getChargeStats']['responses']['200']['schema'] & {
  currentFiscalYearToDateSpend?: number;
};

export type SoftwareSpendStatsResponse = {
  body: ISoftwareSpendStats;
};

type SoftwareSpendParams = {
  filters?: string;
  search?: string;
  useFiscalData?: boolean;
};

async function fetchSoftwareSpendStats(params: SoftwareSpendParams) {
  const { body: softwareSpendStats }: SoftwareSpendStatsResponse = await getData({
    path: `companies/$companyId/softwareSpendCharges/stats`,
    params,
  });
  return softwareSpendStats;
}

export function useSoftwareSpendStats(): UseQueryResult<ISoftwareSpendStats> {
  const { apiRequestFilters } = useFiltersState();
  const searchValue = useSearchState();
  const includeFiscal = useFiscalData();
  // Because we take the stringified API filter directly, an 'empty' filter selection is actually "[]"
  const filtersEmpty = !apiRequestFilters || apiRequestFilters.length <= 2;
  const filters = filtersEmpty ? undefined : apiRequestFilters;
  const search = !searchValue ? undefined : searchValue;

  return useQuery(
    ['softwareSpendStats', filters, search],
    (ctx) =>
      fetchSoftwareSpendStats({
        filters: ctx.queryKey[1],
        search: ctx.queryKey[2],
        useFiscalData: includeFiscal,
      }),
    {
      staleTime: 1000 * 60 * 60 * 12, // 12 hours,
      refetchOnWindowFocus: false,
    },
  );
}
