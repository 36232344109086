import { useCallback } from 'react';
import { useAppDetailsCompanyAppId, useAppMessaging, useEventTracking } from '@hooks';
import { useSavedFiltersListUpdate } from '@contexts/savedFilterContexts/savedFiltersListContext';
import { useSavedFilterModificationState } from '@contexts/savedFilterContexts/savedFilterModificationContext';
import { useFiltersState } from '@contexts/filtersContext';
import { useDefaultTableViewUpdate } from '@contexts/savedFilterContexts/defaultTableViewContext';
import {
  useActiveSavedFilterState,
  useActiveSavedFilterUpdate,
} from '@contexts/savedFilterContexts/activeSavedFilterContext';
import { putData } from '../../../utilities/xhr';

export default function useUpdateSavedFilter() {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { setActiveSavedFilter } = useActiveSavedFilterUpdate();
  const { setDefaultSavedFilter } = useDefaultTableViewUpdate();
  const { apiRequestFilters } = useFiltersState();
  const { currentConfiguration } = useSavedFilterModificationState();
  const { fetchSavedFilters } = useSavedFiltersListUpdate();
  const showMessage = useAppMessaging();
  const sendEvent = useEventTracking();
  const companyAppId = useAppDetailsCompanyAppId();

  const updateSavedFilter = useCallback(() => {
    const { filterName, id: savedFilterId, teamFilter } = activeSavedFilter || {};
    const { columnsOrder, filters, frozenColumns, tableSort } = currentConfiguration;

    return new Promise(async (resolve) => {
      await putData({
        path: `companies/$companyId/savedFilter/${savedFilterId}`,
        params: {
          apiRequestFilters,
          columnsOrder,
          companyAppId,
          defaultFilter: activeSavedFilter.defaultFilter,
          displayFilters: filters,
          filterName,
          frozenColumns,
          tableSort,
          teamFilter,
        },
      })
        .then(() => {
          const updatedSavedFilter = {
            ...activeSavedFilter,
            columnsOrder,
            filters: JSON.parse(filters),
            frozenColumns,
            tableSort,
          };

          fetchSavedFilters();
          setActiveSavedFilter(updatedSavedFilter);

          if (updatedSavedFilter.defaultFilter) {
            setDefaultSavedFilter(updatedSavedFilter);
          }

          showMessage({ message: `${filterName} Saved Filter has been updated.` });
          sendEvent({
            eventName: 'SAVE_CHANGES_SAVED_FILTER',
            data: {
              'Current Filters': filters,
              'Previous Filters': JSON.stringify(activeSavedFilter.filters),
            },
          });
        })
        .catch(({ message }) => {
          showMessage({ message, isError: true });
        })
        .finally(resolve);
    });
  }, [
    activeSavedFilter,
    apiRequestFilters,
    companyAppId,
    currentConfiguration,
    fetchSavedFilters,
    sendEvent,
    setActiveSavedFilter,
    setDefaultSavedFilter,
    showMessage,
  ]);

  return updateSavedFilter;
}
