import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { usePortalContext, checkForProvider } from '@zylo/orchestra';
import { useDefaultTableViewState } from '@contexts/savedFilterContexts/defaultTableViewContext';
import { useSavedFiltersListState } from '@contexts/savedFilterContexts/savedFiltersListContext';
import { useSortState, useSortUpdate } from '@contexts/sortContext';
import { useSearchUpdate } from '@contexts/searchContext';
import { useFiltersState, useFiltersUpdate } from '@contexts/filtersContext';
import { useColumnsState, useColumnsUpdate } from '@contexts/columnsContext';
import { useQueryParameters } from '@hooks';

const ActiveSavedFilterStateContext = createContext();
const ActiveSavedFilterUpdateContext = createContext();

function ActiveSavedFilterProvider({ children, savedFilterStateRef }) {
  const { hasPreselectedColumns } = useColumnsState();
  const { updateColumnsOrder } = useColumnsUpdate();
  const { defaultSavedFilter, defaultView } = useDefaultTableViewState();
  const { defaultSelectedFilters, hasPreselectedFilters } = useFiltersState();
  const { replaceSelectedFilters } = useFiltersUpdate();
  const { insightFilters, savedFilters } = useSavedFiltersListState();
  const { existsInPortal } = usePortalContext();
  const setSearchValue = useSearchUpdate();
  const { hasPreselectedSort } = useSortState();
  const { handleSort: sortTable } = useSortUpdate();
  const [preselectedSavedFilter, setPreselectedSavedFilter] = useState();
  const [activeSavedFilter, setActiveSavedFilter] = useState();
  const queryParameters = useQueryParameters();
  const filtersOverrideRef = useRef(JSON.parse(defaultSelectedFilters));
  const globalSearchRef = useRef('');

  const handleActiveSavedFilterChange = useCallback(
    (newSavedFilter) => {
      setActiveSavedFilter(newSavedFilter);
      if (newSavedFilter && typeof newSavedFilter === 'object') {
        replaceSelectedFilters(newSavedFilter.filters);
        updateColumnsOrder(
          Array.isArray(newSavedFilter.columnsOrder) ? newSavedFilter.columnsOrder : null,
          Array.isArray(newSavedFilter.frozenColumns) ? newSavedFilter.frozenColumns : [],
        );
        sortTable(newSavedFilter.tableSort);
      } else if (newSavedFilter !== undefined) {
        const newFilters = filtersOverrideRef.current;
        const newSearch = globalSearchRef.current;

        filtersOverrideRef.current = JSON.parse(defaultSelectedFilters);
        globalSearchRef.current = '';

        if (newSearch) {
          setSearchValue(newSearch);
        }

        replaceSelectedFilters(newFilters);
        updateColumnsOrder(null);
        sortTable(null);
      }
    },
    [defaultSelectedFilters, replaceSelectedFilters, updateColumnsOrder, setSearchValue, sortTable],
  );

  const savedFilterToApply = useRef();
  const applySavedFilterById = useCallback(
    (savedFilterId) => {
      if (!Object.keys(savedFilters).length) {
        savedFilterToApply.current = savedFilterId;

        return;
      }

      const matchingFilter = Object.values(savedFilters)
        .flat()
        .find(({ id }) => id === savedFilterId);

      if (matchingFilter) {
        handleActiveSavedFilterChange(matchingFilter);
      }
    },
    [handleActiveSavedFilterChange, savedFilters],
  );

  useEffect(() => {
    if (savedFilterToApply.current) {
      const matchingFilter = Object.values(savedFilters)
        .flat()
        .find(({ id }) => id === savedFilterToApply.current);

      if (matchingFilter) {
        savedFilterToApply.current = undefined;
        handleActiveSavedFilterChange(matchingFilter);
      }
    }
  }, [handleActiveSavedFilterChange, savedFilters]);

  useImperativeHandle(
    savedFilterStateRef,
    () => ({
      applySavedFilterById,
    }),
    [applySavedFilterById],
  );

  // applies preselected saved filter / insight filter, if applicable
  useEffect(() => {
    if (preselectedSavedFilter) {
      handleActiveSavedFilterChange(preselectedSavedFilter);
    }
  }, [handleActiveSavedFilterChange, preselectedSavedFilter]);

  // initially applies default saved filter, if applicable
  useEffect(() => {
    if (
      !defaultView &&
      hasPreselectedColumns === false &&
      hasPreselectedFilters === false &&
      hasPreselectedSort === false &&
      defaultSavedFilter &&
      preselectedSavedFilter === null
    ) {
      handleActiveSavedFilterChange(defaultSavedFilter);
    }
  }, [
    handleActiveSavedFilterChange,
    defaultSavedFilter,
    defaultView,
    hasPreselectedColumns,
    hasPreselectedFilters,
    hasPreselectedSort,
    preselectedSavedFilter,
  ]);

  const clearActiveSavedFilter = useCallback(() => {
    // global search can result in consecutive clearActiveSavedFilter calls
    handleActiveSavedFilterChange((currentVal) => (currentVal === null ? false : null));
  }, [handleActiveSavedFilterChange]);

  const returnToDefaultView = useCallback(() => {
    if (defaultSavedFilter) {
      /* uniqueIdentifier makes sure the useEffect that observes activeSavedFilter
         fires when discarding changes to the default saved filter */
      handleActiveSavedFilterChange({ ...defaultSavedFilter, uniqueIdentifier: Symbol('unique') });
    } else {
      const defaults = JSON.parse(defaultView.obj);

      clearActiveSavedFilter();
      updateColumnsOrder(defaults.columnsOrder, defaults.frozenColumns);
      replaceSelectedFilters(defaults.filters);
      sortTable(defaults.tableSort);
    }
  }, [
    clearActiveSavedFilter,
    defaultSavedFilter,
    defaultView,
    replaceSelectedFilters,
    updateColumnsOrder,
    sortTable,
    handleActiveSavedFilterChange,
  ]);

  // handles preselected saved filters / insights, global search and filter overrides from app details
  useEffect(() => {
    if (!queryParameters) return;

    const { filtersOverride, globalSearch, insightFilter, savedFilter } = queryParameters;

    if (globalSearch && !existsInPortal) {
      globalSearchRef.current = globalSearch;

      clearActiveSavedFilter();

      return;
    }

    if (filtersOverride && !existsInPortal) {
      filtersOverrideRef.current = {
        ...JSON.parse(defaultSelectedFilters),
        ...JSON.parse(filtersOverride),
      };
      globalSearchRef.current = '';

      clearActiveSavedFilter();

      return;
    }

    if (insightFilter && !existsInPortal) {
      const preselection = insightFilters.find(({ id }) => id === insightFilter);

      if (preselection) {
        setPreselectedSavedFilter(preselection);
      }

      return;
    }

    if (savedFilter) {
      const preselection = Object.values(savedFilters)
        .flat()
        .find(({ id }) => id === savedFilter);

      if (preselection) {
        setPreselectedSavedFilter(preselection);
      }
    } else if (!existsInPortal) {
      setPreselectedSavedFilter(null);
    }
  }, [
    clearActiveSavedFilter,
    defaultSelectedFilters,
    existsInPortal,
    insightFilters,
    queryParameters,
    savedFilters,
  ]);

  return (
    <ActiveSavedFilterStateContext.Provider value={{ activeSavedFilter }}>
      <ActiveSavedFilterUpdateContext.Provider
        value={{
          clearActiveSavedFilter,
          returnToDefaultView,
          setActiveSavedFilter: handleActiveSavedFilterChange,
        }}
      >
        {children}
      </ActiveSavedFilterUpdateContext.Provider>
    </ActiveSavedFilterStateContext.Provider>
  );
}

ActiveSavedFilterProvider.propTypes = {
  children: PropTypes.node,
};

function useActiveSavedFilterState() {
  const context = useContext(ActiveSavedFilterStateContext);

  checkForProvider(context, 'useActiveSavedFilterState', 'ActiveSavedFilterProvider');

  return context;
}

function useActiveSavedFilterUpdate() {
  const context = useContext(ActiveSavedFilterUpdateContext);

  checkForProvider(context, 'useActiveSavedFilterUpdate', 'ActiveSavedFilterProvider');

  return context;
}

export { ActiveSavedFilterProvider, useActiveSavedFilterState, useActiveSavedFilterUpdate };
