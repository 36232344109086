import { createElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { get } from 'lodash-es';
import { Button, Icon } from '@zylo/orchestra';
import eventTrackingHOC from '../hoc/eventTrackingHOC';
import InputGroup from './inputs/InputGroup';

class ZyloFormField extends PureComponent {
  constructor(props) {
    super(props);

    this.previousValues = [get(props, 'input.value', null)];
    this.state = { dirty: false, saveComplete: false, fieldUpdated: false };
  }

  UNSAFE_componentWillReceiveProps({ input, meta }) {
    const { autosave } = this.props;
    const { value } = input;
    const { dirty, pristine } = meta;
    if (autosave) {
      if (dirty) {
        this.setState({ dirty: true });
        // eslint-disable-next-line react/destructuring-assignment
      } else if (this.state.dirty === true && pristine === true) {
        this.setState(
          {
            dirty: false,
            saveComplete: true,
            fieldUpdated: input.value !== this.previousValues[0],
          },
          () => {
            setTimeout(() => {
              this.setState({ saveComplete: false });
            }, 1500);
          },
        );

        if (!this.previousValues.includes(value)) {
          this.previousValues.push(value);
        }
      }
    }
  }

  handleFieldChange = (newValue) => {
    const { handleChange, input } = this.props;
    const { name, onChange } = input;
    handleChange(name, onChange, newValue);
  };

  handleUndo = () => {
    const { input, handleChange, sendEvent } = this.props;
    const { name, value, onChange } = input;
    const newValue = this.previousValues[this.previousValues.length - 2];
    handleChange(name, onChange, newValue);
    this.previousValues.splice(-1);

    sendEvent({
      eventName: 'AUTOSAVE_UNDO',
      data: {
        'Field Name': name,
        'Previous Value': value,
        'New Value': newValue,
      },
    });
  };

  render() {
    const {
      additionalMarkup,
      autosave,
      className,
      customComponent,
      disabled,
      input,
      inputRef,
      instructions,
      isDisabled,
      label,
      labelColor,
      layout = 'vertical',
      maxLength,
      meta,
      placeholder,
      rows,
      tooltip,
      type,
      readOnly,
      inputGroupPre,
      inputGroupPost,
    } = this.props;
    const { dirty, saveComplete, fieldUpdated } = this.state;
    const { name, value } = input;
    const { error } = meta;
    const isTagNameString = customComponent === 'textarea' || customComponent === 'input';
    const propsToPass = isTagNameString ? { maxLength } : this.props;
    const fieldIsDisabled = disabled || isDisabled;

    const field = customComponent
      ? createElement(customComponent, {
          ...propsToPass,
          name,
          value,
          placeholder,
          disabled: fieldIsDisabled,
          onChange: this.handleFieldChange,
          className: isTagNameString ? `form-element ${className || ''}` : className,
          id: isTagNameString ? name : null,
          label: isTagNameString ? label : null,
          autoComplete: isTagNameString ? 'off' : null,
          rows: customComponent === 'textarea' ? rows : null,
          type: customComponent === 'input' ? type : null,
          ref: inputRef || null,
          readOnly,
        })
      : null;

    return type === 'hidden' ? (
      field
    ) : (
      <div
        className={classNames(
          'zylo-form-field',
          'flex-container',
          layout === 'vertical' ? 'vertical' : 'row',
          layout === 'row' && 'wrap-normal',
          name,
          (!autosave || dirty) && error && 'validation-error',
          dirty && 'dirty-field',
          saveComplete && 'save-complete',
          fieldUpdated && 'field-updated',
          fieldIsDisabled && 'disabled',
        )}
      >
        {label && (
          <div className="flex-container align-center zylo-form-label-wrapper">
            <label
              className={`flex-container align-center zylo-form-label color-${labelColor}`}
              htmlFor={name}
            >
              {label}
            </label>
            {tooltip}
          </div>
        )}
        <InputGroup disabled={fieldIsDisabled} postpend={inputGroupPost} prepend={inputGroupPre}>
          {field}
        </InputGroup>

        {additionalMarkup}
        <span className="zylo-form-message">
          {instructions && <span className="field-instructions">{instructions}</span>}
          {error && <span className="error-message">{error}</span>}
          {autosave && (
            <span>
              <span className="autosave-pending">Saving</span>
              <span className="save-success">
                <Icon color="green" icon="IconCircleCheck" size={1} relativeSize />
                Save Complete
              </span>
              <span className="update-message">
                <span>
                  <Icon color="green" icon="IconCircleCheck" size={1} relativeSize />
                  This field has been updated.
                </span>
                <Button buttonColor="gray" handleClick={this.handleUndo} text="Undo" type="link" />
              </span>
            </span>
          )}
        </span>
      </div>
    );
  }
}

ZyloFormField.propTypes = {
  additionalMarkup: PropTypes.node,
  autosave: PropTypes.bool,
  className: PropTypes.string,
  customComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(['input', 'textarea'])]),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  inputGroupPost: PropTypes.node,
  inputGroupPre: PropTypes.node,
  inputRef: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.object]),
  instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // isDisabled prop exists b/c disabled prop gets overwritten by Field component if passed in directly to ZyloFormField
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelColor: PropTypes.oneOf(['blue', 'darkgray']),
  layout: PropTypes.string,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    error: PropTypes.string,
    pristine: PropTypes.bool,
  }).isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  sendEvent: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  type: PropTypes.string,
};

ZyloFormField.defaultProps = {
  autosave: false,
  customComponent: 'input',
  disabled: false,
  labelColor: 'darkgray',
  type: 'text',
};

export default eventTrackingHOC(ZyloFormField);
