import { createAction, handleActions } from 'redux-actions';
import { get } from 'lodash-es';
import WaterWorksAPI from '../WaterWorksAPI';
import { wrapAsync, handleNetworkError } from '../utilities/util';
import { actions as zyloFormActions } from './zyloForm';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants
// ------------------------------------

export const GET_NETSUITE_SAVED_SEARCHES_SUCCESS = 'GET_NETSUITE_SAVED_SEARCHES_SUCCESS';
export const GET_NETSUITE_SAVED_SEARCHES_FAIL = 'GET_NETSUITE_SAVED_SEARCHES_FAIL';

export const SUBMIT_NETSUITE_SAVED_SEARCH_START = 'SUBMIT_NETSUITE_SAVED_SEARCH_START';
export const SUBMIT_NETSUITE_SAVED_SEARCH_SUCCESS = 'SUBMIT_NETSUITE_SAVED_SEARCH_SUCCESS';
export const SUBMIT_NETSUITE_SAVED_SEARCH_ERROR = 'SUBMIT_NETSUITE_SAVED_SEARCH_ERROR';

// ------------------------------------
// Actions
// ------------------------------------

export const getNetsuiteSavedSearchesSuccess = createAction(GET_NETSUITE_SAVED_SEARCHES_SUCCESS);
export const getNetsuiteSavedSearchesFail = createAction(GET_NETSUITE_SAVED_SEARCHES_FAIL);

export const submitNetsuiteSavedSearchStart = createAction(SUBMIT_NETSUITE_SAVED_SEARCH_START);
export const submitNetsuiteSavedSearchSuccess = createAction(SUBMIT_NETSUITE_SAVED_SEARCH_SUCCESS);
export const submitNetsuiteSavedSearchError = createAction(SUBMIT_NETSUITE_SAVED_SEARCH_ERROR);

async function getNetsuiteSavedSearches({ integrationId }, dispatch, getState) {
  const netsuiteId =
    integrationId ||
    get(getState(), 'integrations.data.connected.pending', []).find(({ type }) => {
      return type === 'netsuite';
    }).id;

  if (netsuiteId) {
    const { token, companyId } = getState().auth;
    let result;

    try {
      result = await WaterWorksAPI.fetchCompanyData(
        token,
        companyId,
        ['integrations', netsuiteId, 'netsuite/savedSearches'],
        {},
      );
    } catch (e) {
      const statusCode = get(e, 'response.statusCode');
      if (statusCode === '404') {
        dispatch(
          getNetsuiteSavedSearchesFail(
            get(e, 'response.body.message', 'Failed to fetch Netsuite Saved Searches'),
          ),
        );
      }
      handleNetworkError('getNetsuiteSavedSearches', e, dispatch, saveZyloFormError, {
        companyId,
        integrationId: netsuiteId,
      });
      return;
    }

    dispatch(getNetsuiteSavedSearchesSuccess(result));
  }
}

async function submitNetsuiteSavedSearch({ integrationId, savedSearch }, dispatch, getState) {
  dispatch(submitNetsuiteSavedSearchStart());

  const { token, companyId } = getState().auth;

  try {
    await WaterWorksAPI.updateCompanyData(
      token,
      companyId,
      ['integrations', integrationId, 'netsuite/savedSearches'],
      { ...savedSearch },
    );
  } catch (e) {
    handleNetworkError('submitNetsuiteSavedSearch', e, dispatch, saveZyloFormError, {
      companyId,
      integrationId,
      savedSearch,
    });

    dispatch(submitNetsuiteSavedSearchError());

    return;
  }

  dispatch(submitNetsuiteSavedSearchSuccess());
  dispatch(
    saveZyloFormSuccess(
      savedSearch.id
        ? `${savedSearch.name} saved search applied to Netsuite integration.`
        : 'Netsuite integration complete',
    ),
  );
}

function storeNetsuiteSavedSearches({ savedSearches }) {
  return (dispatch) => {
    dispatch(getNetsuiteSavedSearchesSuccess(savedSearches));
  };
}

export const actions = {
  getNetsuiteSavedSearches: wrapAsync(getNetsuiteSavedSearches),
  submitNetsuiteSavedSearch: wrapAsync(submitNetsuiteSavedSearch),
  storeNetsuiteSavedSearches,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(
  {
    [GET_NETSUITE_SAVED_SEARCHES_SUCCESS]: (state, { payload }) => {
      return { ...state, netsuiteSavedSearchesList: payload, savedSearchFetchError: null };
    },
    [GET_NETSUITE_SAVED_SEARCHES_FAIL]: (state, { payload }) => {
      return { ...state, netsuiteSavedSearchesList: [], savedSearchFetchError: payload };
    },

    [SUBMIT_NETSUITE_SAVED_SEARCH_START]: (state) => {
      return { ...state, isSubmittingNetsuiteSavedSearch: true };
    },
    [SUBMIT_NETSUITE_SAVED_SEARCH_SUCCESS]: (state) => {
      return {
        ...state,
        netsuiteSavedSearchComplete: true,
        isSubmittingNetsuiteSavedSearch: false,
      };
    },
  },
  {
    netsuiteSavedSearchesList: [],
    savedSearchFetchError: null,
    isSubmittingNetsuiteSavedSearch: false,
    netsuiteSavedSearchComplete: false,
  },
);
