import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { parse, ParsedQuery } from 'query-string';

export function useQueryParameters() {
  const location = useLocation();
  const [queryParameters, setQueryParameters] = useState<ParsedQuery>();
  /* default queryParameters value needs to remain undefined so that consumers
     can determine when parameters have been parsed (undefined means they have not) */

  useEffect(() => {
    setQueryParameters(parse(location.search));
  }, [location]);

  return queryParameters;
}
