import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Tooltip } from '@zylo/orchestra';
import OscillatingText from '@components/OscillatingText';

export function MultiValueCell({ data, handleValueClick }) {
  const [mainValues, setMainValues] = useState([]);
  const [secondaryValues, setSecondaryValues] = useState([]);
  const [measuringInProgress, setMeasuringInProgress] = useState(false);
  const cellElement = useRef();
  const containterElement = useRef();
  const secondaryValuesCount = secondaryValues.length;
  const maxWidth = 300;

  const measureValues = useCallback(
    (elem) => {
      let tagsContainer = elem;

      if (elem) {
        containterElement.current = elem;
      } else {
        tagsContainer = containterElement.current;
      }

      if (!data || !tagsContainer || !cellElement.current) return;

      setMeasuringInProgress(true);

      function formatValues(nodes) {
        const mainValuesUpdate = [];
        const secondaryValuesUpdate = [];

        if (nodes.length) {
          let longestFullValue;
          let potentialPartialValue;
          // 56px of padding + 56px for '+ x more' tooltip if tags overflow
          let totalWidth = 56 + (tagsContainer.clientHeight > 50 ? 56 : 0);
          let remainingWidth = maxWidth - totalWidth;

          nodes.forEach((child, i) => {
            const value = data[i];

            if (value) {
              const valueWidth = child.clientWidth;

              if (valueWidth <= remainingWidth || i === 0) {
                mainValuesUpdate.push({ value, flexShrink: 0 });
                remainingWidth = maxWidth - (totalWidth + valueWidth);
                totalWidth += valueWidth + 5; // 5px right margin

                if (
                  valueWidth > 100 &&
                  (!longestFullValue || valueWidth >= longestFullValue.width)
                ) {
                  longestFullValue = { index: mainValuesUpdate.length - 1, width: valueWidth };
                }
              } else {
                secondaryValuesUpdate.push(value);

                if (!potentialPartialValue || valueWidth < potentialPartialValue.flexShrink) {
                  potentialPartialValue = { value, flexShrink: valueWidth };
                }
              }
            }
          });

          if (mainValuesUpdate.length) {
            mainValuesUpdate[mainValuesUpdate.length - 1].flexShrink = 1;
          }

          if (potentialPartialValue) {
            const { value: partialValue, flexShrink } = potentialPartialValue;

            // only display partial pill if more than 100px of available cell width remains
            if (remainingWidth >= 100) {
              mainValuesUpdate.push({ value: partialValue, flexShrink });
              secondaryValuesUpdate.splice(secondaryValuesUpdate.indexOf(partialValue), 1);

              if (longestFullValue) {
                const { index, width } = longestFullValue;

                mainValuesUpdate[index].flexShrink = width;
                mainValuesUpdate[index].minWidth = width / 2;
              }
            }
          }
        }

        // checking to make sure component has not unmounted
        if (cellElement.current) {
          setMainValues(mainValuesUpdate);
          setSecondaryValues(secondaryValuesUpdate);
          setMeasuringInProgress(false);
        }
      }

      // allows time for tags in sandbox to render for accurate measurement
      setTimeout(() => formatValues(tagsContainer.childNodes), 10);
    },
    [data],
  );

  useLayoutEffect(() => {
    measureValues();
  }, [measureValues]);

  function handleMainValueClick(e) {
    e.stopPropagation();

    if (handleValueClick) {
      handleValueClick(e.target.innerText);
    }
  }

  return (
    <div
      className={classNames(
        'flex-container align-center multi-value-cell',
        !handleValueClick && 'read-only',
      )}
      ref={cellElement}
      style={{ minWidth: data && data.length ? maxWidth : 0, maxWidth }}
    >
      <div className="multi-value-cell-sandbox">
        <div className="flex-container wrap-normal" ref={measureValues}>
          {(data || []).map((value) => (
            <div className="tag" data-testid="multivalue-tag" key={value}>
              {value}
            </div>
          ))}
        </div>
      </div>

      {!measuringInProgress && mainValues.length ? (
        <>
          <div
            className="flex-container main-values-container"
            data-testid="multi-value-cell-main-values"
          >
            {mainValues.map(({ value, flexShrink, minWidth = 'auto' }) => (
              <div
                className="tag"
                key={value}
                onClick={handleMainValueClick}
                style={{ flexShrink, minWidth }}
              >
                <OscillatingText text={value} />
              </div>
            ))}
          </div>

          {!measuringInProgress && secondaryValuesCount > 0 && (
            <Tooltip
              allowBodyHover={!!handleValueClick}
              key="show-more-tags"
              popoverClassName="multi-value-cell-tooltip"
              text={
                <div className="flex-container wrap-normal" style={{ width: 300 }}>
                  {secondaryValues.map((value) => (
                    <div
                      className="tag"
                      key={value}
                      onClick={handleMainValueClick}
                      style={{ whiteSpace: 'normal' }}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              }
            >
              <span className="show-more-tags" onClick={(e) => e.stopPropagation()}>
                {`+ ${secondaryValuesCount} more`}
              </span>
            </Tooltip>
          )}
        </>
      ) : (
        '-'
      )}
    </div>
  );
}

MultiValueCell.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleValueClick: PropTypes.func,
};
