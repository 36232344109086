import PropTypes from 'prop-types';
import classNames from 'clsx';
import { ZyloButton } from '@zylo/orchestra';

export function HandleClickCell({
  className,
  renderConditionalText,
  data,
  handleClick,
  isDisabled,
  rowData,
  text,
}) {
  const disabled = typeof isDisabled === 'function' ? isDisabled(rowData) : isDisabled;

  function onClick() {
    handleClick(rowData);
  }

  function renderText() {
    if (text) {
      return text;
    }
    if (renderConditionalText) {
      return renderConditionalText(data);
    }
    return data;
  }

  const buttonText = renderText();

  return (
    <ZyloButton
      className={classNames('handle-click-cell', className)}
      disabled={disabled}
      onClick={onClick}
      variant="text"
    >
      {buttonText}
    </ZyloButton>
  );
}

HandleClickCell.propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  renderConditionalText: PropTypes.func,
  rowData: PropTypes.shape({}).isRequired,
  text: PropTypes.string,
};
