import { get } from 'lodash-es';

function isObject(testVar) {
  return testVar instanceof Object && !Array.isArray(testVar) && testVar !== null;
}

function buildSearchRegex(search, wildcard = true, caseSensitive = false) {
  return new RegExp(wildcard ? `^.*${search}.*$` : `^${search}$`, caseSensitive ? 'g' : 'ig');
}

function valueMatchesCriteria(value, critera, wildcard = true, caseSensitive = false) {
  const searchRegex = buildSearchRegex(critera, wildcard, caseSensitive);
  return searchRegex.test(value);
}

/**
 * @summary filter an array to the given search criteria.
 * @description filters using a semi-simple regex for string matching. does not support "advanced search"
 *   abilities where the search string may contain column name and </> specifications
 * @param {object[]} array
 * @param {string?} [search = '']
 * @param {object}  [options = {}]  additional search options
 * @param {string?} [options.propertyPath]  the property/nested property to search within
 * @param {boolean} [options.wildcard = true]  perform wildcard searching ie: string is contained within the value
 * @param {boolean} [options.caseSensitive = false] match regardless of text casing
 * @param {number} [options.minSearchLength = 0] prevent searching until the search string is >= provided value
 * @param {boolean} [options.recursive = true] when propertyPath is NOT defined, search for value matches nested within objects
 * @returns {object[]}
 */
function searchArrayOfObjects(array, search = '', options = {}) {
  if (!search || !array || !search.trim() || array.length <= 0) {
    return array || [];
  }
  const {
    propertyPath,
    wildcard = true,
    caseSensitive = false,
    minSearchLength = 0,
    recursive = true,
  } = options;

  if (search.length < minSearchLength) {
    return array || [];
  }

  const propertySearch = (property) => {
    return (object) => {
      const val = get(object, property);
      return valueMatchesCriteria(val, search, wildcard, caseSensitive);
    };
  };

  if (propertyPath) {
    return array.filter(propertySearch(propertyPath));
  }
  const searchFullObject = (obj) => {
    return Object.keys(obj).some((property) => {
      const val = obj[property];
      if (recursive && isObject(val)) {
        return searchFullObject(val);
      }
      return propertySearch(property)(obj);
    });
  };
  return array.filter(searchFullObject);
}

export default searchArrayOfObjects;
