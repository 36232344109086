import downloadBlobUrl from '@utilities/util/downloadBlobUrl';
import { getData } from '@utilities/xhr';
import { buildZAPEndpoint } from '@frameworks/zap';
import { useEventTracking } from './useEventTracking';
import { useAppMessaging } from './useAppMessaging';

export function useBudgetTemplateDownload(fullYear: string) {
  const showMessage = useAppMessaging();
  const sendEvent = useEventTracking();
  const path = buildZAPEndpoint('companyAppBudgets');
  const params = {
    fields:
      '["appLabel","appName", "companyAppId", "currentFiscalYearSpend", "lastFiscalYearSpend", "currentFiscalYearBudget", "nextFiscalYearBudget", "lastFiscalYearBudget", "twoYearsAgoFiscalYearBudget"]',
    filters: `[["oneOf","status",["active","expired","cancelled"]]]`,
    format: 'csv',
  };
  const fileName = `Zylo_budget_template.csv`;
  return () =>
    getData({
      path,
      params,
    })
      .then(({ text }: { text: string }) => {
        const mappedText = mapTextToBudgetColumns(text, parseInt(fullYear));
        downloadBlobUrl(
          window.URL.createObjectURL(new Blob([mappedText], { type: 'text/csv' })),
          fileName,
        );
        sendEvent({ eventName: 'MANUAL_TEMPLATE_DOWNLOAD', data: { uploadType: 'budget' } });
      })
      .catch(() =>
        showMessage({
          isError: true,
          message: 'Sorry, there was an error downloading the template.',
        }),
      );
}

function mapTextToBudgetColumns(text: string, fullYear: number) {
  const columnMap = {
    id: 'Company App Id',
    appLabel: 'App Label',
    appName: 'App Name',
    currentFiscalYearSpend: 'Fiscal YTD Spend',
    lastFiscalYearSpend: 'Last Fiscal Year Spend',
    currentFiscalYearBudget: `FY ${fullYear} Budget`,
    nextFiscalYearBudget: `FY ${fullYear + 1} Budget`,
    lastFiscalYearBudget: `FY ${fullYear - 1} Budget`,
    twoYearsAgoFiscalYearBudget: `FY ${fullYear - 2} Budget`,
  };

  const textSplitByLine = text.split(/\n/);
  const columns = textSplitByLine[0].split(',');
  const restOfFile = textSplitByLine.slice(1).join('\r\n');

  const modifiedColumns = columns
    .map((cell) => {
      if (Object.keys(columnMap).includes(cell)) {
        return columnMap[cell as keyof typeof columnMap];
      }
      return cell;
    })
    .join(',');
  const modifiedText = modifiedColumns + '\r\n' + restOfFile;
  return modifiedText;
}
