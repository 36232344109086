import PropTypes from 'prop-types';
import { Icon } from '@zylo/orchestra';

export function IconCell({ data, icon, color, size = 1, iconTitle }) {
  return (
    <div className="icon-cell">
      <span>
        {data ? (
          <Icon color={color} icon={icon} size={size} titleOverride={iconTitle} relativeSize />
        ) : (
          ''
        )}
      </span>
    </div>
  );
}

IconCell.propTypes = {
  color: PropTypes.string,
  data: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  iconTitle: PropTypes.string,
  size: PropTypes.number,
};
