import { MouseEvent } from 'react';
import { capitalize } from 'lodash-es';

export type StatusCellProps = {
  data: string;
  handleValueClick?: (data: string) => void;
};
export function StatusCell({ data, handleValueClick }: StatusCellProps) {
  function handleStatusClick(e: MouseEvent) {
    e.stopPropagation();

    if (handleValueClick) {
      handleValueClick(data);
    }
  }

  return data ? (
    <div className="flex-container align-center">
      <div className={`tag status-${data}`} onClick={handleStatusClick}>
        {capitalize(data)}
      </div>
    </div>
  ) : (
    <>{'-'}</>
  );
}
