import PropTypes from 'prop-types';
import { Tooltip } from '@zylo/orchestra';
import { MultiValueCell } from '@components/tables/cells';

export function ZyloInsightCell({ data, truncate, rowData }) {
  let cellValue = '-';
  const { betaFilter } = rowData;

  if (data && data.length) {
    cellValue = data;

    if (Array.isArray(data)) {
      return <MultiValueCell data={data} />;
    }

    if (cellValue.length > truncate) {
      cellValue = <Tooltip text={data}> {data.substring(0, truncate)}...</Tooltip>;
    }
  }
  return betaFilter ? <div className="color-red">{cellValue}</div> : <div>{cellValue}</div>;
}

ZyloInsightCell.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  rowData: PropTypes.shape({
    betaFilter: PropTypes.bool,
  }),
  truncate: PropTypes.number,
};
