import { FunctionComponent, ReactFragment, ReactNode } from 'react';
import { ChartCustomizations, Colors, DualAxisSubChartConfig } from '@zylo/orchestra';
import { IGenericMapObject } from './utility';
import { RowData } from './types';

/* I am making this true in Orchestra as well, but adding here to make it avaiable immediately */
export interface ExpandedChartCustomizations extends ChartCustomizations {
  orientation?: 'horizontal' | 'vertical';
}
export type DomainLimits = [number, number][];

/* API Types */
export type ChartDataRequestParams = {
  data: string; // This needs to be a stringified array of strings due to how the API consumes it.
  format?: string;
  groupBy: string;
  sort?: string;
  filters?: string;
  search?: string;
  offset?: number;
  limit?: number;
  usePoStatsData?: boolean;
};

export interface IVisualizationAPIDataPoint {
  [key: string]: any;
  value: number;
  excluded?: boolean;
}

export type IVisualizationAPIAdditionalDataPoint = {
  label: string;
  value: any;
  metadata: IGenericMapObject<any>;
};

export interface IVisualizationAPIAdditionalData
  extends IGenericMapObject<IVisualizationAPIAdditionalDataPoint[]> {}

export interface IVisualizationAPIData extends IGenericMapObject<IVisualizationAPIDataPoint[]> {}

export interface IVisualizationAPIResponse {
  groupedBy: {
    type?: string; // TODO: might need/be able to scope this to a specific set of strings, or a generic.
    key?: string;
  };
  summary: { count: number; numberOfLoadedItems?: number };
  data: IVisualizationAPIData;
  additionalData: IVisualizationAPIAdditionalData;
}

export interface ITrendingVisualizationAPIDataPoint {
  index: number;
  value: number | string; // empty strings are possible
}

export interface ITrendingVisualizationAPIDataSet
  extends IGenericMapObject<ITrendingVisualizationAPIDataPoint[]> {}

export interface ITrendingVisualizationAPIData
  extends IGenericMapObject<ITrendingVisualizationAPIDataSet> {}

export interface ITrendingVisualizationAPIResponse {
  additionalData?: Record<string, RowData>;
  data: ITrendingVisualizationAPIData;
  groupedBy: {
    dataSets: number[];
    end?: { index: number; year: number };
    start: { index: number; year: number };
    type: string;
    key: string;
  };
}

export interface IDynamicVisualizationAPIDataPoint {
  label: string;
  value: number | string; // empty strings are possible
  metadata: {
    insightFilterId?: string;
    isDeleted: boolean;
    uuid: string;
  };
}

export type IDynamicVisualizationAPIDataSet = IDynamicVisualizationAPIDataPoint[];

export interface IDynamicVisualizationAPIData
  extends IGenericMapObject<IDynamicVisualizationAPIDataSet> {}

export interface IDynamicVisualizationAPIResponse {
  data: IDynamicVisualizationAPIData;
  groupedBy: {
    denominator: string;
    key: string;
    type: string;
  };
}

/* Data layer types */
export type DropdownOption = {
  label: string | React.ReactFragment;
  value: string;
};

/* Chart Config types */
export enum VisualizationTypes {
  Custom = 'custom',
  SingleChart = 'singleChart',
  DualAxisChart = 'dualAxisChart',
}

export enum ChartTypes {
  BarChart = 'barChart',
  LineChart = 'lineChart',
  UpsetPlot = 'upsetPlot',
}

export interface IVisualizationSortOption {
  sortLabel: string | ReactFragment;
  sortValue: string;
}

/**
 * Type for the smallest unit of a chart: a single slice of a pie chart, or
 * a single half of a 2 part stacked bar in a bar chart
 */
interface IChartAtomicUnit {
  dataKey: string;
  color: keyof typeof Colors | string;
  label: string;
}
export interface IChartDataGroup {
  groupLabel: string;
  members: IChartAtomicUnit[];
  isCurrency?: boolean;
  isPercent?: boolean;
}

/* Type notes on how to set up different types of charts:
 * To have only a single, unstacked bar on each index point, only one item can be passed in each `dataGroups` array
 * To have multiple stacks in a single bar on each index point, you will need mutiple `members` under a single `dataGroup`
 * To have multiple, unstacked bars per index point, you will need multiple `dataGroups` that each contain a single `member`
 * To have groups with some items stacked and some not, you will need multiple `dataGroups` with 1 or more members each depending on if that group is a stacked bar
 */
type DualAxisBarChartComponentProps = ChartCustomizations & {
  leftChartConfig: Omit<DualAxisSubChartConfig, 'data'>;
  rightChartConfig: Omit<DualAxisSubChartConfig, 'data'>;
};

export interface IChartConfig {
  barClickHandler?: (queryParams: string) => void;
  chartType: ChartTypes;
  colors?: string[];
  componentProps?: Record<any, any> | DualAxisBarChartComponentProps;
  dataGroups: IChartDataGroup[];
  dataKey?: string;
  emptyLineTooltip?: ReactNode;
  emptyStateMaxDomain?: number;
  formatterProps?: { includeYearInLabel?: boolean };
  labelClickHandler?: ({
    additionalData,
    clickedLabelIndex,
  }: {
    additionalData: any;
    clickedLabelIndex: number;
  }) => void;
  legendLabelFormatter?: ({
    id,
    additionalData,
  }: {
    id: string;
    additionalData?: RowData;
  }) => string;
  legendValueTooltip?: FunctionComponent<any>;
}
export interface IBaseVisualizationConfig {
  apiPageSize?: number;
  chartCustomizations?: ExpandedChartCustomizations;
  chartConfig?: IChartConfig;
  chartConfigs?: IChartConfig[];
  customRequestParams?: Record<string, any>;
  dataFilter?: (response: IVisualizationAPIResponse) => IVisualizationAPIResponse;
  disableDataDownload?: boolean;
  disableEmptyState?: boolean;
  disableImageDownload?: boolean;
  endpoint?: string;
  groupBy?: string;
  imageDownloadDimensions?: {
    width?: number | ((data: any) => number);
    height?: number | ((data: any) => number);
  };
  initialSort?: string;
  key: string;
  pageSize?: number;
  sortOptions?: IVisualizationSortOption[];
  title: string;
  visualizationType: VisualizationTypes;
}

export interface ISingleAxisChartConfig extends IBaseVisualizationConfig {
  chartConfig: IChartConfig;
  chartConfigs?: never;
  disableFilters?: boolean;
  disableGroupBy?: boolean;
  disableSort?: boolean;
  visualizationType: VisualizationTypes.SingleChart;
}

export interface IDualAxisChartConfig extends IBaseVisualizationConfig {
  chartConfig?: never;
  chartConfigs: IChartConfig[];
  disableFilters?: boolean;
  disableGroupBy?: boolean;
  disableSort?: boolean;
  visualizationType: VisualizationTypes.DualAxisChart;
}

export interface ICustomChartConfig extends IBaseVisualizationConfig {
  chartConfig?: never;
  chartConfigs?: never;
  disableFilters?: boolean;
  disableGroupBy?: boolean;
  disableSort?: boolean;
  endpoint?: never;
  groupBy?: never;
  initialSort?: never;
  sortOptions?: never;
  visualizationType: VisualizationTypes.Custom;
}

export type VisualizationConfigItem =
  | ISingleAxisChartConfig
  | IDualAxisChartConfig
  | ICustomChartConfig;
