import { camelCase, upperFirst } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Store, Flags } from '@typings';
/**
 * Provides named boolean values for feauture flags. Feauture flags are requested by name and an object containing the requested
 * flags are returned. The property returned will be named by prepending 'is' and appending 'Enabled' to the requested flag in
 * camel case format removing hyphens and underscores. Example: 'flag' will become 'isFlagEnabled', 'hyphenated-flag' will become
 * 'isHyphenatedFlagEnabled' and 'underscore_flag' will become isUnderscoreFlagEnabled.
 */

export function useFeatureFlags(...requestedFeatureFlags: string[]): Flags {
  const flags = useSelector(({ featureFlags }: Store) => featureFlags.flags) ?? {};
  return requestedFeatureFlags.reduce((featureFlagObject, flag) => {
    return {
      ...featureFlagObject,
      [`is${upperFirst(camelCase(flag))}Enabled`]: flags[flag] ?? false,
    };
  }, {});
}
