import { createAction, handleActions } from 'redux-actions';
import { get } from 'lodash-es';
import { wrapAsync } from '../utilities/util';
import { OFFSET } from '../config/config';
import WaterWorksAPI from '../WaterWorksAPI';
import { calculateHasMore } from '../utilities/moduleUtils';
import { actions as zyloFormActions } from './zyloForm';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants
// ------------------------------------
const DEFAULT_SORT_COLUMN = 'filterName';
const DEFAULT_SORT = [`+${DEFAULT_SORT_COLUMN}`];
const DEFAULT_FILTER_STATE = {
  list: [],
  listSort: DEFAULT_SORT,
  hasMore: true,
  offset: 0,
  error: null,
  isLoading: false,
  isRunningFilter: false,
  filtersRunning: [],
  reportForDownload: null,
  unmodified: [],
};

export const FETCH_FILTERS_START = 'FETCH_FILTERS_START';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_FAIL = 'FETCH_FILTERS_FAIL';
export const SORT_FILTERS = 'SORT_FILTERS';

export const RUN_FILTER_SUCCEEDED = 'RUN_FILTER_SUCCEEDED';
export const UPDATE_RUNNING_FILTERS = 'UPDATE_RUNNING_FILTERS';
export const STORE_REPORT_FOR_DOWNLOAD = 'STORE_REPORT_FOR_DOWNLOAD';

export const RESET_FILTERS = 'RESET_FILTERS';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFiltersStart = createAction(FETCH_FILTERS_START);
export const fetchFiltersSuccess = createAction(FETCH_FILTERS_SUCCESS);
export const fetchFiltersFail = createAction(FETCH_FILTERS_FAIL);
export const sortFilters = createAction(SORT_FILTERS);

export const runFilterSucceeded = createAction(RUN_FILTER_SUCCEEDED);
export const updateRunningFilters = createAction(UPDATE_RUNNING_FILTERS);
export const storeReportForDownload = createAction(STORE_REPORT_FOR_DOWNLOAD);

export const resetFiltersState = createAction(RESET_FILTERS);

async function fetchFilters({ offsetOverride }, dispatch, getState) {
  const { auth, filters } = getState();
  const { token, companyId } = auth;
  const { isLoading, offset, listSort } = filters;

  if (!isLoading) {
    dispatch(fetchFiltersStart(true));

    let result;
    try {
      result = await WaterWorksAPI.fetchCompanyData(token, companyId, ['advancedFilters'], {
        offset: Number.isInteger(offsetOverride) ? offsetOverride : offset,
        sort: listSort,
      });
    } catch (e) {
      dispatch(fetchFiltersFail(get(e, 'response.body.message', 'An unexpected error occurred')));

      return;
    }
    dispatch(fetchFiltersSuccess({ offsetOverride, result }));
  }
}

function sortFilterList({ sortBy }, dispatch, getState) {
  dispatch(sortFilters(sortBy));
  fetchFilters({}, dispatch, getState);
}

async function resetFilters() {
  return (dispatch) => {
    dispatch(resetFiltersState());
  };
}

async function monitorRunningFilters(_, dispatch, getState) {
  await fetchFilters({ offsetOverride: 0 }, dispatch, getState);
  const { filters } = getState();
  const { list } = filters;
  const prevRunning = filters.filtersRunning;
  const prevRunningIds = prevRunning.map((filter) => {
    return filter.id;
  });
  const filtersRunning = [];

  // update currently running
  for (let i = 0; i < list.length; i++) {
    const filter = list[i];
    if (filter.reportStatus === 'P') {
      filtersRunning.push(list[i]);
    } else if (prevRunningIds.includes(filter.id)) {
      if (filter.reportStatus === 'A') {
        dispatch(saveZyloFormSuccess(`"${filter.filterName}" report now available for download`));
      } else if (filter.reportStatus === 'E') {
        dispatch(saveZyloFormError(`"${filter.filterName}" report erred`));
      }
    }
  }
  dispatch(updateRunningFilters(filtersRunning));

  if (filtersRunning.length > 0) {
    setTimeout(() => {
      monitorRunningFilters({ running: filtersRunning }, dispatch, getState);
    }, 10000);
  }
}

async function runFilter({ filterId, filterName, index }, dispatch, getState) {
  const { auth, filters } = getState();
  const { token, companyId } = auth;
  const { filtersRunning } = filters;
  try {
    await WaterWorksAPI.updateCompanyData(token, companyId, [
      'advancedFilters',
      filterId,
      'report',
    ]);
  } catch (e) {
    dispatch(saveZyloFormError(get(e, 'response.body.message', 'An unexpected error occurred')));

    return;
  }
  const message = `"${filterName}" queued, check back soon for your results`;
  dispatch(saveZyloFormSuccess(message));
  if (filtersRunning.length === 0) {
    setTimeout(() => {
      monitorRunningFilters({}, dispatch, getState);
    }, 5000);
  }
  dispatch(runFilterSucceeded({ filterName, filterId, index }));
}

async function fetchFilterForDownload({ filterId }, dispatch, getState) {
  const { auth } = getState();
  const { token, companyId } = auth;
  let result;
  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, [
      'advancedFilters',
      filterId,
      'report/url',
    ]);
  } catch (e) {
    dispatch(saveZyloFormError(get(e, 'response.body.message', 'An unexpected error occurred')));

    return;
  }
  dispatch(storeReportForDownload(result));
}

export const actions = {
  resetFilters: wrapAsync(resetFilters),
  runFilter: wrapAsync(runFilter),
  fetchFilterForDownload: wrapAsync(fetchFilterForDownload),
  fetchFilters: wrapAsync(fetchFilters),
  sortFilters: wrapAsync(sortFilterList),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [FETCH_FILTERS_START]: (state, { payload }) => {
      return Object.assign({}, state, { isLoading: payload });
    },
    [FETCH_FILTERS_SUCCESS]: (state, { payload }) => {
      const { result, offsetOverride } = payload;
      const unmodified = state.offset === 0 ? payload : state.unmodified;
      const hasMore = calculateHasMore(result.length);
      let offset = Number.isInteger(offsetOverride) ? offsetOverride : state.offset;
      const list = offset === 0 ? result : state.list.concat(result);
      if (hasMore) {
        offset += OFFSET;
      }

      return Object.assign({}, state, {
        list,
        unmodified,
        offset,
        hasMore,
        error: null,
        isLoading: false,
      });
    },
    [FETCH_FILTERS_FAIL]: (state, { payload }) => {
      return Object.assign({}, state, { error: payload, isLoading: false, hasMore: false });
    },
    [SORT_FILTERS]: (state, { payload }) => {
      const listSort =
        payload.indexOf(DEFAULT_SORT_COLUMN) > -1 ? [payload] : [payload, ...DEFAULT_SORT];
      return Object.assign({}, state, { listSort, offset: 0, unmodified: [] });
    },

    [RUN_FILTER_SUCCEEDED]: (state, { payload }) => {
      const { filterName, filterId, index } = payload;
      const list = [...state.list];
      const filtersRunning = state.filtersRunning;
      filtersRunning.push({ filterName, filterId, reportStatus: 'P' });
      list[index].reportStatus = 'P';
      return Object.assign({}, state, { list, filtersRunning });
    },
    [UPDATE_RUNNING_FILTERS]: (state, { payload }) => {
      return Object.assign({}, state, { filtersRunning: payload });
    },

    [STORE_REPORT_FOR_DOWNLOAD]: (state, { payload }) => {
      return Object.assign({}, state, { reportForDownload: payload.reportUrl });
    },

    [RESET_FILTERS]: () => {
      return DEFAULT_FILTER_STATE;
    },
  },
  DEFAULT_FILTER_STATE,
);
