import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { buildZAPEndpoint } from '@frameworks/zap/helpers';
import { ZAPEndpointsOverrideType } from '../ZAPContext';

/**
 * Returns a function that invalidates queries associated with ZAP framework so the data can be refetched.
 * NOTE: If using custom ZAP endpoints make sure to pass custom endpoint to invalidation method
 **/

export function useInvalidateZapQueries() {
  const queryClient = useQueryClient();

  return useCallback(
    async (ZAPName: string, zapEndpointsOverride?: ZAPEndpointsOverrideType) => {
      // metadata query doesn`t need to be invalidated since it is essentially static
      if (zapEndpointsOverride?.metadata) {
        await queryClient.invalidateQueries(zapEndpointsOverride?.metadata);
      }

      await queryClient.invalidateQueries(
        zapEndpointsOverride?.completions || buildZAPEndpoint(ZAPName, 'completions'),
      );
      await queryClient.invalidateQueries(
        zapEndpointsOverride?.stats || buildZAPEndpoint(ZAPName, 'stats'),
      );
      await queryClient.invalidateQueries(
        zapEndpointsOverride?.tableData || buildZAPEndpoint(ZAPName),
      );
    },
    [queryClient],
  );
}
