import { useState } from 'react';
import { ZyloButton } from '@zylo/orchestra';
import PropTypes from 'prop-types';
import SavedFiltersTables from '@components/savedFilters/modals/SavedFiltersTables';
import { useEventTracking } from '@hooks';

SavedFiltersButton.propTypes = {
  savedFiltersApplyEventName: PropTypes.string,
  savedFiltersClickEventName: PropTypes.string,
};

export default function SavedFiltersButton({
  savedFiltersApplyEventName,
  savedFiltersClickEventName,
}) {
  return (
    <SavedFiltersButton_DisplayLayer
      savedFiltersApplyEventName={savedFiltersApplyEventName}
      {...useDataLayer(savedFiltersClickEventName)}
    />
  );
}

export function SavedFiltersButton_DisplayLayer({
  modalActive,
  handleButtonClick,
  savedFiltersApplyEventName,
  setModalActive,
}) {
  return (
    <>
      <ZyloButton
        buttonType="button"
        collapseMediaQueryWidth={1100}
        handleClick={handleButtonClick}
        icon="IconAdjustments"
        variant="outlined"
      >
        Saved Filters
      </ZyloButton>
      {modalActive && (
        <SavedFiltersTables
          handleClose={() => setModalActive(false)}
          savedFiltersApplyEventName={savedFiltersApplyEventName}
        />
      )}
    </>
  );
}

SavedFiltersButton_DisplayLayer.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
};

function useDataLayer(savedFiltersClickEventName) {
  const [modalActive, setModalActive] = useState(false);
  const sendEvent = useEventTracking();
  const handleButtonClick = () => {
    setModalActive(true);
    if (savedFiltersClickEventName) {
      sendEvent({
        eventName: savedFiltersClickEventName,
      });
    }
  };

  return { handleButtonClick, modalActive, setModalActive };
}
