import { useQuery } from 'react-query';
import { CompanyApp } from '@typings';
import { getData } from '@utilities/xhr';

export type CompanyAppsResponse = {
  body: { companyApps: CompanyApp[] };
};

export function useGetCompanyApps(searchValue: string | undefined, limit: number) {
  return useQuery(['companyApp', searchValue], async () => {
    const response: CompanyAppsResponse = await getData({
      path: `companies/$companyId/search/apps/`,
      params: {
        search: searchValue,
        limit: limit,
        filters: '[["bool","is_supplier","No"]]',
      },
    });

    return response.body.companyApps;
  });
}
