import { Tooltip } from '@zylo/orchestra';
import { convertRoles, rolesLabelMapping } from '@utilities/roleUtil';

export type RolesCellProps = {
  data: string[] | undefined;
  isAdvancedPermissionsCustomer?: boolean;
  truncate?: number;
};

export function RolesCell({ data, isAdvancedPermissionsCustomer, truncate }: RolesCellProps) {
  if (data) {
    const filteredData = data.filter((value) => {
      return value !== 'app-catalog';
    });

    const roles = (isAdvancedPermissionsCustomer ? filteredData : convertRoles(filteredData))
      .map((role) => {
        return rolesLabelMapping[role as keyof typeof rolesLabelMapping] ?? role;
      })
      .join(', ');

    if (truncate && roles.length > truncate) {
      return (
        <div className="flex-container roles-cell">
          <Tooltip text={roles}> {roles.substring(0, truncate)}...</Tooltip>
        </div>
      );
    } else {
      return <div className="flex-container roles-cell">{roles}</div>;
    }
  }

  return <></>;
}
