import { useQuery } from 'react-query';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';
import { getData } from '@utilities/xhr';

type DefaultStatData = Record<string, string | number | undefined>;
type DefaultResponse = { body: { stats: DefaultStatData } };

export type GetTableStatisticsArgs<Response, StatisticData> = {
  enabled?: boolean;
  handleResponse?: (data: Response) => StatisticData;
  path: string;
};

export function useGetTableStatistics<Response = unknown, StatisticData = DefaultStatData>({
  enabled = true,
  handleResponse,
  path,
}: GetTableStatisticsArgs<Response, StatisticData>) {
  const { apiRequestFilters = '[]' } = useFiltersState();
  const searchValue = useSearchState() ?? '';

  return useQuery(
    [path, apiRequestFilters, searchValue],
    async () => {
      const response: Response = await getData({
        path,
        params: {
          filters: apiRequestFilters,
          search: searchValue,
        },
      });

      return handleResponse ? handleResponse(response) : (response as DefaultResponse).body.stats;
    },
    {
      enabled,
      keepPreviousData: true,
    },
  );
}
