import { FunctionComponent, useEffect, useRef, MutableRefObject } from 'react';
import styled from 'styled-components/macro';
import classNames from 'clsx';
import {
  Icons,
  TableHorizontalAlignment,
  ZyloTable,
  ZyloTableColumnProps,
  ZyloTableRef,
  ZyloTableSortConfig,
  ZyloTableState,
  ZyloTableSelectionState,
} from '@zylo/orchestra';
import { Cell } from 'react-table';
import ZAPTableHeader from '@frameworks/zap/table/ZAPTableHeader';
import { ZAPSharedProps } from '@frameworks/zap/ZAP';
import { useZAPState } from '@frameworks/zap/ZAPContext';
import { useSortState, useSortUpdate } from '@contexts/sortContext';
import { useColumnsState } from '@contexts/columnsContext';
import { MetadataResponse, useFeatureFlags, useGetTableData } from '@hooks';
import type { PartialMetadataColumn } from '@hooks';
import { DefaultCell } from '@components/tables/cells';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';

const ZAPTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  z-index: 1;
`;

export type ZAPTableProps<T extends object> = {
  defaultSelectionState?: ZyloTableSelectionState<T>;
  disableBulkEdit?: boolean;
  disableSelectAll?: boolean;
  formatTableHeader?: (value: string) => string;
  minTableHeight?: number;
  onTableRowClick?: (data: T) => void;
  selectable?: boolean;
  sortable?: boolean;
  includeCompare?: boolean;
  tableRef?: MutableRefObject<ZyloTableRef<T> | undefined>;
};

export function ZAPTable<T extends object>({
  defaultSelectionState,
  disableBulkEdit = false,
  disableSelectAll = false,
  formatTableHeader,
  includeCompare = true,
  minTableHeight,
  onTableRowClick,
  selectable = false,
  sortable = true,
  tableRef,
}: ZAPTableProps<T>) {
  const {
    columns,
    compareEnabled,
    handleSortTable,
    hasMore,
    hasNoData,
    isLoading,
    loadMore,
    noDataText,
    rowSelectionKey,
    searchValue,
    selectedFilters,
    sortConfig,
    tableData,
    ZAPMetadata,
    ZAPName,
  } = useDataLayer<T>(formatTableHeader);
  const initialTableState = useRef<{
    searchValue?: string;
    selectedFilters: { [k: string]: any };
  }>({
    searchValue,
    selectedFilters,
  });

  useEffect(() => {
    if (
      tableRef &&
      ((initialTableState.current?.searchValue &&
        initialTableState.current?.searchValue !== searchValue) ||
        (initialTableState.current?.selectedFilters &&
          initialTableState.current?.selectedFilters !== selectedFilters))
    ) {
      tableRef.current?.resetTableSelection();
    }
  }, [searchValue, selectedFilters, tableRef]);

  return (
    <ZAPTableContainer className={classNames(hasNoData && 'has-no-data')} data-testid="zap-table">
      <ZyloTable
        columns={columns}
        data={tableData ?? []}
        defaultSelectionState={defaultSelectionState}
        disableSelectAll={disableSelectAll}
        hasMore={hasMore}
        header={
          <ZAPTableHeader disableBulkEdit={disableBulkEdit} includeCompare={includeCompare} />
        }
        loadMore={loadMore}
        loading={isLoading}
        minHeight={minTableHeight}
        noDataText={noDataText}
        onRowClick={onTableRowClick}
        onSortChange={handleSortTable}
        selectBy={rowSelectionKey}
        selectNamePrefix={ZAPName}
        selectable={ZAPMetadata?.settings?.bulkEditingEnabled || compareEnabled || selectable}
        sortConfig={sortConfig}
        sortable={sortable}
        tableRef={tableRef}
        stickyHeader
      />
    </ZAPTableContainer>
  );
}

type UseDataLayerValues<T extends object> = {
  columns: ZyloTableColumnProps<T>[];
  compareEnabled: boolean;
  handleSortTable(tableState: ZyloTableState<T>): void;
  hasMore: boolean | undefined;
  hasNoData: boolean;
  isLoading: boolean;
  loadMore(): void;
  noDataText?: string | JSX.Element;
  rowSelectionKey: ZAPSharedProps<T>['rowSelectionKey'];
  searchValue?: string;
  selectedFilters: { [k: string]: any };
  sortConfig: ZyloTableSortConfig<T> | undefined;
  tableData: T[] | undefined;
  ZAPMetadata: MetadataResponse['body'] | undefined;
  ZAPName: ZAPSharedProps<T>['ZAPName'] | undefined;
};
type GetTableDataResponse<T extends object> = {
  body: T[];
};

function useDataLayer<T extends object>(
  formatTableHeader?: (value: string) => string,
): UseDataLayerValues<T> {
  const { endpoints, tableProps, ZAPMetadata, ZAPName } = useZAPState();
  const searchValue = useSearchState();
  const { selectedFilters } = useFiltersState();
  const { currentSort } = useSortState();
  const { handleSort } = useSortUpdate();
  const { isCompareAppsEnabled } = useFeatureFlags('compare-apps');
  const { noDataText, rowSelectionKey, rowActionsComponent } = tableProps;
  const compareEnabled = isCompareAppsEnabled && ZAPName === 'companyApps';

  const { data, fetchNextPage, hasNextPage, isFetching } = useGetTableData({
    handleResponse: (data: GetTableDataResponse<T>) => data.body,
    path: endpoints.tableData!,
  });
  const tableData = data?.pages.flat();

  const {
    columns: { activeColumns },
    frozenColumns,
  } = useColumnsState();

  const colMap: ZyloTableColumnProps<T>[] = activeColumns.map(
    (c: PartialMetadataColumn): Partial<ZyloTableColumnProps<T>> => {
      return {
        accessor: c.columnName as keyof T,
        Header: formatTableHeader ? formatTableHeader(c.displayName as string) : c.displayName,
        horizontalAlignment:
          c.columnAlignment ??
          (c.isNumeric ? TableHorizontalAlignment.right : TableHorizontalAlignment.left),
        ignoreRowClick: c.ignoreRowClick,
        icon: c.headerIcon as Icons,
        sortDescFirst: c.isNumeric,
        sticky: Boolean(frozenColumns.find((fc: string) => fc === c.columnName)),
        disableSortBy: c.disableColumnSort,
        ...(c.customComponent
          ? {
              Cell: (data: any) => {
                const CellComponent = c.customComponent as FunctionComponent<any>;
                return c.customComponent ? (
                  <CellComponent
                    data={data.value}
                    rowData={data.row.original}
                    truncate={c.truncate}
                    {...c.customComponentProps}
                  />
                ) : (
                  <DefaultCell data={data.value} />
                );
              },
            }
          : null),
        ...(c.tooltipText
          ? {
              tooltip: {
                allowBodyHover: c.allowTooltipBodyHover,
                text: c.tooltipText,
              },
            }
          : null),
        columnWidth: c.width,
      };
    },
  );

  if (rowActionsComponent) {
    colMap.push({
      accessor: 'customRowActions' as keyof T,
      Header: '',
      ignoreRowClick: true,
      disableSortBy: true,
      horizontalAlignment: TableHorizontalAlignment.right,
      Cell: ({ row }: Cell<T>) => {
        return rowActionsComponent(row.original);
      },
    });
  }

  const currentSortString = typeof currentSort === 'string' ? currentSort : '';
  function handleSortTable<T extends object>({ sortConfig }: ZyloTableState<T>) {
    if (!sortConfig) return;
    handleSort(`${sortConfig.descending ? '-' : '+'}${sortConfig.column.toString()}`);
  }

  const sortConfig: ZyloTableSortConfig<T> | undefined = currentSortString
    ? {
        column: currentSortString.substring(1) as keyof T,
        descending: currentSortString.substring(0, 1) === '-',
      }
    : undefined;

  return {
    columns: colMap,
    compareEnabled,
    handleSortTable,
    hasMore: hasNextPage,
    hasNoData: !isFetching && !tableData?.length,
    isLoading: isFetching,
    loadMore: fetchNextPage,
    noDataText,
    rowSelectionKey: rowSelectionKey as keyof T,
    selectedFilters,
    searchValue: searchValue ?? null,
    sortConfig: sortConfig,
    tableData,
    ZAPMetadata,
    ZAPName,
  };
}
