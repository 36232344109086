import { Colors } from '@zylo/orchestra';

/**
 * Move an array entry to a different index
 * @param list - a list of values, of which only strings will be merged
 * @param fromPosition - array index of item to move
 * @param toPosition - array index of final destination of item (shifting)
 * @example
 * moveWithinList(['foo', 'bar', 'zot'], 0, 2);
 * // ['bar', 'zot', 'foo']
 */
function moveWithinList<T>(list: T[], fromPosition: number, toPosition: number) {
  const result = Array.from(list);
  const [removed] = result.splice(fromPosition, 1); // remove
  result.splice(toPosition, 0, removed); // add back in

  return result;
}

/**
 * Remove an array entry from one array and insert it into another array at a specified index
 * @param sourceList - an array of any type, the origin array of the item
 * @param sourceIndex - the index in sourceList of the item to remove
 * @param destinationList - an array of any type, the destination array for the item
 * @param destinationIndex - the index in sourceList of the item to remove
 * @example
 * moveToNewList(['foo', 'bar'], 0, ['zot', 'qux'], 1);
 * // { newSource: ['bar'], newDestination: ['zot', 'foo', 'qux'] }
 */
function moveToNewList(
  sourceList: any[],
  sourceIndex: number,
  destinationList: any[],
  destinationIndex: number,
) {
  const newSource = Array.from(sourceList);
  const newDestination = Array.from(destinationList);
  newSource.splice(sourceIndex, 1);
  newDestination.splice(destinationIndex, 0, sourceList[sourceIndex]);
  return { newSource, newDestination };
}

function getDroppableStyle(isDraggingOver: boolean) {
  return { background: isDraggingOver ? Colors.lightblue : 'transparent' };
}

function getDraggableStyle(isDragging: boolean, draggableStyle?: object): React.CSSProperties {
  return {
    userSelect: 'none',
    background: isDragging ? Colors.grey1 : 'white',
    ...draggableStyle,
  };
}

export { moveWithinList, moveToNewList, getDroppableStyle, getDraggableStyle };
