import { useQuery, UseQueryResult } from 'react-query';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';
import { getData } from '@utilities/xhr';

type GetTableTotalCountData = {
  count: number;
};
export type GetTableTotalCountResponse = {
  body: GetTableTotalCountData;
};

export type GetTableTotalCountArgs = {
  enabled?: boolean;
  path: string;
};

export function useGetTableTotalCount({
  enabled = true,
  path,
}: GetTableTotalCountArgs): UseQueryResult<GetTableTotalCountData> {
  const { apiRequestFilters = '[]' } = useFiltersState();
  const searchValue = useSearchState() ?? '';

  return useQuery<GetTableTotalCountData>(
    [path, apiRequestFilters, searchValue],
    async () => {
      const { body }: GetTableTotalCountResponse = await getData({
        path: `${path}/count`,
        params: {
          filters: apiRequestFilters,
          search: searchValue,
        },
      });

      return body;
    },
    {
      enabled,
      keepPreviousData: false,
    },
  );
}
