import { Link, useLocation } from '@reach/router';
import styled from 'styled-components/macro';
import classNames from 'clsx';
import { PropTypes } from 'prop-types';
import { Icon } from '@zylo/orchestra';

const UpsellIcon = styled.div`
  margin-left: 5px;
  margin-top: -1px;
`;

function TabRoutesLabel({ isHomeTab, label, routeBase, to, onTabClick, isUpsellEnabled }) {
  const location = useLocation();

  function setClassName({ isPartiallyCurrent }) {
    const [currentTabRoute] = location.pathname.split('/').slice(-1);
    const isActiveTab =
      isPartiallyCurrent || (isHomeTab && [routeBase, ''].includes(currentTabRoute));

    return {
      className: classNames(
        'flex-container justify-center align-center tab-label',
        isActiveTab && 'active',
      ),
    };
  }

  return (
    <Link
      getProps={setClassName}
      onClick={() => {
        if (onTabClick) onTabClick(to);
      }}
      to={to}
    >
      {label}
      {isUpsellEnabled && (
        <UpsellIcon>
          <Icon className="tab-icon" color="gray" icon="IconLock" />
        </UpsellIcon>
      )}
    </Link>
  );
}

TabRoutesLabel.propTypes = {
  isHomeTab: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  routeBase: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default TabRoutesLabel;
