import { useLocation } from '@reach/router';
import { usePortalContext } from '@zylo/orchestra';
import { useGetAppDetailsTab, useSidebarVisibilitySensor } from '@hooks';
import { ZAP_COMPARE_DRAWER } from '@frameworks/zap/compare/ZAPCompareButton';

const visualizationDownloadEventObjects: any = {
  '/subscriptions': {
    visualizationPage: 'SUBSCRIPTIONS',
  },
  '/subscriptions/compare': {
    visualizationPage: 'COMPARE',
  },
  quickViewCompare: {
    visualizationPage: 'COMPARE_QUICKVIEW',
  },
  quickViewOverview: {
    visualizationPage: 'OVERVIEW_QUICKVIEW',
  },
  quickViewPayments: {
    visualizationPage: 'PAYMENTS_QUICKVIEW',
  },
  fullView: {
    visualizationPage: 'OVERVIEW_FULLVIEW',
  },
  '/employees': {
    visualizationPage: 'TEAM',
  },
  '/payments': {
    visualizationPage: 'PAYMENTS',
  },
  '/spend/purchaseOrders': {
    visualizationPage: 'PURCHASE_ORDERS',
  },
  '/spend/budget': {
    visualizationPage: 'BUDGET',
  },
  '/spend': {
    visualizationPage: 'SPEND',
  },
  '/': {
    visualizationPage: 'DASHBOARD',
  },
};

export function useVisualizationDownloadEventParams() {
  const { pathname } = useLocation();
  const { existsInPortal } = usePortalContext();
  const { currentTab } = useGetAppDetailsTab();
  const isCompareSidebarOpen = useSidebarVisibilitySensor({
    sidebarName: ZAP_COMPARE_DRAWER,
  });

  if (pathname === '/subscriptions' && !existsInPortal) {
    return visualizationDownloadEventObjects['/subscriptions'];
  } else if (pathname === '/subscriptions' && existsInPortal && !isCompareSidebarOpen) {
    return currentTab === 'PAYMENTS_QUICKVIEW_DOWNLOAD_CHARTIMG'
      ? visualizationDownloadEventObjects.quickViewPayments
      : visualizationDownloadEventObjects.quickViewOverview;
  } else if (pathname === '/subscriptions' && existsInPortal && isCompareSidebarOpen) {
    return visualizationDownloadEventObjects.quickViewCompare;
  } else if (pathname === '/subscriptions/compare') {
    return visualizationDownloadEventObjects['/subscriptions/compare'];
  } else if (
    pathname.startsWith('/subscriptions') &&
    pathname.split('/').length > 1 &&
    !pathname.includes('/payments')
  ) {
    return visualizationDownloadEventObjects.fullView;
  } else if (
    pathname.startsWith('/subscriptions') &&
    pathname.split('/').length > 1 &&
    pathname.includes('/payments') &&
    !pathname.includes('purchaseOrder')
  ) {
    return visualizationDownloadEventObjects['/payments'];
  } else {
    return visualizationDownloadEventObjects[pathname as string] ?? '';
  }
}
