import { FormFieldWrapper, Label } from '@zylo/orchestra';
import { useController } from 'react-hook-form';
import { FieldConfig, FieldHOCWrapperInputProps } from '@frameworks/form-fns/types';
import { FieldBulkEditSelectWrapper, RedText, StyledMiniSelect } from './FieldBulkBlankWrapper';
import { BulkEditOperationOption } from './types';

function BulkEditActionSelectField(props: FieldHOCWrapperInputProps) {
  const { methods, editing, fieldConfig, children, wrapperConfig } = props;
  const { wrapperName, defaultValue: wrapperDefault } = wrapperConfig;
  const calculatedDefault = wrapperDefault || { label: 'Add', value: 'append' };

  const {
    field: { onChange, value },
  } = useController({
    name: wrapperName,
    control: methods?.control,
    defaultValue: calculatedDefault,
  });

  const miniSelectOptions: BulkEditOperationOption[] = [
    { label: 'Add', value: 'append' },
    { label: 'Remove', value: 'array_remove' },
    { label: 'Clear All', value: 'remove' },
    { label: 'Replace  All', value: 'replace' },
  ];

  if (!children) {
    return <>{children}</>;
  }

  const fieldOptions = fieldConfig[1];
  const { name, label } = fieldOptions;

  const newLabel = editing ? (
    <FieldBulkEditSelectWrapper>
      {label ? <Label htmlFor={name}>{label}</Label> : <></>}
      <StyledMiniSelect
        name={wrapperName}
        onChange={onChange}
        options={miniSelectOptions}
        value={value}
      />
    </FieldBulkEditSelectWrapper>
  ) : (
    label
  );
  const referenceBreaker: FieldConfig = [...fieldConfig];
  referenceBreaker[1] = {
    ...fieldConfig[1],
    label: '', // we don't need this label to render
  };

  if (value.value === 'remove') {
    return (
      <FormFieldWrapper>
        <>{newLabel}</>
        <RedText>Clear all applicable tags</RedText>
      </FormFieldWrapper>
    );
  } else {
    return (
      <FormFieldWrapper>
        {newLabel}
        {children({ ...props, fieldConfig: referenceBreaker })}
      </FormFieldWrapper>
    );
  }
}

export default function FieldBulkActionTypeWrapper(props: FieldHOCWrapperInputProps) {
  const { editing, children } = props;
  if (!children) {
    return <></>;
  }
  if (editing) {
    return <BulkEditActionSelectField {...props} />;
  }

  return children(props);
}
