import PropTypes from 'prop-types';
import classNames from 'clsx';
import { reduxObject } from '@utilities/propTypes';

export function NumberCell({ callback, className, data, isClickable, rowData }) {
  const number = Array.isArray(data) ? data.length : parseInt(data, 10);
  const isClickEnabled = callback && isClickable && number > 0;
  const value = number || number === 0 ? number.toLocaleString() : '-';

  function handleClick(e) {
    if (isClickEnabled) {
      e.stopPropagation();
      if (callback) {
        callback(rowData);
      }
    }
  }

  return (
    <div className="numerical-cell">
      {isClickEnabled ? (
        <span
          className={classNames('clickable', className)}
          onClick={handleClick}
          onKeyPress={handleClick}
          role="button"
          tabIndex="0"
        >
          {value}
        </span>
      ) : (
        <span className={className}>{value}</span>
      )}
    </div>
  );
}

NumberCell.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  isClickable: PropTypes.bool,
  rowData: reduxObject,
};
