import { get } from 'lodash-es';
import { cloneElement, createElement } from 'react';
import { CellProps, RowData } from '@typings';

/** When using IconClickCell you should use either icons or iconFunction prop and not both.
 *  The icons prop should be an array containing Icons and / or Tooltips. The IconArrayProps type
 * will cause a type error if both icons and iconFunction are used simultaneously. This is only
 * visible in .tsx files currently.
 */

type IconArrayProps<T = RowData> =
  | { iconFunction?: (rowData: T) => JSX.Element[]; icons?: never }
  | { iconFunction?: never; icons: JSX.Element[] };

export type IconClickCellProps = Pick<CellProps, 'rowData'> &
  Partial<CellProps> &
  IconArrayProps & {
    additionalComponent?: React.FunctionComponent<any> | React.ComponentClass<any, any>;
  };

export function IconClickCell({
  rowData,
  icons = [],
  iconFunction = () => [],
  additionalComponent,
  ...props
}: IconClickCellProps) {
  return (
    <div className="flex-container align-center icon-click-cell">
      {[...icons, ...iconFunction(rowData)].map((component, i) => {
        const { props } = component;

        const componentProps = {
          key: `icon-${i}`,
          onClick: undefined,
        };

        let componentChildren = props.children;

        if (props.onClick) {
          componentProps.onClick = get(component, 'props.onClick', () => {}).bind(null, rowData);
        } else {
          componentChildren =
            props.children &&
            cloneElement(props.children, {
              onClick: get(props.children, 'props.onClick', () => {}).bind(null, rowData),
            });
        }

        return component && cloneElement(component, componentProps, componentChildren);
      })}
      {additionalComponent && (
        <div className="flex-2">{createElement(additionalComponent, { ...props, rowData })}</div>
      )}
    </div>
  );
}
