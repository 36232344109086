import { useMutation, useQueryClient } from 'react-query';
import { useAppMessaging } from '@hooks';
import { deleteData } from '@utilities/xhr';
import { useInvalidateZapQueries } from '@frameworks/zap';

export type DeleteContractArgs = {
  contractId: string;
};

export function useDeleteContract() {
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();
  const invalidateZapQueries = useInvalidateZapQueries();

  return useMutation(
    async ({ contractId }: DeleteContractArgs) =>
      await deleteData({
        path: `companies/$companyId/contracts/${contractId}`,
      }),
    {
      onSuccess: ({ body }) => {
        invalidateZapQueries('contracts');
        queryClient.invalidateQueries(['companyApp']);
        showMessage({ message: body.message });
      },
      onError: () =>
        showMessage({
          message: 'There was an error deleting the contract. Please try again.',
          isError: true,
        }),
    },
  );
}
