import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Colors } from '@zylo/orchestra';
import moment from 'moment';

function createCustomPropType(validator) {
  function isRequiredWrapper(isRequired = false) {
    return (props, propName, componentName) => {
      if (props[propName] === undefined || props[propName] === null) {
        if (isRequired) {
          throw new Error(`Required prop \`${propName}\` not supplied to \`${componentName}\`.`);
        }
      } else {
        // prop exists
        validator(props, propName, componentName);
      }
    };
  }

  const propValidator = isRequiredWrapper(false);
  propValidator.isRequired = isRequiredWrapper(true);
  return propValidator;
}

const dynamicTableColumns = PropTypes.arrayOf(
  PropTypes.shape({
    columnName: PropTypes.string,
    customComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    displayName: PropTypes.string,
  }),
);

const validDate = createCustomPropType((props, propName, componentName) => {
  if (!moment(props[propName]).isValid()) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Moment date validation failed.`,
    );
  }
  return null;
});

const reduxFormField = createCustomPropType((props, propName, componentName) => {
  if (props[propName] && props[propName].type !== Field) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected type \`Field\`.`,
    );
  }
  return null;
});

const reduxObject = PropTypes.shape({});
reduxObject.shape = function shape(propsObj = {}) {
  return PropTypes.shape(propsObj);
};

const permissionsShape = PropTypes.shape({
  canAccess: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
});

const permissionsObject = PropTypes.shape({
  admin: permissionsShape,
  apps: permissionsShape,
  charges: permissionsShape,
  integrations: permissionsShape,
  superAdmin: permissionsShape,
  users: permissionsShape,
  workflows: permissionsShape,
});

const tableHeaderProps = {
  allowSelectAll: PropTypes.bool,
  allowTooltipBodyHover: PropTypes.bool,
  className: PropTypes.string,
  columnName: PropTypes.string,
  disableColumnSort: PropTypes.bool,
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleHeaderClick: PropTypes.func,
  headerIcon: PropTypes.node,
  isNumeric: PropTypes.bool,
  keyName: PropTypes.string,
  selectAllData: PropTypes.arrayOf(PropTypes.shape({})),
  sortIcon: PropTypes.string,
  style: PropTypes.shape({}),
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const oneOfColor = PropTypes.oneOf(Object.keys(Colors));

export {
  oneOfColor,
  dynamicTableColumns,
  validDate,
  reduxFormField,
  reduxObject,
  permissionsObject,
  tableHeaderProps,
};
