export type LSErrorHandler = (e: unknown) => void;

/** Given a `key`, retrieves the `value` from `localStorage`. Optionally a `defaultValue` may be passed. */
export function getValue<TLocalStorageValue>(
  key: string,
  defaultValue?: TLocalStorageValue,
  onError?: LSErrorHandler,
) {
  if (key === undefined || key === '') return defaultValue;

  try {
    const serializedValue = localStorage.getItem(key);
    const result: TLocalStorageValue = serializedValue ? JSON.parse(serializedValue) : defaultValue;

    return result;
  } catch (error) {
    handleError(onError, error);
  }
}

/** Given a `key` and `value`, stores the `value` in `localStorage`. */
export function storeValue<T>(key: string, value: T, onError?: LSErrorHandler) {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    handleError(onError, error);
  }
}

/**
 * Given a `key` and `value` updates current value to the provided one in `localStorage`.
 * If the new value is undefined the previous value will be removed.
 */
export function updateValue<T>(key: string, value: T, onError?: LSErrorHandler) {
  try {
    value !== undefined ? storeValue(key, value) : localStorage.removeItem(key);
  } catch (error) {
    handleError(onError, error);
  }
}

function handleError(fn: LSErrorHandler | undefined, e: unknown) {
  if (typeof fn == 'function') {
    fn(e);
  }
}
