import { useMutation, useQueryClient } from 'react-query';
import { useAppMessaging } from '@hooks';
import { deleteData } from '@utilities/xhr';

export type DeleteContractDocumentArgs = {
  documentId: string;
  contractId: string;
};

export function useDeleteContractDocument() {
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ documentId, contractId }: DeleteContractDocumentArgs) =>
      await deleteData({
        path: `companies/$companyId/documents/${documentId}?entityId=${contractId}&entityType=contract`,
      }),
    {
      onSuccess: ({ body }) => {
        queryClient.invalidateQueries(['contractsDocumentData']);
        showMessage({ message: body.message });
      },
      onError: () =>
        showMessage({
          message: 'There was an error deleting the document. Please try again.',
          isError: true,
        }),
    },
  );
}
