import { createAction, handleActions } from 'redux-actions';
import WaterWorksAPI from '@src/WaterWorksAPI';
import { wrapAsync, handleNetworkError } from '@utilities/util';
import { calculateHasMore } from '@utilities/moduleUtils';
import { actions as zyloFormActions } from './zyloForm';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT = `-${DEFAULT_SORT_COLUMN}`;
const DEFAULT_ALERTS_STATE = {
  accountAlerts: [],
  list: [],
  listSort: [DEFAULT_SORT],
  hasMore: true,
  isLoading: false,
  isSaving: false,
  error: null,
  details: null,
};

export const FETCH_ALERTS_START = 'FETCH_ALERTS_START';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAIL = 'FETCH_ALERTS_FAIL';

export const SAVE_ALERT_START = 'SAVE_ALERT_START';
export const EDIT_ALERT_SUCCESS = 'EDIT_ALERT_SUCCESS';
export const EDIT_ALERT_FAIL = 'EDIT_ALERT_FAIL';

export const SET_ALERT_DETAILS = 'SET_ALERT_DETAILS';

export const SORT_ALERTS = 'SORT_ALERTS';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';

export const FETCH_ACCOUNT_ALERTS_START = 'FETCH_ACCOUNT_ALERTS_START';
export const FETCH_ACCOUNT_ALERTS_SUCCESS = 'FETCH_ACCOUNT_ALERTS_SUCCESS';
export const FETCH_ACCOUNT_ALERTS_FAIL = 'FETCH_ACCOUNT_ALERTS_FAIL';

export const SAVE_ACCOUNT_ALERT_START = 'CREATE_ACCOUNT_ALERT_START';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAlertsStart = createAction(FETCH_ALERTS_START);
export const fetchAlertsSuccess = createAction(FETCH_ALERTS_SUCCESS);
export const fetchAlertsFail = createAction(FETCH_ALERTS_FAIL);

export const saveAlertStart = createAction(SAVE_ALERT_START);
export const editAlertSuccess = createAction(EDIT_ALERT_SUCCESS);
export const editAlertFail = createAction(EDIT_ALERT_FAIL);

export const setAlertDetails = createAction(SET_ALERT_DETAILS);

export const sortAlerts = createAction(SORT_ALERTS);

export const clearAlerts = createAction(CLEAR_ALERTS);

export const saveAccountAlertStart = createAction(SAVE_ACCOUNT_ALERT_START);

async function fetchAlerts(
  { companyAppId, zyloUserAccountId, offset, contractId },
  dispatch,
  getState,
) {
  dispatch(fetchAlertsStart());
  const { auth, alerts } = getState();
  const zuaId = !zyloUserAccountId ? auth.zyloUserAccountId : zyloUserAccountId;
  const { token, companyId } = auth;
  const { listSort, hasMore, list } = alerts;
  const calculatedOffset = hasMore ? list.length : 0;
  const offsetValue = Number.isInteger(offset) ? offset : calculatedOffset;
  let result;

  try {
    let endpoint;

    if (contractId) {
      endpoint = ['contracts', contractId, 'alerts'];
    } else {
      endpoint = companyAppId ? ['apps', companyAppId, 'alerts'] : ['users', zuaId, 'alerts'];
    }

    result = await WaterWorksAPI.fetchCompanyData(token, companyId, endpoint, {
      limit: 50,
      sort: listSort,
      hasCompanyApp: true,
      offset: offsetValue,
    });
  } catch (e) {
    handleNetworkError('fetchAlerts', e, dispatch, fetchAlertsFail, {
      companyAppId,
      zuaId,
      offset,
    });

    return;
  }

  dispatch(fetchAlertsSuccess({ data: result, offset: offsetValue }));
}

async function sortAlertsList(
  { companyAppId, sortBy, zyloUserAccountId, contractId },
  dispatch,
  getState,
) {
  dispatch(sortAlerts(sortBy));
  let payload;
  if (contractId) {
    payload = { zyloUserAccountId, contractId };
  } else {
    payload = companyAppId ? { companyAppId } : { zyloUserAccountId };
  }
  fetchAlerts(payload, dispatch, getState);
}

function clearAlertList() {
  return (dispatch) => {
    dispatch(clearAlerts());
  };
}

async function addEditAlert(
  { alertId, companyAppId, contractId, zyloUserAccountId, data },
  dispatch,
  getState,
) {
  const { auth } = getState();
  const { token, companyId } = auth;
  const {
    alertType,
    method,
    recipients,
    chargeType,
    transactionThreshold,
    alertNotificationPeriod,
    memo,
  } = data;

  const alertBody = {
    companyAppId: companyAppId || data.companyAppId,
    contractId,
    alertType,
    method,
    recipients,
    memo,
    settings: ['renewal', 'contract-renewal'].includes(alertType)
      ? { renewal: { notificationPeriod: alertNotificationPeriod } }
      : { transaction: { chargeType, transactionThreshold } },
  };

  dispatch(saveAlertStart());

  try {
    if (alertId) {
      await WaterWorksAPI.updateCompanyData(
        token,
        companyId,
        ['users', zyloUserAccountId, 'alerts', alertId],
        { ...alertBody },
      );
    } else {
      await WaterWorksAPI.postCompanyData(
        token,
        companyId,
        ['users', zyloUserAccountId, 'alerts'],
        {
          ...alertBody,
        },
      );
    }
  } catch (e) {
    handleNetworkError('addEditAlert', e, dispatch, saveZyloFormError, { data });
    dispatch(editAlertFail());

    return;
  }

  fetchAlerts({ offset: 0, companyAppId, zyloUserAccountId, contractId }, dispatch, getState);
  dispatch(editAlertSuccess());
  dispatch(saveZyloFormSuccess(alertId ? 'Alert updated' : `New ${alertType} alert created`));
}

function selectAlertToEdit(alert) {
  return (dispatch) => {
    dispatch(setAlertDetails(alert));
  };
}

async function deleteAlert(
  { alertId, zyloUserAccountId, companyAppId, contractId },
  dispatch,
  getState,
) {
  const { auth } = getState();
  const { token, companyId } = auth;
  let result;

  try {
    result = await WaterWorksAPI.deleteCompanyData(token, companyId, ['alerts', alertId]);
  } catch (e) {
    handleNetworkError('deleteAlert', e, dispatch, saveZyloFormError, {
      alertId,
      companyAppId,
    });

    return;
  }

  dispatch(saveZyloFormSuccess(result.message));

  let payload;
  if (contractId) {
    payload = { zyloUserAccountId, contractId };
  } else {
    payload = companyAppId ? { companyAppId } : { zyloUserAccountId };
  }
  payload.offset = 0;
  fetchAlerts(payload, dispatch, getState);
}

export const actions = {
  fetchAlerts: wrapAsync(fetchAlerts),
  sortAlerts: wrapAsync(sortAlertsList),
  clearAlerts: clearAlertList,
  addEditAlert: wrapAsync(addEditAlert),
  deleteAlert: wrapAsync(deleteAlert),
  selectAlertToEdit,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [FETCH_ALERTS_START]: (state) => {
      return { ...state, isLoading: true };
    },
    [FETCH_ALERTS_SUCCESS]: (state, { payload }) => {
      const { data, offset } = payload;
      const list = offset === 0 ? data : state.list.concat(data);
      const hasMore = calculateHasMore(data.length);

      return {
        ...state,
        list,
        hasMore,
        error: null,
        isLoading: false,
      };
    },
    [FETCH_ALERTS_FAIL]: (state, { payload }) => {
      return { ...state, error: payload, isLoading: false, hasMore: false };
    },
    [SORT_ALERTS]: (state, { payload }) => {
      const listSort =
        payload.indexOf(DEFAULT_SORT_COLUMN) > -1 ? [payload] : [payload, DEFAULT_SORT];

      return { ...state, listSort };
    },
    [SET_ALERT_DETAILS]: (state, { payload }) => {
      return { ...state, details: payload };
    },

    [SAVE_ALERT_START]: (state) => {
      return { ...state, isSaving: true };
    },

    [EDIT_ALERT_SUCCESS]: (state) => {
      return { ...state, details: null, isSaving: false };
    },

    [EDIT_ALERT_FAIL]: (state) => {
      return { ...state, isSaving: false };
    },

    [CLEAR_ALERTS]: (state) => {
      return { ...state, ...DEFAULT_ALERTS_STATE, accountAlerts: state.accountAlerts };
    },
    [SAVE_ACCOUNT_ALERT_START]: (state) => {
      return { ...state, isSavingAccountAlert: true };
    },
  },
  DEFAULT_ALERTS_STATE,
);
