import { useState } from 'react';
import styled from 'styled-components/macro';
import { Icon, Tooltip, Icons, ColorsUnion, Button, ButtonColor } from '@zylo/orchestra';

const animation: string = 'fadeInRight 0.1s cubic-bezier(1, 0.79, 0.83, 0.67)';
const ActionsIconContainer = styled.div`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 25%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }

  .actions-cell {
    &__buttons,
    &__icons {
      align-items: center;
      animation: ${animation};
      display: flex;
      position: relative;
    }
  }
`;

/**
 * Values here should correlate to the icon shown
 */
export enum TableActions {
  edit = 'IconEdit',
  delete = 'IconTrash',
}

export interface ActionsIcon {
  /**
   * Text displayed when prompting for confirmation of action.
   */
  confirmationText: string;
  handleClick(rowData: {}): void;
  /**
   * Optional, defaults to icon set by `type`. If an icon is specified here
   * it will override the icon provided by `type`.
   */
  icon?: Icons;
  /**
   * If true, will prompt the user to confirm the action before
   * executing the function passed to `handleClick`
   */
  requireConfirmation?: boolean;
  tooltip?: string;
  /**
   * What type of action is rendered
   */
  type: TableActions;
}

export type ActionsCellProps = {
  columnIndex: number;
  icons: ActionsIcon[];
  rowData: object;
  setScrollToColumn: (column: number) => void;
  triggerTableResize: (resize: boolean) => void;
};

export function ActionsCell({
  columnIndex = -1,
  icons = [],
  rowData = {},
  setScrollToColumn,
  triggerTableResize,
}: ActionsCellProps) {
  const [_icons, setIcons] = useState(icons);
  const [confirmingIcon, setConfirmingIcon] = useState<ActionsIcon | null>(null);

  function determineColor(i: ActionsIcon): ColorsUnion | ButtonColor {
    switch (i.type) {
      case 'IconTrash':
        return 'red';
      default:
        return 'blue';
    }
  }

  function iconClicked(index: number): void {
    const iconsCopy: ActionsIcon[] = [..._icons];
    const i: ActionsIcon = iconsCopy[index];
    if (i.requireConfirmation) {
      setConfirmingIcon(i);
      setIcons(iconsCopy);
      triggerTableResize(true);
      setScrollToColumn(columnIndex);
    } else {
      i.handleClick(rowData);
    }
  }

  function cancelConfirmation(): void {
    const iconsCopy: ActionsIcon[] = [..._icons];
    setConfirmingIcon(null);
    setIcons(iconsCopy);
  }

  function renderConfirmationButtons(i: ActionsIcon): JSX.Element {
    return (
      <div className="actions-cell__buttons">
        <Button
          buttonColor="gray"
          handleClick={cancelConfirmation}
          text={'Cancel'}
          type="link"
        ></Button>
        <Button
          buttonColor={determineColor(i) as ButtonColor}
          handleClick={() => {
            cancelConfirmation();
            i.handleClick(rowData);
          }}
          text={i.confirmationText}
          type="link"
        ></Button>
      </div>
    );
  }

  function renderIcon(i: ActionsIcon, index: number): JSX.Element {
    return (
      <Icon
        hoverColor={determineColor(i) as ColorsUnion}
        icon={i.icon ?? i.type}
        onClick={() => iconClicked(index)}
        size={1.2}
        relativeSize
      ></Icon>
    );
  }

  function renderTooltip(i: ActionsIcon, index: number): JSX.Element {
    return <Tooltip text={i.tooltip}>{renderIcon(i, index)}</Tooltip>;
  }

  return (
    <ActionsIconContainer className="actions-cell flex-container align-center icon-click-cell">
      {confirmingIcon && renderConfirmationButtons(confirmingIcon)}
      {!confirmingIcon && (
        <div className="actions-cell__icons">
          {_icons.map((i: ActionsIcon, index: number) => {
            return i.tooltip ? renderTooltip(i, index) : renderIcon(i, index);
          })}
        </div>
      )}
    </ActionsIconContainer>
  );
}
