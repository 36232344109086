import { positiveWholeNumber } from './regex';

/** Validation functions to use with form fields. These functions will be applied via the `rules` prop.
 * ```
 * [
 *   'FormField',
 *   {
 *     label: 'Last Name',
 *     name: 'lastName',
 *     rules: { validate: { check: validations.validatePositiveWholeNumber }}
 *   }
 * ]
 * ```
 * **Note** the property name `check` is only an example can be whatever name you prefer.
 *
 * See [Apply validation](https://react-hook-form.com/get-started#Applyvalidation) for more information.
 * */
export const validations = {
  validatePositiveWholeNumber,
  validateNotWhiteSpacesOnly,
};

/** All validators should return `undefined` when a field passes validation. */
const noError = undefined;

export const validEmailInstructions = 'Please provide a valid email address.';
/**
 * Tests if the provided input is a valid email address.
 * */
export function validateEmailAddress(v: string) {
  if (!v) return noError;

  const validEmailRegex = new RegExp(
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
  );

  return validEmailRegex.test(v) ? noError : validEmailInstructions;
}

export const positiveWholeNumberInstructions =
  'Please provide a positive whole number without decimals, commas or symbols.';
/**
 * Tests if the provided input is a positive whole number without decimals or commas.
 * */
export function validatePositiveWholeNumber(v: string) {
  return positiveWholeNumber.test(v) ? noError : positiveWholeNumberInstructions;
}

export const validURLInstructions = 'Please provide a valid url containing http(s)://';
/**
 * Tests if the provided input is a valid URL.
 * */
export function validateURL(v: string) {
  if (!v) return noError;

  const validUrlRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  return validUrlRegex.test(v) ? noError : validURLInstructions;
}
/**
 * Tests if the provided input has any characters besides blank spaces.
 * */
export function validateNotWhiteSpacesOnly(v: string, descriptor: string) {
  const notBlankStringRegex = /\S/;
  return notBlankStringRegex.test(v) ? noError : `Please enter a valid ${descriptor}.`;
}
