import { Children, cloneElement } from 'react';
import styled, { css, CSSProperties } from 'styled-components/macro';
import { Borders, BoxShadows, Colors, defaultborder, usePortalContext } from '@zylo/orchestra';
import TabPane, { TabPaneProps } from '@components/layout/TabPane';
import Tabs from './Tabs';
import TabsRoutes from './TabsRoutes';

export type TabPaneElement = JSX.Element & {
  props: TabPaneProps;
};

function getChildTabs(children: TabPaneElement | TabPaneElement[] | undefined) {
  return Children.map(children, (c) => (c?.type === TabPane ? c : null));
}

const ZyloTabsContainer = styled.div<{
  contentBackground: Colors | 'transparent';
  contentPadding: CSSProperties['padding'];
  existsInQuickView: boolean;
  headerPadding?: CSSProperties['padding'];
}>`
  display: flex;
  width: 100%;

  ${({ existsInQuickView }) => {
    if (!existsInQuickView) {
      return css`
        background: ${Colors.white};
        border: ${defaultborder};
        border-radius: ${Borders.radius.large};
        box-shadow: ${BoxShadows.standard};
        flex: 1 auto;
        overflow: hidden;
      `;
    } else {
      return css`
        flex: 1 0 auto;
      `;
    }
  }}

  .tabs {
    display: flex;
    flex-direction: column;

    ${({ existsInQuickView }) => {
      if (!existsInQuickView) {
        return css`
          overflow-y: auto;
        `;
      }
    }}

    .topbar {
      padding: ${({ headerPadding }) => headerPadding ?? '0 0 0 1.25rem'};
    }

    &.details-tabs {
      .topbar {
        .tabs-labels {
          .tab-label {
            border-width: 4px;
            padding: 0.25rem 0.25rem 0.5rem;
            color: ${Colors.grey8};

            &:hover {
              color: ${Colors.teal6};
            }

            &.active {
              border-color: ${Colors.teal6};
              color: ${Colors.teal6};
            }
          }
        }
      }
    }

    ${({ existsInQuickView }) => {
      if (!existsInQuickView) {
        return css`
          .tabs-content-wrapper {
            height: auto;
          }
        `;
      }
    }}

    .tabs-content {
      overflow-x: hidden;
      overflow-y: auto;
      padding: ${({ contentPadding }) => contentPadding ?? '1.25rem'};

      ${({ contentBackground }) => {
        return css`
          background: ${contentBackground};
        `;
      }}
    }
  }
`;

export function ZyloTabs({
  activeTab,
  className,
  children,
  contentBackground = 'transparent',
  contentPadding = '1.25rem',
  enableRouting = false,
  headerPadding = '0 0 0 1.25rem',
  onTabChange,
  routeBase,
}: {
  /**
   * Ignored if `enableRouting` is `true`.
   */
  activeTab?: string;
  className?: string;
  children: TabPaneElement | TabPaneElement[] | undefined;
  contentBackground?: Colors | 'transparent';
  contentPadding?: CSSProperties['padding'];
  /**
   * Defaults to `false`
   */
  enableRouting?: boolean;
  headerPadding?: CSSProperties['padding'];
  /**
   * Event fired when a tab changes.
   */
  onTabChange?: (tabId: string) => void;
  /**
   * Required for routed tabs
   */
  routeBase?: string;
}) {
  const tabs = getChildTabs(children);
  const { existsInPortal } = usePortalContext();

  return (
    <ZyloTabsContainer
      className={className}
      contentBackground={contentBackground}
      contentPadding={contentPadding}
      existsInQuickView={existsInPortal}
      headerPadding={headerPadding}
    >
      {tabs && (
        <>
          {enableRouting && routeBase ? (
            <TabsRoutes onTabClick={onTabChange} routeBase={routeBase} isDetails>
              {tabs.map((t) => {
                const tabProps = t.props;
                return cloneElement(tabProps.children, {
                  key: tabProps.tabId,
                  tabLabel: tabProps.label,
                  tabId: tabProps.tabId,
                });
              })}
            </TabsRoutes>
          ) : (
            <Tabs
              onTabChange={(tabId) => {
                if (onTabChange) onTabChange(tabId);
              }}
              selected={activeTab ? activeTab : (tabs[0].props as TabPaneProps).tabId}
              isDetails
            >
              {tabs}
            </Tabs>
          )}
        </>
      )}
    </ZyloTabsContainer>
  );
}
