import { useQuery } from 'react-query';
import { useFeatureFlags, useGetCompanyApp } from '@hooks';
import { ZMF_APP_IDS } from '@utilities/constants';
import { getData } from '@utilities/xhr';

const {
  githubAppId,
  microsoft365AppId,
  salesforceCRMAppId,
  veevaCRMAppId,
  veevaVaultAppId,
  zoomAppId,
} = ZMF_APP_IDS;

const utilizationTileV1Blacklist = [
  githubAppId,
  microsoft365AppId,
  salesforceCRMAppId,
  veevaCRMAppId,
  veevaVaultAppId,
  zoomAppId,
];

export type AppUtilizationSourceResponse = {
  body: { source: string; lastFetchDate: string; lastProcessDate: string };
};

export function useGetAppUtilizationSource(companyAppId: string, enabled = true) {
  const { data: details, isSuccess } = useGetCompanyApp(companyAppId);
  const { isUtilizationTileEnabled } = useFeatureFlags('companyAppNotes', 'utilizationTile');

  const { data, isLoading } = useQuery(
    ['appUtilizationSource', companyAppId],
    async () => {
      const response: AppUtilizationSourceResponse = await getData({
        path: `companies/$companyId/apps/${companyAppId}/integrationSource`,
      });

      return response.body;
    },
    {
      enabled: isSuccess && enabled,
      keepPreviousData: true,
    },
  );

  const renderUtilizationTile =
    isUtilizationTileEnabled &&
    Boolean(details?.userCount) &&
    Boolean(data?.source) &&
    !utilizationTileV1Blacklist.includes(companyAppId);

  return { data, isLoading, renderUtilizationTile };
}
