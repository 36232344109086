import { formatCurrency } from '@utilities/currencyUtil';
import { RowData } from '@typings';

export type AlertDescriptionCellProps = {
  rowData: RowData;
};

export function AlertDescriptionCell({ rowData }: AlertDescriptionCellProps) {
  const { alertType, settings } = rowData;
  const { transaction, renewal } = settings;

  const getMessage = (alertType: string) => {
    switch (alertType) {
      case 'transaction':
        return `Payments > than ${transaction && formatCurrency(transaction.transactionThreshold)}`;
      case 'renewal':
      case 'contract-renewal':
        return `${renewal && renewal.notificationPeriod} days before renewal`;
      default:
        return '-';
    }
  };

  return <div className="alert-description-cell">{getMessage(alertType.toString())}</div>;
}
