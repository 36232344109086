import styled from 'styled-components/macro';
import classNames from 'clsx';
import { Colors, Icon, Tooltip, defaultborder, FontStyles } from '@zylo/orchestra';
import { ColumnPickerFieldArray } from './typings/types';

const Subcategory = styled.div`
  color: ${Colors.black};
  background: ${Colors.lightgray};
  font-size: ${FontStyles.size['14px']};
  font-weight: ${FontStyles.weight[500]};
  padding: 0.15rem 1.5rem;
  width: 100%;
`;

const Fields = styled.div`
  margin-bottom: 1.5rem;

  .field {
    align-items: center;
    background: ${Colors.white};
    border-bottom: ${defaultborder};
    color: ${Colors.black};
    cursor: pointer;
    display: flex;
    font-size: ${FontStyles.size['14px']};
    font-weight: ${FontStyles.weight[400]};
    justify-content: space-between;
    padding: 0.15rem 1.5rem 0.15rem 2rem;
    transition: all 100ms ease;
    width: 100%;

    &:hover {
      background: ${Colors.color200};
    }

    .field-tooltip:hover {
      text-decoration: underline;
    }

    &--active,
    &--active:hover {
      background: rgba(36, 150, 212, 0.1);
    }

    &--locked,
    &--locked:hover {
      background: ${Colors.backgroundgray};
      cursor: not-allowed;

      .field-tooltip:hover {
        text-decoration: none;
      }
    }
  }
`;

type ChooseFieldFunction = (fieldUpdateKeys: any, index: number) => void;

type ChooseFieldsCategoryFieldsProps = {
  subcategory?: string;
  fields: ColumnPickerFieldArray;
  onChooseFields: ChooseFieldFunction;
};

export default function ChooseFieldsCategoryFields({
  subcategory,
  fields,
  onChooseFields,
}: ChooseFieldsCategoryFieldsProps) {
  return fields.length > 0 ? (
    <>
      {subcategory ? <Subcategory>{subcategory}</Subcategory> : null}

      <Fields>
        {fields.map(
          ({ displayName, isInactive, isLocked, fieldUpdateKeys, title, tooltipText }, index) => {
            const allowBodyHover = typeof tooltipText === 'object';
            const isDisabled = tooltipText === '' || tooltipText === undefined;
            const visibilityIcon = isInactive ? 'IconEyeOff' : 'IconEye';

            return (
              <div
                className={classNames(
                  'field',
                  isLocked && 'field--locked',
                  !isInactive && 'field--active',
                )}
                key={displayName}
                onClick={isLocked ? undefined : () => onChooseFields(fieldUpdateKeys, index)}
              >
                <Tooltip
                  allowBodyHover={allowBodyHover}
                  bodyStyle={{ minWidth: 300 }}
                  className="field-tooltip"
                  disabled={isDisabled}
                  text={tooltipText}
                  title={title}
                >
                  {displayName}
                </Tooltip>
                <Icon
                  color={isInactive || isLocked ? 'darkgray' : 'blue'}
                  icon={isLocked ? 'IconLock' : visibilityIcon}
                  testId={
                    isLocked
                      ? 'choose-fields-locked-icon'
                      : visibilityIcon === 'IconEyeOff'
                      ? 'choose-fields-hide-icon'
                      : 'choose-fields-show-icon'
                  }
                />
              </div>
            );
          },
        )}
      </Fields>
    </>
  ) : null;
}
