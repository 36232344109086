import { useCallback, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { Icon } from '@zylo/orchestra';
import { useCompanyData } from '@hooks/company';
import {
  BooleanCell,
  CurrencyCell,
  DateCell,
  DefaultCell,
  NumberCell,
  PercentVisualizationCellLightweight,
} from '@components/tables/cells';
import { useColumnsUpdate } from '@contexts/columnsContext';
import { useFiltersUpdate } from '@contexts/filtersContext';
import { useSortUpdate } from '@contexts/sortContext';
import { FilterTypes, MetaDataColumn } from '@typings';
import { IMetadataField, FieldDataTypes } from '@typings';
import { getData } from '@utilities/xhr';

export type MetadataResponse = {
  body: {
    fields: IMetadataField[];
    settings?: {
      bulkEditingEnabled: boolean;
      bulkEditingFieldsOrderByDataType: string[];
    };
  };
};

export type PartialMetadataColumn = Partial<MetaDataColumn>;
export type MapFieldToBaseColumn = (field: IMetadataField) => MetaDataColumn;
export type MapFieldToPartialColumn = (field: IMetadataField) => PartialMetadataColumn;
export type FieldToColumnMappers = [...MapFieldToPartialColumn[]];

// Only used internally to type the map/reduce function
type MapFieldsToColumnsFunction = (field: IMetadataField) => MetaDataColumn;
export type MetaDataFiltersArgs = {
  enabled?: boolean;
  fieldToColumnMappers?: FieldToColumnMappers;
  path: string;
};

export const defaultFieldToColumnMappers: FieldToColumnMappers = [defaultMapCellToColumn];

/** Fetches integration metadata from the given `path` (URL to the resource), formats the metadata
 * as columns and filters and stores the columns in the columns context and filters in the filters context.
 * A set of `fieldToColumnMappers` functions may optionally be passed to the hook to allow for customized
 * mapping of the final IMetaDataFields as needed. The first function passed must provide all of the
 * Required properties on the IMetaDataField type, and each following function can return any additional
 * properties (ie tooltips, customComponent, etc).
 **/
export function useTableMetadata({
  enabled = true,
  fieldToColumnMappers = defaultFieldToColumnMappers,
  path,
}: MetaDataFiltersArgs) {
  const { setColumns, setDefaultColumnsOrder, setDefaultFrozenColumns } = useColumnsUpdate();
  const { storeFilters } = useFiltersUpdate();
  const { buildSortMap } = useSortUpdate();
  const { primaryUserStore } = useCompanyData().companyData;
  const metadataFetchMap = useRef<Record<string, boolean>>({});

  /*
    Callback that will be passed to a `map` function over all of the Fields that are retrieved
    from the API. For each Field, it runs a reduce over all of the provided `fieldToColumnMappers`
    and returns a full MetaDataColumn based on the combined results.
  */
  const fieldToColumnMapper: MapFieldsToColumnsFunction = useCallback(
    (field: IMetadataField): MetaDataColumn =>
      [mapFieldToIcon(primaryUserStore), defaultMapFieldsToColumns, ...fieldToColumnMappers].reduce(
        (dataObject: PartialMetadataColumn, currentMapperFunction) =>
          Object.assign(dataObject, currentMapperFunction(field)),
        {},
      ) as MetaDataColumn,
    [fieldToColumnMappers, primaryUserStore],
  );

  const { data, isSuccess } = useQuery<MetadataResponse>(path, () => getData({ path }), {
    enabled,
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    refetchOnWindowFocus: false,
  });

  const metadataCache = useRef<MetadataResponse['body']>();
  useEffect(() => {
    if (!data?.body) return;

    metadataCache.current = data.body;
  }, [data?.body]);

  useEffect(() => {
    /* The metadataFetchMap check prevents this useEffect from running after the metadata has been fetched.
       Without it, the useEffect was occassionally re-running and accidentally resetting columns */
    if (!isSuccess || metadataFetchMap.current[path]) return;

    metadataFetchMap.current = { ...metadataFetchMap.current, [path]: true };

    const fields = data?.body.fields;
    const columns = data?.body.fields
      .filter((field) => !field.filterOnly) // Remove metadata fields that are not visible columns
      .map(fieldToColumnMapper);

    storeFilters(
      fields
        .filter(({ canFilter }) => canFilter)
        /*  TO FIX: This is a hack for savings-center to remove Eventable Type & Eventable Id from the filter options. The back end needs these to be returned in the metadata to be able to filter by these fields in the microservice, but we don't actually want to show them in filters here. In future, we will need to add a new property to the ZAP metadata like backEndFilterOnly, but that will be a larger change */
        .filter(({ key }) => key !== 'eventableType' && key !== 'eventableId')
        .sort((a, b) => a.displayKey.toLowerCase().localeCompare(b.displayKey.toLowerCase())),
    );

    buildSortMap(fields);

    const activeColumns = columns.filter(({ isInactive }) => !isInactive);
    const freezeColumns = columns.filter(({ isFrozen }) => isFrozen);

    setColumns({
      activeColumns,
      freezeColumns,
      inactiveColumns: columns.filter(({ isInactive }) => isInactive),
    });
    setDefaultColumnsOrder(activeColumns.map(({ columnName }) => columnName));
    setDefaultFrozenColumns(freezeColumns.map(({ columnName }) => columnName));
  }, [
    buildSortMap,
    data,
    isSuccess,
    path,
    setColumns,
    setDefaultColumnsOrder,
    setDefaultFrozenColumns,
    storeFilters,
    fieldToColumnMapper,
  ]);

  return {
    zapMetadata: data?.body || metadataCache.current,
  };
}

export function defaultMapFieldsToColumns({
  columnAlignment,
  dataSource,
  dataType,
  displayKey,
  filterOn,
  isDefaultHidden,
  isFrozen,
  isLocked,
  key,
  sortable,
  subtype,
  tooltipText,
}: IMetadataField): MetaDataColumn {
  const { link, linkText, text } = tooltipText ?? {};

  const tooltip = text ? (
    <>
      {text}
      {link && linkText && (
        <div style={{ marginTop: '12px' }}>
          <a href={link} rel="noopener noreferrer" target="_blank">
            {linkText} <Icon icon="IconExternalLink" size={14} />
          </a>
        </div>
      )}
    </>
  ) : (
    ''
  );

  return {
    allowTooltipBodyHover: true,
    columnName: key,
    columnAlignment,
    disableColumnSort: !sortable,
    displayName: displayKey,
    dataSource,
    dataType,
    filterOn,
    isFrozen,
    isInactive: isDefaultHidden,
    isLocked,
    subtype,
    tooltipText: tooltip,
  };
}

export function defaultMapCellToColumn({ type }: IMetadataField): PartialMetadataColumn {
  switch (type) {
    case FilterTypes.boolean:
      return { customComponent: BooleanCell, customComponentProps: { dataIsString: true } };

    case FilterTypes.currency:
      return { customComponent: CurrencyCell, isNumeric: true };

    case FilterTypes.date:
      return { customComponent: DateCell, isNumeric: true };

    case FilterTypes.number:
      return { customComponent: NumberCell, isNumeric: true };

    case FilterTypes.percentage:
      return { customComponent: PercentVisualizationCellLightweight, isNumeric: true };

    default:
      return { customComponent: DefaultCell, customComponentProps: { truncate: 40 } };
  }
}

export function mapFieldToIcon(
  primaryUserStore: string,
): (f: IMetadataField) => PartialMetadataColumn {
  return ({ dataSource, dataType }) => {
    const col: PartialMetadataColumn = {
      headerIcon: '',
    };
    if (dataSource === 'zylo') {
      switch (dataType) {
        case FieldDataTypes.file:
          col.headerIcon = 'IconFile';
          break;
        case FieldDataTypes.financial:
        case FieldDataTypes.fiscal:
          col.headerIcon = 'IconCurrencyDollar';
          break;
        case FieldDataTypes.purchaseOrder:
          col.headerIcon = 'IconJumpRope';
          break;
        case FieldDataTypes.singleSignOn:
          col.headerIcon = 'IconCloudLock';
          break;
        case FieldDataTypes.security:
          col.headerIcon = 'IconShieldLock';
          break;
        case FieldDataTypes.appCatalog:
          col.headerIcon = 'IconVersions';
          break;
        case FieldDataTypes.input:
          col.headerIcon = 'IconUser';
          break;
        case FieldDataTypes.usage:
          col.headerIcon = 'IconChartBar';
          break;
        case FieldDataTypes.budget:
          col.headerIcon = 'IconReportMoney';
          break;
        case FieldDataTypes.events:
          col.headerIcon = 'IconCalendarEvent';
          break;
        case FieldDataTypes.custom:
        case FieldDataTypes.metric:
        case FieldDataTypes.user:
        case FieldDataTypes.zylo:
        default:
          col.headerIcon = 'IconCircleLetterZ';
      }
    } else if (dataSource === primaryUserStore) {
      col.headerIcon = 'IconUser';
    } else {
      col.headerIcon = 'IconJumpRope';
    }

    return col;
  };
}
