import { useEffect } from 'react';

/**
 * Injects 'viewport' meta tag on component load; removes on unmount
 */
export function useMobileMetaTag() {
  useEffect(() => {
    // '<meta name="viewport" content="width=device-width, initial-scale=1.0">';
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('id', 'viewport');
    metaTag.setAttribute('name', 'viewport');
    metaTag.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0');
    const docHead = document.getElementsByTagName('head')[0];
    docHead.appendChild(metaTag);
    return () => {
      docHead.removeChild(metaTag);
    };
  }, []);
}
