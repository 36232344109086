import { buildZAPEndpoint } from '@frameworks/zap/helpers';
import { useZAPState } from '@frameworks/zap/ZAPContext';
import TableExportButton from '@components/buttons/TableExportButton';

export default function ZAPTableExportButton() {
  const { endpoints, gridName = '', ZAPName } = useZAPState();
  let endpoint = buildZAPEndpoint(ZAPName);

  if (endpoints.tableData.split('?').length > 1) {
    const endpointParts = endpoints.tableData.split('?');
    const queryParams = new URLSearchParams(endpointParts[1]);
    const urlParams: { [key: string]: string } = {};

    queryParams.forEach((value, key) => {
      urlParams[key] = value;
    });

    endpoint = endpointParts[0];

    return <TableExportButton endpoint={endpoint} gridName={gridName} urlParams={urlParams} />;
  }

  return <TableExportButton endpoint={endpoint} gridName={gridName} />;
}
