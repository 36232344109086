import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { createContextHelper, usePortalContext } from '@zylo/orchestra';
import { get } from 'lodash-es';
import { useEventTracking, useQueryParameters, useSidebarVisibilitySensor } from '@hooks';
import {
  IDynamicVisualizationAPIDataSet,
  IGenericMapObject,
  ITrendingVisualizationAPIData,
  ITrendingVisualizationAPIResponse,
  IVisualizationAPIData,
  IVisualizationAPIResponse,
  SetState,
  VisualizationConfigItem,
} from '@typings';
import { useInitialVisualizations } from '@components/visualizations/shared/hooks/useInitialVisualizations';
import { ZAP_COMPARE_DRAWER } from '@frameworks/zap/compare/ZAPCompareButton';

export type VisualizationDataState = {
  additionalChartData?: IVisualizationAPIResponse['additionalData'];
  currentChartData?:
    | IVisualizationAPIData
    | ITrendingVisualizationAPIData
    | IDynamicVisualizationAPIDataSet;
  chartDataGroupedBy?:
    | IVisualizationAPIResponse['groupedBy']
    | ITrendingVisualizationAPIResponse['groupedBy'];
  chartDataIsEmpty?: boolean;
  chartDataIsLoading?: boolean;
  chartDataRequestParams?: IGenericMapObject<any>;
  emptyStateContent?: ReactElement<any, any> | string;
  imageDownloadData?: IVisualizationAPIData;
};

export type VisualizationStateType = VisualizationDataState & {
  selectedVisualization?: VisualizationConfigItem;
  visualizationConfigs: VisualizationConfigItem[];
  visualizationKey: string;
};

const [useVisualizationState, VisualizationStateContext] =
  createContextHelper<VisualizationStateType>('useVisualizationState', 'VisualizationProvider');

export type VisualizationUpdateType = {
  handleVisualizationChange: (selectedKey: string) => void;
  setDataState: SetState<VisualizationDataState>;
  storeChartDataRequestParams: (param: IGenericMapObject<any>) => void;
};

const [useVisualizationUpdate, VisualizationUpdateContext] =
  createContextHelper<VisualizationUpdateType>('useVisualizationUpdate', 'VisualizationProvider');

export type VisualizationProviderProps = {
  emptyStateContent?: VisualizationDataState['emptyStateContent'];
  visualizationConfigs: VisualizationConfigItem[];
  visualizationKey: string;
};

function VisualizationProvider({
  children,
  emptyStateContent,
  visualizationConfigs,
  visualizationKey,
}: VisualizationProviderProps & { children: ReactNode }) {
  const [selectedVisualizationKey, setSelectedVisualizationKey] = useState<string | undefined>();
  const selectedVisualization = useMemo(() => {
    return visualizationConfigs.find(({ key }) => key === selectedVisualizationKey);
  }, [selectedVisualizationKey, visualizationConfigs]);
  const { initialVisualizations, setInitialVisualization } = useInitialVisualizations();
  const chartDataRequestParams = useRef({});
  const isCompareSidebarOpen = useSidebarVisibilitySensor({
    sidebarName: ZAP_COMPARE_DRAWER,
  });
  const sendEvent = useEventTracking();
  const location = useLocation();
  const [dataState, setDataState] = useState<VisualizationDataState>({
    currentChartData: [],
    chartDataIsEmpty: true,
  });
  const { existsInPortal } = usePortalContext();
  const queryParams = useQueryParameters();
  const selectedVisualQueryParam = useMemo(
    () => JSON.parse(get(queryParams, visualizationKey, '{}') as string).selectedVisual,
    [queryParams, visualizationKey],
  );
  const storedVisualSelection = useMemo(
    () => initialVisualizations[visualizationKey],
    [initialVisualizations, visualizationKey],
  );

  const sendChartEvent = useCallback(() => {
    if (!selectedVisualization) return;

    const selectedChart = selectedVisualization!.title.split(' ').join('_');
    let eventInfo;

    if (location.pathname === '/subscriptions' && !existsInPortal) {
      eventInfo = {
        eventName: 'SUBSCRIPTIONS_CHART_SELECT',
        chartPage: 'Subscriptions',
      };
    } else if (location.pathname === '/subscriptions/compare') {
      eventInfo = {
        eventName: 'COMPARE_CHART_SELECT',
        chartPage: 'Compare',
      };
    } else if (location.pathname === '/subscriptions' && existsInPortal && !isCompareSidebarOpen) {
      eventInfo = {
        eventName: 'OVERVIEW_QUICKVIEW_CHART_SELECT',
        chartPage: 'Overview Quickview',
      };
    } else if (location.pathname === '/subscriptions' && existsInPortal && isCompareSidebarOpen) {
      eventInfo = {
        eventName: 'COMPARE_QUICKVIEW_CHART_SELECT',
        chartPage: 'Compare Quickview',
      };
    } else if (
      location.pathname.startsWith('/subscriptions') &&
      location.pathname.split('/').length > 1
    ) {
      eventInfo = {
        eventName: 'OVERVIEW_FULLVIEW_CHART_SELECT',
        chartPage: 'Overview Fullview',
      };
    } else if (location.pathname === '/employees') {
      eventInfo = {
        eventName: 'TEAM_CHART_SELECT',
        chartPage: 'Team',
      };
    } else if (location.pathname === '/') {
      eventInfo = {
        eventName: 'DASHBOARD_TRENDING_CHART_DROPDOWN_SELECT',
        chartPage: 'Dashboard',
      };
    } else if (location.pathname === '/payments') {
      eventInfo = {
        eventName: 'PAYMENTS_CHART_SELECT',
        chartPage: 'Payments',
      };
    }

    if (eventInfo) {
      sendEvent({
        eventName: eventInfo.eventName,
        data: {
          'Chart Page': eventInfo.chartPage,
          'Selected Chart': selectedChart,
        },
      });
    }
  }, [existsInPortal, isCompareSidebarOpen, location.pathname, selectedVisualization, sendEvent]);

  const handleVisualizationChange = useCallback(
    (selectedKey: string) => {
      setInitialVisualization(visualizationKey, selectedKey);
    },
    [setInitialVisualization, visualizationKey],
  );

  // set initial selected visualization and visualization options
  const initialPreselectionComplete = useRef(false);
  useEffect(() => {
    if (
      initialPreselectionComplete.current ||
      !visualizationConfigs ||
      visualizationConfigs.length === 0
    )
      return;

    handleVisualizationChange(
      selectedVisualQueryParam || storedVisualSelection || visualizationConfigs[0].key,
    );
    initialPreselectionComplete.current = true;
  }, [
    handleVisualizationChange,
    selectedVisualQueryParam,
    storedVisualSelection,
    visualizationConfigs,
  ]);

  useEffect(() => {
    setSelectedVisualizationKey(storedVisualSelection);
    sendChartEvent();
  }, [storedVisualSelection, sendChartEvent]);

  const storeChartDataRequestParams = useCallback((params) => {
    chartDataRequestParams.current = params;
  }, []);

  return (
    <VisualizationStateContext
      value={{
        ...dataState,
        chartDataRequestParams: chartDataRequestParams.current,
        emptyStateContent,
        selectedVisualization,
        visualizationConfigs,
        visualizationKey,
      }}
    >
      <VisualizationUpdateContext
        value={{
          handleVisualizationChange,
          setDataState,
          storeChartDataRequestParams,
        }}
      >
        {children}
      </VisualizationUpdateContext>
    </VisualizationStateContext>
  );
}

export { VisualizationProvider, useVisualizationState, useVisualizationUpdate };
