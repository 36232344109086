import { useState } from 'react';
import { identity } from 'lodash-es';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormBuilderProps, FormControlProps, FormValues, ObserverValues } from './types';
import { FormComponent } from './FormComponent';
import { fieldBuilder } from './formHelpers';
import { formloader } from './formLoader';
import { FieldReturn } from './Field/FieldReturn';
/**
 * Provides form functionality, loading state for the form and field placeholders.
 * If a loading state is not needed or desired, the `status` prop is optional. When
 * it is not provided the form will bypass the loading state.
 */
export const ZyloForm = formloader(ZyloFormProvider);
/**
 * A utility component to determine whether `FormProvider` in conjunction with
 * `useFormContext` or `useForm` should be used.
 */
export function ZyloFormProvider({ methods, ...props }: FormBuilderProps) {
  if (methods) {
    return (
      <FormProvider {...methods}>
        <ZyloFormMethodsFromUseFormContext {...props} />
      </FormProvider>
    );
  } else {
    return <ZyloFormMethodsFromUseForm {...props} />;
  }
}
/**
 * A utility component to provide form methods to `FormBuilder` via `useFormContext`.
 */
function ZyloFormMethodsFromUseFormContext(props: FormBuilderProps) {
  const methods = useFormContext();
  return <FormBuilder {...props} methods={methods} />;
}
/**
 * A utility component to provide form methods to `FormBuilder` via `useForm`.
 */
function ZyloFormMethodsFromUseForm({ mode = 'onSubmit', ...props }: FormBuilderProps) {
  const methods = useForm({ defaultValues: props.defaultValues, mode });
  return <FormBuilder {...props} methods={methods} />;
}
/**
 * Provides the primary functionality of `ZyloForm`.
 */
export function FormBuilder({
  defaultValues,
  editMode,
  fields,
  methods: formMethods,
  onCancel,
  onEdit,
  onSubmit,
  onValueChange,
  readOnly,
  status = 'success',
  ...props
}: FormBuilderProps) {
  const [editing, setEditing] = useState(editMode === 'enabled');
  const toggleEditing = editMode === 'enabled' ? identity : () => setEditing((state) => !state);
  const { handleSubmit, reset, watch, ...methods } = formMethods!;
  const { formState } = methods;
  const observerValues: ObserverValues = { formState, watch };
  const formValues = watch();

  if (onValueChange) {
    onValueChange(formValues, formState);
  }

  const controlProps: FormControlProps = {
    editing,
    readOnly,
    saveDisabled: !formState.isDirty,
    status,

    onCancel() {
      toggleEditing();
      reset(defaultValues);

      if (typeof onCancel === 'function') {
        onCancel();
      }
    },

    onEdit() {
      toggleEditing();

      if (typeof onEdit === 'function') {
        onEdit();
      }
    },
    ...observerValues,
  };

  function handleFormSubmit(data: FormValues) {
    if (onSubmit) {
      onSubmit(data, () => reset(data));
    }
    toggleEditing();
  }

  return (
    <FormComponent {...props} {...controlProps} onSubmit={handleSubmit(handleFormSubmit)}>
      {fieldBuilder(
        typeof fields === 'function' ? fields(observerValues) : fields,
        (fieldConfig) => {
          return (
            <FieldReturn
              editing={editing}
              formElementConfig={fieldConfig}
              key={fieldConfig[1].name}
              methods={methods}
              obeserverValues={observerValues}
              readOnly={readOnly}
              status={status}
            />
          );
        },
      )}
    </FormComponent>
  );
}
