import { ZyloButton } from '@zylo/orchestra';
import { useSidebarUpdate } from '@contexts/sidebarContext';
import ColumnPicker, { ColumnPickerProps } from './ColumnPicker';

export type ColumnPickerButtonProps = Pick<ColumnPickerProps, 'categoriesInfo'> & {
  gridName: string;
};

export default function ColumnPickerButton({ gridName, ...displayProps }: ColumnPickerButtonProps) {
  const implementationName = `${gridName}_COLUMN_PICKER`;
  return (
    <ColumnPickerButton_DisplayLayer
      {...displayProps}
      {...useDataLayer({ implementationName })}
      implementationName={implementationName}
    />
  );
}

type ColumnPickerButtonDisplayLayerProps = Omit<ColumnPickerButtonProps, 'gridName'> & {
  handleButtonClick(): void;
  implementationName: string;
};

export function ColumnPickerButton_DisplayLayer({
  categoriesInfo,
  handleButtonClick,
  implementationName,
}: ColumnPickerButtonDisplayLayerProps) {
  return (
    <>
      <ZyloButton
        buttonType="button"
        collapseMediaQueryWidth={1100}
        handleClick={handleButtonClick}
        icon="IconLayoutColumns"
        variant="outlined"
      >
        Fields
      </ZyloButton>

      <ColumnPicker categoriesInfo={categoriesInfo} implementationName={implementationName} />
    </>
  );
}

function useDataLayer({ implementationName }: { implementationName: string }) {
  const { showSidebar } = useSidebarUpdate();

  return {
    handleButtonClick: () => showSidebar(implementationName),
  };
}
