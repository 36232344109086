import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSessionCacheUpdate } from '@contexts/sessionCacheContext';

export function useClearAppDetailsOnUnmount() {
  const dispatch = useDispatch();
  const updateSessionCache = useSessionCacheUpdate();

  useEffect(
    () => () => {
      updateSessionCache('appDetailsCompanyAppId', undefined);
    },
    [dispatch, updateSessionCache],
  );
}
