import { createAction, handleActions } from 'redux-actions';
import { INITIALIZE } from 'redux-form/lib/actionTypes';
import { reset as resetForm } from 'redux-form';
import { get } from 'lodash-es';
import { actions as statusBarActions } from './statusBar';

// ------------------------------------
// Constants
// ------------------------------------

const DEFAULT_ZYLO_FORM_STATE = {
  formName: '',
  isSaving: false,
  payloadData: null,
  savedData: null,
  formSubmissionError: null,
  failedSaveAttempts: [],
};

export const SAVE_ZYLO_FORM_START = 'SAVE_ZYLO_FORM_START';
export const SAVE_ZYLO_FORM_SUCCESS = 'SAVE_ZYLO_FORM_SUCCESS';
export const SAVE_ZYLO_FORM_FAIL = 'SAVE_ZYLO_FORM_FAIL';
export const CLEAR_SUCCESSFUL_SAVE = 'CLEAR_SUCCESSFUL_SAVE';
export const RECORD_FAILED_SAVE_ATTEMPT = 'RECORD_FAILED_SAVE_ATTEMPT';
export const REMOVE_FAILED_ATTEMPT = 'REMOVE_FAILED_ATTEMPT';

// ------------------------------------
// Actions
// ------------------------------------

export const saveZyloFormStart = createAction(SAVE_ZYLO_FORM_START);
export const saveZyloFormSucceeded = createAction(SAVE_ZYLO_FORM_SUCCESS);
export const saveZyloFormFailed = createAction(SAVE_ZYLO_FORM_FAIL);
export const clearSuccessfulSave = createAction(CLEAR_SUCCESSFUL_SAVE);
export const recordFailedSaveAttempt = createAction(RECORD_FAILED_SAVE_ATTEMPT);
export const removeFailedAttempt = createAction(REMOVE_FAILED_ATTEMPT);

// autosave forms pass in values as a prop in case user navigates away from form while save is pending
function saveZyloForm({ formName, method, autosaveValues, params = {} }, onSaveCallback) {
  return (dispatch, getState) => {
    const { auth, form } = getState();
    const { companyId } = auth;
    const { syncErrors, values } = get(form, formName, {});
    const formData = autosaveValues || values || {};

    if (!syncErrors) {
      method({ companyId, ...params, data: formData });
      dispatch(saveZyloFormStart({ formName, formData }));
      if (onSaveCallback) {
        onSaveCallback();
      }
    } else {
      dispatch(recordFailedSaveAttempt(formName));
      dispatch(
        statusBarActions.showZyloErrorMessage(
          'Fill in all required fields, resolve any form errors and try again.',
        ),
      );
    }
  };
}

function saveZyloFormSuccess(successMessage, messageDuration) {
  return (dispatch, getState) => {
    const { form, zyloForm } = getState();

    dispatch(saveZyloFormSucceeded(zyloForm.payloadData));
    setTimeout(() => {
      dispatch(clearSuccessfulSave());
    }, 1000);

    if (successMessage || !get(form, `${zyloForm.formName}.values`)) {
      dispatch(
        statusBarActions.showZyloSuccessMessage(
          successMessage || 'Autosave Complete',
          messageDuration,
        ),
      );
    }
  };
}

function saveZyloFormError(errorMessage) {
  return (dispatch, getState) => {
    dispatch(recordFailedSaveAttempt(getState().zyloForm.formName));
    dispatch(saveZyloFormFailed(errorMessage));

    if (errorMessage) {
      dispatch(statusBarActions.showZyloErrorMessage(errorMessage));
    }
  };
}

function removeFailedSaveAttempt(form) {
  return (dispatch) => {
    dispatch(removeFailedAttempt(form));
  };
}

function resetZyloForm(formName = '') {
  return (dispatch) => {
    if (formName) {
      dispatch(resetForm(formName));
    }
  };
}

export const actions = {
  saveZyloForm,
  saveZyloFormSuccess,
  saveZyloFormError,
  removeFailedSaveAttempt,
  resetZyloForm,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(
  {
    [INITIALIZE]: ({ failedSaveAttempts, formName }) => {
      return { ...DEFAULT_ZYLO_FORM_STATE, formName, failedSaveAttempts };
    },
    [SAVE_ZYLO_FORM_START]: (state, { payload }) => {
      const { formName, formData } = payload;

      return {
        ...state,
        formName,
        payloadData: formData,
        isSaving: true,
        savedData: null,
        formSubmissionError: null,
      };
    },
    [SAVE_ZYLO_FORM_SUCCESS]: (state, { payload }) => {
      const savedData = {};
      savedData[state.formName] = payload || state.payloadData;

      return { ...state, isSaving: false, savedData, payloadData: null };
    },
    [SAVE_ZYLO_FORM_FAIL]: (state, { payload }) => {
      return { ...state, isSaving: false, payloadData: null, formSubmissionError: payload };
    },
    [CLEAR_SUCCESSFUL_SAVE]: (state) => {
      return { ...state, savedData: null };
    },
    [RECORD_FAILED_SAVE_ATTEMPT]: (state, { payload }) => {
      const failedSaveAttempts = state.failedSaveAttempts.slice(0);
      failedSaveAttempts.push(payload);

      return { ...state, failedSaveAttempts };
    },
    [REMOVE_FAILED_ATTEMPT]: (state, { payload }) => {
      const failedSaveAttempts = state.failedSaveAttempts.slice(0);
      const index = failedSaveAttempts.indexOf(payload);

      if (index > -1) {
        failedSaveAttempts.splice(index, 1);
      }

      return { ...state, failedSaveAttempts };
    },
  },
  DEFAULT_ZYLO_FORM_STATE,
);
