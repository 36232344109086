import { RegisterOptions } from 'react-hook-form';
import { validatePositiveWholeNumber } from './validations';

type FieldRules = Record<string, RegisterOptions>;
/**
 * Common validations and settings for fields.
 * ```
 * ['FormField', { rules: fieldRules.defaultNumberRules }]
 * ```
 * Addtionally you may want to provide your own rules. See
 * [Apply validation](https://react-hook-form.com/get-started#Applyvalidation) for more information.
 * */
export const fieldRules: FieldRules = {
  positiveWholeNumberRules: {
    validate: { positiveWholeNumber: validatePositiveWholeNumber },
    valueAsNumber: true,
  },
};
