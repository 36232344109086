import classNames from 'clsx';
import PropTypes from 'prop-types';
import { genKey } from '@utilities/util';

const instanceId = genKey();

export default function SegmentedSelector({
  canDeselect = false,
  className,
  fieldKey,
  isDisabled,
  onChange,
  options,
  value,
}) {
  function handleClick(e) {
    const clickedIndex = parseInt(e.target.getAttribute('data-index'), 10);

    if (!Number.isNaN(clickedIndex)) {
      const option = options[clickedIndex];
      let newValue = option.value;

      if (canDeselect && option.value === value) {
        newValue = '';
      }

      onChange(fieldKey ? { fieldKey, value: newValue } : newValue);
    }
  }

  return (
    <div
      className={classNames(
        'flex-container justify-center segmented-selector',
        className,
        canDeselect && 'can-deselect',
        isDisabled && 'disabled',
      )}
    >
      {options.map((option, i) => {
        const isActive = option.value === value;

        return (
          <div
            className={classNames(
              'segmented-selector-section',
              option.value === undefined && 'empty-segmented-selector-section',
              isActive && 'active-segmented-selector-section',
            )}
            data-index={option.value === undefined ? null : i}
            data-testid={isActive ? 'active' : 'inactive'}
            key={`${instanceId}_${option.value}`}
            onClick={isDisabled || option.value === undefined ? null : handleClick}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
}

SegmentedSelector.propTypes = {
  className: PropTypes.string,
  fieldKey: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
