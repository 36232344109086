import classNames from 'clsx';
import styled from 'styled-components/macro';
import { Button, Colors, defaultborder, FontStyles, ZyloButton } from '@zylo/orchestra';
import { ReactNode } from 'react';
import { FormControlProps } from '@frameworks/form-fns/types';

const FormHeaderStyles = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0 0.5rem 0;
  color: ${Colors.teal5};
  font-size: ${FontStyles.size['14px']};
  font-weight: ${FontStyles.weight[500]};
  border-bottom: ${defaultborder};
  margin-bottom: 20px;

  .form-header {
    &__title {
      margin: unset;
      padding: unset;
      font-size: ${FontStyles.size['20px']};
      font-weight: ${FontStyles.weight[500]};
      line-height: 1.5;
    }

    &__actions {
      align-items: center;
      display: flex;
    }

    &__actions-item {
      margin-left: 0.5rem;

      &:first-child {
        margin-left: 0;
      }
    }

    &__edit-button {
      margin-bottom: 0.125rem;
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      max-width: 10rem;

      button {
        min-height: unset;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
`;

export type FormHeaderProps = FormControlProps & {
  actions?: ReactNode[];
  className?: string;
  title: string;
};

export function ZyloFormHeader({
  actions,
  className,
  editing,
  onCancel,
  onEdit,
  readOnly,
  saveDisabled,
  title,
}: FormHeaderProps) {
  if (!readOnly) {
    return (
      <FormHeaderStyles>
        <h3 className="form-header__title">{title}</h3>

        <div className="form-header__actions">
          {actions && (
            <>
              {actions.map((a, i) => {
                return (
                  <div className="form-header__actions-item" key={i}>
                    {a}
                  </div>
                );
              })}
            </>
          )}
          {editing ? (
            <div
              className={classNames(
                'form-header__controls',
                'form-header__actions-item',
                className,
              )}
            >
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button
                buttonType="submit"
                color="secondary"
                disabled={saveDisabled}
                variant="contained"
              >
                Save
              </Button>
            </div>
          ) : (
            <ZyloButton
              icon="IconEdit"
              onClick={onEdit}
              testId="zylo-form-icon-edit"
              variant="text"
            />
          )}
        </div>
      </FormHeaderStyles>
    );
  } else {
    return (
      <FormHeaderStyles>
        <h3 className="form-header__title">{title}</h3>
      </FormHeaderStyles>
    );
  }
}
