import { useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import styled from 'styled-components/macro';
import { Colors, FontStyles } from '@zylo/orchestra';
import { useCompanyAppsSearch } from '@hooks';
import { CompanyApp } from '@typings';
import TypeAhead from './TypeAhead';
import SubscriptionOption from './SubscriptionOption';

const AppSelectorInputContainer = styled.div`
  padding: 0px 0px 20px 0px;

  .select-app-label {
    color: ${Colors.grey9};
    font-size: ${FontStyles.size['14px']};
    font-weight: ${FontStyles.weight[400]};
  }

  .Select {
    z-index: 3;
  }
  .Select-menu {
    height: 75px;
  }
`;

export type AppsSelectorInputProps = {
  handleSearchChange: (search: string) => void;
  companyApps?: Array<CompanyApp>;
  onChange: (arg0: string) => void;
  data: string;
  disableSelectAppText?: boolean;
};

export default function AppSelectorInput(props: AppsSelectorInputProps) {
  return <AppSelectorInput_DisplayLayer {...props} {...useDataLayer()} />;
}

export function AppSelectorInput_DisplayLayer({
  handleSearchChange,
  companyApps,
  onChange,
  data,
  disableSelectAppText,
}: AppsSelectorInputProps) {
  const [selectedApp, setSelectedApp] = useState<CompanyApp>({} as CompanyApp);
  const [savedAppId, setSavedAppId] = useState(data);

  useEffect(() => {
    if (!companyApps) {
      return;
    }
    const savedApp = companyApps.find((item) => item.appId === savedAppId);
    if (savedApp) {
      setSelectedApp(savedApp);
    }
  }, [companyApps, savedAppId]);

  function renderCompanyApp() {
    return <SubscriptionOption option={selectedApp}>{selectedApp.appLabel}</SubscriptionOption>;
  }

  function handleChange(_: never, option: CompanyApp) {
    if (option !== undefined) {
      setSelectedApp(option);
      setSavedAppId(option.id);
      onChange(option.id);
    }
  }

  return (
    <AppSelectorInputContainer>
      {!disableSelectAppText && <div className="select-app-label">Select App:</div>}
      <TypeAhead
        allowCustomValue={false}
        allowMultiple={false}
        customOption={SubscriptionOption}
        customValueRenderer={renderCompanyApp}
        handleSearchChange={debounce(handleSearchChange, 300)}
        labelKey="appLabel"
        matchPos="any"
        name="companyApp"
        onChange={handleChange}
        options={companyApps}
        value={selectedApp}
        valueKey="appLabel"
      />
    </AppSelectorInputContainer>
  );
}

function useDataLayer() {
  const { searchCompanyApps, queryData } = useCompanyAppsSearch({ limit: 50 });
  const { data: companyApps } = queryData;

  return {
    handleSearchChange: searchCompanyApps,
    companyApps,
  };
}
