import superagent from 'superagent';
import { LS_PROFILE_KEY } from '../config/config';

function replacePathCompanyId(companyId, path) {
  return companyId ? `${path.replace('$companyId', companyId)}` : '';
}

export function deleteData({ path, params = {} }) {
  const { companyId, token } = JSON.parse(localStorage[LS_PROFILE_KEY]);

  return superagent
    .delete(`${process.env.API_URI}/${replacePathCompanyId(companyId, path)}`)
    .set({ Authorization: token })
    .send(params);
}

export function getData({ path, params = {} }) {
  const { companyId, token } = JSON.parse(localStorage[LS_PROFILE_KEY]);

  return superagent
    .get(`${process.env.API_URI}/${replacePathCompanyId(companyId, path)}`, params)
    .set({ Authorization: token });
}

export function postData({ path, params = {} }) {
  const { companyId, token } = JSON.parse(localStorage[LS_PROFILE_KEY]);

  return superagent
    .post(`${process.env.API_URI}/${replacePathCompanyId(companyId, path)}`)
    .set({ Authorization: token })
    .send(params);
}

export function uploadFile({ path, file, _params = {} }) {
  const { companyId, token } = JSON.parse(localStorage[LS_PROFILE_KEY]);

  return superagent
    .post(`${process.env.API_URI}/${replacePathCompanyId(companyId, path)}`)
    .set({ Authorization: token })
    .attach('contents', file);
}

export function putData({ path, params = {} }) {
  const { companyId, token } = JSON.parse(localStorage[LS_PROFILE_KEY]);

  return superagent
    .put(`${process.env.API_URI}/${replacePathCompanyId(companyId, path)}`)
    .set({ Authorization: token })
    .send(params);
}

export function patchData({ path, params = {} }) {
  const { companyId, token } = JSON.parse(localStorage[LS_PROFILE_KEY]);

  return superagent
    .patch(`${process.env.API_URI}/${replacePathCompanyId(companyId, path)}`)
    .set({ Authorization: token })
    .send(params);
}
