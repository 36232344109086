import PropTypes from 'prop-types';
import { Icon } from '@zylo/orchestra';
import { connect } from 'react-redux';
import { useEventTracking } from '@hooks';
import { actions as filterActions } from '@modules/filters';

function ReportsRunCellComponent({ isRunning, reportDefinitionId, reportName, index, runFilter }) {
  const sendEvent = useEventTracking();

  function handleRunClick() {
    runFilter({ filterId: reportDefinitionId, filterName: reportName, index });
    sendEvent({ eventName: 'RUN_REPORT', data: { 'Report Name': reportName } });
  }

  return (
    <div className="reporting-action-cell">
      <Icon
        color={isRunning ? 'blue' : 'navy'}
        hoverColor="blue"
        icon="IconRefresh"
        onClick={!isRunning ? handleRunClick : null}
        size={1.5}
        spin={isRunning}
        relativeSize
      />
    </div>
  );
}

const mapStateToProps = (_, { rowData }) => {
  const { reportStatus, filterName, advancedFilterDefinitionId } = rowData;
  return {
    reportDefinitionId: advancedFilterDefinitionId,
    reportName: filterName,
    isRunning: reportStatus === 'P',
  };
};

ReportsRunCellComponent.propTypes = {
  index: PropTypes.number.isRequired,
  isRunning: PropTypes.bool,
  reportDefinitionId: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  runFilter: PropTypes.func.isRequired,
};

export const ReportsRunCell = connect(mapStateToProps, { ...filterActions })(
  ReportsRunCellComponent,
);
