import styled from 'styled-components/macro';
import { Colors, SegmentedBar, BarSegment, ColorsUnion } from '@zylo/orchestra';

export type PercentVisualizationColorMap = {
  empty: ColorsUnion;
  primary: ColorsUnion;
  primaryText?: ColorsUnion;
  secondary: ColorsUnion;
  secondaryText?: ColorsUnion;
  overflow: ColorsUnion;
  overflowText?: ColorsUnion;
};

export const LowerIsBetterColorMap: PercentVisualizationColorMap = {
  empty: 'grey2',
  primary: 'teal4',
  secondary: 'warning',
  overflow: 'error',
  overflowText: 'error',
};

export const StaticColorMap: PercentVisualizationColorMap = {
  empty: 'grey2',
  primary: 'sienna4',
  secondary: 'sienna4',
  overflow: 'sienna4',
};

const PercentVisualizationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  .bar-container {
    width: 250px;
    padding: 0px 10px;
  }
`;
const PercentVisualizationText = styled.div<{ textColor?: ColorsUnion }>`
  margin: auto;
  padding-left: 5px;
  color: ${({ textColor }: { textColor?: ColorsUnion }) => textColor || Colors.grey9};
`;

export type PercentVisualizationCellCustomizations = {
  colorMap: PercentVisualizationColorMap;
  multipleThresholdsColorMap?: [
    {
      color: ColorsUnion;
      operatorFn: (x: number) => boolean;
    },
  ];
  numberColorMap?: PercentVisualizationColorMap;
  primaryColorThreshold: number;
  overflowThreshold: number;
  showOverflow: boolean;
};
export type PercentVisualizationCellProps = Partial<PercentVisualizationCellCustomizations> & {
  data: string;
  hasMultipleColorThresholds?: boolean;
  isPercent?: boolean;
};

export function getBarValues(
  displayPercent: number = 0,
  customizations: PercentVisualizationCellCustomizations,
): [BarSegment, BarSegment] {
  const { colorMap, showOverflow, primaryColorThreshold, overflowThreshold } = customizations;
  const isOverflowing: boolean = displayPercent > overflowThreshold;
  const isCloseToLimit = !isOverflowing && displayPercent >= primaryColorThreshold;
  let secondaryBarValue: number = overflowThreshold - displayPercent; // Secondary value assuming not overflowing

  if (isOverflowing && showOverflow) {
    secondaryBarValue = displayPercent - overflowThreshold;
  } else if (isOverflowing && !showOverflow) {
    secondaryBarValue = 0;
  }

  const primaryBarValue: number = overflowThreshold - secondaryBarValue;
  return [
    {
      id: 'primaryBar',
      value: primaryBarValue,
      color:
        (isCloseToLimit || isOverflowing) && showOverflow ? colorMap?.secondary : colorMap?.primary,
    },
    {
      id: 'secondaryBar',
      value: secondaryBarValue,
      color: isOverflowing && showOverflow ? colorMap?.overflow : colorMap?.empty,
    },
  ];
}

export function getTextColor(
  displayPercent: number | undefined = undefined,
  customizations: PercentVisualizationCellCustomizations,
  isPercent: boolean = true,
  parsedData: number | undefined = 0,
  primaryColor: ColorsUnion = 'teal4',
): ColorsUnion | undefined {
  const { colorMap, showOverflow, primaryColorThreshold, overflowThreshold } = customizations;
  const isOverflowing: boolean = !!displayPercent && displayPercent > overflowThreshold;
  const isCloseToLimit =
    !isOverflowing && !!displayPercent && displayPercent >= primaryColorThreshold;

  if (isPercent) {
    if (!displayPercent || !showOverflow) {
      return undefined;
    } else if (isOverflowing) {
      return colorMap.overflowText;
    } else if (isCloseToLimit) {
      return colorMap.secondaryText;
    }
    return colorMap.primaryText;
  }

  if (parsedData > 0) {
    return primaryColor;
  }

  return 'grey9';
}

export function PercentVisualizationCell({
  data,
  colorMap = StaticColorMap,
  showOverflow = false,
  primaryColorThreshold = 80,
  overflowThreshold = 100,
}: PercentVisualizationCellProps) {
  const parsedData: number = parseFloat(data);
  const percentText = !!parsedData ? `${parsedData}%` : 'N/A';
  const cellCustomizations: PercentVisualizationCellCustomizations = {
    colorMap,
    showOverflow,
    primaryColorThreshold,
    overflowThreshold,
  };

  const bars = getBarValues(parsedData || 0, cellCustomizations);

  return (
    <PercentVisualizationContainer>
      <div className="bar-container">
        <SegmentedBar barHeight={20} bars={bars} cornerRadius={8} height={50} />
      </div>
      <PercentVisualizationText textColor={getTextColor(parsedData, cellCustomizations)}>
        {percentText}
      </PercentVisualizationText>
    </PercentVisualizationContainer>
  );
}
