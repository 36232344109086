import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import moment from 'moment';
import { Colors, Icon, Tooltip } from '@zylo/orchestra';

const localizedDateFormat = moment.localeData().longDateFormat('L');
const formatPlaceholder = localizedDateFormat.toLowerCase();
const dateFormatDivider = localizedDateFormat.includes('/') ? '/' : '-';
const alternateFormatDivider = localizedDateFormat.includes('/') ? '-' : '/';
const dividerRegex = new RegExp(alternateFormatDivider, 'g');
const validCharacterRegex = /^[0-9/-]*$/;
const dateFormatParts = localizedDateFormat.split(dateFormatDivider);
const monthIndex = dateFormatParts.indexOf('MM');
const dayIndex = dateFormatParts.indexOf('DD');
const yearIndex = dateFormatParts.indexOf('YYYY');

function DatePickerInput({
  checkForDisabledDate,
  disabled,
  disableFuture,
  disablePast,
  focused,
  name = 'date-picker-input',
  onBlur,
  onChange,
  onFocus,
  placeholder = formatPlaceholder,
  readOnly,
  value = '',
}) {
  const currentFormat = useRef(localizedDateFormat);
  const [inputValue, setInputValue] = useState(
    value ? moment(new Date(value)).format(currentFormat.current) : value,
  );
  const [invalidDateMessage, setInvalidDateMessage] = useState('');
  const [canDisplayError, setCanDisplayError] = useState(false);
  const keyRef = useRef();
  const debouncedChangeHandler = useRef(debounce(onChange, 500));
  const displayErrorMessage = canDisplayError && invalidDateMessage;

  useEffect(() => {
    if (value) {
      setCanDisplayError(true);
      setInputValue(moment(new Date(value)).format(currentFormat.current));
    } else {
      setCanDisplayError(false);
      setInputValue(value);
    }
  }, [value]);

  function validateDate(dateString, callback = () => {}) {
    const [dateSegment1 = '', dateSegment2 = '', dateSegment3 = ''] =
      dateString.split(dateFormatDivider);
    let [formatSegment1, formatSegment2, formatSegment3] =
      localizedDateFormat.split(dateFormatDivider);
    let isDateValid = false;

    // the logic below allows a date of 1/1/2020 to be submitted if the required format is MM/DD/YYYY
    if (formatSegment1 === 'MM' && dateSegment1.length === 1) {
      formatSegment1 = 'M';
    } else if (formatSegment2 === 'MM' && dateSegment2.length === 1) {
      formatSegment2 = 'M';
    } else if (formatSegment3 === 'MM' && dateSegment3.length === 1) {
      formatSegment3 = 'M';
    }

    if (formatSegment1 === 'DD' && dateSegment1.length === 1) {
      formatSegment1 = 'D';
    } else if (formatSegment2 === 'DD' && dateSegment2.length === 1) {
      formatSegment2 = 'D';
    } else if (formatSegment3 === 'DD' && dateSegment3.length === 1) {
      formatSegment3 = 'D';
    }

    const dateFormat = `${formatSegment1}${dateFormatDivider}${formatSegment2}${dateFormatDivider}${formatSegment3}`;
    const momentDate = moment(dateString, dateFormat, true);

    currentFormat.current = dateFormat;

    if (momentDate.isValid()) {
      setInvalidDateMessage(null);
      isDateValid = true;
      callback(momentDate);

      if (checkForDisabledDate(dateString)) {
        if (disableFuture) {
          setInvalidDateMessage('Future dates are disabled. Please enter a date before today.');
        } else if (disablePast) {
          setInvalidDateMessage('Past dates are disabled. Please enter a date on or after today.');
        }
      }
    } else {
      setInvalidDateMessage(`Please use the ${formatPlaceholder} date format.`);
    }

    return isDateValid;
  }

  function handleInputChange(e) {
    let newValue = e.target.value.replace(dividerRegex, dateFormatDivider);

    setInputValue((currentVal) => {
      // date has been manually deleted
      if (newValue === '') {
        onChange();

        return '';
      }

      // backspace is always allowed
      if (currentVal.length > newValue.length) {
        validateDate(newValue, debouncedChangeHandler.current);

        return newValue;
      }

      const dateSegments = newValue.split(dateFormatDivider);
      const month = dateSegments[monthIndex] || '';
      const day = dateSegments[dayIndex] || '';
      const year = dateSegments[yearIndex] || '';

      if (
        !validCharacterRegex.test(newValue) || // invalid character
        newValue.includes(`${dateFormatDivider}${dateFormatDivider}`) || // consecutive dividers
        newValue[0] === dateFormatDivider || // starts with divider
        dateSegments.length > 3 || // too many dividers
        newValue.length > 10 || // too many characters
        month === '00' || // invalid month
        day === '00' || // invalid day
        year === '0000' // invalid year
      ) {
        return currentVal;
      }

      let lastKey = keyRef.current;
      let dividerPlaceholder = '';

      if (lastKey) {
        if (lastKey !== dateFormatDivider) {
          if (lastKey === alternateFormatDivider) {
            lastKey = dateFormatDivider; // substitutes correct divider if necessary
          }

          const possibleReformat = `${newValue.substr(
            0,
            newValue.length - 1,
          )}${dateFormatDivider}${lastKey}`;

          if (Number(month) > 12) {
            if (monthIndex === 2 || month !== newValue.substr(0 - month.length)) {
              return currentVal;
            }

            newValue = possibleReformat;

            if (
              monthIndex === 0 &&
              ((Number(month.substr(0, 2) === 2) && Number(lastKey) > 2) || Number(lastKey) > 3)
            ) {
              dividerPlaceholder = dateFormatDivider;
            }
          } else if (Number(day) > 31) {
            if (dayIndex === 2 || day !== newValue.substr(0 - day.length)) {
              return currentVal;
            }

            newValue = possibleReformat;

            if (dayIndex === 0 && Number(lastKey) > 1) {
              dividerPlaceholder = dateFormatDivider;
            }
          } else if (year.length > 4) {
            if (yearIndex === 2 || year !== newValue.substr(0 - year.length)) {
              return currentVal;
            }

            newValue = possibleReformat;

            if (
              yearIndex === 0 &&
              ((yearIndex + 1 === monthIndex && Number(lastKey) > 1) || Number(lastKey) > 3)
            ) {
              dividerPlaceholder = dateFormatDivider;
            }
          } else if (
            lastKey === newValue.substr(-1) && // character was added to end of string
            dateSegments.length < 3 && // less than 2 dividers
            ((monthIndex < 2 && Number(month) > 1 && month === newValue.substr(0 - month.length)) || // month is not last in format, month is at the end of current string and is greater than 1
              (dayIndex < 2 && Number(day) > 3 && day === newValue.substr(0 - day.length)) || // day is not last in format, day is at the end of current string and is greater than 3
              year.length === 4 || // year is longer than 4 characters
              (yearIndex !== 0 && newValue.length === 2) || // format does not start with year and current string is two characters long
              (yearIndex !== 1 && dateSegments[1] && dateSegments[1].length === 2)) // year is not second in format and second segment of current string is two characters
          ) {
            dividerPlaceholder = dateFormatDivider;
          }
        }
      }

      keyRef.current = undefined;

      const updatedInputValue = `${newValue}${dividerPlaceholder}`;

      validateDate(updatedInputValue, debouncedChangeHandler.current);

      return updatedInputValue;
    });
  }

  function handleInputKeyDown(e) {
    const { key, metaKey } = e;

    keyRef.current = !metaKey && validCharacterRegex.test(key) ? key : undefined;

    if (key === 'Tab') {
      onBlur();
    }
  }

  function handleInputBlur(e) {
    if (e.target.value) {
      setCanDisplayError(true);
    }
  }

  return (
    <Tooltip
      disabled={!displayErrorMessage}
      popoverClassName="date-picker-error"
      text={invalidDateMessage}
      title="Invalid Date"
    >
      <div className="date-picker-input-container">
        <input
          className="date-picker-input"
          disabled={disabled}
          name={name}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onClick={onFocus}
          onFocus={onFocus}
          onKeyDown={handleInputKeyDown}
          placeholder={focused ? formatPlaceholder : placeholder}
          readOnly={readOnly}
          style={{ color: displayErrorMessage && !focused ? Colors.red : undefined }}
          type="text"
          value={inputValue}
        />
        {value ? (
          <Icon
            color="darkgray"
            icon="IconX"
            onClick={() => onChange('')}
            size={1.4}
            relativeSize
          />
        ) : (
          <Icon color="darkgray" icon="IconCalendarEvent" onClick={onFocus} size={1} relativeSize />
        )}
      </div>
    </Tooltip>
  );
}

DatePickerInput.propTypes = {
  checkForDisabledDate: PropTypes.func,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
};

export default DatePickerInput;
