import { useQuery } from 'react-query';
import { Functionality, HttpResponse } from '@typings';
import { getData } from '@utilities/xhr';

export function useGetCompanyAppFunctionalities(companyAppId: string | undefined) {
  return useQuery(
    ['companyApp', 'functionalities', companyAppId],
    async () => {
      const response: HttpResponse<Functionality[]> = await getData({
        path: `companies/$companyId/companyApps/${companyAppId}/functionalities`,
      });

      return response.body;
    },
    {
      enabled: Boolean(companyAppId),
      keepPreviousData: true,
    },
  );
}
