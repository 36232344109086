import { createNumberMask } from 'redux-form-input-masks';
import { mgQueryClient } from '@lib/components/AppQueryClient';
import { Company } from '@typings';

export type CurrencyCodes = 'AUD' | 'EUR' | 'GBP' | 'USD';
export type CurencySymbolLookup = {
  [k in CurrencyCodes]: string;
};
const currencySymbolLookup: CurencySymbolLookup = {
  AUD: 'A$',
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export const formatInputCurrencyConfig = (extra = {}) => ({
  allowNegative: true,
  decimalPlaces: 2,
  locale: 'en-US',
  prefix:
    currencySymbolLookup[
      (mgQueryClient.getQueryData('companyData') as Company).internalPreferences
        .chargeCurrency as keyof typeof currencySymbolLookup
    ] || '',
  ...extra,
});

// Formats numbers with decimals into currency as user types
function formatInputCurrency() {
  return createNumberMask(formatInputCurrencyConfig());
}

// Formats numbers with decimals into currency
function formatDecimalCurrency(num: number) {
  return num || Number.isInteger(num)
    ? Number(num).toLocaleString('en-US', {
        style: 'currency',
        currency: (mgQueryClient.getQueryData('companyData') as Company).internalPreferences
          .chargeCurrency as keyof typeof currencySymbolLookup,
      })
    : '';
}

// Formats whole numbers into currency
function formatCurrency(num: number) {
  return num || Number.isInteger(num)
    ? parseInt(num.toString(), 10).toLocaleString('en-US', {
        style: 'currency',
        currency: (mgQueryClient.getQueryData('companyData') as Company).internalPreferences
          .chargeCurrency as keyof typeof currencySymbolLookup,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : '';
}

export default function getCurrencySymbol(
  currencySymbolLookup: CurencySymbolLookup,
  currencyKey: keyof typeof currencySymbolLookup,
) {
  return currencySymbolLookup[currencyKey];
}

export {
  currencySymbolLookup,
  formatInputCurrency,
  formatDecimalCurrency,
  formatCurrency,
  getCurrencySymbol,
};
