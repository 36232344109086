import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { checkForProvider } from '@zylo/orchestra';
import { useQueryParameters } from '@hooks';

const ColumnsStateContext = createContext();
const ColumnsUpdateContext = createContext();

function ColumnsProvider({ children, columnsUrlParam = 'columns' }) {
  const queryParams = useQueryParameters();
  const [columns, setColumns] = useState({
    activeColumns: [],
    freezeColumns: [],
    inactiveColumns: [],
  });
  const [columnsOrder, setColumnsOrder] = useState([]);
  const [defaultColumnsOrder, setDefaultColumnsOrder] = useState();
  const [frozenColumns, setFrozenColumns] = useState([]);
  const [defaultFrozenColumns, setDefaultFrozenColumns] = useState([]);
  const [columnsPool, setColumnsPool] = useState();
  const nonSavedFilterDefaultColumns = useRef();
  const preselectedColumns = useRef();

  useEffect(() => {
    if (columns.activeColumns.length) {
      if (!columnsPool) {
        const columnsLookup = {};

        Object.values(columns)
          .flat()
          .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
          .forEach((column) => {
            columnsLookup[column.columnName] = column;
          });

        setColumnsPool(columnsLookup);
      }

      setColumnsOrder(columns.activeColumns.map(({ columnName }) => columnName));

      setFrozenColumns(
        columns.freezeColumns ? columns.freezeColumns.map(({ columnName }) => columnName) : [],
      );

      if (!nonSavedFilterDefaultColumns.current) {
        nonSavedFilterDefaultColumns.current = columns;
      }
    }
  }, [columns, columnsPool]);

  const updateColumnsOrder = useCallback(
    (newColumnsOrder, newFrozenColumns) => {
      if (!columnsPool) return;

      if (newColumnsOrder) {
        const columnsUpdate = {
          activeColumns: newColumnsOrder
            .map((columnName) => columnsPool[columnName])
            .filter(Boolean),
          freezeColumns: newFrozenColumns
            .map((columnName) => columnsPool[columnName])
            .filter(Boolean),
          inactiveColumns: [],
        };

        Object.entries(columnsPool).forEach(([columnName, column]) => {
          if (!newColumnsOrder.includes(columnName)) {
            columnsUpdate.inactiveColumns.push(column);
          }
        });

        setColumns(columnsUpdate);
      } else {
        setColumns(nonSavedFilterDefaultColumns.current);
      }
    },
    [columnsPool],
  );

  useEffect(() => {
    if (!queryParams) return;

    if (queryParams[columnsUrlParam]) {
      const preselection = JSON.parse(queryParams[columnsUrlParam]);
      const { columnsOrder = [], frozenColumns = [] } = preselection;

      preselectedColumns.current = preselection;
      updateColumnsOrder(columnsOrder, frozenColumns);
    }
  }, [queryParams, columnsUrlParam, updateColumnsOrder]);

  return (
    <ColumnsStateContext.Provider
      value={{
        columns,
        columnsOrder,
        defaultColumnsOrder,
        defaultFrozenColumns,
        frozenColumns,
        hasPreselectedColumns: !!preselectedColumns.current,
      }}
    >
      <ColumnsUpdateContext.Provider
        value={{ setColumns, setDefaultColumnsOrder, setDefaultFrozenColumns, updateColumnsOrder }}
      >
        {children}
      </ColumnsUpdateContext.Provider>
    </ColumnsStateContext.Provider>
  );
}

ColumnsProvider.propTypes = {
  children: PropTypes.node,
};

function useColumnsState() {
  const context = useContext(ColumnsStateContext);

  checkForProvider(context, 'useColumnsState', 'ColumnsProvider');

  return context;
}

function useColumnsUpdate() {
  const context = useContext(ColumnsUpdateContext);

  checkForProvider(context, 'useColumnsUpdate', 'ColumnsProvider');

  return context;
}

export { ColumnsProvider, useColumnsState, useColumnsUpdate };
