import { useState } from 'react';
import styled from 'styled-components/macro';
import { Colors, FontStyles } from '@zylo/orchestra';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { moveToNewList, moveWithinList } from '@utilities/dndUtil';
import { useColumnsState, useColumnsUpdate } from '@contexts/columnsContext';
import DraggableSortField from './DraggableSortField';
import DroppableSortFieldCatagory from './DroppableSortFieldCatagory';
import { ColumnPickerField, ColumnPickerSortCategories, ColumnsState } from './typings/types';

const SortFieldsWapper = styled.div`
  background: ${Colors.white};
  padding: 0 0 1.5rem;
  width: 100%;

  & > div:first-child {
    padding: 1rem 1.5rem;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .sort-fields-title {
    align-items: center;
    background: ${Colors.grey2};
    color: ${Colors.grey9};
    display: flex;
    font-size: ${FontStyles.size['14px']};
    font-weight: ${FontStyles.weight[500]};
    justify-content: space-between;
    padding: 0.3rem 1.5rem;
    width: 100%;

    span {
      font-size: ${FontStyles.size['12px']};
      font-weight: ${FontStyles.weight[400]};
    }
  }

  .sort-fields-scroll-container {
    background: transparent;
  }
`;

export default function SortFields() {
  return <SortFields_DisplayLayer {...useDataLayer()} />;
}

export type SortFieldsDisplayLayerProps = {
  columns: ColumnsState;
  setColumns(updaterFunction: (previousState: ColumnsState) => ColumnsState): void;
  frozenColumns: string[];
};

export type ColumnId = keyof ColumnPickerSortCategories;

export function SortFields_DisplayLayer({
  columns,
  frozenColumns,
  setColumns,
}: SortFieldsDisplayLayerProps) {
  const { activeColumns, freezeColumns } = columns;

  const [fields, setFields] = useState(() => ({
    activeColumns: activeColumns.filter(
      ({ columnName }: ColumnPickerField) => !frozenColumns.includes(columnName),
    ),
    freezeColumns: freezeColumns,
  }));

  function handleDragEnd({ destination, source }: DropResult) {
    if (!destination) return;

    const destinationId = destination.droppableId as ColumnId;
    const sourceId = source.droppableId as ColumnId;
    const destinationIndex = destination.index;
    const sourceIndex = source.index;

    // The item has not changed position
    if (destinationId === sourceId && destinationIndex === sourceIndex) return;

    const fieldsUpdate = { ...fields };

    // Reorder fields
    if (sourceId === destinationId) {
      const newFieldsList = moveWithinList(fields[destinationId], sourceIndex, destinationIndex);

      fieldsUpdate[destinationId] = newFieldsList;

      setFields(fieldsUpdate);

      setColumns((previousState: ColumnsState) => ({
        ...previousState,
        ...fieldsUpdate,
        activeColumns: fieldsUpdate.freezeColumns.concat(fieldsUpdate.activeColumns),
      }));
    } else {
      // More than 3 fields are not allowed in freezeFields
      if (destinationId === 'freezeColumns' && fields.freezeColumns.length >= 3) return;

      // Move from active to freeze, or vice versa.
      const { newSource, newDestination } = moveToNewList(
        fields[sourceId],
        sourceIndex,
        fields[destinationId],
        destinationIndex,
      );

      fieldsUpdate[sourceId] = newSource;
      fieldsUpdate[destinationId] = newDestination;

      setFields(fieldsUpdate);

      setColumns((previousState: ColumnsState) => ({
        ...previousState,
        ...fieldsUpdate,
        activeColumns: fieldsUpdate.freezeColumns.concat(fieldsUpdate.activeColumns),
      }));
    }
  }

  return (
    <SortFieldsWapper>
      <div>
        <p>Drag and drop fields to reorder.</p>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="sort-fields-title">
          Freeze Fields <span>Choose up to 3 table columns to freeze.</span>
        </div>
        <DroppableSortFieldCatagory
          categoryLimit={3}
          id="freezeColumns"
          numberOfItemsCurrentlyInCategory={fields.freezeColumns.length}
        >
          {fields.freezeColumns.map(({ displayName, dataSource, sourceKey }, i) => (
            <DraggableSortField
              category="freezeFields"
              dataSource={dataSource}
              id={sourceKey! || displayName}
              index={i}
              key={sourceKey || displayName}
              name={displayName}
              sourceKey={sourceKey}
            />
          ))}
        </DroppableSortFieldCatagory>

        <div className="sort-fields-title">Active Fields</div>
        <div className="sort-fields-scroll-container">
          <DroppableSortFieldCatagory id="activeColumns">
            {fields.activeColumns.map(({ displayName, dataSource, sourceKey }, i) => (
              <DraggableSortField
                category="active"
                dataSource={dataSource}
                id={sourceKey! || displayName}
                index={i}
                key={sourceKey || displayName}
                name={displayName}
                sourceKey={sourceKey}
              />
            ))}
          </DroppableSortFieldCatagory>
        </div>
      </DragDropContext>
    </SortFieldsWapper>
  );
}

function useDataLayer() {
  const { columns, frozenColumns } = useColumnsState();
  const { setColumns } = useColumnsUpdate();

  return {
    columns,
    frozenColumns,
    setColumns,
  };
}
