import { capitalize } from 'lodash-es';
import { FormValues, validateEmailAddress, validateURL } from '@frameworks/form-fns';
import { BulkEditOperation } from './types';

export function extractApiValueFromFormValue(formValue?: any): string | string[] | '' {
  if (!formValue) {
    return '';
  }

  if (typeof formValue !== 'string') {
    // Multi select array values (select options)
    if (Array.isArray(formValue)) {
      if (formValue.length > 0) {
        return formValue.map(({ value }: { label: string; value: string }) => value);
      }
      return '';
    }

    // Single select values (select option)
    if (isFormValueBlank(formValue)) {
      return 'NULL';
    }

    return formValue.value;
  }

  // String value
  return formValue;
}

export function isFormValueBlank(formValue: any) {
  if (typeof formValue !== 'string') {
    return !!formValue && (formValue.value === 'NULL' || formValue.value === 'blank');
  }

  return !formValue;
}

export function extractDisplayValueFromFormValue(formValue?: any): string | string[] {
  if (formValue === undefined) {
    return '';
  }
  if (typeof formValue !== 'string') {
    if (Array.isArray(formValue)) {
      if (formValue.length > 0) {
        return formValue.map(({ label }: { label: string; value: string }) => label);
      }
    } else if (isFormValueBlank(formValue)) {
      return 'Blank';
    } else {
      return formValue.label;
    }
  }

  return validateEmailAddress(formValue as string) && validateURL(formValue as string)
    ? capitalize(formValue as string)
    : formValue;
}

export function getMatchingOperationValue(
  fieldKey: string,
  allFormValues: FormValues,
): BulkEditOperation | undefined {
  const fieldMatchingOperationName = `${fieldKey}ZyloOperation`;
  const operationValue = extractApiValueFromFormValue(allFormValues[fieldMatchingOperationName]);
  return operationValue !== '' ? (operationValue as BulkEditOperation) : undefined;
}

export function formValueHasChange(
  fieldKey: string,
  formValue: any, // TODO: Fix this with the TODO in FormValues in the /types file
  allFormValues: FormValues,
): boolean {
  const apiFormValue = extractApiValueFromFormValue(formValue);
  const operationValue = getMatchingOperationValue(fieldKey, allFormValues);
  return (
    // The field has a mini-select operation selected and is not blank
    (operationValue &&
      (operationValue === 'remove' || (operationValue !== 'replace' && apiFormValue !== ''))) ||
    // The field is a 'simple' field without a mini-select and is not blank
    (formValue !== '' && formValue !== null)
  );
}
