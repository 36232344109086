import { useCallback, useState } from 'react';
import { debounce } from 'lodash-es';

/**
 * A generic hook for tracking a state with a debounce delay before the update.
 * This is useful for inputs that will call an API after user input, like a search box, to
 * prevent frequent calls before the user has finished typing.
 * @type StateType Typescript type of the state being stored. Defaults to <string | undefined>
 * @param defaultValue Default value
 * @param debounceTime Amount of time to wait for further changes before state is updated. Defaults to 500ms
 * @returns [currentValue, debouncedSetValue] Matches the return type of `setState`, but with the setter function having a debounce timer.
 */
export default function useDebouncedState<StateType = string | undefined>(
  defaultValue: StateType,
  debounceTime: number = 500,
): [StateType, (value: StateType) => void] {
  const [currentValue, setCurrentValue] = useState<StateType>(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetValue = useCallback(
    debounce((val: StateType) => setCurrentValue(val), debounceTime),
    [debounceTime, setCurrentValue],
  );
  return [currentValue, debouncedSetValue];
}
