import { useMutation } from 'react-query';
import { useZAPUpdate } from '@frameworks/zap';
import { patchData } from '@utilities/xhr';
import { useAppMessaging } from '@hooks';
import { BulkEditApiPatchField } from './types';

export type BulkEditPatchParams = {
  fields: BulkEditApiPatchField[];
  rows: {
    selectAll: boolean;
    deselectedRows: any[];
    selectedRows: any[];
  };
  filters: any;
  search: string;
};

function spreadRowEditData(rows?: any[]) {
  if (!rows) {
    return rows;
  }

  return rows.map((row) => row?._metadata?.edit || { id: row?.id });
}

function normalizeParams(params: BulkEditPatchParams) {
  return {
    ...params,
    rows: {
      selectedRows: spreadRowEditData(params.rows.selectedRows),
      deselectedRows: spreadRowEditData(params.rows.deselectedRows),
      selectAll: params.rows.selectAll,
    },
  };
}

export function useZAPBulkEditSubmit(updateEndpoint: string) {
  const showMessage = useAppMessaging();
  const { invalidateQueries } = useZAPUpdate();

  return useMutation(
    async (params: BulkEditPatchParams) => {
      return await patchData({
        path: updateEndpoint,
        params: normalizeParams(params),
      });
    },
    {
      onSuccess: async ({ body }) => {
        invalidateQueries();
        showMessage({ message: body.message });
      },
      onError: () =>
        showMessage({
          message: 'There was an error updating your subscriptions. Please try again.',
          isError: true,
        }),
    },
  );
}
