import CryptoJS from 'crypto-js';
import launchDarkly from 'ldclient-js';
import { createAction, handleActions } from 'redux-actions';
import { wrapAsync, handleNetworkError } from '@utilities/util';
import { LAUNCH_DARKLY_CLIENT_ID } from '@src/config/config';
import { mgQueryClient } from '@lib/components/AppQueryClient';
import { actions as integrationActions } from './integrations';

const cacheKey = 'FF';
const CRYPTO_KEY = 'C&F)J@NcRfTjWnZr';

const sessionFlags = sessionStorage.getItem(cacheKey);

const TEMPLATE = {
  companySlug: null,
  flags: null,
  haveLoaded: false,
  launchDarklyClient: null,
  zyloAuthId: null,
};

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_COMPANY_FEATURE_FLAGS_SUCCESS = 'FETCH_COMPANY_FEATURE_FLAGS_SUCCESS';
export const INITIALIZE_LAUNCH_DARKLY_CLIENT_SUCCESS = 'INITIALIZE_LAUNCH_DARKLY_CLIENT_SUCCESS';
export const UPDATE_DEVELOPMENT_FEATURE_FLAGS_SUCCESS = 'UPDATE_DEVELOPMENT_FEATURE_FLAGS_SUCCESS';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCompanyFeatureFlagsSuccess = createAction(FETCH_COMPANY_FEATURE_FLAGS_SUCCESS);
export const initializeLaunchDarklyClientSuccess = createAction(
  INITIALIZE_LAUNCH_DARKLY_CLIENT_SUCCESS,
);
export const updateDeveloperFeatureFlagsSuccess = createAction(
  UPDATE_DEVELOPMENT_FEATURE_FLAGS_SUCCESS,
);

async function fetchCompanyFeatureFlags(_, dispatch, getState) {
  const { launchDarklyClient } = getState().featureFlags;

  launchDarklyClient.on('ready', () => {
    dispatch(fetchCompanyFeatureFlagsSuccess(launchDarklyClient.allFlags()));
    dispatch(integrationActions.mapIntegrationDataForCompany());

    const { ENABLE_ALL_FEATURE_FLAGS, DISABLE_FEATURE_FLAGS } = process.env;

    if (process.env.NODE_ENV === 'development') {
      const flagsToDisableLocally = DISABLE_FEATURE_FLAGS ? DISABLE_FEATURE_FLAGS.split(';') : [];
      const { flags } = getState().featureFlags;
      const allFlags = Object.keys(flags);
      const newFlags = {};

      allFlags.forEach((flag) => {
        if (flagsToDisableLocally.includes(flag)) {
          newFlags[flag] = false;
        } else if (ENABLE_ALL_FEATURE_FLAGS === 'true' && typeof flags[flag] === 'boolean') {
          newFlags[flag] = true;
        } else {
          newFlags[flag] = flags[flag];
        }
      });

      dispatch(updateDeveloperFeatureFlagsSuccess(newFlags));
    }
  });
}

async function initializeLaunchDarklyClient(params, dispatch, getState) {
  const { auth, featureFlags } = getState();
  const zyloAuthId = auth.zyloAuthId || params.zyloAuthId;
  const zyloUserAccountId = auth.zyloUserAccountId || params.zyloUserAccountId;
  const { companySlug, internalPreferences = {} } =
    mgQueryClient.getQueryData(['companyData']) || params;
  const { companyAccountType, isPoc } = internalPreferences;
  let result;

  if (
    LAUNCH_DARKLY_CLIENT_ID &&
    zyloAuthId &&
    companySlug &&
    zyloAuthId !== featureFlags.zyloAuthId &&
    companySlug !== featureFlags.companySlug &&
    !window.Cypress
  ) {
    try {
      result = await launchDarkly.initialize(LAUNCH_DARKLY_CLIENT_ID, {
        key: zyloAuthId,
        custom: {
          companySlug,
          isZyloTeamMember: auth.email.includes('@zylo.com'),
          poc: isPoc,
          type: companyAccountType,
          zuaId: zyloUserAccountId,
        },
      });
    } catch (e) {
      handleNetworkError('initializeLaunchDarklyClient', e, dispatch, null, {
        zyloAuthId,
        companySlug,
        poc: isPoc,
        type: companyAccountType,
      });
      return;
    }

    dispatch(
      initializeLaunchDarklyClientSuccess({
        zyloAuthId,
        zyloUserAccountId,
        companySlug,
        launchDarklyClient: result,
      }),
    );
    fetchCompanyFeatureFlags({}, dispatch, getState);
  }
}

export const actions = {
  initializeLaunchDarklyClient: wrapAsync(initializeLaunchDarklyClient),
};
// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [INITIALIZE_LAUNCH_DARKLY_CLIENT_SUCCESS]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [FETCH_COMPANY_FEATURE_FLAGS_SUCCESS]: (state, { payload }) => {
      sessionStorage.setItem(cacheKey, CryptoJS.AES.encrypt(JSON.stringify(payload), CRYPTO_KEY));

      return {
        ...state,
        haveLoaded: true,
        flags: { ...payload },
      };
    },
    [UPDATE_DEVELOPMENT_FEATURE_FLAGS_SUCCESS]: (state, { payload }) => {
      sessionStorage.setItem(cacheKey, CryptoJS.AES.encrypt(JSON.stringify(payload), CRYPTO_KEY));
      return { ...state, flags: payload };
    },
  },
  {
    ...TEMPLATE,
    flags: sessionFlags
      ? JSON.parse(CryptoJS.AES.decrypt(sessionFlags, CRYPTO_KEY).toString(CryptoJS.enc.Utf8))
      : null,
  },
);
