import { useEffect } from 'react';

/**
 * sets page title on mount
 * @param title - title of the page
 */
export function usePageTitle(title: string) {
  useEffect(() => {
    document.title = `Zylo | ${title}`;
  }, [title]);
}
