import { useState } from 'react';
import { Icon } from '@zylo/orchestra';
import { useColumnsState, useColumnsUpdate } from '../../contexts/columnsContext';
import SearchBar from '../forms/SearchBar';
import ChooseFieldsCategoryFields from './ChooseFieldsCategoryFields';
import ChooseFieldsCategoryTitle from './ChooseFieldsCategoryTitle';
import { ColumnPickerField, ColumnPickerSourcesObject, ColumnsState } from './typings/types';
import { ChooseFieldsStyles } from './ChooseFields';

export default function AppUsersChooseFields() {
  return <AppUsersChooseFields_DisplayLayer {...useDataLayer()} />;
}

type AppUsersFieldUpdateKeys = ['direct' | 'primary', 'metric' | 'user'];

export type AppUsersChooseFieldsDisplayLayerProps = {
  columns: ColumnsState;
  frozenColumns: string[];
  setColumns(columns: ColumnsState): void;
};

export function AppUsersChooseFields_DisplayLayer({
  columns,
  frozenColumns,
  setColumns,
}: AppUsersChooseFieldsDisplayLayerProps) {
  const [fields, setFields] = useState(() => buildFieldsSourcesObject(columns, frozenColumns));
  const [searchValue, setSearchValue] = useState('');

  function handleClearSearch() {
    setFields(buildFieldsSourcesObject(columns, frozenColumns));
  }

  function handleSearch(searchTerm: string) {
    const { activeColumns, freezeColumns, inactiveColumns = [] } = columns;

    setSearchValue(searchTerm);

    setFields(
      buildFieldsSourcesObject(
        {
          freezeColumns,
          activeColumns: activeColumns.filter(({ displayName }: ColumnPickerField) =>
            displayName.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
          inactiveColumns: inactiveColumns.filter(({ displayName }: ColumnPickerField) =>
            displayName.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        },
        frozenColumns,
      ),
    );
  }

  function handleChooseField(fieldUpdateKeys: AppUsersFieldUpdateKeys, index: number) {
    const { activeColumns, freezeColumns, inactiveColumns = [] } = columns;
    const [source, list] = fieldUpdateKeys;
    const field = fields[source][list][index];
    const updatedField = { ...field, isFrozen: false, isInactive: !field.isInactive };

    setFields((previousState) => {
      previousState[source][list].splice(index, 1, updatedField);
      return previousState;
    });

    if (field.isInactive) {
      const columnsUpdate = {
        freezeColumns,
        activeColumns: [...activeColumns, updatedField],
        inactiveColumns: inactiveColumns.filter(
          (column: ColumnPickerField) =>
            !(field.displayName === column.displayName && field.dataSource === column.dataSource),
        ),
      };

      setColumns(columnsUpdate);
    }

    if (!field.isInactive) {
      const columnsUpdate = {
        activeColumns: activeColumns.filter(
          (column: ColumnPickerField) =>
            !(field.displayName === column.displayName && field.dataSource === column.dataSource),
        ),
        freezeColumns: freezeColumns.filter(
          (column: ColumnPickerField) =>
            !(field.displayName === column.displayName && field.dataSource === column.dataSource),
        ),
        inactiveColumns: [...inactiveColumns, updatedField],
      };

      setColumns(columnsUpdate);
    }
  }

  const { direct, primary } = fields;

  const directSourceTitle = direct.metric[0]?.title ?? direct.user[0]?.title ?? '';

  const primarySourceTitle = primary.metric[0]?.title ?? primary.user[0]?.title ?? '';

  return (
    <ChooseFieldsStyles>
      <div className="choose-fields-search-bar">
        <SearchBar
          clearSearch={handleClearSearch}
          handleSearch={handleSearch}
          placeholder="Search Fields"
          searchValue={searchValue}
        />
      </div>

      <div className="choose-fields-scroll-container">
        {primary.user.length > 0 || primary.metric.length > 0 ? (
          <>
            <ChooseFieldsCategoryTitle
              category={primarySourceTitle}
              icon="IconUser"
              testId="app-users-fields-user"
              tooltipText={
                <>
                  User properties from the selected Primary User Store.
                  <div style={{ marginTop: '12px' }}>
                    <a
                      href="https://zylo.zendesk.com/hc/en-us/articles/360061240531-Primary-User-Store"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Learn more. <Icon icon="IconExternalLink" size={14} />
                    </a>
                  </div>
                </>
              }
            />

            <ChooseFieldsCategoryFields
              fields={primary.user}
              onChooseFields={handleChooseField}
              subcategory="User Properties"
            />

            <ChooseFieldsCategoryFields
              fields={primary.metric}
              onChooseFields={handleChooseField}
              subcategory="Metrics"
            />
          </>
        ) : null}

        {direct.user.length > 0 || direct.metric.length > 0 ? (
          <>
            <ChooseFieldsCategoryTitle
              category={directSourceTitle}
              icon="IconJumpRope"
              testId="app-users-fields-direct-integration"
              tooltipText="User properties and activity metrics from the direct utilization or SSO integration."
            />
            <ChooseFieldsCategoryFields
              fields={direct.user}
              onChooseFields={handleChooseField}
              subcategory="User Properties"
            />
            <ChooseFieldsCategoryFields
              fields={direct.metric}
              onChooseFields={handleChooseField}
              subcategory="Metrics"
            />
          </>
        ) : null}
      </div>
    </ChooseFieldsStyles>
  );
}

function useDataLayer() {
  const { columns, frozenColumns } = useColumnsState();
  const { setColumns } = useColumnsUpdate();

  return {
    columns,
    frozenColumns,
    setColumns,
  };
}

function buildFieldsSourcesObject(
  { activeColumns, inactiveColumns = [] }: ColumnsState,
  frozenColumns: string[],
): ColumnPickerSourcesObject {
  return [
    ...activeColumns.map((column) => ({ ...column, isInactive: false })),
    ...inactiveColumns.map((column) => ({ ...column, isInactive: true })),
  ]
    .map((column: ColumnPickerField) =>
      frozenColumns.includes(column.columnName)
        ? { ...column, isFrozen: true }
        : { ...column, isFrozen: false },
    )
    .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
    .reduce(handleBuildFieldsSourceObject, {
      direct: { metric: [], user: [] },
      primary: { metric: [], user: [] },
    });
}

function handleBuildFieldsSourceObject(
  previousValue: ColumnPickerSourcesObject,
  currentValue: ColumnPickerField,
) {
  const sourceKey = currentValue.sourceKey!.replace(/(\.|_)\w+/, '') as 'direct' | 'primary';

  return {
    ...previousValue,
    [sourceKey]: {
      ...previousValue[sourceKey],
      [currentValue.dataType]: [
        ...previousValue[sourceKey][currentValue.dataType as 'metric' | 'user'],
        { ...currentValue, fieldUpdateKeys: [sourceKey, currentValue.dataType] },
      ],
    },
  };
}
