import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';
import classNames from 'clsx';
import { Match, Router } from '@reach/router';
import PageNotFound from '@pages/PageNotFound';
import TopBar from './TopBar';
import TabRoutesLabel from './TabRoutesLabel';

function TabsRoutes({
  children,
  className = '',
  contentClass,
  isDetails = false,
  leftContent,
  onTabClick,
  rightContent,
  routeBase,
  showLabels = true,
}) {
  // remove null/falsy tabs (e.g. feature-flag excluded tabs)
  const sanitized = Children.toArray(children).filter((t) => !!t);
  const tabLabels = (
    <ul className="tabs-labels">
      {sanitized.map(({ props }, index) => {
        const { tabId, tabLabel, isUpsellEnabled } = props;

        return (
          <TabRoutesLabel
            isHomeTab={index === 0}
            isUpsellEnabled={isUpsellEnabled}
            key={tabId}
            label={tabLabel}
            onTabClick={onTabClick}
            routeBase={routeBase}
            tabId={tabId}
            to={tabId}
          />
        );
      })}
    </ul>
  );

  return (
    <div
      className={classNames(
        `tabs flex-container vertical flex-1`,
        isDetails ? 'details-tabs' : 'standard-tabs',
        className,
      )}
    >
      {leftContent || showLabels || rightContent ? (
        <TopBar
          centerContent={showLabels && !isDetails ? tabLabels : null}
          leftContent={isDetails ? tabLabels : leftContent}
          rightContent={rightContent}
        />
      ) : null}
      <div className="tabs-content-wrapper">
        <div className={`tabs-content ${contentClass || ''}`}>
          <Match path=":tabRoute/*">
            {({ match }) => {
              if (match) {
                return (
                  <Router>
                    {sanitized.map((r) => {
                      return cloneElement(r, {
                        path: `${r.props.tabId}/*`,
                      });
                    })}
                    <PageNotFound default />
                  </Router>
                );
              }

              return sanitized[0];
            }}
          </Match>
        </div>
      </div>
    </div>
  );
}

TabsRoutes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  isDetails: PropTypes.bool,
  leftContent: PropTypes.node,
  onTabClick: PropTypes.func,
  rightContent: PropTypes.node,
  routeBase: PropTypes.string.isRequired,
  showLabels: PropTypes.bool,
};

export default TabsRoutes;
