import COUNTRIES from './countries.json';
import CURRENCIES from './currencies.json';
import { FILTER_TYPES } from './filterUtils/filterConstants';

export { COUNTRIES, CURRENCIES };

export const METADATA_TABLE_CELLS = {
  ...FILTER_TYPES,
};

// Industry values (restricted) for companies
export const COMPANY_INDUSTRIES = [
  'Agriculture',
  'Apparel',
  'Banking',
  'Biotechnology',
  'Chemicals',
  'Communications',
  'Construction',
  'Consulting',
  'Education',
  'Electronics',
  'Energy',
  'Engineering',
  'Entertainment',
  'Environmental',
  'Finance',
  'Food & Beverage',
  'Government',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Machinery',
  'Manufacturing',
  'Media',
  'Not For Profit',
  'Other',
  'Recreation',
  'Retail',
  'Shipping',
  'Technology',
  'Telecommunications',
  'Transportation',
  'Utilities',
];

// Company sizes values in ranges.
export const COMPANY_SIZES = [
  '0 - 100',
  '101 - 500',
  '501 - 1000',
  '1001 - 2500',
  '2501 - 5000',
  '5001 - 10000',
  '10000+',
];

export const CONTRACT_FIELDS = [
  'billFrequency',
  'cancellationPolicy',
  'contractNotificationLength',
  'monthlyAmount',
  'paymentMethod',
  'purchasedLicenseCount',
  'renewalDate',
  'remitToAddress',
  'startDate',
  'subscriptionType',
  'totalContractValue',
  'yearByYearContractValue',
  'yearToDateSpend',
  'contractTermSpend',
];

export const CUSTOM_FIELDS_TYPES = {
  currency: 'Currency',
  date: 'Date',
  choice: 'Dropdown',
  number: 'Number',
  text: 'Text',
  boolean: 'Yes / No',
};

export const STANDARD_FIELD_LABELS = {
  accountNumber: 'Account Number',
  activityThreshold: 'Activity Threshold',
  appLabel: 'Label',
  appName: 'Name',
  billFrequency: 'Billing Frequency',
  businessGoals: 'Business Goals',
  businessOwnerUserInternalPreferenceId: 'Business Owner',
  businessUnit: 'Business Unit',
  cancellationPolicy: 'Cancellation Policy',
  contractNotificationLength: 'Cancellation Notification Length',
  costCenterBreakdown: 'Cost Centers',
  createdAt: 'Created Date',
  holdsPii: 'Holds PII',
  ssoEnforced: 'SSO Enforced',
  itOwnerUserInternalPreferenceId: 'IT Owner',
  itSupported: 'IT Supported',
  launchUrl: 'URL',
  lastTransactionDate: 'Last Transaction Date',
  monthlyAmount: 'Monthly Amount',
  nextAction: 'Next Action',
  appOwnerUserInternalPreferenceId: 'Application Owner',
  paymentMethod: 'Payment Method',
  purchasedLicenseCount: 'Licenses Purchased',
  remitToAddress: 'Remit To Address',
  renewalDate: 'Contract End Date',
  signOnMode: 'Sign On Mode',
  source: 'Sources',
  startDate: 'Contract Start Date',
  subscriptionType: 'Subscription Type',
  tags: 'Tags',
  totalContractValue: 'Total Contract Value',
  trueUp: 'True Up',
  yearByYearContractValue: 'Year 1 Contract Value',
  supplierName: 'Supplier Name',
  chargeCount: 'Number of Transactions',
  percentActiveLicenses: 'Percent Active Licenses',
  appOwnerUserEmail: 'App Owner',
  itOwnerUserEmail: 'IT Owner',
  businessOwnerUserEmail: 'Business Owner',
  totalCostCentersCount: 'Number of Cost Centers',
  primaryCostCenter: 'Cost Center',
  isCloud: 'Cloud',
  lastExpenseTransactionDate: 'Last Expense Transaction Date',
  lastApTransactionDate: 'Last AP Transaction Date',
  subcategory: 'Subcategory',
  category: 'Category',
  utilization: 'Active Users',
  userCount: 'Users',
  usersExpensingCount: 'Number of Users Expensing',
  expenseSpend: 'Expense Spend',
  apSpend: 'AP Spend',
  spend: 'Annual Spend',
  uniqueUserCount: 'Unique Users',
  uniqueActiveUserCount: 'Unique Active Users',
  poCount: 'PO Count',
  activePoCount: 'Active PO Count',
  totalApprovedPo: 'Total Approved PO',
  totalPoSoftwareSpend: 'Total PO Software Spend',
  isSaml: 'SAML Capable',
  isDirectIntegration: 'Available Direct Integration',
  isPriceBenchmarks: 'Available Price Benchmark',
};

export const ZMF_APP_IDS = {
  docusignAppId: '3300829b-805f-49b7-9517-e4e236c55f6d',
  githubAppId: '31c91384-c94f-4e0d-882a-4235f75be279',
  gSuiteAppId: '51926a78-d6f0-4984-ad15-e6f1344f8cf7',
  informaticaCloudAppId: '98630807-605b-43bf-a4e4-b572814c72b7',
  microsoft365AppId: '30a65aae-5a03-4ef8-acc6-b91e3f62c94f',
  microsoftDynamicsCRMAppId: '49948873-a553-4e62-9c69-9238d12da849',
  oktaAppId: '52106edc-ea8f-48c8-837c-4029207df9b7',
  salesforceCRMAppId: '1902d945-3d52-452a-85a5-edf861aacefc',
  trelloAppId: '03bb6f36-e924-4696-9d9f-9de32b5671e9',
  veevaCRMAppId: '330f67aa-7b52-402f-a64b-211ce4517dd1',
  veevaVaultAppId: '4be378b0-9d8b-4bfd-a8cb-54ff6baa0e4f',
  zoomAppId: '4706c862-d161-45b8-9caf-7f5790d2e139',
  zyloAppId: '5ea2b309-f471-4ed5-9646-368710aaa713',
};

// NOTE: keep up to date with waterworks/api/helpers/constants.js#PO_MATCH_METHODS
export const PO_MATCH_METHODS = {
  MANUAL_ASSIGN: 'manual_assign',
  MANUAL_UNASSIGN: 'manual_unassign',
};
