import { createContext, useContext, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { checkForProvider } from '@zylo/orchestra';
import { useColumnsState } from '../columnsContext';
import { useFiltersState } from '../filtersContext';
import { useSortState } from '../sortContext';
import { useDefaultTableViewState } from './defaultTableViewContext';
import { useActiveSavedFilterState } from './activeSavedFilterContext';

const SavedFilterModificationStateContext = createContext();
const SavedFilterModificationUpdateContext = createContext();

function SavedFilterModificationProvider({ children, savedFilterStateRef }) {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { columnsOrder, frozenColumns } = useColumnsState();
  const { defaultView } = useDefaultTableViewState();
  const { selectedFilters, apiRequestFilters } = useFiltersState();
  const { currentSort } = useSortState();
  const [activeSavedFilterComparison, setActiveSavedFilterComparison] = useState({
    id: null,
    isInsight: false,
    comparisonString: '',
  });
  const [currentConfiguration, setCurrentConfiguration] = useState({
    obj: {},
    comparisonString: '',
  });
  const [defaultViewHasBeenModified, setDefaultViewHasBeenModified] = useState(false);
  const [savedFilterHasBeenModified, setSavedFilterHasBeenModified] = useState(false);

  useImperativeHandle(
    savedFilterStateRef,
    () => ({
      apiRequestFilters,
      currentConfiguration: currentConfiguration.obj,
      savedFilterHasBeenModified,
    }),
    [apiRequestFilters, currentConfiguration.obj, savedFilterHasBeenModified],
  );

  // observe activeSavedFilter change
  useEffect(() => {
    setActiveSavedFilterComparison(
      activeSavedFilter
        ? {
            id: activeSavedFilter.id,
            isInsight: activeSavedFilter.isInsight,
            comparisonString: `${activeSavedFilter.columnsOrder},${JSON.stringify(
              activeSavedFilter.filters,
            )},${activeSavedFilter.frozenColumns},${activeSavedFilter.tableSort}`,
          }
        : { id: null, isInsight: false, comparisonString: '' },
    );
    setDefaultViewHasBeenModified(false);
    setSavedFilterHasBeenModified(false);
  }, [
    activeSavedFilter,
    setActiveSavedFilterComparison,
    setDefaultViewHasBeenModified,
    setSavedFilterHasBeenModified,
  ]);

  // observe columnsOrder and/or frozenColumns change
  useEffect(() => {
    if (!columnsOrder) return;

    setCurrentConfiguration(({ obj }) => ({
      obj: {
        ...obj,
        columnsOrder,
        frozenColumns,
      },
      comparisonString: `${columnsOrder},${obj.filters},${frozenColumns},${obj.tableSort}`,
    }));
  }, [columnsOrder, frozenColumns]);

  // observe filters change
  useEffect(() => {
    const stringifiedFilters = JSON.stringify(selectedFilters);

    setCurrentConfiguration(({ obj }) => ({
      obj: {
        ...obj,
        filters: stringifiedFilters,
      },
      comparisonString: `${obj.columnsOrder},${stringifiedFilters},${obj.frozenColumns},${obj.tableSort}`,
    }));
  }, [selectedFilters]);

  // observe sort change
  useEffect(() => {
    setCurrentConfiguration(({ obj }) => ({
      obj: {
        ...obj,
        tableSort: currentSort,
      },
      comparisonString: `${obj.columnsOrder},${obj.filters},${obj.frozenColumns},${currentSort}`,
    }));
  }, [currentSort]);

  const checkForModification = useEffect(() => {
    if (defaultView) {
      let defaultModified = false;

      if (
        // default is a saved filter but doesn't match current active saved filter or vice versa
        JSON.parse(defaultView.obj).id !== activeSavedFilterComparison.id ||
        // current config doesn't match default config
        defaultView.stringified !== currentConfiguration.comparisonString
      ) {
        defaultModified = true;
      }

      setDefaultViewHasBeenModified(defaultModified);

      if (activeSavedFilterComparison.comparisonString && !activeSavedFilterComparison.isInsight) {
        setSavedFilterHasBeenModified(
          activeSavedFilterComparison.comparisonString !== currentConfiguration.comparisonString,
        );
      }
    }
  }, [activeSavedFilterComparison, currentConfiguration, defaultView]);

  return (
    <SavedFilterModificationStateContext.Provider
      value={{
        currentConfiguration: currentConfiguration.obj,
        defaultViewHasBeenModified,
        savedFilterHasBeenModified,
      }}
    >
      <SavedFilterModificationUpdateContext.Provider
        value={{
          checkForModification,
          setDefaultViewHasBeenModified,
          setActiveSavedFilterComparison,
          setSavedFilterHasBeenModified,
        }}
      >
        {children}
      </SavedFilterModificationUpdateContext.Provider>
    </SavedFilterModificationStateContext.Provider>
  );
}

SavedFilterModificationProvider.propTypes = {
  children: PropTypes.node,
};

function useSavedFilterModificationState() {
  const context = useContext(SavedFilterModificationStateContext);

  checkForProvider(context, 'useSavedFilterModificationState', 'SavedFilterModificationProvider');

  return context;
}

function useSavedFilterModificationUpdate() {
  const context = useContext(SavedFilterModificationUpdateContext);

  checkForProvider(context, 'useSavedFilterModificationUpdate', 'SavedFilterModificationProvider');

  return context;
}

export {
  SavedFilterModificationProvider,
  useSavedFilterModificationState,
  useSavedFilterModificationUpdate,
};
