import PropTypes from 'prop-types';
import { Icon } from '@zylo/orchestra';
import { reduxObject } from '@utilities/propTypes';

export function MetadataCell({ callback, data = {}, columnIndex, setScrollToColumn }) {
  const handleClick = () => {
    callback(data);
    if (setScrollToColumn) setScrollToColumn(columnIndex);
  };

  return (
    <div className="metadata-cell">
      {Object.keys(data).length > 0 ? (
        <Icon color="blue" icon="IconInfoCircle" onClick={handleClick} size={1.25} relativeSize />
      ) : (
        '-'
      )}
    </div>
  );
}

MetadataCell.propTypes = {
  callback: PropTypes.func.isRequired,
  columnIndex: PropTypes.number,
  data: reduxObject,
  setScrollToColumn: PropTypes.func,
};
