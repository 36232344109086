import styled from 'styled-components/macro';
import { Borders, Colors, defaultborder, FontStyles, ZyloButton } from '@zylo/orchestra';
import { ReactNode } from 'react';
import SavedFiltersButton from '@components/savedFilters/SavedFiltersButton';
import SmartSearchBar from '@components/search/SmartSearchBar';
import SavedFiltersDisplay from '@components/savedFilters/SavedFiltersDisplay';

const PageControlsContainer = styled.div<{ noTopLeftContent: boolean }>`
  .top-content {
    display: flex;
    justify-content: ${({ noTopLeftContent }) => (noTopLeftContent ? 'flex-end' : 'space-between')};
    align-items: center;
    padding: 15px;
    border-radius: ${Borders.radius.large};
    background-color: ${Colors.white};
    margin-bottom: 10px;
    margin-top: 10px;
    border: ${defaultborder};

    .top-left-content {
      display: flex;
    }

    .top-right-content {
      display: flex;
      align-items: center;
    }
    .pre-search-copy {
      font-weight: ${FontStyles.weight[400]};
      font-size: ${FontStyles.size['14px']};
      margin: 0 0.75rem;
    }

    .pre-search-tooltip,
    .search-filter-container {
      margin-right: 0.5rem;
    }
  }

  .show-saved-filter-name {
    padding-top: 0.5rem;
  }
`;

const PrimaryButtonContainer = styled.div`
  margin-left: 0.5rem;
`;

export type PageControlsProps = {
  chartClickHandler?: () => void;
  customSearchBar?: React.ReactNode;
  eventName?: string;
  frozenKeys?: string[];
  filtersMenu?: ReactNode;
  includeChartBtn?: boolean;
  includeFilters?: boolean;
  includeSearchBar?: boolean;
  includeSavedFilters?: boolean;
  preSearchCopy?: ReactNode;
  preSearchTooltip?: ReactNode;
  primaryBtn?: ReactNode;
  showChart?: boolean;
};

export default function PageControls({
  chartClickHandler = () => {},
  customSearchBar,
  eventName,
  filtersMenu,
  frozenKeys,
  includeChartBtn = false,
  includeFilters = false,
  includeSavedFilters = true,
  includeSearchBar = false,
  preSearchCopy,
  preSearchTooltip,
  primaryBtn,
  showChart,
}: PageControlsProps) {
  const noTopLeftContent = !includeSearchBar && !includeFilters && !includeChartBtn;

  return (
    <PageControlsContainer noTopLeftContent={noTopLeftContent}>
      <div className="top-content">
        {!noTopLeftContent ? (
          <div className="top-left-content">
            {customSearchBar ? (
              customSearchBar
            ) : includeSearchBar ? (
              <SmartSearchBar searchEventName={eventName ? `${eventName}_SEARCH` : undefined} />
            ) : null}
            {includeFilters && (
              <>
                {!!filtersMenu && filtersMenu}
                {includeSavedFilters && (
                  <SavedFiltersButton
                    savedFiltersApplyEventName={
                      eventName ? `${eventName}_SAVED_FILTERS_APPLY` : undefined
                    }
                    savedFiltersClickEventName={
                      eventName ? `${eventName}_SAVED_FILTERS_CLICK` : undefined
                    }
                  />
                )}
              </>
            )}
            {includeChartBtn && (
              <ZyloButton
                collapseMediaQueryWidth={1100}
                icon="IconChartBar"
                onClick={chartClickHandler}
                variant="outlined"
              >
                {showChart ? 'Hide' : 'View'} Chart
              </ZyloButton>
            )}
          </div>
        ) : null}
        <div className="top-right-content">
          {preSearchCopy && (
            <div className="pre-search-copy" data-testid="pre-search-copy">
              {preSearchCopy}
            </div>
          )}
          {preSearchTooltip && (
            <div className="pre-search-tooltip" data-testid="pre-search-tooltip">
              {preSearchTooltip}
            </div>
          )}
          {primaryBtn && <PrimaryButtonContainer>{primaryBtn}</PrimaryButtonContainer>}
        </div>
      </div>
      {includeFilters && (
        <SavedFiltersDisplay frozenKeys={frozenKeys} includeSavedFilters={includeSavedFilters} />
      )}
    </PageControlsContainer>
  );
}
