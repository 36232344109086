import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { reduxObject } from '@utilities/propTypes';

class UserCellComponent extends PureComponent {
  static propTypes = {
    authCanAccessUsers: PropTypes.bool.isRequired,
    data: PropTypes.string,
    idProp: PropTypes.string,
    rowData: reduxObject,
  };

  handleClick = (e) => {
    const { data, rowData, idProp } = this.props;
    const id = rowData[idProp];

    if (data && id) {
      e.stopPropagation();
    }
  };

  render() {
    const { data, rowData, idProp, authCanAccessUsers } = this.props;
    const id = rowData[idProp];

    return (
      <div className={`${id && authCanAccessUsers ? 'highlight-cell' : 'color-black'}`}>
        <Link
          className="disable-highlight-hover"
          onClick={authCanAccessUsers ? this.handleClick : null}
          to={id ? `/employees/${id}` : ''}
        >
          {data || '-'}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    authCanAccessUsers: get(auth.permissions, 'users.canAccess'),
  };
};

export const UserCell = connect(mapStateToProps, null)(UserCellComponent);
