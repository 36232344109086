import { useEffect, useRef } from 'react';
import { useEventTracking } from '@hooks';

export function useCalculateQuickViewDuration(
  action: string,
  additionalData?: Record<string, any>,
) {
  const startRef = useRef(Date.now());
  const sendEvent = useEventTracking();

  useEffect(() => {
    const start = startRef;

    return () => {
      sendEvent({
        eventName: action,
        data: {
          'duration open': `${(Date.now() - start.current) / 1000} seconds`,
          ...additionalData,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, sendEvent]);
}
