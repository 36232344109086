import { capitalize } from 'lodash-es';
import moment from 'moment';
import { NullUXValues } from '@typings';
import { formatCurrency } from '../currencyUtil';
import { formatNumber } from '../util';
import { FILTER_TYPES, STATIC_FILTER_OPTIONS, STATIC_FILTER_NULL_OPTIONS } from './filterConstants';

const { ARRAY, CURRENCY, DATE, NUMBER, PERCENTAGE, STRING } = FILTER_TYPES;

function humanizeFilterValue(type, subtype, value) {
  let displayValue = value;

  function formatNumberValue(number) {
    switch (subtype) {
      case 'currency':
        return formatCurrency(number);
      case 'percentage':
        return `${Math.round(number * 100)}%`;
      default:
        return formatNumber(number);
    }
  }

  function extractNullLabel(value) {
    return STATIC_FILTER_OPTIONS.nullOptions.find((o) => o.value === value).label;
  }

  function mapObject(arrays) {
    const m = {};
    const flattened = arrays.flat();
    flattened.forEach((prop) => (m[prop.value] = prop.label));
    return m;
  }

  if (type === DATE) {
    const [
      firstValue,
      firstOperator,
      secondValue,
      secondOperator,
      ,
      thirdOperator,
      thirdValue,
      fourthOperator,
    ] = value.split(':');
    const dateRangeBucketsMap = mapObject([STATIC_FILTER_OPTIONS.dateRangeBuckets]);
    const fiscalMap = mapObject([
      STATIC_FILTER_OPTIONS.fiscalQuarters,
      STATIC_FILTER_OPTIONS.fiscalYears,
    ]);
    const formattedFirstDate = moment(new Date(firstValue)).format('L');

    if (dateRangeBucketsMap[value] || fiscalMap[value]) {
      displayValue = dateRangeBucketsMap[value] ?? fiscalMap[value];
    } else if (firstValue && secondValue) {
      if (firstValue === 'today') {
        displayValue = `${firstOperator === '-' ? 'Last' : 'Next'} ${formatNumber(
          Number(secondValue),
        )} ${capitalize(
          secondOperator.substr(0, secondOperator.length - (secondValue === '1' ? 1 : 0)),
        )} from Today`;
      } else if (firstValue === 'month') {
        displayValue = `${firstOperator === '-' ? 'Last' : 'Next'} Calendar Month`;
      } else if (firstValue === 'custom') {
        displayValue = `${firstOperator === '-' ? 'From Last' : 'From Next'} ${formatNumber(
          Number(secondValue),
        )} ${capitalize(
          secondOperator.substr(0, secondOperator.length - (secondValue === '1' ? 1 : 0)),
        )} ${thirdOperator === '-' ? 'to Last' : 'to Next'} ${formatNumber(
          Number(thirdValue),
        )} ${capitalize(
          fourthOperator.substr(0, fourthOperator.length - (thirdValue === '1' ? 1 : 0)),
        )}`;
      } else if (firstValue === secondValue) {
        displayValue = `${formattedFirstDate}`;
      } else {
        displayValue = `${formattedFirstDate} through ${moment(new Date(secondValue)).format('L')}`;
      }
    } else if (firstOperator === 'gte') {
      displayValue = `on or after ${formattedFirstDate}`;
    } else if (firstOperator === 'lte') {
      displayValue = `on or before ${formattedFirstDate}`;
    } else if (firstOperator === 'gt') {
      displayValue = `after ${formattedFirstDate}`;
    } else if (firstOperator === 'lt') {
      displayValue = `before ${formattedFirstDate}`;
    } else if (STATIC_FILTER_NULL_OPTIONS.includes(firstValue)) {
      displayValue = extractNullLabel(firstValue);
    }
  } else if (type === NUMBER || type === CURRENCY || type === PERCENTAGE) {
    const numbers = value
      .split(':')
      .filter((potentialNumber) => !Number.isNaN(Number(potentialNumber)))
      .map((num) => Number(num));
    const firstNumber = formatNumberValue(numbers[0]);

    if (numbers.length === 2) {
      if (numbers[0] === numbers[1]) {
        displayValue = `equals ${firstNumber}`;
      } else {
        displayValue = `${firstNumber} through ${formatNumberValue(numbers[1])}`;
      }
    } else if (value.includes(':gte')) {
      displayValue = `${firstNumber} or more`;
    } else if (value.includes(':lte')) {
      displayValue = `${firstNumber} or less`;
    } else if (value.includes(':gt')) {
      displayValue = `greater than ${firstNumber}`;
    } else if (value.includes(':lt')) {
      displayValue = `less than ${firstNumber}`;
    } else if (STATIC_FILTER_NULL_OPTIONS.includes(value)) {
      displayValue = extractNullLabel(value);
    }
  } else if (type === STRING || type === ARRAY) {
    displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
    if (value === 'NULL' || value === 'Not Specified (-)') {
      displayValue = NullUXValues.NULL;
    } else if (value === 'EXISTS') {
      displayValue = NullUXValues.EXISTS;
    }
  }

  return displayValue;
}

export default humanizeFilterValue;
