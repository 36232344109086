import moment from 'moment';
import { useAppDetailsCompanyAppId } from '@hooks/useAppDetailsCompanyAppId';
import { useGetCompanyApp } from '@hooks/useGetCompanyApp';
import { getData } from '@utilities/xhr';
import { useVisualizationState } from '@components/visualizations/shared/contexts/visualizationContext';
import downloadBlobUrl from '@utilities/util/downloadBlobUrl';

export default function useDownloadVisualizationData() {
  const { chartDataRequestParams, selectedVisualization } = useVisualizationState();
  const companyAppId = useAppDetailsCompanyAppId();
  const { data } = useGetCompanyApp(companyAppId);
  const { appLabel } = data || {};

  const fileName = `Zylo.${appLabel || ''}${
    appLabel ? '.' : ''
  }${selectedVisualization?.title.replace(/ /g, '')}.${moment().format('M.DD.YY')}.csv`;

  return () => {
    getData({
      path: selectedVisualization?.endpoint ?? '',
      params: { ...chartDataRequestParams, format: 'csv' },
    }).then(({ text }: { text: string }) => {
      downloadBlobUrl(window.URL.createObjectURL(new Blob([text], { type: 'text/csv' })), fileName);
    });
  };
}
