import { Borders, Colors, defaultborder } from '@zylo/orchestra';
import { CSSProperties, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import defaultImage from '@assets/img/logo_missing.png';
import { LOGO_URI } from '../config/config';

type AppLogoProps = {
  className?: string;
  filename: string | undefined;
  style?: CSSProperties;
  logoSize?: string;
};

type AppLogoContainerProps = {
  logoSize: string;
};
const AppLogoContainer = styled.span<AppLogoContainerProps>`
  ${({ logoSize }) => {
    return css`
      background: ${Colors.white};
      border: ${defaultborder};
      border-radius: ${Borders.radius.half};
      height: ${logoSize};
      min-width: ${logoSize};
      overflow: hidden;
      width: ${logoSize};

      .app-logo {
        max-width: 100%;
        height: auto;
      }
    `;
  }}
`;

export default function AppLogo({ className, filename, style, logoSize = '2rem' }: AppLogoProps) {
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    setErrored(false);
  }, [filename]);

  function handleError() {
    setErrored(true);
  }

  const url = filename ? `${LOGO_URI}${filename}` : null;
  const imgSrc = errored ? defaultImage : url;

  return (
    <AppLogoContainer
      className={`flex-container justify-center align-center app-logo-container ${className}`}
      logoSize={logoSize}
      style={style}
    >
      <img alt={filename} className="app-logo" onError={handleError} src={imgSrc ?? ''} />
    </AppLogoContainer>
  );
}
