import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { get, isEqual, pick } from 'lodash-es';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { usePortalContext, checkForProvider } from '@zylo/orchestra';
import { convertApiFilters, formatApiRequestFilters, FILTER_TYPES } from '../utilities/filterUtils';

const { DATE, NUMBER, STRING } = FILTER_TYPES;
const FiltersStateContext = createContext();
const FiltersUpdateContext = createContext();

function FiltersProvider({
  children,
  defaultSelectedFilters = '{}',
  filtersUrlParam,
  frozenKeys,
  hiddenFilters = {},
}) {
  const location = useLocation();
  const [filterGroups, setFilterGroups] = useState();
  const [filters, setFilters] = useState();
  const [filterMap, setFilterMap] = useState();
  const [apiRequestFilters, setApiRequestFilters] = useState();
  const [selectedFilters, setSelectedFilters] = useState();
  const preselectedFilters = useRef();
  const { existsInPortal } = usePortalContext();

  useEffect(() => {
    if (selectedFilters) return;

    if (!existsInPortal) {
      const urlFilters = parse(location.search)[filtersUrlParam];
      let parsedFilters = JSON.parse(urlFilters || '{}');

      // this allows us to parse both the old array-based format (still lives in email templates) and the new object-based format
      if (Array.isArray(parsedFilters)) {
        parsedFilters = convertApiFilters(parsedFilters);
      }

      preselectedFilters.current = urlFilters;
      setSelectedFilters({
        ...JSON.parse(defaultSelectedFilters),
        ...parsedFilters,
      });
    } else {
      setSelectedFilters(JSON.parse(defaultSelectedFilters));
    }
  }, [defaultSelectedFilters, filtersUrlParam, location, selectedFilters, existsInPortal]);

  useEffect(() => {
    if (!selectedFilters || !filterMap) return;

    setApiRequestFilters(
      formatApiRequestFilters({ ...selectedFilters, ...hiddenFilters }, filterMap),
    );
  }, [filterMap, hiddenFilters, selectedFilters]);

  const storeFilters = useCallback(
    (filtersToStore) => {
      const map = {};

      const updatedFiltersToStore = filtersToStore.map((filter) => {
        if (frozenKeys?.includes(filter.key)) {
          return { ...filter, freezeFilterValue: true };
        }
        return filter;
      });

      updatedFiltersToStore.forEach((filter) => {
        // sourceKey is for the user apps table filters
        if (filter.sourceKey) {
          filter.key = filter.sourceKey;
          map[filter.sourceKey] = filter;
        } else {
          map[filter.key] = filter;
        }
      });

      setFilterMap(map);
      setFilters(updatedFiltersToStore);
    },
    [frozenKeys],
  );

  const handleFilterChange = useCallback(
    (filterKey, value) => {
      // we treat missing filters as string types
      const { type: filterType } = get(filterMap, filterKey, { type: STRING });
      let updatedValue = value;

      if (filterType === STRING) {
        updatedValue = value;

        if (typeof value === 'string') {
          updatedValue = [value];
        }
      } else if (filterType === DATE || filterType === NUMBER) {
        updatedValue = value === ':::' ? [] : value;
      }

      setSelectedFilters((currentFilters) => {
        let filterUpdate;

        if (
          updatedValue === null ||
          updatedValue === undefined ||
          (Array.isArray(updatedValue) && !updatedValue.length)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [filterKey]: _, ...remainingFilters } = currentFilters;

          filterUpdate = remainingFilters;
        } else {
          filterUpdate = { ...currentFilters, [filterKey]: updatedValue };
        }

        return filterUpdate;
      });
    },
    [filterMap],
  );

  const replaceSelectedFilters = useCallback(
    (newFilters) => {
      setSelectedFilters((currentSelectedFilters) => {
        function enforceFrozenFilters() {
          const frozenFilterKeys = !!filters
            ? filters.filter((filter) => filter.freezeFilterValue).map((filter) => filter.key)
            : [];
          const sanitizedChanges = Object.assign(
            { ...newFilters },
            pick(currentSelectedFilters, ...frozenFilterKeys),
          );
          return sanitizedChanges;
        }

        const sanitizedNewFilters = enforceFrozenFilters(newFilters);

        return isEqual(currentSelectedFilters, sanitizedNewFilters)
          ? currentSelectedFilters
          : sanitizedNewFilters;
      });
    },
    [filters],
  );

  const resetSelectedFilters = useCallback(() => {
    const defaultFilters = JSON.parse(defaultSelectedFilters);

    setSelectedFilters(defaultFilters);
  }, [defaultSelectedFilters]);

  return (
    <FiltersStateContext.Provider
      value={{
        apiRequestFilters,
        defaultSelectedFilters,
        filterGroups,
        filterMap,
        filters,
        hasPreselectedFilters: !!preselectedFilters.current,
        selectedFilters,
      }}
    >
      <FiltersUpdateContext.Provider
        value={{
          resetSelectedFilters,
          handleFilterChange,
          replaceSelectedFilters,
          setFilterGroups,
          storeFilters,
        }}
      >
        {children}
      </FiltersUpdateContext.Provider>
    </FiltersStateContext.Provider>
  );
}

FiltersProvider.propTypes = {
  children: PropTypes.any,
  defaultSelectedFilters: PropTypes.string,
  filtersUrlParam: PropTypes.string,
  frozenKeys: PropTypes.arrayOf('string'),
};

function useFiltersState() {
  const context = useContext(FiltersStateContext);

  checkForProvider(context, 'useFiltersState', 'FiltersProvider');

  return context;
}

function useFiltersUpdate() {
  const context = useContext(FiltersUpdateContext);

  checkForProvider(context, 'useFiltersUpdate', 'FiltersProvider');

  return context;
}

export { FiltersProvider, useFiltersState, useFiltersUpdate };
