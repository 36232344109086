import { ColorsUnion, Icon, Icons, Tooltip } from '@zylo/orchestra';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

const StatusBadgeContainer = styled.span`
  position: initial;
  padding: 0 0 0 5px;
  flex-shrink: 0;
`;

export type StatusBadgeProps = {
  status: 'active' | 'pending' | 'expired' | 'cancelled' | 'deleted' | 'inactive';
  tooltipActions?: ReactNode;
};

export default function StatusBadge({ status, tooltipActions }: StatusBadgeProps) {
  const statusLookup: {
    [k in StatusBadgeProps['status']]: {
      color: ColorsUnion;
      icon: Icons;
      name: string;
    } | null;
  } = {
    active: null,
    deleted: null,
    inactive: null,
    pending: null,
    expired: { name: 'Contract Expired', icon: 'IconAlertTriangle', color: 'yellow' },
    cancelled: { name: 'Application Cancelled', icon: 'IconCircleX', color: 'darkgray' },
  };
  const badge = statusLookup[status];

  return badge ? (
    <StatusBadgeContainer className="flex-container justify-center align-center status-badge-container">
      <Tooltip actionContent={tooltipActions} text={badge.name} allowBodyHover>
        <Icon color={badge.color} icon={badge.icon} size={18} />
      </Tooltip>
    </StatusBadgeContainer>
  ) : null;
}
