import { get } from 'lodash-es';
import moment from 'moment';

export default function sortList(list, sortObj, tiebreaker) {
  if (!list || !sortObj) return;

  const { sortDirection, sortType, sortValueKey } = sortObj;

  return [...list].sort((a, b) => {
    const aSortValue = a.sortValues[sortValueKey];
    const bSortValue = b.sortValues[sortValueKey];
    const aTiebreaker = get(a, tiebreaker, '').toLowerCase();
    const bTiebreaker = get(b, tiebreaker, '').toLowerCase();
    const tiebreakerResult = aTiebreaker > bTiebreaker ? 1 : -1;
    let firstSortValue = aSortValue;
    let secondSortValue = bSortValue;

    if (sortDirection === 'desc') {
      firstSortValue = bSortValue;
      secondSortValue = aSortValue;
    }

    if (sortType === 'number' || sortType === 'string') {
      if (firstSortValue > secondSortValue) return 1;
      if (firstSortValue < secondSortValue) return -1;
    } else if (sortType === 'date') {
      const firstMoment = moment(firstSortValue);
      const secondMoment = moment(secondSortValue);
      const firstMomentIsValid = firstMoment.isValid();
      const secondMomentIsValid = secondMoment.isValid();

      if (firstMomentIsValid && secondMomentIsValid) {
        if (firstMoment.isBefore(secondSortValue)) return 1;
        if (firstMoment.isAfter(secondSortValue)) return -1;
      } else {
        if (firstMomentIsValid && !secondMomentIsValid) return 1;
        if (!firstMomentIsValid && secondMomentIsValid) return -1;
      }
    }

    return tiebreakerResult;
  });
}
