import { getAWSCompanyLogoPath } from '@utilities/util';
import { reconcileQueryStatuses } from '@frameworks/form-fns';
import { CompanyBrandResponse, useGetCompanyBranding } from './useGetCompanyBranding';
import { useCompanyData } from './company';

export type BrandingType = 'app_catalog' | 'company';
/**
 * Handles fetching and formatting company branding for logo, logo alt text and primary color. Additionally,
 * returns the reconciled status of both `useGetCompany` and `useGetCompanyBranding` as a single status.
 */
export function useCompanyBranding(type: BrandingType) {
  const { data: branding, status: brandingStatus } = useGetCompanyBranding() ?? {};
  const { companyData, status: companyStatus } = useCompanyData();
  const { logo, primaryColor } = handleCompanyBranding(branding ?? [], type);

  return {
    logo,
    logoAltText: `${companyData?.companyName ?? ''} Logo`,
    primaryColor,
    status: reconcileQueryStatuses(brandingStatus, companyStatus),
  };
}

function handleCompanyBranding(
  branding: CompanyBrandResponse['body'],
  type: 'app_catalog' | 'company',
) {
  const {
    companyId,
    logoFilename,
    primaryColor = '',
  } = branding.find((record) => record.type === type) ?? {};

  const logo =
    logoFilename && !logoFilename.startsWith('http') && companyId
      ? getAWSCompanyLogoPath(logoFilename, companyId)
      : logoFilename ?? '';

  return { logo, primaryColor };
}
