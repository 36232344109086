import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash-es';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { checkForProvider } from '@zylo/orchestra';

const SearchStateContext = createContext();
const SearchUpdateContext = createContext();

function SearchProvider({ children, searchUrlParam }) {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    const filterParams = parse(location.search)[searchUrlParam];
    setSearchValue((currentVal) => filterParams || (currentVal ?? ''));
  }, [location.search, searchUrlParam]);

  return (
    <SearchStateContext.Provider value={searchValue}>
      <SearchUpdateContext.Provider value={setSearchValue}>{children}</SearchUpdateContext.Provider>
    </SearchStateContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.node,
  searchUrlParam: PropTypes.string,
};

const debouncedProviderCheck = debounce(checkForProvider, 500);

function useSearchState(props) {
  const context = useContext(SearchStateContext);

  if (get(props, 'disableProviderCheck')) {
    debouncedProviderCheck(context, 'useSearchState', 'SearchProvider');
  }

  return context;
}

function useSearchUpdate() {
  const context = useContext(SearchUpdateContext);

  checkForProvider(context, 'useSearchUpdate', 'SearchProvider');

  return context;
}

export { SearchProvider, useSearchState, useSearchUpdate };
