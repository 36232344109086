import { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

function OscillatingText({ text }) {
  const containerRef = useRef();
  const textRef = useRef();
  const [textOverflow, setTextOverflow] = useState(0);
  const [tagWidth, setTagWidth] = useState('auto');

  function calculateOverflow() {
    const containerWidth = containerRef.current.clientWidth;
    const textWidth = textRef.current.clientWidth;
    let overflow = textWidth - containerWidth;

    // we don't have css classes that handle overflow > 300px
    if (overflow > 300) {
      overflow = 300;
    }

    // round up to the nearest 10px
    setTextOverflow(overflow > 0 ? Math.ceil(overflow / 10) * 10 : 0);
  }

  useLayoutEffect(
    () => (textRef.current ? setTagWidth(textRef.current.clientWidth) : 'auto'),
    [textRef],
  );

  return (
    <div
      className="oscillating-text-wrapper"
      onMouseOver={calculateOverflow}
      ref={containerRef}
      style={{
        width: tagWidth,
      }}
    >
      <div className={`oscillating-text oscillating-text-${textOverflow}`} ref={textRef}>
        {text}
      </div>
    </div>
  );
}

OscillatingText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default OscillatingText;
