import { camelCase, isEqual, get, upperFirst } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Permission, Store } from '@typings';

type UsePermissionsAdmin = {
  authCanAccessAdmin?: boolean;
  authCanAccessAppCatalogAdmin?: boolean;
  authCanAccessProductAdmin?: boolean;
  authCanAccessSuperAdmin?: boolean;
};

type UsePermissionsCore = {
  appsReadOnlyUser?: boolean;
  authCanAccessApps?: boolean;

  automationReadOnlyUser?: boolean;
  authCanAccessAutomation?: boolean;

  chargesReadOnlyUser?: boolean;
  authCanAccessCharges?: boolean;

  contractsReadOnlyUser?: boolean;
  authCanAccessContracts?: boolean;

  integrationsReadOnlyUser?: boolean;
  authCanAccessIntegrations?: boolean;

  usersReadOnlyUser?: boolean;
  authCanAccessUsers?: boolean;
};

type UsePermissionsFeatures = {
  authCanAccessAddApps?: boolean;
  authCanAccessDashboard?: boolean;
  authCanAccessDocs?: boolean;
  authCanAccessGlimpse?: boolean;
  authCanAccessInsightsWorkbench?: boolean;
  authCanAccessProfileAlerts?: boolean;
  authCanAccessRecentActivity?: boolean;
  authCanAccessReporting?: boolean;
  authCanAccessSuppliers?: boolean;
  authCanAccessUploadHistory?: boolean;
  authCanAccessUploads?: boolean;
  authCanAccessWorkflows?: boolean;
};

type UsePermissions = UsePermissionsAdmin & UsePermissionsCore & UsePermissionsFeatures;
type UsePermission = keyof UsePermissions;

export type UsePermissionsReturn = ReturnType<typeof usePermissions>;
/**
 * Provides named boolean values for core access, integrations, admin, super-admin, feature-specific permissions & vues.
 * Permission types are requested by name. An object containing the requested permissions will be returned.
 */
export function usePermissions(...requestedPermissions: Permission[]) {
  // keep individual useSelector calls - needed for isEqual comparision to remove unncessary re-renders
  const authIsViewless = useSelector(({ auth }: Store) => auth.authIsViewless, isEqual);
  const usesVues = useSelector(({ auth }: Store) => auth.usesVues, isEqual);
  const permissionsHaveLoaded = useSelector(
    ({ auth }: Store) => auth.permissions.haveLoaded,
    isEqual,
  );
  const permissions = useSelector(({ auth }: Store) => auth.permissions, isEqual);

  const requestedValues = requestedPermissions.reduce((permissionsObj, permission) => {
    const { canAccess, readOnly } = get(permissions, permission) ?? {};

    if (canAccess !== undefined) {
      permissionsObj[`authCanAccess${upperFirst(camelCase(permission))}` as UsePermission] =
        canAccess;
    }

    if (readOnly !== undefined) {
      permissionsObj[`${permission}ReadOnlyUser` as UsePermission] = readOnly;
    }

    return permissionsObj;
  }, {} as Record<UsePermission, boolean>);

  return { authIsViewless, permissionsHaveLoaded, usesVues, ...requestedValues };
}
