import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkForProvider } from '@zylo/orchestra';
import { useColumnsState } from '../columnsContext';
import { useFiltersState } from '../filtersContext';
import { useSortState } from '../sortContext';
import { useSavedFiltersListState } from './savedFiltersListContext';

const DefaultTableViewStateContext = createContext();
const DefaultTableViewUpdateContext = createContext();

function DefaultTableViewProvider({ children }) {
  const { defaultColumnsOrder, defaultFrozenColumns } = useColumnsState();
  const { defaultSelectedFilters } = useFiltersState();
  const { savedFilters } = useSavedFiltersListState();
  const { defaultSort } = useSortState();
  const [nonSavedFilterDefaultView, setNonSavedFilterDefaultView] = useState();
  const [defaultSavedFilter, setDefaultSavedFilter] = useState();
  const [defaultView, setDefaultView] = useState();

  // find default saved filter if it exists
  useEffect(() => {
    if (!savedFilters.myFilters) return;

    setDefaultSavedFilter(
      savedFilters.myFilters.find(({ defaultFilter }) => defaultFilter) || null,
    );
  }, [savedFilters.myFilters]);

  // determine non-saved-filter default view
  useEffect(() => {
    if (defaultColumnsOrder && defaultSelectedFilters && defaultSort) {
      setNonSavedFilterDefaultView({
        columnsOrder: defaultColumnsOrder,
        filters: JSON.parse(defaultSelectedFilters),
        frozenColumns: defaultFrozenColumns,
        tableSort: defaultSort,
      });
    }
  }, [defaultColumnsOrder, defaultSelectedFilters, defaultSort, defaultFrozenColumns]);

  // determine defaultView
  useEffect(() => {
    if (defaultSavedFilter !== undefined && nonSavedFilterDefaultView !== undefined) {
      const defaultSource = defaultSavedFilter || { ...nonSavedFilterDefaultView, id: null };

      setDefaultView({
        obj: JSON.stringify(defaultSource),
        stringified: `${defaultSource.columnsOrder},${JSON.stringify(defaultSource.filters)},${
          defaultSource.frozenColumns
        },${defaultSource.tableSort}`,
      });
    }
  }, [defaultSavedFilter, nonSavedFilterDefaultView]);

  return (
    <DefaultTableViewStateContext.Provider value={{ defaultSavedFilter, defaultView }}>
      <DefaultTableViewUpdateContext.Provider value={{ setDefaultSavedFilter }}>
        {children}
      </DefaultTableViewUpdateContext.Provider>
    </DefaultTableViewStateContext.Provider>
  );
}

DefaultTableViewProvider.propTypes = {
  children: PropTypes.node,
};

function useDefaultTableViewState() {
  const context = useContext(DefaultTableViewStateContext);

  checkForProvider(context, 'useDefaultTableViewState', 'DefaultTableViewProvider');

  return context;
}

function useDefaultTableViewUpdate() {
  const context = useContext(DefaultTableViewUpdateContext);

  checkForProvider(context, 'useDefaultTableViewUpdate', 'DefaultTableViewProvider');

  return context;
}

export { DefaultTableViewProvider, useDefaultTableViewState, useDefaultTableViewUpdate };
