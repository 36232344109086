import { useEffect, useState } from 'react';
import { Switch } from '@zylo/orchestra';
import { CellProps, RowData } from '@typings';

export type SwitchCellProps = Omit<CellProps, 'data'> & {
  onChange(r: RowData, cv: boolean): void;
  data: boolean;
  disabled?: boolean;
};

export function SwitchCell({ data, disabled, onChange, rowData }: SwitchCellProps) {
  const [isActive, setIsActive] = useState(data);

  useEffect(() => {
    setIsActive(data as boolean);
  }, [data]);

  function handleSwitchChange() {
    setIsActive((currentVal) => {
      onChange(rowData, !currentVal);
      return !currentVal;
    });
  }

  return (
    <div
      className="switch-cell"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Switch active={isActive} disabled={disabled} onChange={handleSwitchChange} />
    </div>
  );
}
