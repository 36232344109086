import { ElementType, MutableRefObject, useRef, ReactNode, useImperativeHandle } from 'react';
import { ZyloTableRef } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { useZAPState, ZAPEndpointsOverrideType, ZAPProvider } from '@frameworks/zap/ZAPContext';
import { CellMappings, ZAPNames } from '@frameworks/zap/types';
import {
  ZAPStatisticsCards,
  ZAPStatisticsCardsProps,
} from '@frameworks/zap/statistics/ZAPStatisticsCards';
import PageControls, { PageControlsProps } from '@components/layout/PageControls';
import { ZAPFiltersMenu, ZAPFiltersMenuProps } from '@frameworks/zap/ZAPFiltersMenu';
import { ZAPTable, ZAPTableProps } from '@frameworks/zap/table/ZAPTable';
import {
  MetadataTableProviders,
  MetadataTableProvidersProps,
} from '@components/filters/TableProviders';
import { ColumnPickerProps } from '@components/columnPicker/ColumnPicker';
import { useChartToggle } from '@hooks';
import useComponentStateObserver from '@hooks/useComponentStateObserver';

const ZAPContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`;

/** Temporary component until we can access `useZAPState` in `ZAPFiltersMenu` */
function ZapFilterMenuWrapper(props: Omit<ZAPFiltersMenuProps, 'path'>) {
  const { endpoints } = useZAPState();
  return <ZAPFiltersMenu {...props} path={endpoints.completions} />;
}

// Aliases for use when implementing a ZAP™

/** Default standardized values are provided (based on the ⚡ZAP™ Framework) and
 * should rarely need to be overriden. When providing overrides only values that
 * you wish to override need to be provided they will be merged with the default
 * values. Icons will only appear in the Column Picker if the associated data
 * types are present in the metadata.
 */
export type ZAPCategoriesInfo = ColumnPickerProps['categoriesInfo'];
/** An array of tuples passed to the `cellMappings` prop. The first element is
 * the string to match against the 'key' property of the metadata response.
 * The second element is the custom cell component and props that the given
 * column should use instead of the default mapping. Custom components with
 * click handlers may be passed as well. The array should have a stable reference.
 * The type argument should be the type of the data that will be displayed in the table.
 */
export type ZAPCellMappings<T extends object> = CellMappings<T>;
/** An array of objects passed to the `stats` prop. Properties relate to the stat card
 * to be displayed. The 'key' property should match the property name of the stat
 * value in the API response from `/zap/{ZAPName}/stats`.
 */
export type ZAPStats = ZAPStatisticsCardsProps['stats'];

export type ZAPSharedProps<T extends object> = {
  /** This is allows a custom bulk edit form to be provided instead of dynamically building the form based on metadata from the ZAP API */
  customBulkEditForm?: ElementType;
  /** Boolean prop to allow field picker */
  includeFieldPicker?: boolean;
  /** Boolean prop to allow the table data to be downloaded */
  includeTableExport?: boolean;
  /** This is the key that will be used from the table data row objects that will be used for selection */
  rowSelectionKey?: keyof T;
  /** This is used to render the table without the Page Controls section. Charts and Stats that are passed in are still rendered. */
  showPageControls?: boolean;
  /** This is optional content that will get rendered in the ZAP table header */
  tableHeaderContent?: ReactNode;
  /** This is an optional ref or callback to get / observe ZAP state from above */
  zapRef?: MutableRefObject<{}> | (() => void);
  /** This is used with various ZAP™ endpoints ("/zap/{ZAPName}", "/zap/{ZAPName}/stats", etc.). */
  ZAPName: ZAPNames;
  /** This is used to override endpoints generated in ZAP with custom endpoints */
  /** NOTE: In order to invalidate custom endpoint queries custom endpoints will need to be passed to useInvalidateZapQueries callback */
  zapEndpointsOverride?: ZAPEndpointsOverrideType;
  zapId?: string;
};

export type ZAPProps<T extends object> = Omit<
  ColumnPickerProps,
  'handleClose' | 'implementationName'
> &
  Omit<MetadataTableProvidersProps, 'children'> &
  Omit<PageControlsProps, 'chartClickHandler' | 'showChart'> &
  Omit<ZAPFiltersMenuProps, 'path'> &
  ZAPSharedProps<T> &
  Partial<ZAPStatisticsCardsProps> &
  ZAPTableProps<T> & {
    /** The ZAP™ will handle toggling the chart. */
    charts?: ReactNode;
    cellMappings?: ZAPCellMappings<T>;
    /** gridName is the human-readable name of the ZAP™ implementation. This is used for event tracking */
    gridName: string;
    includeFieldPicker?: boolean;
    noDataText?: string | JSX.Element;
    rowActionsComponent?: (row: T) => JSX.Element;
  };

export type ZAPSavedFilterState = {
  apiRequestFilters: string;
  applySavedFilterById: (savedFilterId: string) => void;
  currentConfiguration: Record<string, any>;
  savedFilterHasBeenModified: boolean;
  updateSavedFilter: () => Promise<void>;
};

/** Provides a single component to implement the common elements of ZAP™. `PageControls`, `Statistics`, `Charts`, `Table`,
 * `ColumnPicker`, `SavedFiltersForm`, `SavedFiltersTables`.
 *
 * @example
 * ```
 * const cellMappings: ZAPCellMappings = [
 *   ['appLabel', { customComponent: SubscriptionCell }],
 * ]
 * const stats: ZAPStats = [
 *   {
 *     isCurrency: true,
 *     label: 'Annual Spend',
 *     tooltipText:
 *       'Total spend in the last 12 months for applications in the current filtered view.',
 *     key: 'spendSum',
 *   },
 * ]
 *
 *
 * function Applications() {
 *   return (
 *      <ZAP
 *        ZAPName="companyApps"
 *        cellMappings={cellMappings}
 *        charts={
 *          <StandardVisualizationCard
 *            initialVisualization="topCategoriesByAppCountWithSpend"
 *            visualizationConfigs={subscriptionsTabVisualizationConfigs}
 *          />
 *         }
 *         defaultSelectedFilters={SUBSCRIPTIONS_TABLE_DEFAULT_FILTERS}
 *         defaultSort="+appLabel"
 *         eventName="APPLICATIONS_TAB"
 *         filtersUrlParam="filters"
 *         gridName="Applications"
 *         includeChartBtn={true}
 *         // This is optional if a row click event is required.
 *         onTableRowClick={(app: CompanyApp) =>
 *           navigate(typeof app === 'object' ? `/subscriptions/${app.id}` : '/subscriptions')
 *         }
 *         primaryBtn={<AddSubscriptionsButton />}
 *         savedFiltersType={SAVED_FILTERS_TYPES.COMPANY_APPS}
 *         searchUrlParam="globalSearch"
 *         sortUrlParam="sort"
 *         stats={stats}
 *       />
 *    )
 * }
 * ```
 */
export function ZAP<T extends object>({
  customBulkEditForm,
  categoriesInfo,
  cellMappings,
  charts,
  columnsUrlParam,
  companyAppId,
  defaultSelectedFilters,
  defaultSelectionState,
  defaultSort,
  disableSelectAll,
  eventName,
  filtersUrlParam,
  formatTableHeader,
  frozenKeys,
  gridName,
  hiddenFilters,
  includeChartBtn = true,
  includeFilters = true,
  includeFieldPicker = true,
  includeSearchBar = true,
  includeTableExport = true,
  includeSavedFilters = true,
  disableBulkEdit = false,
  includeCompare = false,
  minTableHeight,
  noDataText,
  onTableRowClick,
  preSearchTooltip,
  primaryBtn,
  rowActionsComponent,
  rowSelectionKey = 'id' as keyof T,
  savedFiltersType,
  searchUrlParam,
  selectable,
  showPageControls = true,
  sortable = true,
  sortUrlParam,
  stats,
  tableHeaderContent,
  zapId,
  zapEndpointsOverride,
  zapRef,
  ZAPName,
}: ZAPProps<T>) {
  const [isChartVisible, toggleChart] = useChartToggle(ZAPName, true);
  const tableRef = useRef<ZyloTableRef<T>>();
  const {
    componentState: zapSavedFilterState,
    componentStateObserver: zapSavedFilterStateObserver,
  } = useComponentStateObserver();

  useImperativeHandle(
    zapRef,
    () => ({
      savedFilterState: zapSavedFilterState,
      tableState: tableRef.current,
    }),
    [zapSavedFilterState, tableRef],
  );

  return (
    <MetadataTableProviders
      columnsUrlParam={columnsUrlParam}
      companyAppId={companyAppId}
      defaultSelectedFilters={defaultSelectedFilters}
      defaultSort={defaultSort}
      filtersUrlParam={filtersUrlParam}
      frozenKeys={frozenKeys}
      hiddenFilters={hiddenFilters}
      savedFilterStateRef={zapSavedFilterStateObserver}
      savedFiltersType={savedFiltersType}
      searchUrlParam={searchUrlParam}
      sortUrlParam={sortUrlParam}
    >
      <ZAPProvider
        ZAPName={ZAPName}
        categoriesInfo={categoriesInfo}
        customBulkEditForm={customBulkEditForm}
        eventLocation={eventName}
        gridName={gridName}
        tableProps={{
          cellMappings,
          includeFieldPicker,
          includeTableExport,
          noDataText,
          rowSelectionKey,
          tableHeaderContent,
          rowActionsComponent,
        }}
        zapEndpointsOverride={zapEndpointsOverride}
        zapId={zapId}
      >
        <ZAPContainer>
          {showPageControls ? (
            <PageControls
              chartClickHandler={toggleChart}
              eventName={eventName}
              filtersMenu={
                <ZapFilterMenuWrapper
                  filterApplyEventName={eventName ? `${eventName}_FILTERS_APPLIED` : undefined}
                />
              }
              frozenKeys={frozenKeys}
              includeChartBtn={Boolean(charts) && includeChartBtn}
              includeFilters={includeFilters}
              includeSavedFilters={includeSavedFilters}
              includeSearchBar={includeSearchBar}
              preSearchTooltip={preSearchTooltip}
              primaryBtn={primaryBtn}
              showChart={isChartVisible}
            />
          ) : null}
          {stats ? <ZAPStatisticsCards stats={stats} /> : null}
          {isChartVisible ? charts : null}
          <ZAPTable
            defaultSelectionState={defaultSelectionState}
            disableBulkEdit={disableBulkEdit}
            disableSelectAll={disableSelectAll}
            formatTableHeader={formatTableHeader}
            includeCompare={includeCompare}
            minTableHeight={minTableHeight}
            onTableRowClick={onTableRowClick}
            selectable={selectable}
            sortable={sortable}
            tableRef={tableRef}
          />
        </ZAPContainer>
      </ZAPProvider>
    </MetadataTableProviders>
  );
}
