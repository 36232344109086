import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { HttpResponse, ZyloUser } from '@typings';

type ProfileResponse = HttpResponse<ZyloUser>;

export function useGetProfileInformation(userId: string) {
  return useQuery(
    ['userProfile', userId],
    async () => {
      const response: ProfileResponse = await getData({
        path: `companies/$companyId/zyloUserAccounts/${userId}`,
      });
      return response.body;
    },
    {
      enabled: Boolean(userId),
    },
  );
}
