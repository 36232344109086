import { createContext, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { checkForProvider } from '@zylo/orchestra';
import { SAVED_FILTERS_TYPES } from '@components/savedFilters/utilities/savedFiltersConstants';
import { getData } from '@utilities/xhr';
import useDebouncedState from '@hooks/useDebouncedState';
import { handleNetworkError, wrapAsync } from '@utilities/util';
import { useCompanyData } from '@hooks/company';
import { handleOldSavedFilterKeys } from '../utilities/savedFiltersListContextUtil';
import { useSavedFiltersTypeState } from './savedFiltersTypeContext';

const SavedFiltersListStateContext = createContext();
const SavedFiltersListUpdateContext = createContext();

function SavedFiltersListProvider({ children, companyAppId }) {
  const dispatch = useDispatch();
  const savedFiltersType = useSavedFiltersTypeState();
  const {
    primaryUserStoreCompanyAppId,
    primaryUserStoreIntegrationId,
    id: companyId,
  } = useCompanyData().companyData;
  const [insightFilters, setInsightFilters] = useState([]);
  const [savedFilters, setSavedFilters] = useState({});
  const [insightFilterTypes, setInsightFilterTypes] = useState({});
  const [paramsReady, setParamsReady] = useDebouncedState(false, 100);
  const apiRequestParams = useMemo(() => {
    if (savedFiltersType === SAVED_FILTERS_TYPES.USER_GROUPS) {
      return { companyAppId: primaryUserStoreCompanyAppId };
    }

    if (companyAppId) {
      return { companyAppId };
    }

    return undefined;
  }, [savedFiltersType, companyAppId, primaryUserStoreCompanyAppId]);

  useEffect(() => setParamsReady(true), [apiRequestParams, setParamsReady]);
  const fetchInsightFilters = useCallback(() => {
    if (!paramsReady || !savedFiltersType || savedFiltersType === SAVED_FILTERS_TYPES.USER_GROUPS)
      return;

    getData({
      path: 'insightFilters',
      params: { companyId: companyId, filterType: savedFiltersType, ...apiRequestParams },
    })
      .then(({ body }) => {
        const insightTypes = {
          integrationType: body.integrationType,
          mappingType: body.mappingType,
        };

        let formattedResponse;
        if (savedFiltersType === SAVED_FILTERS_TYPES.APP_USERS) {
          formattedResponse = handleOldSavedFilterKeys(
            body.insightFilters,
            primaryUserStoreIntegrationId,
          );
        } else {
          formattedResponse = body.insightFilters.map((insightFilter) => {
            return {
              ...insightFilter,
              filters: JSON.parse(insightFilter.displayFilters),
            };
          });
        }

        setInsightFilters(formattedResponse);
        setInsightFilterTypes(insightTypes);
      })
      // Only handle expired token errors, other errors have been ignored until now (02.21.2022) but unhandled. Providing a catch reduces noise during development and testing.
      .catch((err) => {
        wrapAsync(handleNetworkError)('fetchInsightFilters', err, dispatch, () => {}, {});
      });
  }, [
    paramsReady,
    savedFiltersType,
    companyId,
    apiRequestParams,
    primaryUserStoreIntegrationId,
    dispatch,
  ]);

  // initial insight filters fetch
  useEffect(fetchInsightFilters, [fetchInsightFilters]);

  const fetchSavedFilters = useCallback(() => {
    if (!paramsReady || !savedFiltersType) return;

    getData({
      path: `companies/$companyId/savedFilters/${savedFiltersType}`,
      params: apiRequestParams,
    })
      .then(({ body }) => {
        const fetchedFilters = body.reduce(
          (filterObject, filter) => {
            if (filter.teamFilter) {
              return {
                ...filterObject,
                teamFilters: [
                  ...filterObject.teamFilters,
                  { ...filter, filters: JSON.parse(filter.displayFilters) },
                ],
              };
            }

            return {
              ...filterObject,
              myFilters: [
                ...filterObject.myFilters,
                { ...filter, filters: JSON.parse(filter.displayFilters) },
              ],
            };
          },
          { myFilters: [], teamFilters: [] },
        );

        if (savedFiltersType === SAVED_FILTERS_TYPES.APP_USERS) {
          fetchedFilters.myFilters = handleOldSavedFilterKeys(
            fetchedFilters.myFilters,
            primaryUserStoreIntegrationId,
          );
          fetchedFilters.teamFilters = handleOldSavedFilterKeys(
            fetchedFilters.teamFilters,
            primaryUserStoreIntegrationId,
          );
        }

        setSavedFilters(fetchedFilters);
      })
      // Only handle expired token errors, other errors have been ignored until now (02.21.2022) but unhandled. Providing a catch reduces noise during development and testing.
      .catch((err) => {
        wrapAsync(handleNetworkError)('fetchSavedFilters', err, dispatch, () => {}, {});
      });
  }, [paramsReady, apiRequestParams, savedFiltersType, primaryUserStoreIntegrationId, dispatch]);

  // initial saved filters fetch
  useEffect(fetchSavedFilters, [fetchSavedFilters]);

  return (
    <SavedFiltersListStateContext.Provider
      value={{ insightFilters, insightFilterTypes, savedFilters }}
    >
      <SavedFiltersListUpdateContext.Provider value={{ fetchInsightFilters, fetchSavedFilters }}>
        {children}
      </SavedFiltersListUpdateContext.Provider>
    </SavedFiltersListStateContext.Provider>
  );
}

SavedFiltersListProvider.propTypes = {
  children: PropTypes.node,
  companyAppId: PropTypes.string,
};

function useSavedFiltersListState() {
  const context = useContext(SavedFiltersListStateContext);

  checkForProvider(context, 'useSavedFiltersListState', 'SavedFiltersListProvider');

  return context;
}

function useSavedFiltersListUpdate() {
  const context = useContext(SavedFiltersListUpdateContext);

  checkForProvider(context, 'useSavedFiltersListUpdate', 'SavedFiltersListProvider');

  return context;
}

export { SavedFiltersListProvider, useSavedFiltersListState, useSavedFiltersListUpdate };
