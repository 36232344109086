import create from 'zustand';
import { persist } from 'zustand/middleware';

type UseInitialVisualizationsStateType = {
  initialVisualizations: {
    [key: string]: string | {} | any;
  };
};

type UseInitialVisualizationsUpdateType = {
  setInitialVisualization: (key: string, value: string) => void;
};

export const useInitialVisualizations = create(
  persist<UseInitialVisualizationsStateType & UseInitialVisualizationsUpdateType>(
    (set: any) => ({
      initialVisualizations: {},
      setInitialVisualization: (key: string, value: string) =>
        set((state: UseInitialVisualizationsStateType) => ({
          initialVisualizations: value
            ? { ...state.initialVisualizations, [key]: value }
            : state.initialVisualizations,
        })),
    }),
    {
      name: 'default-visualization-storage',
    },
  ),
);
