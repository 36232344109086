import superagent from 'superagent';
import { GenericError } from './utilities/util';

const request = superagent;
const { API_URI } = process.env;

export default class WaterWorksAPI {
  /**
   * Call auth route to auth through token, email&pw, sso, refresh token, or switch companies
   * @param token the authorization token
   * @param params the request body
   * @returns {Promise}
   */
  static preauth(params = {}) {
    return request
      .post(`${API_URI}/preauth`)
      .set({ 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  static auth(token = null, params = {}) {
    return request
      .post(`${API_URI}/zyloUserAccounts/auth`)
      .set({ Authorization: token })
      .withCredentials()
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  static logout(token = null, params = {}) {
    return request
      .post(`${API_URI}/zyloUserAccounts/logout`)
      .set({ Authorization: token })
      .send(params)
      .withCredentials()
      .then((res) => {
        return res.body;
      });
  }

  static refreshAuth(token, params = {}) {
    return request
      .post(`${API_URI}/zyloUserAccounts/refresh`)
      .set({ Authorization: token })
      .send(params)
      .withCredentials()
      .then((res) => {
        return res.body;
      });
  }

  static postZyloUserAccountData(endpoint, params) {
    return request
      .post(`${API_URI}/zyloUserAccounts/${endpoint}`)
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  static postZyloUserAccountDataWithToken(token, endpoint, params) {
    return request
      .post(`${API_URI}/zyloUserAccounts/${endpoint}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Call update password route.
   * @param params the params to submit
   * @returns {Promise}
   */
  static updatePassword(token, params) {
    return request
      .put(`${API_URI}/zyloUserAccounts/password`)
      .set({ Authorization: token })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Fetch Zylo Master File data
   */
  static fetchData(token, endpoint, params) {
    return request
      .get(`${API_URI}/${endpoint}`, params)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .then((res) => {
        return res.body || res.text;
      });
  }

  /**
   * Add Zylo Master File data
   */
  static postData(token, endpoint, params) {
    return request
      .post(`${API_URI}/${endpoint}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Edit Zylo Master File data
   */
  static updateData(token, endpoint, params) {
    return request
      .put(`${API_URI}/${endpoint}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Get company-specific data
   */
  static fetchCompanyData(token, companyId, endpoint = [''], params = {}) {
    if (!companyId) {
      throw new GenericError();
    }

    const computedEndpoint = endpoint.join('/');

    return request
      .get(`${API_URI}/companies/${companyId}/${computedEndpoint}`, params)
      .set({ Authorization: token })
      .then((res) => {
        return res.body || res.text;
      });
  }

  /**
   * Post company-specific data
   */
  static postCompanyData(token, companyId, endpoint = [''], params) {
    const computedEndpoint = endpoint.join('/');
    return request
      .post(`${API_URI}/companies/${companyId}/${computedEndpoint}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Delete data
   */
  static deleteCompanyData(token, companyId, endpoint = [''], params, queryParams = {}) {
    const computedEndpoint = endpoint.join('/');
    return request
      .del(`${API_URI}/companies/${companyId}/${computedEndpoint}`)
      .query(queryParams)
      .send(params)
      .set({ Authorization: token })
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Update Company data
   */
  static updateCompanyData(token, companyId, endpoint = [''], params, queryParams = {}) {
    const computedEndpoint = endpoint.join('/');
    return request
      .put(`${API_URI}/companies/${companyId}/${computedEndpoint}`)
      .query(queryParams)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Get activities
   * @param params the params to submit
   * @returns {Promise}
   */
  static fetchActivities(token, companyId, params) {
    if (!companyId) {
      throw new GenericError();
    }

    return request
      .get(`${API_URI}/companies/${companyId}/activities`, params)
      .set({ Authorization: token })
      .then((res) => {
        return res.body;
      });
  }

  static fetchApplicationData(token, endpoint = [''], params) {
    const computedEndpoint = endpoint.join('/');
    return request
      .get(`${API_URI}/apps/${computedEndpoint}`, params)
      .set({ Authorization: token })
      .then((res) => {
        return res.body;
      });
  }
  /**
   * Request App
   */
  static requestApp(token, params) {
    return request
      .post(`${API_URI}/apps/request`)
      .set({ Authorization: token })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }
  /**
   * Add Company Apps
   */
  static addCompanyApps(token, companyId, params) {
    return request
      .post(`${API_URI}/companies/${companyId}/apps`)
      .set({ Authorization: token })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Upload company data
   */
  static uploadCompanyData(token, companyId, endpoint = [''], { fileField, file }, params = {}) {
    const computedEndpoint = endpoint.join('/');
    const formData = new FormData();
    params.forEach((key) => {
      formData.append(key, params[key]);
    });
    formData.append(fileField, file);
    return request
      .post(`${API_URI}/companies/${companyId}/${computedEndpoint}`)
      .set({ Authorization: token })
      .send(formData)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Get Company User
   *
   * @param {String} token        Authorization token
   * @param {String} companyId    Id of the company
   * @param {String} userId       Id of the user
   * @param {Object} params       Params to send
   * @returns {Promise}
   */
  static getCompanyUser(token, companyId, userId, params) {
    return request
      .get(`${API_URI}/companies/${companyId}/users/${userId}`, params)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .then((res) => {
        return res.body;
      });
  }
  /**
   * Get Company User
   *
   * @param {String} token        Authorization token
   * @param {String} companyId    Id of the company
   * @param {String} userId       Id of the user
   * @param {Object} params       Params to send
   * @returns {Promise}
   */
  static getCompanyZyloUserAccount(token, companyId, zyloUserAccountId, params) {
    return request
      .get(`${API_URI}/companies/${companyId}/zyloUserAccounts/${zyloUserAccountId}`, params)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .then((res) => {
        return res.body;
      });
  }
  /**
   * Update Company User
   *
   * @param {String} token                Authorization token
   * @param {String} companyId            Id of the company
   * @param {String} zyloUserAccountId    Id of the zylo user
   * @param {Object} params               Params to send
   * @returns {Promise}
   */
  static updateZyloUser(token, companyId, zyloUserId, params) {
    if (!companyId) {
      throw new GenericError();
    }

    return request
      .put(`${API_URI}/companies/${companyId}/zyloUserAccounts/${zyloUserId}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  static validateIntegration(token, companyId, type, params) {
    return request
      .put(`${API_URI}/companies/${companyId}/integrations/${type}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  static reconnectIntegration(token, integrationId, companyId, type, params) {
    return request
      .put(`${API_URI}/companies/${companyId}/integrations/${type}/auth/${integrationId}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .send(params)
      .then((res) => {
        return res.body;
      });
  }

  static deleteIntegration(token, companyId, integrationId) {
    return request
      .del(`${API_URI}/companies/${companyId}/integrations/${integrationId}`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .then((res) => {
        return res.body;
      });
  }

  static getWebhookStatus(token, companyId, integrationId) {
    return request
      .get(`${API_URI}/companies/${companyId}/integrations/${integrationId}/webhook/status`)
      .set({ Authorization: token, 'Content-Type': 'application/json' })
      .then((res) => {
        return res.body;
      });
  }

  /**
   * public endpoint to get a survey for the landing page
   */
  static getSurvey(companyId, companyAppId, surveyId) {
    return request
      .get(`${API_URI}/companies/${companyId}/apps/${companyAppId}/surveys/${surveyId}`)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * public endpoint to send a survey for the landing page
   */
  static submitSurvey(companyId, companyAppId, surveyId, params) {
    const requestBody = Object.assign({}, params, {
      userId: params.userId,
      userInternalPreferenceId: params.userInternalPreferenceId,
    });
    return request
      .put(`${API_URI}/companies/${companyId}/apps/${companyAppId}/surveys/${surveyId}`)
      .send(requestBody)
      .then((res) => {
        return res.body;
      });
  }

  /**
   * Get Veeva Vault user liecense data
   */
  static fetchVeevaVaultLicenseData(token, companyId, companyAppId, endpoint = [''], params) {
    if (!companyAppId) {
      throw new GenericError();
    }

    const computedEndpoint = endpoint.join('/');

    return request
      .get(
        `${API_URI}/companies/${companyId}/veevaVaultUserLicenses/${companyAppId}/${computedEndpoint}`,
        params,
      )
      .set({ Authorization: token })
      .then((res) => {
        return res.body || res.text;
      });
  }

  static submitWorkflowResponse(responseId, body) {
    return request
      .put(`${API_URI}/workflows/responses/${responseId}`)
      .send(body)
      .then((res) => {
        return res.body || res.text;
      });
  }
}
