import { FieldReturn } from './Field/FieldReturn';
import { FormBuilderProps } from './types';
import { FormComponent } from './FormComponent';
import { fieldBuilder } from './formHelpers';

/**
 * Provides a non-initialized version of the form when dealing with data that needs
 * to be retrieved from an API.
 * */
export function FormPlaceholder({ error, fields, ...props }: Omit<FormBuilderProps, 'onSubmit'>) {
  return (
    /*
     This functionality is important to maintain separately from FormBuilder because fetching data 
     from an API (especially with multiple data sources) tends to result in the useForm hook
     being initialized in a way that causes the form to behave inconsistently. For example the 
     form may have missing data on initial render or the form may behave as if its data has been 
     edited.
    */
    <FormComponent {...props} error={error}>
      {fieldBuilder(typeof fields === 'function' ? fields() : fields, (fieldConfig) => {
        return (
          <FieldReturn
            formElementConfig={fieldConfig}
            key={fieldConfig[1].name}
            status={props.status}
          />
        );
      })}
    </FormComponent>
  );
}
