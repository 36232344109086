import { StatisticsCardProps } from '@components/StatisticsCard';
import { useGetTableStatistics } from '@hooks';
import { useZAPState } from '@frameworks/zap/ZAPContext';

export type Stat = Omit<StatisticsCardProps, 'isLoading' | 'value'> & {
  render?: (stat: TableStat, tableStats: TableStat[]) => boolean;
  key: string;
};

type TableStat = Omit<Stat, 'key'> & {
  value: any;
};

export function useZAPTableStats(stats: Stat[] | undefined) {
  const { endpoints } = useZAPState();
  const { data, isLoading } = useGetTableStatistics({ path: endpoints.stats });

  if (!stats) return { isLoading: false, tableStats: [] };

  return {
    isLoading,
    tableStats: stats.map(({ key, ...rest }) => ({ ...rest, value: data ? data[key] : undefined })),
  };
}
