import PropTypes from 'prop-types';
import classNames from 'clsx';

function Input(props) {
  const { className, id, name, onChange, type = 'text', ...rest } = props;
  function handleChange(e) {
    e.preventDefault();
    onChange(e.target.value);
  }

  return (
    <input
      autoComplete="off"
      className={classNames('form-element', className)}
      data-lpignore="true"
      id={id}
      name={name}
      onChange={handleChange}
      type={type}
      {...rest}
    />
  );
}

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['text', 'number', 'url', 'email', 'color']),
  value: PropTypes.string,
};

export default Input;
