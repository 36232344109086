import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NumberInput from '@components/forms/inputs/NumberInput';
import ReactSelect from '@components/forms/inputs/ReactSelect';
import { formatInputCurrencyConfig } from '@utilities/currencyUtil';
import {
  boundsFromValue,
  STATIC_FILTER_OPTIONS,
  STATIC_FILTER_NULL_OPTIONS,
} from '@utilities/filterUtils';

function NumberFilter({ name, onChange, subtype, value = ':::' }) {
  const initialBounds = useRef(boundsFromValue(value));
  const [lowerBound, setLowerBound] = useState(
    initialBounds.current[0] === '' ? '' : Number(initialBounds.current[0]),
  );
  const [upperBound, setUpperBound] = useState(
    initialBounds.current[1] === '' ? '' : Number(initialBounds.current[1]),
  );
  const [operator, setOperator] = useState();
  const [numberMask, setNumberMask] = useState({ locale: 'en-US' });
  const valueRef = useRef(value);
  const operatorOptions = [
    {
      optionType: 'standardOperators',
      options: STATIC_FILTER_OPTIONS.numberOperators,
    },
    {
      optionType: 'nullOptions',
      options: STATIC_FILTER_OPTIONS.nullOptions,
    },
  ];

  useEffect(() => {
    if (!operator && value) {
      if (value === ':::') {
        setOperator('between');
      } else if (STATIC_FILTER_NULL_OPTIONS.includes(value)) {
        setOperator(value);
      } else {
        const [val1, oper1, val2, oper2] = value.split(':');
        let initialOperator = `${oper1}`;

        if (oper2) {
          if (val1 === val2) {
            initialOperator = 'equals';
          } else {
            initialOperator = 'between';
          }
        }

        setOperator(initialOperator);
      }
    }
  }, [operator, value]);

  useEffect(() => {
    if (subtype === 'currency') {
      setNumberMask(formatInputCurrencyConfig({ decimalPlaces: 0 }));
    } else if (subtype === 'percentage') {
      setNumberMask({ multiplier: 0.01, decimalPlaces: 0, suffix: '%', stringValue: true });
    }
  }, [subtype]);

  useEffect(() => {
    if (operator && operator !== 'equals' && operator !== 'between') {
      setUpperBound('');
    }
  }, [operator]);

  useEffect(() => {
    const lowerBoundIsNumber = typeof lowerBound === 'number';
    const upperBoundIsNumber = typeof upperBound === 'number';
    let formattedValue;

    if (!operator) return;

    switch (operator) {
      case 'NULL':
      case 'EXISTS':
        formattedValue = operator;
        break;
      case 'equals':
        formattedValue = lowerBoundIsNumber ? `${lowerBound}:gte:${lowerBound}:lte` : ':::';

        break;
      case 'between': {
        if (lowerBoundIsNumber && upperBoundIsNumber) {
          const [lowestNumber, highestNumber] = [lowerBound, upperBound].sort((a, b) => a - b);

          formattedValue = `${lowestNumber}:gte:${highestNumber}:lte`;
        } else if (lowerBound) {
          formattedValue = `${lowerBound}:gte::`;
        } else if (upperBound) {
          formattedValue = `${upperBound}:lte::`;
        } else {
          formattedValue = ':::';
        }

        break;
      }
      default:
        formattedValue = lowerBoundIsNumber ? `${lowerBound}:${operator}::` : null;
    }

    if (formattedValue !== undefined && formattedValue !== valueRef.current) {
      valueRef.current = formattedValue;
      onChange(formattedValue);
    }
  }, [operator, onChange, lowerBound, upperBound]);

  function handleOperatorChange(selectedVal) {
    setOperator(selectedVal);
  }

  return (
    <div className="number-filter filter-select">
      <ReactSelect
        onChange={handleOperatorChange}
        options={operatorOptions}
        searchable={true}
        value={operator}
      />

      {operator && !STATIC_FILTER_NULL_OPTIONS.includes(operator) && (
        <div className="flex-container align-center number-filter-inputs">
          <NumberInput
            name={`${name}-lower-bound`}
            numberMask={numberMask}
            onChange={setLowerBound}
            value={lowerBound}
          />

          {operator === 'between' && (
            <>
              <span className="number-filter-divider">and</span>
              <NumberInput
                name={`${name}-upper-bound`}
                numberMask={numberMask}
                onChange={setUpperBound}
                value={upperBound}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

NumberFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  subtype: PropTypes.string,
  value: PropTypes.string,
};

export default NumberFilter;
