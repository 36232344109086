import StatisticsCard, { FlexCardsContainer } from '@components/StatisticsCard';
import { Stat, useZAPTableStats } from '@frameworks/zap/hooks';

export type ZAPStatisticsCardsProps = {
  stats: Stat[];
};

export function ZAPStatisticsCards({ stats }: ZAPStatisticsCardsProps) {
  const { tableStats, isLoading } = useZAPTableStats(stats);

  return (
    <FlexCardsContainer>
      {tableStats.map((stat) => {
        const { label, render, value, ...props } = stat;
        if (render === undefined || render(stat, tableStats)) {
          return (
            <StatisticsCard
              isLoading={isLoading}
              key={label}
              label={label}
              value={value}
              {...props}
            />
          );
        }
        return null;
      })}
    </FlexCardsContainer>
  );
}
