import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { definitions } from '@typings/api.d';

export type AppInsights = Omit<definitions['Insight'], 'type'> & { type: string };

export type AppInsightsResponse = {
  body: AppInsights[];
};

export type AppInsightsParams = {
  companyAppId: string;
};

export function useAppInsights({ companyAppId }: AppInsightsParams) {
  const { data } = useQuery(
    ['appInsights', companyAppId],
    async () => {
      const appInsights: AppInsightsResponse = await getData({
        path: `companies/$companyId/apps/${companyAppId}/insights`,
      });
      return appInsights.body.filter((insight) => insight.type !== 'docusign_deprovision');
    },
    {
      enabled: Boolean(companyAppId),
      staleTime: 1000 * 60 * 60 * 12, // 12 hours
    },
  );

  return data ?? [];
}
