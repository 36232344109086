import { useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal, Icon } from '@zylo/orchestra';
import { connect } from 'react-redux';
import { stringify } from 'query-string';
import PopoverMenu from '@components/PopoverMenu';
import PopoverMenuItem from '@components/PopoverMenuItem';
import { useEventTracking, usePermissions } from '@hooks';
import { actions as documentActions } from '@modules/documents';
import { actions as companyAppActions } from '@modules/companyApps';
import { reduxObject } from '@utilities/propTypes';

const { API_URI } = process.env;

function DocumentActionCellComponent({
  appLabel,
  companyId,
  deleteDocument,
  details,
  documentId,
  filename,
  isSupplier = '',
}) {
  const { appId, appName, id: companyAppId } = details;
  const { appsReadOnlyUser, authCanAccessApps } = usePermissions('apps');
  const [showModal, setShowModal] = useState(false);
  const sendEvent = useEventTracking();

  function handleShowModal() {
    setShowModal(!showModal);
  }

  function handleDeleteDocument() {
    handleShowModal();
    let type = isSupplier ? 'suppliers' : 'apps';

    deleteDocument({
      subId: companyAppId,
      documentId,
      type,
    });
  }

  function handleDownloadClick() {
    if (appId) {
      sendEvent({
        eventName: 'APP_DETAILS_DOCS_ACTION',
        data: { 'App Name': appName, 'Doc Name': filename, Action: 'Download' },
      });
    } else {
      sendEvent({
        eventName: 'SUPPLIER_DETAILS_DOCS_ACTION',
        data: { 'Supplier Name': appLabel, 'Doc Name': filename, Action: 'Download' },
      });
    }
  }

  function handleViewClick() {
    if (appId) {
      sendEvent({
        eventName: 'APP_DETAILS_DOCS_ACTION',
        data: { 'App Name': appName, 'Doc Name': filename, Action: 'View' },
      });
    } else {
      sendEvent({
        eventName: 'SUPPLIER_DETAILS_DOCS_ACTION',
        data: { 'Supplier Name': appLabel, 'Doc Name': filename, Action: 'View' },
      });
    }
  }

  const ellipses = <Icon hoverColor="blue" icon="IconDots" size={1} relativeSize />;
  const url = `${API_URI}/companies/${companyId}/apps/${companyAppId}/documents/${documentId}`;
  const downloadUrl = `${url}?${stringify({ download: true })}`;

  return (
    <div className="action-cell">
      <PopoverMenu menuButton={ellipses}>
        <PopoverMenuItem
          action={handleViewClick}
          icon="IconEye"
          linkProps={{
            href: url,
            target: '_blank',
          }}
          optionText="View"
        />
        <PopoverMenuItem
          action={handleDownloadClick}
          icon="IconDownload"
          linkProps={{
            href: downloadUrl,
            download: filename,
          }}
          optionText="Download"
        />
        {!appsReadOnlyUser && authCanAccessApps && (
          <PopoverMenuItem action={handleShowModal} icon="IconTrash" optionText="Delete" />
        )}
      </PopoverMenu>

      <ConfirmationModal
        contentText="Deleting this document from Zylo is a permanent action and cannot be reversed."
        handleDismissClick={handleShowModal}
        handlePrimaryClick={handleDeleteDocument}
        isOpen={showModal}
        primaryButtonColor="red"
        primaryButtonText="Delete"
      />
    </div>
  );
}

const mapStateToProps = ({ documents, auth }, { rowData }) => {
  const { companyId } = auth;
  const { id: documentId, filename, appLabel } = rowData;
  const { documentUrlError, documentUrl } = documents;

  return {
    documentId,
    filename,
    appLabel,
    companyId,
    documentUrlError,
    documentUrl,
  };
};

DocumentActionCellComponent.propTypes = {
  appLabel: PropTypes.string,
  companyId: PropTypes.string,
  deleteDocument: PropTypes.func,
  details: reduxObject,
  documentId: PropTypes.string,
  filename: PropTypes.string,
};

export const DocumentActionCell = connect(mapStateToProps, {
  ...documentActions,
  ...companyAppActions,
})(DocumentActionCellComponent);
