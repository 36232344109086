import { OFFSET } from '../config/config';
import { emptyStringToNull } from './util';

/**
 * Calculate the offset for the next request
 * @param givenOffset an explicit offset override
 * @param stateParams the state for the current fetch system
 * @param stateParams.list the list of data
 * @param stateParams.hasMore does the endpoint have more data
 * @param dataListKey the property in state where the data array is stored
 */
function calculateOffset(
  givenOffset = undefined,
  stateParams: { [k: string]: string } & { hasMore?: boolean } = {},
  dataListKey = 'list',
) {
  if (Number.isInteger(givenOffset)) {
    return givenOffset;
  }
  const { hasMore, [dataListKey]: list } = stateParams;
  const calculatedOffset = hasMore ? list.length : 0;
  return calculatedOffset;
}

/**
 * ternary for determining the limit to use
 * @param limit optional limit override to use
 * @param defaultTo the default to fallback to
 */
function useLimit(limit: number | undefined = undefined, defaultTo: number = OFFSET) {
  return Number.isInteger(limit) ? limit : defaultTo;
}

/**
 * ternary for determining if endpoint has more data available
 * @param dataLength length of fetched data
 * @param limit the limit used for the data fetch
 * @return the determinination that the endpoint has more data
 */
function calculateHasMore(dataLength: number, limit: number = OFFSET) {
  return dataLength > 0 && dataLength % limit === 0;
}

/**
 * build or reset the list data
 */
function buildList(offset = 0, fetchedData = [], stateList = []) {
  return offset === 0 ? fetchedData : stateList.concat(fetchedData);
}

/**
 * parses and returns the sort field to be set in redux state
 * @param sortKey the property that holds the new sort value
 */
function calculateSort(updateStatePayload: { [k: string]: string | any } = {}, sortKey = 'sortBy') {
  const { [sortKey]: sortBy } = updateStatePayload;
  return typeof sortBy === 'string' ? [sortBy] : sortBy;
}

/**
 * maps an array of objects, invoking a method on each object[field]
 */
function mapFields<T>(
  data: T[] = [],
  fields: (keyof T)[] = [],
  mapFn: (value: any, field: keyof T, record: T) => void = () => {},
) {
  const fieldsToMap = Array.isArray(fields) ? fields : [fields];
  return data.map((record) => {
    return fieldsToMap.reduce((modRecord, field) => {
      return {
        ...modRecord,
        [field]: mapFn(modRecord[field], field, modRecord),
      };
    }, record);
  });
}

/**
 * invokes mapFields with emptyStringToNull
 * @param  {Array}  [data=[]]
 * @param  {string[]|string}  [fields=[]]
 * @return {Array}
 */
function castEmptyFieldsToNull(data = [], fields = []) {
  return mapFields(data, fields, emptyStringToNull);
}

const DEFAULT_STATE_TEMPLATE = Object.freeze({
  list: [],
  hasMore: true,
  sort: ['+name'],
  isLoading: false,
  error: null,
});

export {
  calculateOffset,
  useLimit,
  calculateHasMore,
  buildList,
  calculateSort,
  mapFields,
  castEmptyFieldsToNull,
  DEFAULT_STATE_TEMPLATE,
};
