import { useEventTracking } from '@hooks';
import { useSearchState, useSearchUpdate } from '../../contexts/searchContext';
import SearchBar from '../forms/SearchBar';

type SmartSearchBarProps = {
  searchEventName?: string;
};

export default function SmartSearchBar({ searchEventName }: SmartSearchBarProps) {
  return <SmartSearchBar_DisplayLayer {...useDataLayer(searchEventName)} />;
}

export function SmartSearchBar_DisplayLayer(props: {
  clearSearch: () => void;
  handleSearch: (searchValue: string) => void;
  searchValue: string;
}) {
  return <SearchBar {...props} />;
}

function useDataLayer(searchEventName?: string) {
  const searchValue = useSearchState();
  const setSearchValue = useSearchUpdate();
  const sendEvent = useEventTracking();

  function clearSearch() {
    setSearchValue('');
  }

  function handleSearch(searchValue: string) {
    setSearchValue(searchValue);
    if (searchEventName) {
      sendEvent({
        eventName: searchEventName,
        data: { 'Search Input': searchValue },
      });
    }
  }

  return { clearSearch, handleSearch, searchValue };
}
