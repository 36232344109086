import { useQuery, UseQueryResult } from 'react-query';
import { getData } from '@utilities/xhr';

interface ITopAppParams {
  appId?: string;
  topAppsFilters: string;
}
interface IUseTopAppsBenchmarkProps extends ITopAppParams {}

type TopAppBenchmarkResponse = {
  body: ITopAppsBenchmarkResult;
};

interface ITopAppsBenchmarkResult {
  topApps: ITopAppBenchmark[];
  matchedApps: number;
}

export interface ITopAppBenchmark {
  row: number;
  appName: string;
  appId: string;
  cohort: string;
  category: string;
  subcategory: string;
  functionality: string;
  preferenceScore: string;
  isOwnedByCompany: boolean;
  ownedApps?: {
    appLabels: string[];
    ids: string[];
  };
}

function dedupeArrayByKey<T>(array: T[] | undefined, key: string): T[] {
  if (!array) {
    return [];
  }
  // @ts-ignore need investigate error `Type 'IterableIterator<T>' is not an array type or a string type. Use compiler option '--downlevelIteration' to allow iterating of iterators.ts(2569)`
  return [...new Map(array.reverse().map((item) => [item[key], item])).values()];
}

async function fetchTopApps(params: ITopAppParams): Promise<ITopAppsBenchmarkResult> {
  const {
    body: { topApps, matchedApps },
  }: TopAppBenchmarkResponse = await getData({
    path: `companies/$companyId/benchmark/topapps`,
    params,
  });

  const dedupedApps = dedupeArrayByKey(topApps, 'appName');
  // Sorting here so we can reliably assume it's sorted by Row number before consumption
  return {
    topApps: dedupedApps.sort((app1, app2) => app1.row - app2.row),
    matchedApps: matchedApps,
  };
}

export function useTopAppsBenchmark({
  appId,
  topAppsFilters,
}: IUseTopAppsBenchmarkProps): UseQueryResult<ITopAppsBenchmarkResult | undefined> {
  // Filters are required by the API, but some setups may try to load this hook
  // before default filters have be propagated. If no filters, disable the query.
  // Because we take the stringified API filter directly, an 'empty' filter selection is actually "[]"
  const hasFilters = !!topAppsFilters && topAppsFilters.length > 2;
  return useQuery(
    ['topApps', { appId, topAppsFilters }],
    () => fetchTopApps({ appId, topAppsFilters }),
    {
      staleTime: 1000 * 60 * 60 * 12, // 12 hours
      enabled: hasFilters,
    },
  );
}
