import { API_REQUEST_FILTER_TYPES } from './filterConstants';

const { BOOL, NUMERIC_RANGE, RANGE, TEXT } = API_REQUEST_FILTER_TYPES;

export default function convertApiFilters(filters) {
  const filtersArray = Array.isArray(filters) ? filters : JSON.parse(filters);
  const filtersObj = {};

  function formatRange(rangeValues) {
    let separators = ':::';
    const rangeString = rangeValues.reduce((str, val) => {
      separators = separators.substring(1);
      return `${str}${val}:`;
    }, '');

    return `${rangeString}${separators}`;
  }

  filtersArray.forEach((filter) => {
    const [type, filterName, ...filterValues] = filter;

    switch (type) {
      case BOOL:
        filtersObj[filterName] = filterValues;
        break;
      case NUMERIC_RANGE:
        filtersObj[filterName] = formatRange(filterValues);
        break;
      case RANGE:
        filtersObj[filterName] = formatRange(filterValues);
        break;
      case TEXT:
        filtersObj[filterName] = filterValues;
        break;
      default:
        filtersObj[filterName] = filterValues[0];
    }
  });

  return filtersObj;
}
