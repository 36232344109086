import { debounce } from 'lodash-es';
import { ReactElement } from 'react';
import { SelectOption } from '@typings';
import { useBulkEditSearchOptions } from './bulkEditDrawer/useBulkEditSearchOptions';

type ChildrenProps = {
  onSearch: (term: string) => void;
  options: SelectOption<string>[];
};

export type ZAPComboboxWrapperProps = {
  addBlank?: boolean;
  children: (props: ChildrenProps) => ReactElement;
  minSearchLength: number;
  fieldKey: string;
  ZAPName?: string;
};

export default function ZAPComboboxWrapper({
  addBlank,
  children,
  minSearchLength,
  fieldKey,
  ZAPName,
}: ZAPComboboxWrapperProps) {
  const { handleSearch, options } = useBulkEditSearchOptions({
    addBlank,
    editSlug: fieldKey,
    minSearchLength,
    ZAPName,
  });
  return children({
    onSearch: debounce(handleSearch, 250),
    options,
  });
}
