import { useMutation, useQueryClient } from 'react-query';
import { definitions } from '@typings/api';
import { useAppMessaging } from '../hooks';
import { putData } from '../utilities/xhr';

type SoftwareSpendChargeResponse = Required<definitions['SoftwareSpendCharge']>;
type ChargeResponse = Required<definitions['Charge']>;
type SavePOReassignmentResponse = SoftwareSpendChargeResponse & ChargeResponse;

export type PurchaseOrderParams = {
  id: string;
  poItemToReassign: {
    purchaseOrderId?: string | null | undefined;
    poLineItemId?: string | null | undefined;
    poMatchMethod?: string | null | undefined;
  };
};

export default function useSavePOReassignment() {
  const queryClient = useQueryClient();
  const showMessage = useAppMessaging();

  return useMutation(
    async ({ id, poItemToReassign }: PurchaseOrderParams) => {
      const responseData = await putData({
        path: `companies/$companyId/charges/${id}`,
        params: poItemToReassign,
      }).then((response: { body: SavePOReassignmentResponse }) => {
        const { body } = response;
        return body;
      });
      return responseData;
    },
    {
      onSuccess: async (data) => {
        if (data.softwareSpendChargeResponse.poNumberPoLineItemNumber === '') {
          showMessage({
            message: `Payment has been unassigned`,
            isError: false,
          });
        } else {
          showMessage({
            message: `Payment has been successfully attached to PO: ${data.softwareSpendChargeResponse.purchaseOrderNumber}`,
            isError: false,
          });
        }

        await queryClient.invalidateQueries(['companies/$companyId/softwareSpendCharges']);
      },
      onError: () => {
        showMessage({
          message: 'There was an error attaching payment to selected purchase order',
          isError: true,
        });
      },
    },
  );
}
