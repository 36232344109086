import classNames from 'clsx';
import { Colors, Tooltip, Transition } from '@zylo/orchestra';
import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import AppLogo from '@components/AppLogo';
import StatusBadge from '@components/applications/StatusBadge';
import TruncatedText from '@components/TruncatedText';
import { usePermissions } from '@hooks';

const StyledAppLogo = styled(AppLogo)``;

const SubscriptionCellContainer = styled.div`
  ${StyledAppLogo} {
    margin-right: 0.5rem;
  }

  width: 100%;
`;

const StyledLink = styled(Link)`
  color: ${Colors.darkteal};
  ${Transition()}

  &:hover {
    color: ${Colors.forrestteal};
  }
`;

export function SubscriptionCell({
  data,
  disableClick,
  labelAccessor,
  rowData,
  truncate,
  hideBadge,
}: {
  data: any;
  disableClick?: boolean;
  labelAccessor?: string;
  rowData: any;
  truncate?: number;
  hideBadge?: boolean;
}) {
  const { authCanAccessApps } = usePermissions('apps');

  const isClickDisabled = disableClick || !authCanAccessApps;

  const {
    appDomain,
    companyAppId,
    domain,
    hideStatus,
    id,
    isNew,
    launchUrl,
    status,
    subscriptionCompanyAppId,
  } = labelAccessor ? data : rowData;
  const logoInfo = appDomain || domain || launchUrl;
  const label = labelAccessor ? data[labelAccessor] : data;

  /**
   * NOTE: id/companyAppId are the same value, unfortunately they are used interchangably in the api on different endpoints (or were at one point). subscriptionCompanyAppId is also the same value, used for the software spend charges table.
   * appDomain/domain/launchUrl are theoretically the same case .. however launchUrl became a separate data field changeable by the user,
   * so moving forward we should NEVER use it as a substitute for the unique url we use to store/fetch an app's logo
   * we are keeping it here for legacy compatibility
   */

  return (
    <SubscriptionCellContainer
      className={classNames(
        'flex-container align-center justify-between subscription-cell',
        isClickDisabled && 'not-clickable',
      )}
    >
      <div className="flex-container align-center subscription-cell-name-container">
        {logoInfo && <StyledAppLogo filename={logoInfo} />}
        {isClickDisabled ? (
          <TruncatedText limit={truncate} text={label} />
        ) : (
          <StyledLink
            className="subscription-cell-name"
            to={`/subscriptions/${companyAppId || subscriptionCompanyAppId || id}`}
          >
            <TruncatedText limit={truncate} text={label} />
          </StyledLink>
        )}
        {!hideStatus && !hideBadge && <StatusBadge status={status} />}
        {isNew === 'Yes' && (
          <div className="flag-container flex-container justify-center align-center wrap-none">
            <Tooltip text="Subscription created in the last 30 days.">
              <div className="new-app" />
            </Tooltip>
          </div>
        )}
      </div>
    </SubscriptionCellContainer>
  );
}
