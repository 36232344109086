import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { camelCase } from 'lodash-es';
import { Field } from 'redux-form';
import AutosaveForm from '@components/forms/AutosaveForm';
import ZyloFormField from '@components/forms/ZyloFormField';
import { formatNumber, normalizeNumber, genKey } from '@utilities/util';
import { reduxObject } from '@utilities/propTypes';

export class PurchasedLicensesCell extends PureComponent {
  static propTypes = {
    appSpecificFields: reduxObject,
    rowData: reduxObject,
    save: PropTypes.func.isRequired,
  };

  static defaultProps = {
    appSpecificFields: {},
  };

  constructor(props) {
    super(props);

    const { rowData } = props;

    this.state = {
      propertyName: rowData.name ? camelCase(rowData.name.toLowerCase()).replace(/\./, '') : '',
      formKey: genKey(),
    };
  }

  static getDerivedStateFromProps({ rowData }, { propertyName, needsReset }) {
    const stateUpdate = {};
    const newPropertyName = camelCase(rowData.name.toLowerCase()).replace(/\./, '');

    if (newPropertyName !== propertyName) {
      stateUpdate.propertyName = newPropertyName;
      stateUpdate.formKey = genKey();
      stateUpdate.needsReset = true;
    } else if (needsReset) {
      stateUpdate.needsReset = false;
    }

    return stateUpdate;
  }

  validateField = (fields) => {
    const { propertyName } = this.state;
    const val = fields[propertyName];
    const errors = {};

    if (Number.isNaN(val)) {
      errors[propertyName] = 'Invalid number value';
    }

    return { ...errors };
  };

  render() {
    const { appSpecificFields, save } = this.props;
    const { propertyName, formKey, needsReset } = this.state;
    const values = {
      [propertyName]: appSpecificFields[propertyName],
    };

    return (
      <div className="input-cell">
        {needsReset ? (
          <div />
        ) : (
          <AutosaveForm
            data={values}
            form={`PurchasedLicensesCell-${formKey}`}
            saveMethod={save}
            validate={this.validateField}
          >
            <Field
              component={ZyloFormField}
              format={formatNumber}
              name={propertyName}
              normalize={normalizeNumber}
            />
          </AutosaveForm>
        )}
      </div>
    );
  }
}
