import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { Icon } from '@zylo/orchestra';

class SearchBar extends PureComponent {
  static propTypes = {
    clearSearch: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    searchOnChange: PropTypes.bool,
    searchValue: PropTypes.string,
  };

  static defaultProps = {
    searchValue: '',
    isDisabled: false,
    placeholder: 'Search',
    searchOnChange: false,
  };

  constructor() {
    super();
    this.input = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.input.current.value = this.props.searchValue;
    }
  }

  handleKeyPress = (e) => {
    if (!this.props.searchOnChange) {
      if (e.key === 'Escape' || e.key === 'Esc') {
        this.input.current.blur();
      } else if (e.key === 'Enter' && e.target.value.trim().length) {
        this.props.handleSearch(e.target.value);
        this.input.current.blur();
      }
    }
  };

  handleChange = (e) => {
    const { handleSearch, searchOnChange } = this.props;

    if (searchOnChange) {
      handleSearch(e.target.value);
    }
  };

  handleClearSearch = () => {
    this.input.current.value = '';
    this.props.clearSearch();
  };

  handleSearchIconClick = () => {
    this.props.handleSearch(this.input.current.value);
  };

  render() {
    const { isDisabled, placeholder, searchValue } = this.props;

    const iconProps = {
      disabled: isDisabled,
      size: 1.5,
      relativeSize: true,
      hoverColor: 'teal6',
    };
    let afterIcon = (
      <Icon icon="IconX" onClick={this.handleClearSearch} testId="icon-close" {...iconProps} />
    );

    if (!searchValue) {
      afterIcon = (
        <Icon
          icon="IconSearch"
          onClick={this.handleSearchIconClick}
          testId="icon-search"
          {...iconProps}
        />
      );
    }

    return (
      <div className="search-filter-container">
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          defaultValue={searchValue}
          disabled={isDisabled}
          name="search"
          onChange={this.handleChange}
          onKeyDown={this.handleKeyPress}
          placeholder={placeholder}
          ref={this.input}
          spellCheck="false"
          type="text"
        />
        <span className="search-action">{afterIcon}</span>
      </div>
    );
  }
}

export default SearchBar;
