import styled, { css } from 'styled-components/macro';
import { Borders, Colors, defaultborder, useTableConfigState } from '@zylo/orchestra';

type PercentChangeCellLightweightContainerProps = {
  condensed: boolean;
  percent: number;
  rowHeight: number;
};

const PercentChangeCellLightweightContainer = styled.div<PercentChangeCellLightweightContainerProps>`
  display: flex;
  justify-content: space-around;

  .per-change-cell {
    display: flex;
    height: ${({ rowHeight }) => `${rowHeight + 3}px`};
    width: 250px;

    &__section {
      align-items: center;
      display: flex;
      flex: 1 0;

      &:first-child {
        justify-content: flex-end;
      }

      &:last-child {
        justify-content: flex-start;
      }
    }

    &__bar {
      height: ${({ condensed }) => (condensed ? '1rem' : '1.25rem')};
      ${({ percent }) => css`
        min-width: ${percent === 0 ? 0 : '8px'};
        width: ${percent < 0 ? percent * -1 : percent}%;
      `}

      &--negative {
        background: ${Colors.warning};
        border-radius: ${Borders.radius.medium} 0 0 ${Borders.radius.medium};
        margin-left: 0.5rem;
      }

      &--positive {
        background: ${Colors.blue2};
        border-radius: 0 ${Borders.radius.medium} ${Borders.radius.medium} 0;
        margin-right: 0.5rem;
      }
    }

    &__divider {
      border-right: ${defaultborder};
      width: 1px;
    }
  }
`;

type PercentChangeCellLightweightProps = {
  barHeight?: number;
  cornerRadius?: number;
  data: string;
  padding?: number;
};

export function PercentChangeCellLightweight({ data }: PercentChangeCellLightweightProps) {
  const { condensed, rowHeight } = useTableConfigState();
  const percent = parseInt(data) || 0;
  return (
    <PercentChangeCellLightweightContainer
      condensed={Boolean(condensed)}
      percent={percent}
      rowHeight={rowHeight ?? 43}
    >
      <div className="per-change-cell">
        <div className="per-change-cell__section">
          {percent < 0 && (
            <>
              <span className="per-change-cell__number">{percent}%</span>
              <div className="per-change-cell__bar per-change-cell__bar--negative"></div>
            </>
          )}
        </div>
        <div className="per-change-cell__divider"></div>
        <div className="per-change-cell__section">
          {percent >= 0 && (
            <>
              <div className="per-change-cell__bar per-change-cell__bar--positive"></div>
              <span className="per-change-cell__number">+{percent}%</span>
            </>
          )}
        </div>
      </div>
    </PercentChangeCellLightweightContainer>
  );
}
