import { useMutation, useQueryClient } from 'react-query';
import { putData } from '@utilities/xhr';
import { useAppMessaging } from '@hooks/useAppMessaging';
import cleanAppDetailsPayloadData from '@utilities/cleanAppDetailsPayloadData';
import { useGetCompanyApp } from '@hooks';
import { useCompanyData } from '@hooks/company';

export function useUpdateCompanyApp(companyAppId: string | undefined) {
  const client = useQueryClient();
  const showMessage = useAppMessaging();
  const { data: currentCompanyApp } = useGetCompanyApp(companyAppId);
  const { preferences } = useCompanyData().companyData;

  const {
    appDetails: appDetailsPreferences = {
      application: [],
      general: [],
      subscription: [],
    },
  } = preferences;

  return useMutation(
    async (updates: Record<string, unknown>) => {
      const response = await putData({
        path: `companies/$companyId/apps/${companyAppId}`,
        params: cleanAppDetailsPayloadData({
          appDetailsPreferences,
          payloadData: { ...(currentCompanyApp ?? {}), ...updates },
        }),
      });

      return response;
    },
    {
      onSuccess: async ({ body }) => {
        showMessage({
          message: body.message,
        });
        await client.invalidateQueries(['companyApp', companyAppId]);
      },
      onError: () => {
        showMessage({
          message: 'There was an error updating your subscription. Please try again.',
          isError: true,
        });
      },
    },
  );
}
