import { useQuery } from 'react-query';
import { Contract, HttpResponse } from '@typings';
import { getData } from '@utilities/xhr';

export function useGetCompanyAppContracts(companyAppId: string | undefined) {
  return useQuery(
    ['companyApp', 'contracts', companyAppId],
    async () => {
      const response: HttpResponse<Contract[]> = await getData({
        path: `companies/$companyId/apps/${companyAppId}/contracts`,
      });

      return response.body;
    },
    {
      enabled: Boolean(companyAppId),
    },
  );
}
