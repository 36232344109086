import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { debounce } from 'lodash-es';
import { ConfirmationModal, Icon } from '@zylo/orchestra';
import { useDispatch, useSelector } from 'react-redux';
import PopoverMenu from '@components/PopoverMenu';
import PopoverMenuItem from '@components/PopoverMenuItem';
import SubscriptionOption from '@components/forms/inputs/SubscriptionOption';
import TypeAhead from '@components/forms/inputs/TypeAhead';
import { useEventTracking } from '@hooks';
import { actions as chargeActions } from '@modules/companyCharges';
import { getData } from '@utilities/xhr';
import { reduxObject } from '@utilities/propTypes';

/**
 * This cell can be used for tables that pull charges from the charges data stored in
 * the Redux store. For a cell that works with the software_spend_charges table as
 * well, see CompanyAppSelectorCell
 */
export function LegacyCompanyAppSelectorCell(props) {
  return <LegacyCompanyAppSelectorCell_DisplayLayer {...props} {...useDataLayer()} />;
}

LegacyCompanyAppSelectorCell.propTypes = {
  data: PropTypes.string,
  index: PropTypes.number,
  isSupplierPayments: PropTypes.bool,
  rowData: reduxObject,
};

export function LegacyCompanyAppSelectorCell_DisplayLayer({
  additionalOnChange = () => {},
  modifiedChargesAppCache,
  data,
  editCompanyCharge,
  handleSearchChange,
  index,
  isSupplierPayments,
  rowData,
  searchOptions,
  sendEvent,
}) {
  const [isConfirmingModal, setIsConfirmingModal] = useState(false);
  const [reassigning, setReassigning] = useState(false);

  function handleBlur() {
    setReassigning(false);
  }

  function handleChange(_, option) {
    const { id, transactionId } = rowData;

    if (option) {
      editCompanyCharge({
        chargeId: id,
        appData: option,
        chargeIndex: index,
      });
      additionalOnChange();
      setReassigning(false);
      sendEvent({
        eventName: 'REASSIGNED_PAYMENT',
        data: {
          'Charge Id': id,
          'Transaction Id': transactionId,
          'Company App Id': option.id,
        },
      });
    }
  }

  function handleReassign() {
    handleSearchChange('');
    setReassigning(true);
    sendEvent({
      eventName: 'REASSIGN_PAYMENT_CLICK',
    });
  }

  function handleUnassign() {
    const { companyAppId, id, subscriptionCompanyAppId, transactionId } = rowData;

    setIsConfirmingModal(false);
    editCompanyCharge({
      chargeId: id,
      appData: { appDomain: '', appLabel: '', id: '' },
      chargeIndex: index,
    });
    sendEvent({
      eventName: 'UNASSIGNED_PAYMENT',
      data: {
        'Charge Id': id,
        'Transaction Id': transactionId,
        'Company App Id': companyAppId || subscriptionCompanyAppId,
      },
    });
  }

  function handleUnassignModalOpen() {
    setIsConfirmingModal(true);
    sendEvent({
      eventName: 'UNASSIGN_PAYMENT_CLICK',
    });
  }

  function handleUnassignModalClose() {
    setIsConfirmingModal(false);
  }

  function renderSelectedApp() {
    const selectedOption = modifiedChargesAppCache[data] || rowData;

    return (
      <SubscriptionOption option={selectedOption}>{selectedOption.appLabel}</SubscriptionOption>
    );
  }

  return (
    <div className="flex-container align-center justify-end company-app-selector-cell">
      {(isSupplierPayments || reassigning) && (
        <TypeAhead
          allowCustomValue={false}
          allowMultiple={false}
          customOption={SubscriptionOption}
          customValueRenderer={renderSelectedApp}
          handleSearchChange={debounce(handleSearchChange, 300)}
          labelKey="appLabel"
          matchPos="any"
          name="companyApp"
          onBlur={handleBlur}
          onChange={handleChange}
          options={searchOptions}
          value={data}
          valueKey="id"
          showMenuByDefault
        />
      )}

      {!isSupplierPayments && !reassigning && (
        <div
          className={classNames(
            'selected-option-read-only flex-container justify-between align-center',
            data ? '' : 'color-gray',
          )}
        >
          {data ? renderSelectedApp() : 'Unassigned'}
          <PopoverMenu
            menuButton={<Icon hoverColor="blue" icon="IconDots" size={1} relativeSize />}
          >
            <PopoverMenuItem action={handleReassign} icon="IconSwitch3" optionText="Reassign" />
            <PopoverMenuItem
              action={handleUnassignModalOpen}
              icon="IconUnlink"
              isDisabled={!data}
              optionText="Unassign"
            />
          </PopoverMenu>
        </div>
      )}

      <ConfirmationModal
        contentText="Unassigning this transaction will remove it from your Zylo account. This is a permanent action and cannot be undone."
        handleDismissClick={handleUnassignModalClose}
        handlePrimaryClick={handleUnassign}
        headerText="Unassign Payment"
        isOpen={isConfirmingModal}
        width={330}
      />
    </div>
  );
}

LegacyCompanyAppSelectorCell_DisplayLayer.propTypes = {
  additionalOnChange: PropTypes.func,
  data: PropTypes.string,
  editCompanyCharge: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isSupplierPayments: PropTypes.bool,
  modifiedChargesAppCache: reduxObject,
  rowData: reduxObject,
  searchOptions: PropTypes.arrayOf(reduxObject),
  sendEvent: PropTypes.func.isRequired,
};

function useDataLayer() {
  const { modifiedChargesAppCache } = useSelector(({ companyCharges }) => companyCharges);
  const [searchOptions, setSearchOptions] = useState([]);
  const dispatch = useDispatch();
  const sendEvent = useEventTracking();

  function editCompanyCharge(params) {
    dispatch(chargeActions.editCompanyCharge(params));
  }

  function handleSearchChange(search) {
    getData({
      path: 'companies/$companyId/apps',
      params: { limit: 6, search, sort: '+appLabel' },
    }).then(({ body }) => {
      setSearchOptions(body.companyApps);
    });
  }

  return {
    modifiedChargesAppCache,
    editCompanyCharge,
    handleSearchChange,
    searchOptions,
    sendEvent,
  };
}
