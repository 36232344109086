import { Tooltip } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import AppLogo from '@components/AppLogo';

const StyledAppStackCell = styled.div`
  align-items: center;
  display: flex;

  p {
    margin: 0 0 0 0.5rem;
  }
`;

export type AppStackCellProps = {
  data: string;
  logoFilename: string;
  isUnclassified: boolean;
};

export function AppStackCell({ data, logoFilename, isUnclassified }: AppStackCellProps) {
  return (
    <StyledAppStackCell>
      <AppLogo filename={logoFilename} />
      <p className="subscription-cell-name">{data}</p>
      {isUnclassified && (
        <Tooltip text="This data could not be attributed to a specific application and has been assigned to the supplier." />
      )}
    </StyledAppStackCell>
  );
}
