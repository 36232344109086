import { get } from 'lodash-es';
import { QueryStatus } from 'react-query';
import { FieldConfig, FormElementConfig } from './types';

/**
 * Reconciles multiple status values. Will return a status of 'loading'
 * as long as one status still has the value of 'loading'. If 'error' is
 * returned it is recommended that you check for data availability and
 * then decide what to show the user.
 *
 * **Note**: 'idle' has been removed in react-query v4 and is treated
 * as 'success' by this function.
 *
 * ```
 * reconcileQueryStatuses('error', 'loading', 'success') => 'loading'
 * reconcileQueryStatuses('idle', 'loading', 'success') => 'loading'
 * reconcileQueryStatuses('error', 'success', 'success') => 'error'
 * reconcileQueryStatuses('error', 'idle', 'success') => 'error'
 * reconcileQueryStatuses('success', 'success', 'success') => 'success'
 * reconcileQueryStatuses('idle', 'success', 'success') => 'success'
 * ```
 * */
export function reconcileQueryStatuses(...statuses: QueryStatus[]): QueryStatus {
  if (statuses.includes('loading')) return 'loading';
  if (statuses.includes('error')) return 'error';
  if (statuses.every((s) => s === 'success')) return 'success';

  return 'success';
}

/**
 * Provides defensive checks against null | undefined or bad elements in the `FieldConfigArray`.
 * The second argument takes a function that will be used to map over the array rendering the fields.
 * */
export function fieldBuilder(
  f: FormElementConfig[],
  b: (c: Exclude<FormElementConfig, null | undefined>) => JSX.Element | null,
) {
  const fields = f.filter(configFilter) as FieldConfig[];
  return fields.map(b);
}
/**
 * A predicate function that provides defensive checks against null | undefined or
 * bad elements in the `FieldConfigArray`.
 * */
export function configFilter(f: FormElementConfig) {
  if (
    !Array.isArray(f) ||
    /* 
      The following 3 checks verify that the second element of the tuple is
      an 'object' that is neither an array or null.
    */
    !Boolean(f[1]) ||
    Array.isArray(f[1]) ||
    typeof f[1] !== 'object' ||
    /* 
      Checking that the form element render option is not set to false.
      Use of this property is optional, the form should behave as if it 
      were set to true.
    */
    (f[2] && !(f[2]?.render ?? true))
  ) {
    return false;
  }

  return true;
}

/**
 * Returns an error message for the given field from an errors object. If no message
 * exists, returns undefined.
 * */
export function errorMessageHandler<T extends {}, K extends keyof T>(
  errors: T,
  name: K,
): string | undefined {
  return get(errors, `${String(name)}.message`, undefined);
}

type ChangeHandler<T> = (v: T) => void;
/**
 * Manages the calling of the change handlers provided by the form and the form's consumer.
 */
export function handleChangeHelper<T>(v: T, form: ChangeHandler<T>, external?: ChangeHandler<T>) {
  form(v);

  if (typeof external === 'function') {
    external(v);
  }
}
