import { useMutation, useQueryClient } from 'react-query';
import { useAppMessaging } from '@hooks';
import { putData } from '@utilities/xhr';
import { Contract } from '@typings';
import { useInvalidateZapQueries } from '@frameworks/zap';

type ContractRequest = {
  data: Contract;
  contractId: string;
};

async function saveContractData({ data, contractId }: ContractRequest) {
  const response = await putData({
    path: `companies/$companyId/contracts/${contractId}`,
    params: { ...data },
  });
  return response;
}
export default function useSaveContractData() {
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();
  const invalidateZapQueries = useInvalidateZapQueries();

  return useMutation(
    ({ data, contractId }: ContractRequest) => saveContractData({ data, contractId }),
    {
      onSuccess: () => {
        invalidateZapQueries('contracts');
        showMessage({
          message: `Contract has been successfully saved.`,
          isError: false,
        });
        queryClient.invalidateQueries(['contractData']);
        queryClient.invalidateQueries(['companyApp', 'contracts']);
      },
      onError: () => {
        showMessage({
          message: 'There was an error updating the contract.',
          isError: true,
        });
      },
    },
  );
}
