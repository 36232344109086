import { useQuery } from 'react-query';
import { HttpResponse, Vue } from '@typings';
import { getData } from '@utilities/xhr';

export type UseGetVuesParameters = {
  offset?: number;
  sort?: string;
  types?: Vue['type'][];
  /**
   * Passing `zyloAuthId` will override a `type` parameter set to "manual" and return
   * both "manual" and "appOwner" vues if they exist for the given `zyloAuthId`.
   * */
  zyloAuthId?: string;
};

export type VuesResponse = HttpResponse<{ appOwner?: Vue[]; manual: Vue[] }>;

export function useGetVues(params: UseGetVuesParameters) {
  return useQuery(['vues', params], async () => {
    const response: VuesResponse = await getData({
      path: `companies/$companyId/vues`,
      params,
    });

    return response.body;
  });
}
