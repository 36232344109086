import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@zylo/orchestra';

export class ToolTipIconWithTextCell extends PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,
    disableIcon: PropTypes.bool,
    disableToolTip: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    message: PropTypes.string,
    styling: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };
  static defaultProps = { message: '', disableToolTip: true, disableIcon: false };

  render() {
    const { message, text, color, icon, disableToolTip, disableIcon, styling } = this.props;
    const textClass = `color-${color}`;
    return (
      <div>
        {!disableToolTip ? (
          <div className={styling}>
            <div>
              <p className={textClass}>{text}</p>
            </div>
            <Tooltip style={{ maxWidth: 500 }} text={message}>
              <Icon
                color={color}
                icon={icon}
                role="rowheader"
                size={1.25}
                titleOverride=""
                relativeSize
              />
            </Tooltip>
          </div>
        ) : (
          <div className={styling}>
            <div>
              <p className={textClass}>{text}</p>
            </div>
            {!disableIcon && (
              <Icon
                color={color}
                icon={icon}
                role="rowheader"
                size={1.25}
                titleOverride=""
                relativeSize
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
