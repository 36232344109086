import AppLogo from '@components/AppLogo';
import TruncatedText from '@components/TruncatedText';
import { Contract } from '@typings';

export type SupplierCellProps = {
  data: Contract['supplier'];
  truncate: number;
};

export function SupplierCell({ data, truncate }: SupplierCellProps) {
  const { supplierLabel, supplierDomain } = data;

  return (
    <div className="flex-container align-center justify-between subscription-cell">
      <div className="flex-container align-center subscription-cell-name-container">
        {supplierDomain && <AppLogo filename={supplierDomain} />}
        <p className="subscription-cell-name">
          <TruncatedText limit={truncate} text={supplierLabel} />
        </p>
      </div>
    </div>
  );
}
