import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------

const DEFAULT_STATUS_BAR_STATE = {
  successMessage: null,
  errorMessage: null,
  displayStatusBar: false,
  messageExtended: false,
};

export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';

export const ATTEMPT_MESSAGE_HIDE = 'ATTEMPT_MESSAGE_HIDE';
export const EXTEND_MESSAGE = 'EXTEND_MESSAGE';

// ------------------------------------
// Actions
// ------------------------------------

export const showSuccessMessage = createAction(SHOW_SUCCESS_MESSAGE);
export const showErrorMessage = createAction(SHOW_ERROR_MESSAGE);

export const attemptMessageHide = createAction(ATTEMPT_MESSAGE_HIDE);
export const extendMessage = createAction(EXTEND_MESSAGE);

function showZyloSuccessMessage(successMessage, messageDuration) {
  return (dispatch) => {
    dispatch(showSuccessMessage(successMessage));
    setTimeout(() => {
      dispatch(attemptMessageHide({ override: false }));
    }, messageDuration || 6000);
  };
}

function showZyloErrorMessage(errorMessage) {
  return (dispatch) => {
    dispatch(showErrorMessage(errorMessage));
    setTimeout(() => {
      dispatch(attemptMessageHide({ override: false }));
    }, 6000);
  };
}

function extendZyloMessage() {
  return (dispatch) => {
    dispatch(extendMessage());
  };
}

function dismissZyloMessage() {
  return (dispatch) => {
    dispatch(attemptMessageHide({ override: true }));
  };
}

export const actions = {
  showZyloSuccessMessage,
  showZyloErrorMessage,
  extendZyloMessage,
  dismissZyloMessage,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions(
  {
    [SHOW_SUCCESS_MESSAGE]: (state, { payload }) => {
      return {
        ...state,
        successMessage: payload || 'Save Complete',
        errorMessage: null,
        displayStatusBar: true,
      };
    },
    [SHOW_ERROR_MESSAGE]: (state, { payload }) => {
      return { ...state, errorMessage: payload, successMessage: null, displayStatusBar: true };
    },
    [ATTEMPT_MESSAGE_HIDE]: (state, { payload }) => {
      let hidePayload = { ...state };

      if (!state.messageExtended || payload.override) {
        hidePayload = { ...DEFAULT_STATUS_BAR_STATE };
      }

      return hidePayload;
    },
    [EXTEND_MESSAGE]: (state) => {
      return { ...state, messageExtended: true };
    },
  },
  DEFAULT_STATUS_BAR_STATE,
);
