import { useQuery } from 'react-query';
import { Categorization, HttpResponse } from '@typings';
import { getData } from '@utilities/xhr';

export type AppCategories = Categorization['data'];

type GetAppCategoriesOptions = { enabled?: boolean };

export function useGetAppCategories({ enabled = true }: GetAppCategoriesOptions) {
  return useQuery(
    ['appCategories'],
    async () => {
      const response: HttpResponse<AppCategories> = await getData({
        path: 'companies/$companyId/appCategories',
      });

      return response.body;
    },
    { enabled },
  );
}
