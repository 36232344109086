import { useCallback } from 'react';
import { get } from 'lodash-es';
import { useAppDetailsCompanyAppId } from '@hooks';
import {
  useActiveSavedFilterState,
  useActiveSavedFilterUpdate,
} from '@contexts/savedFilterContexts/activeSavedFilterContext';
import { useFiltersState } from '@contexts/filtersContext';
import { useSavedFilterModificationState } from '@contexts/savedFilterContexts/savedFilterModificationContext';
import { useSavedFiltersListUpdate } from '@contexts/savedFilterContexts/savedFiltersListContext';
import { useSavedFiltersTypeState } from '@contexts/savedFilterContexts/savedFiltersTypeContext';
import { useAppMessaging, useEventTracking } from '@hooks';
import { postData } from '@utilities/xhr';

export default function useCreateSavedFilter() {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { setActiveSavedFilter } = useActiveSavedFilterUpdate();
  const { apiRequestFilters = '[]' } = useFiltersState();
  const { currentConfiguration } = useSavedFilterModificationState();
  const { fetchSavedFilters } = useSavedFiltersListUpdate();
  const savedFiltersType = useSavedFiltersTypeState();
  const showMessage = useAppMessaging();
  const sendEvent = useEventTracking();
  const companyAppId = useAppDetailsCompanyAppId();

  const createSavedFilter = useCallback(
    async ({ filterName, onError = () => {}, onSuccess = () => {}, teamFilter }) => {
      const { columnsOrder, filters, frozenColumns, tableSort } = currentConfiguration;

      await postData({
        path: `companies/$companyId/savedFilters/${savedFiltersType}`,
        params: {
          apiRequestFilters,
          columnsOrder,
          companyAppId: companyAppId ?? undefined,
          displayFilters: filters,
          filterName,
          frozenColumns,
          tableSort,
          teamFilter,
        },
      })
        .then(({ body }) => {
          showMessage({ message: `${filterName} Saved Filter has been created.` });

          setActiveSavedFilter({
            columnsOrder,
            filterName,
            filters: JSON.parse(filters),
            frozenColumns,
            id: body.id,
            tableSort,
            teamFilter,
          });

          fetchSavedFilters();

          onSuccess();

          if (get(activeSavedFilter, 'isInsight')) {
            sendEvent({
              eventName: 'SAVE_From_Insights_SAVED_FILTER',
              data: {
                Filters: filters,
                'Insight Name': filterName,
              },
            });
          } else {
            sendEvent({
              eventName: 'SAVE_AS_NEW_SAVED_FILTER',
              data: {
                Filters: filters,
                'Saved Filters Type': savedFiltersType,
              },
            });
          }
        })
        .catch(({ message }) => {
          onError(message);
        });
    },
    [
      activeSavedFilter,
      apiRequestFilters,
      companyAppId,
      currentConfiguration,
      fetchSavedFilters,
      savedFiltersType,
      sendEvent,
      setActiveSavedFilter,
      showMessage,
    ],
  );

  return createSavedFilter;
}
