import { useCallback, useMemo } from 'react';
import { Tooltip, useTableConfigState, ZyloButton, ZyloButtonProps } from '@zylo/orchestra';
import { useSidebarUpdate } from '@contexts/sidebarContext';
import { usePageCacheUpdate } from '@contexts/pageCacheContext';
import { useZAPState } from '@frameworks/zap/ZAPContext';
import { useGetTableData } from '@hooks';
import { GetZyloTableDataResponse } from '@pages/employees/hooks/useGetZyloTableData';
import { RowData } from '@src/typings';
import { useEventTracking } from '@hooks';
import getSelectedTableRows from '../table/utilities/getSelectedTableRows';

const compareLimit = 5;

export const ZAP_COMPARE_DRAWER = 'ZAP_COMPARE_DRAWER';

export default function ZAPCompareButton() {
  return <ZAPCompareButton_DisplayLayer {...useDataLayer()} />;
}

type ZAPCompareButtonDisplayProps = DataLayerValues & {
  color?: ZyloButtonProps['color'];
};

export function ZAPCompareButton_DisplayLayer({
  color = 'secondary',
  handleButtonClick,
  isCompareEnabled,
}: ZAPCompareButtonDisplayProps) {
  return (
    <div className="action">
      <Tooltip
        disabled={isCompareEnabled}
        text={`To compare items, select up to ${compareLimit} items from the rows of the table below.`}
      >
        <div>
          <ZyloButton
            color={color}
            disabled={!isCompareEnabled}
            icon="IconFileDiff"
            onClick={handleButtonClick}
          >
            Compare
          </ZyloButton>
        </div>
      </Tooltip>
    </div>
  );
}

type DataLayerValues = {
  handleButtonClick: () => void;
  isCompareEnabled: boolean;
};

function useDataLayer() {
  const { endpoints, tableProps } = useZAPState();
  const { showSidebar } = useSidebarUpdate();
  const { selectionState } = useTableConfigState();
  const sendEvent = useEventTracking();
  const updatePageCache = usePageCacheUpdate();
  const { data } = useGetTableData({
    handleResponse: (data: GetZyloTableDataResponse<RowData>) => data.body,
    path: endpoints.tableData!,
  });
  const tableData = data?.pages.flat();
  const selections = useMemo(
    () =>
      getSelectedTableRows({
        selectionKey: tableProps.rowSelectionKey as string,
        selectionState,
        tableData,
      }),
    [selectionState, tableData, tableProps.rowSelectionKey],
  );

  const handleButtonClick = useCallback(() => {
    sendEvent({
      eventName: 'APP_COMPARE_NAVIGATION_CLICK',
      data: {
        entryPoint: 'Subscriptions Table',
      },
    });
    updatePageCache(
      'zapCompareSelections',
      selections.map(({ appLabel, id }) => ({ label: appLabel, value: id })),
    );
    showSidebar(ZAP_COMPARE_DRAWER);
  }, [selections, sendEvent, showSidebar, updatePageCache]);

  return {
    handleButtonClick,
    isCompareEnabled: selections.length > 1 && selections.length <= compareLimit,
  };
}
