import type {
  FormContentCommonProps,
  FieldConfig,
  UnifiedControlProps,
  FormElementOptions,
} from '../types';
import { FormFieldPlaceholder } from './FormFieldPlaceholder';
import FieldControllerWrapper from './FieldControlWrapper';
import { FieldInputWithLabel } from './FieldInputWithLabel';

export type FieldProps = FormContentCommonProps & {
  fieldConfig: FieldConfig;
};
/**
 * Provides a non-initialized version of the form when dealing with data that needs
 * to be retrieved from an API.
 * */

export function Field(fieldProps: FieldProps) {
  const { editing, fieldConfig, methods, status } = fieldProps;
  switch (status) {
    case 'error':
      return <FormFieldPlaceholder fieldConfig={fieldConfig} error />;

    case 'loading':
      return <FormFieldPlaceholder fieldConfig={fieldConfig} />;

    case 'idle':
    case 'success':
    default:
      // The Fields component will be called without the 'methods'
      // prop during 'loading' and 'error' states. The 'idle',
      // 'success' and default cases will not be reached without
      // the methods prop being provided. This is the reason for
      // the use of the non-null assertion (!) on the next line.
      const [field, { name, rules, defaultValue }] = fieldConfig;
      const { wrapper }: FormElementOptions = fieldConfig.length > 2 ? fieldConfig[2]! : {};

      return (
        <FieldControllerWrapper
          defaultValue={defaultValue}
          field={field}
          formMethods={methods!}
          name={name}
          rules={rules}
        >
          {(controlProps: UnifiedControlProps) => {
            if (wrapper) {
              const { wrapperComponent: Wrapper, wrapperConfig } = wrapper;
              return (
                <Wrapper
                  {...controlProps}
                  {...fieldProps}
                  editing={editing}
                  fieldConfig={fieldConfig}
                  methods={fieldProps.methods!}
                  wrapperConfig={wrapperConfig}
                >
                  {({ fieldConfig: updatedFieldConfig }) => {
                    return (
                      <FieldInputWithLabel
                        {...fieldProps}
                        controlProps={controlProps}
                        fieldConfig={updatedFieldConfig}
                      />
                    );
                  }}
                </Wrapper>
              );
            }
            return (
              <FieldInputWithLabel
                {...fieldProps}
                controlProps={controlProps}
                fieldConfig={fieldConfig}
              />
            );
          }}
        </FieldControllerWrapper>
      );
  }
}
