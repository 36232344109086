import { useQuery, UseQueryResult } from 'react-query';
import { getData } from '@utilities/xhr';
import { CompanyAppSearchOptions } from '@typings';

export type UseCompanySearchOptionsResult = UseQueryResult<CompanyAppSearchOptions | undefined>;

type CompanyAppSearchOptionsResponse = {
  body: CompanyAppSearchOptions;
};

async function fetchCompanySearchOptions() {
  const response: CompanyAppSearchOptionsResponse = await getData({
    path: `companies/$companyId/searchOptions/companyApp`,
  });

  return response.body;
}

export function useCompanySearchOptions() {
  // Filters are required by the API, but some setups may try to load this hook
  // before default filters have be propagated. If no filters, disable the query.
  return useQuery(['companyApp', 'searchOptions'], () => fetchCompanySearchOptions(), {
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
  });
}
