/**
 * Helper function to format a field that has no value and is readOnly
 * @param {*} str string value from the form field
 * @param {*} readOnly boolean to check if field is readOnly
 * @param {*} formatFunc function to format fields if there is a value
 * @example
 * format={(value) => formatEmptyField(value, !isEditing, formatCurrency)}
 */
function formatEmptyField(str = '', readOnly, formatFunc) {
  if (!str && !Number.isInteger(str) && readOnly) {
    return '-';
  }
  if (formatFunc) {
    return formatFunc(str);
  }
  return str;
}

export default formatEmptyField;
