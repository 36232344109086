import { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select-plus';
import classNames from 'clsx';
import { isEqual } from 'lodash-es';
import { Icon } from '@zylo/orchestra';
import 'react-select-plus/dist/react-select-plus.css';

const filterTypeAheadOptions = (options, value, valueKey) => {
  return options && value && value.length > 0
    ? options.filter((option) => {
        return Array.isArray(value)
          ? !value.includes(option[valueKey])
          : value !== option[valueKey];
      })
    : options;
};

class TypeAhead extends Component {
  static propTypes = {
    addValueTest: PropTypes.func,
    allowCustomValue: PropTypes.bool,
    allowMultiple: PropTypes.bool,
    className: PropTypes.string,
    customOption: PropTypes.func,
    customValueRenderer: PropTypes.func,
    disabled: PropTypes.bool,
    forceLowerCase: PropTypes.bool,
    handleSearchChange: PropTypes.func,
    isLoading: PropTypes.bool,
    keepMenuOpen: PropTypes.bool,
    labelKey: PropTypes.string,
    matchPos: PropTypes.oneOf(['any', 'start']),
    name: PropTypes.string.isRequired,
    noResultsMessage: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    openOnClick: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    passSelectionOnChange: PropTypes.bool,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    removeValueTest: PropTypes.func,
    showMenuByDefault: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    valueKey: PropTypes.string,
  };

  static defaultProps = {
    value: [],
    options: [],
    allowMultiple: true,
    allowCustomValue: true,
    placeholder: '',
    forceLowerCase: false,
    disabled: false,
    passSelectionOnChange: false,
    valueKey: 'value',
    labelKey: 'label',
    matchPos: 'start',
    onFocus: () => {},
    onBlur: () => {},
    openOnClick: false,
    addValueTest: () => true,
    removeValueTest: () => true,
    showMenuByDefault: false,
  };

  constructor(props) {
    super(props);
    const { value, valueKey, options, allowMultiple } = props;

    this.pillLabelMapping = {};

    if (Array.isArray(value)) {
      value.forEach((val) => {
        this.pillLabelMapping[val] = val;
      });
    }

    options.forEach((option) => {
      this.pillLabelMapping[option.value] = option.label;
    });

    this.state = {
      propsValue: value, // eslint-disable-line react/no-unused-state
      propsOptions: options, // eslint-disable-line react/no-unused-state
      value,
      options:
        allowMultiple && options ? filterTypeAheadOptions(options, value, valueKey) : options,
      stagedValue: '',
      isFocused: false,
      selectedOption: options && options.find((option) => option[valueKey] === value),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { value, valueKey, options, allowMultiple } = props;
    const { propsValue, propsOptions } = state;
    const computeOptions = () => {
      return allowMultiple ? filterTypeAheadOptions(options, state.value) : options;
    };
    let stateUpdate = null;

    if (!allowMultiple && value !== propsValue) {
      stateUpdate = {
        propsValue: value,
        value,
        selectedOption: options
          ? options.find((option) => {
              return option[valueKey] === value;
            })
          : null,
      };
    } else if (!isEqual(options, propsOptions)) {
      stateUpdate = {
        options: computeOptions(),
        propsOptions: options,
      };
    } else if (allowMultiple && value !== propsValue) {
      stateUpdate = {
        propsValue: value,
        value,
      };
    }
    return stateUpdate;
  }

  handleFocus = () => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    onFocus();
  };

  performBlur = (e) => {
    const { keepMenuOpen, name, onBlur, allowMultiple } = this.props;
    const nextState = { isFocused: false };

    this.setState(nextState, () => {
      if (keepMenuOpen) {
        document.getElementById(name).focus();
      }

      if (!allowMultiple) {
        nextState.stagedValue = '';
      }

      onBlur(e);
    });
  };

  handleBlur = (e) => {
    // onBlur sends the event, onClose does not.
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Kinda gross.. but basically checking if they clicked the "add" button
    if (e?.relatedTarget?.childNodes[0]?.classList?.contains('icon-tabler-plus')) {
      this.handleAddValue(this.performBlur(e));
    } else {
      this.performBlur(e);
    }
  };

  handleKeyPress = (e) => {
    const { allowCustomValue, allowMultiple, addValueTest } = this.props;
    const { value, stagedValue } = this.state;

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (
        allowCustomValue &&
        !this.select.getFocusedOption() &&
        stagedValue.trim().length > 0 &&
        addValueTest(stagedValue)
      ) {
        e.preventDefault();
        this.handleAddValue();
      }
    } else if (value && e.key === 'Backspace' && !stagedValue) {
      const { removeValueTest, onChange } = this.props;

      if (removeValueTest(allowMultiple ? value[value.length - 1] : value)) {
        this.setState((currentState) => {
          const updatedValue = allowMultiple
            ? [...currentState.value].slice(0, value.length - 1)
            : '';

          onChange(updatedValue);

          return { value: updatedValue };
        });
      }
    }
  };

  handleTextChange = (value) => {
    const { handleSearchChange, forceLowerCase } = this.props;
    const returnVal = forceLowerCase ? value.toLowerCase() : value;

    if (handleSearchChange) {
      handleSearchChange(value);
    }

    this.setState({ stagedValue: returnVal });

    return returnVal;
  };

  handleAddValue = (callback) => {
    const { allowMultiple, valueKey, onChange } = this.props;

    this.setState(({ value, stagedValue, options }) => {
      let newValue = stagedValue;
      let newOptions = options;

      if (allowMultiple) {
        if (value.includes(stagedValue)) {
          return {};
        }

        newValue = [...value, stagedValue];
        newOptions = filterTypeAheadOptions(options, value, valueKey);
      }

      this.select.clearValue(new Event('click'));
      onChange(newValue);

      return {
        value: newValue,
        stagedValue: '',
        options: newOptions,
      };
    }, callback);
  };

  handlePillRemoveClick = (e) => {
    const { index } = e.currentTarget.dataset;
    const { options, onChange, showMenuByDefault, valueKey } = this.props;

    this.setState(({ value }) => {
      const updatedValue = [...value];
      updatedValue.splice(index, 1);

      onChange(updatedValue);

      return {
        options: filterTypeAheadOptions(options, updatedValue, valueKey),
        value: updatedValue,
      };
    });

    if (showMenuByDefault) {
      this.select.focus();
    }
  };

  handleSelection = (selection) => {
    const { onChange, passSelectionOnChange, valueKey, allowMultiple, showMenuByDefault } =
      this.props;

    if (selection) {
      this.setState(({ options, value }) => {
        let updatedOptions = options;
        let updatedValue = selection[valueKey];

        if (allowMultiple) {
          if (value.includes(updatedValue)) {
            return { value };
          }

          this.pillLabelMapping[selection.value] = selection.label;
          updatedOptions = filterTypeAheadOptions(options, selection[valueKey], valueKey);
          updatedValue = [...value, selection[valueKey]];
        }

        if (passSelectionOnChange) {
          onChange(selection);
        } else {
          onChange(updatedValue, selection);
        }

        return {
          value: updatedValue,
          stagedValue: '',
          options: updatedOptions,
          isFocused: !(!showMenuByDefault || !allowMultiple), // Reverse of 'closeOnSelection' below
        };
      });
    }
  };

  renderCustomValue = () => {
    const { customValueRenderer } = this.props;
    const { selectedOption } = this.state;

    return customValueRenderer(selectedOption);
  };

  render() {
    const {
      name,
      className,
      placeholder,
      customOption,
      customValueRenderer,
      allowMultiple,
      allowCustomValue,
      isLoading,
      disabled,
      valueKey,
      labelKey,
      matchPos,
      addValueTest,
      removeValueTest,
      readOnly,
      noResultsMessage,
      showMenuByDefault,
      openOnClick,
    } = this.props;
    const { value, options, stagedValue, selectedOption, isFocused } = this.state;

    return (
      <div
        className={classNames(
          'flex-container align-center type-ahead',
          className,
          !options || options.length === 0 ? ' no-options' : null,
          isFocused && 'is-focused',
          disabled && 'disabled',
          allowMultiple && 'multiple',
          readOnly && 'read-only',
        )}
      >
        {allowMultiple
          ? value.length > 0 && (
              <div className="flex-container pill-container">
                {value.map((item, i) => {
                  return (
                    <div className="flex-container align-center type-ahead-pill" key={item}>
                      <div className="pill-text" data-testid="typeahead-multiselect-value">
                        {this.pillLabelMapping[item] || item}
                      </div>
                      {!disabled && removeValueTest(item) && (
                        <Icon
                          color="white"
                          data-index={i}
                          icon="IconCircleX"
                          onClick={this.handlePillRemoveClick}
                          size={1}
                          relativeSize
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )
          : !stagedValue && (
              <div
                className="Select Select--single type-ahead-text single-value"
                onClick={(_) => {
                  this.select.focus();
                }}
              >
                <div className="Select-control">
                  <div className="Select-value">
                    <span className="Select-value-label">
                      {customValueRenderer
                        ? this.renderCustomValue()
                        : selectedOption
                        ? selectedOption[labelKey]
                        : value}
                    </span>
                  </div>
                </div>
              </div>
            )}

        <div className="flex-container align-center dropdown-container">
          <Select
            autoFocus={showMenuByDefault}
            className="type-ahead-text"
            closeOnSelect={!showMenuByDefault || !allowMultiple}
            disabled={disabled}
            id={name}
            inputProps={{ readOnly }}
            isLoading={!readOnly && isLoading}
            labelKey={labelKey}
            matchPos={matchPos}
            name={name}
            noResultsText={noResultsMessage || null}
            onBlur={this.handleBlur}
            onBlurResetsInput={!allowCustomValue}
            onChange={this.handleSelection}
            onClose={this.handleBlur}
            onFocus={this.handleFocus}
            onInputChange={this.handleTextChange}
            onInputKeyDown={this.handleKeyPress}
            onOpen={this.handleFocus}
            openOnClick={openOnClick}
            openOnFocus={showMenuByDefault || openOnClick}
            optionComponent={customOption}
            options={options}
            placeholder={allowMultiple || !value || value.length === 0 ? placeholder : ''}
            ref={(input) => {
              this.select = input;
            }}
            valueKey={valueKey}
          />

          {allowCustomValue && addValueTest(stagedValue) && (
            <Icon
              color="yellow"
              disabled={!stagedValue || stagedValue.trim().length === 0}
              icon="IconPlus"
              onClick={(_) => {
                this.handleAddValue();
              }}
              size={1.5}
              testId="add-new"
              relativeSize
            />
          )}
        </div>
      </div>
    );
  }
}

export default TypeAhead;
