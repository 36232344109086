import styled from 'styled-components/macro';
import { ColorsUnion, PercentChangeBar } from '@zylo/orchestra';

const PercentChangeCellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  .chart-container {
    width: 250px;
    padding: 0px 10px;
  }
`;

type PercentChangeCellProps = {
  barHeight?: number;
  cornerRadius?: number;
  data: string;
  padding?: number;
};

export function PercentChangeCell({ data, ...rest }: PercentChangeCellProps) {
  const percent = parseInt(data) || 0;
  const barColor: ColorsUnion = percent <= 0 ? 'warning' : 'blue2';
  return (
    <PercentChangeCellContainer>
      <div className="chart-container">
        <PercentChangeBar barColor={barColor} height={50} percent={percent} {...rest} />
      </div>
    </PercentChangeCellContainer>
  );
}
