import { NullUXValues } from '@typings';
import {
  API_REQUEST_FILTER_TYPES,
  FILTER_TYPES,
  STATIC_FILTER_NULL_OPTIONS,
} from './filterConstants';

export default function formatApiRequestFilters(filtersToFormat, filterMap) {
  const { BOOL, ONEOF, RANGE } = API_REQUEST_FILTER_TYPES;
  const { ARRAY, CURRENCY, BOOLEAN, DATE, NUMBER, PERCENTAGE, STRING } = FILTER_TYPES;
  const formattedFilters = [];

  function getFilterType(type, value) {
    switch (type) {
      case BOOLEAN:
        return BOOL;
      case CURRENCY:
      case DATE:
      case NUMBER:
      case PERCENTAGE:
        return value.length > 0
          ? STATIC_FILTER_NULL_OPTIONS.includes(value[0])
            ? BOOL
            : RANGE
          : RANGE;
      default:
        return ONEOF;
    }
  }

  function formatDateFilter(dateValue, filterOn) {
    if (dateValue === 'neverLoggedIn') {
      return [BOOL, filterOn, 'NULL'];
    } else if (dateValue === 'hasLoggedIn') {
      return [BOOL, filterOn, 'EXISTS'];
    } else if (STATIC_FILTER_NULL_OPTIONS.includes(dateValue)) {
      return [BOOL, filterOn, dateValue];
    } else {
      return [RANGE, filterOn, ...dateValue.split(':').filter(Boolean)];
    }
  }

  function formatFilterValues(type, value) {
    switch (type) {
      case BOOLEAN:
        if (value === NullUXValues.NULL) {
          return 'NULL';
        } else if (value === NullUXValues.EXISTS) {
          return 'EXISTS';
        }
        return value;
      case CURRENCY:
      case NUMBER:
      case PERCENTAGE:
        return value
          .split(':')
          .filter(Boolean)
          .map((val) => {
            return Number.isNaN(Number(val)) ? val : Number(val);
          });
      default:
        return [value];
    }
  }

  Object.entries(filtersToFormat).forEach(([filterKey, filterValues]) => {
    const associatedFilter = filterMap[filterKey];

    // filter associated with selected filter no longer exists
    if (!associatedFilter) return;

    const { filterOn, key, type } = associatedFilter;
    const filterParam = filterOn || key;

    if (type === BOOLEAN) {
      if (Array.isArray(filterValues)) {
        filterValues.forEach((booleanFilter) => {
          formattedFilters.push(['bool', filterParam, formatFilterValues(type, booleanFilter)]);
        });
      } else {
        formattedFilters.push(['bool', filterParam, formatFilterValues(type, filterValues)]);
      }
    } else if (type === DATE) {
      if (Array.isArray(filterValues)) {
        filterValues.forEach((dateFilter) => {
          formattedFilters.push(formatDateFilter(dateFilter, filterParam));
        });
      } else {
        formattedFilters.push(formatDateFilter(filterValues, filterParam));
      }
    } else if (
      [STRING, ARRAY].includes(type) &&
      (filterValues.includes('NULL') || filterValues.includes('EXISTS'))
    ) {
      if (filterValues.includes('NULL')) {
        formattedFilters.push(['bool', filterParam, 'NULL']);
      }
      if (filterValues.includes('EXISTS')) {
        formattedFilters.push(['bool', filterParam, 'EXISTS']);
      }
      const reducedFilterValues = filterValues.filter(
        (item) => item !== 'NULL' && item !== 'EXISTS',
      );
      if (reducedFilterValues.length > 0) {
        const formattedValue = formatFilterValues(type, reducedFilterValues);
        formattedFilters.push([
          getFilterType(type, formattedValue),
          filterParam,
          ...formattedValue,
        ]);
      }
    } else {
      const formattedValue = formatFilterValues(type, filterValues);
      formattedFilters.push([getFilterType(type, formattedValue), filterParam, ...formattedValue]);
    }
  });

  return JSON.stringify(formattedFilters);
}
