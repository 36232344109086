export const AHA_CLIENT_ID = process.env.AHA_CLIENT_ID;
export const API_URI = process.env.API_URI_PROD;
export const AZURE_AD_CLIENT_ID = process.env.AZURE_AD_CLIENT_ID;
export const BOX_CLIENT_ID = process.env.BOX_CLIENT_ID;
export const CLIENT_URI = process.env.CLIENT_URI;
export const DEPLOY = process.env.DEPLOY;
export const DOCUSIGN_CLIENT_ID = process.env.DOCUSIGN_CLIENT_ID;
export const DROPBOX_APP_KEY = process.env.DROPBOX_APP_KEY;
export const GITHUB_CLIENT_ID = process.env.GITHUB_CLIENT_ID;
export const GATEWAY_HTTP_URL_ROOT = process.env.GATEWAY_HTTP_URL_ROOT;
export const JIRACLOUD_CLIENT_ID = process.env.JIRACLOUD_CLIENT_ID;
export const WRIKE_CLIENT_ID = process.env.WRIKE_CLIENT_ID;
export const LAUNCH_DARKLY_CLIENT_ID = process.env.LAUNCH_DARKLY_CLIENT_ID;
export const LOGO_URI = process.env.LOGO_URI || '//logo.clearbit.com/';
export const LS_PROFILE_KEY = 'zyloProfile';
export const NODE_ENV = process.env.NODE_ENV;
export const OFFSET = 50;
export const PAGERDUTY_CLIENT_ID = process.env.PAGERDUTY_CLIENT_ID;
export const RELEASE = process.env.RELEASE;
export const SEGMENT_SOURCE_ID = process.env.SEGMENT_SOURCE_ID;
export const SENTRY_KEY = process.env.SENTRY_KEY;
export const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID;
export const SMARTSHEET_CLIENT_ID = process.env.SMARTSHEET_CLIENT_ID;
export const STRIPE_KEY = process.env.STRIPE_KEY || 'pk_test_IlOEk9mj0LCtdWDSq6b1wvSd';
export const ZENDESK_CLIENT_ID = process.env.ZENDESK_CLIENT_ID;
export const ZOOM_CLIENT_ID = process.env.ZOOM_CLIENT_ID;
export const OUTREACH_CLIENT_ID = process.env.OUTREACH_CLIENT_ID;
export const WEBPACK_NONCE = window.__webpack_nonce__;

// Delete the global source for the nonce ASAP to minimize
// access to the nonce value.
delete window.__webpack_nonce__;
