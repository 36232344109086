import { createAction, handleActions } from 'redux-actions';
import { get } from 'lodash-es';
import WaterWorksAPI from '../WaterWorksAPI';
import integrationMasterList from '../utilities/integrationMasterList';
import { wrapAsync, handleNetworkError } from '../utilities/util';
import { actions as netsuiteSavedSearchActions } from './netsuiteSavedSearches';
import { actions as zyloFormActions } from './zyloForm';
import { actions as statusBarActions } from './statusBar';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants
// ------------------------------------

export const GET_LIST_OF_COMPANY_INTEGRATIONS_SUCCESS = 'GET_LIST_OF_COMPANY_INTEGRATIONS_SUCCESS';
export const GET_LIST_OF_COMPANY_INTEGRATIONS_ERROR = 'GET_LIST_OF_COMPANY_INTEGRATIONS_ERROR';
export const VALIDATE_INTEGRATION_SUCCESS = 'VALIDATE_INTEGRATION_SUCCESS';
export const VALIDATE_INTEGRATION_ERROR = 'VALIDATE_INTEGRATION_ERROR';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const RECONNECT_INTEGRATION_SUCCESS = 'RECONNECT_INTEGRATION_SUCCESS';
export const RECONNECT_INTEGRATION_ERROR = 'RECONNECT_INTEGRATION_ERROR';
export const DISCONNECT_INTEGRATION_SUCCESS = 'DISCONNECT_INTEGRATION_SUCCESS';
export const SET_REDIRECT_PARAMS = 'SET_REDIRECT_PARAMS';
export const MAP_INTEGRATION_DATA_SUCCESS = 'MAP_INTEGRATION_DATA_SUCCESS';
export const GET_WEBHOOK_STATUS_START = 'GET_WEBHOOK_STATUS_START';
export const GET_WEBHOOK_STATUS_SUCCESS = 'GET_WEBHOOK_STATUS_SUCCESS';
export const GET_WEBHOOK_STATUS_ERROR = 'GET_WEBHOOK_STATUS_ERROR';

// ------------------------------------
// Actions
// ------------------------------------

export const getListOfCompanyIntegrationsSuccess = createAction(
  GET_LIST_OF_COMPANY_INTEGRATIONS_SUCCESS,
);
export const getListOfCompanyIntegrationsError = createAction(
  GET_LIST_OF_COMPANY_INTEGRATIONS_ERROR,
);
export const validateIntegrationSuccess = createAction(VALIDATE_INTEGRATION_SUCCESS);
export const validateIntegrationError = createAction(VALIDATE_INTEGRATION_ERROR);
export const deleteIntegrationSuccess = createAction(DELETE_INTEGRATION_SUCCESS);
export const reconnectIntegrationSuccess = createAction(RECONNECT_INTEGRATION_SUCCESS);
export const reconnectIntegrationError = createAction(RECONNECT_INTEGRATION_ERROR);
export const disconnectIntegrationSuccess = createAction(DISCONNECT_INTEGRATION_SUCCESS);
export const setRedirectParams = createAction(SET_REDIRECT_PARAMS);
export const mapIntegrationDataSuccess = createAction(MAP_INTEGRATION_DATA_SUCCESS);

export const getWebhookStatusStart = createAction(GET_WEBHOOK_STATUS_START);
export const getWebhookStatusSuccess = createAction(GET_WEBHOOK_STATUS_SUCCESS);
export const getWebhookStatusError = createAction(GET_WEBHOOK_STATUS_ERROR);

function mapIntegrationDataForCompany() {
  return (dispatch, getState) => {
    const { auth, featureFlags } = getState();
    const { activeIntegrations = [] } = getState().integrations;
    const mappedData = {
      connected: { error: [], success: [], pending: [] },
      notConnected: {},
    };

    integrationMasterList(auth.companyId, featureFlags.flags).forEach((integration) => {
      const { category, type } = integration;
      const connectedIntegrations = activeIntegrations.filter(
        (activeIntegration) =>
          activeIntegration.type === type && activeIntegration.status !== 'Deleting',
      );

      if (connectedIntegrations.length === 0) {
        if (mappedData.notConnected[category]) {
          mappedData.notConnected[category].push(integration);
        } else {
          mappedData.notConnected[category] = [integration];
        }
      } else {
        connectedIntegrations.forEach((connectedIntegration) => {
          const status = connectedIntegration.status.split(' ')[0].toLowerCase();

          get(mappedData, `connected.${status}`, []).push({
            ...integration,
            ...connectedIntegration,
          });
        });
      }
    });

    dispatch(mapIntegrationDataSuccess({ mappedData }));
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getListOfCompanyIntegrations(params, dispatch, getState) {
  const { auth } = getState();
  const { token, companyId } = auth;
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, ['integrations']);
  } catch (e) {
    handleNetworkError(
      'getListOfCompanyIntegrations',
      e,
      dispatch,
      getListOfCompanyIntegrationsError,
      { companyId },
    );

    return;
  }

  dispatch(getListOfCompanyIntegrationsSuccess(result));
  dispatch(mapIntegrationDataForCompany());
}

async function validateIntegration({ data, type, displayName, isOauth }, dispatch, getState) {
  const { token, companyId } = getState().auth;
  const { useSavedSearch, ...rest } = data;
  let integrationData = data;
  let result;

  if (type === 'netsuite') {
    integrationData = rest;
  }

  try {
    result = await WaterWorksAPI.validateIntegration(token, companyId, type, integrationData);
  } catch (e) {
    handleNetworkError('validateIntegration', e, dispatch, saveZyloFormError, {
      companyId,
      type,
      data: integrationData,
    });
    dispatch(validateIntegrationError(true));
    setTimeout(() => {
      dispatch(validateIntegrationError(false));
    }, 100);
    return;
  }

  if (type === 'netsuite') {
    const { storeNetsuiteSavedSearches, submitNetsuiteSavedSearch } = netsuiteSavedSearchActions;
    if (useSavedSearch) {
      dispatch(
        storeNetsuiteSavedSearches({
          savedSearches: get(result, 'configurations.savedSearchOptions', []),
        }),
      );
    } else {
      dispatch(
        submitNetsuiteSavedSearch({
          integrationId: result.id,
          savedSearch: { id: '', name: 'Get All Transactions' },
        }),
      );
    }
  }

  // timeout for oauth integrations so user has time to read modal after redirect back to our app
  setTimeout(
    () => {
      dispatch(
        validateIntegrationSuccess({
          eventName: 'INTEGRATION_CONNECTED',
          integrationName: type,
          data: result,
        }),
      );
      dispatch(saveZyloFormSuccess(`${displayName} integration connected.`));
      getListOfCompanyIntegrations({}, dispatch, getState);
    },
    isOauth ? 2000 : 0,
  );
}

async function reconnectIntegration(
  { data, type, displayName, integrationId, isOauth, handleCloseModal },
  dispatch,
  getState,
) {
  const { token, companyId } = getState().auth;
  const { useSavedSearch, ...rest } = data;
  let integrationData = data;
  let result;

  if (type === 'netsuite') {
    integrationData = rest;
  }

  try {
    result = await WaterWorksAPI.reconnectIntegration(
      token,
      integrationId,
      companyId,
      type,
      integrationData,
    );
  } catch (e) {
    handleNetworkError('reconnectIntegration', e, dispatch, saveZyloFormError, {
      companyId,
      type,
      data: integrationData,
      preventLogout: true,
    });
    dispatch(reconnectIntegrationError(true));
    setTimeout(() => {
      dispatch(reconnectIntegrationError(false));
    }, 100);
    return;
  }

  if (type === 'netsuite') {
    const { storeNetsuiteSavedSearches, submitNetsuiteSavedSearch } = netsuiteSavedSearchActions;
    if (useSavedSearch) {
      dispatch(
        storeNetsuiteSavedSearches({
          savedSearches: get(result, 'configurations.savedSearchOptions', []),
        }),
      );
    } else {
      dispatch(
        submitNetsuiteSavedSearch({
          integrationId: result.id,
          savedSearch: { id: '', name: 'Get All Transactions' },
        }),
      );
    }
  }

  // timeout for oauth integrations so user has time to read modal after redirect back to our app
  setTimeout(
    () => {
      dispatch(
        reconnectIntegrationSuccess({
          eventName: 'INTEGRATION_RECONNECTED',
          integrationName: type,
          data: result,
        }),
      );
      if (handleCloseModal) {
        handleCloseModal();
      }
      dispatch(saveZyloFormSuccess(`${displayName} integration re-connected.`));
      getListOfCompanyIntegrations({}, dispatch, getState);
    },
    isOauth ? 2000 : 0,
  );
}

async function disconnectIntegration({ integration }, dispatch, getState) {
  const { token, companyId } = getState().auth;
  const { type, displayName } = integration;

  try {
    await WaterWorksAPI.disconnectIntegration(token, companyId, type);
  } catch (e) {
    handleNetworkError('disconnectIntegration', e, dispatch, saveZyloFormError, {
      companyId,
      type,
    });

    return;
  }

  dispatch(
    disconnectIntegrationSuccess({ eventName: 'INTEGRATION_DISCONNECTED', integrationName: type }),
  );
  dispatch(saveZyloFormSuccess(`${displayName} integration disconnected.`));
  getListOfCompanyIntegrations({}, dispatch, getState);
}

function setRedirectParameters(params) {
  return (dispatch) => {
    dispatch(setRedirectParams(params));
  };
}

async function deleteIntegration({ integrationId, displayName }, dispatch, getState) {
  const { token, companyId } = getState().auth;

  try {
    await WaterWorksAPI.deleteIntegration(token, companyId, integrationId);
  } catch (e) {
    handleNetworkError('deleteIntegration', e, dispatch, saveZyloFormError, {
      companyId,
      integrationId,
    });

    return;
  }

  dispatch(
    deleteIntegrationSuccess({
      eventName: 'INTEGRATION_DELETED',
      integrationId,
      integrationName: displayName,
    }),
  );

  getListOfCompanyIntegrations({}, dispatch, getState);
  dispatch(statusBarActions.showZyloSuccessMessage(`${displayName} integration deleted.`));
}

async function getWebhookStatus(integrationId, dispatch, getState) {
  const { token, companyId } = getState().auth;
  let webhookStatus;
  dispatch(getWebhookStatusStart({ integrationId }));
  dispatch(mapIntegrationDataForCompany());
  try {
    webhookStatus = await WaterWorksAPI.getWebhookStatus(token, companyId, integrationId, {});
  } catch (e) {
    handleNetworkError('getWebhookStatus', e, dispatch, saveZyloFormError, {
      companyId,
      integrationId,
    });
    dispatch(getWebhookStatusError({ integrationId }));
    dispatch(mapIntegrationDataForCompany());
    return;
  }

  dispatch(getWebhookStatusSuccess({ integrationId, webhookStatus }));
  dispatch(mapIntegrationDataForCompany());
}

export const actions = {
  mapIntegrationDataForCompany,
  getListOfCompanyIntegrations: wrapAsync(getListOfCompanyIntegrations),
  validateIntegration: wrapAsync(validateIntegration),
  reconnectIntegration: wrapAsync(reconnectIntegration),
  disconnectIntegration: wrapAsync(disconnectIntegration),
  setRedirectParameters,
  deleteIntegration: wrapAsync(deleteIntegration),
  getWebhookStatus: wrapAsync(getWebhookStatus),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [GET_LIST_OF_COMPANY_INTEGRATIONS_SUCCESS]: (state, { payload }) => {
      return { ...state, activeIntegrations: payload };
    },
    [MAP_INTEGRATION_DATA_SUCCESS]: (state, { payload }) => {
      const { mappedData } = payload;
      return { ...state, data: mappedData };
    },
    [GET_LIST_OF_COMPANY_INTEGRATIONS_ERROR]: (state, { payload }) => {
      return { ...state, error: payload };
    },
    [VALIDATE_INTEGRATION_SUCCESS]: (state) => {
      return state;
    },
    [VALIDATE_INTEGRATION_ERROR]: (state, { payload }) => {
      return { ...state, validationFailed: payload };
    },
    [RECONNECT_INTEGRATION_SUCCESS]: (state) => {
      return state;
    },
    [RECONNECT_INTEGRATION_ERROR]: (state, { payload }) => {
      return { ...state, validationFailed: payload };
    },
    [GET_WEBHOOK_STATUS_START]: (state, { payload }) => {
      const { integrationId } = payload;
      const { activeIntegrations = [] } = state;
      const newActiveIntegrations = [...activeIntegrations];
      const webhookIntegration = newActiveIntegrations.find(
        (integration) => integration.id === integrationId,
      );
      webhookIntegration.isGetWebhookStatusLoading = true;
      return { ...state, activeIntegrations: newActiveIntegrations };
    },
    [GET_WEBHOOK_STATUS_ERROR]: (state, { payload }) => {
      const { integrationId } = payload;
      const { activeIntegrations = [] } = state;
      const newActiveIntegrations = [...activeIntegrations];
      const webhookIntegration = newActiveIntegrations.find(
        (integration) => integration.id === integrationId,
      );
      webhookIntegration.isGetWebhookStatusLoading = false;
      return { ...state, activeIntegrations: newActiveIntegrations };
    },
    [GET_WEBHOOK_STATUS_SUCCESS]: (state, { payload }) => {
      const { integrationId, webhookStatus } = payload;
      const { activeIntegrations = [] } = state;
      const newActiveIntegrations = [...activeIntegrations];
      const webhookIntegration = newActiveIntegrations.find(
        (integration) => integration.id === integrationId,
      );
      webhookIntegration.stats.webhookConnected = webhookStatus.connected;
      webhookIntegration.isGetWebhookStatusLoading = false;
      return { ...state, activeIntegrations: newActiveIntegrations };
    },
    [DELETE_INTEGRATION_SUCCESS]: (state) => {
      return state;
    },
    [SET_REDIRECT_PARAMS]: (state, { payload }) => {
      return { ...state, redirectParams: payload };
    },
  },
  {
    data: {
      connected: { error: [], pending: [], success: [] },
      notConnected: {},
    },
    activeIntegrations: [],
    pendingDeleteIntegrations: [],
    redirectParams: {},
    validationFailed: false,
  },
);
