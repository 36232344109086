import { get } from 'lodash-es';
import { Tooltip, Icon } from '@zylo/orchestra';
import type {
  Flags,
  GenericIntegrationValues,
  IGenericMapObject,
  IIntegrationMasterListConfigurationObject,
} from '@typings';
import ahaLogo from '@assets/img/aha_logo.png';
import airtableLogo from '@assets/img/airtable_logo.png';
import asanaLogo from '@assets/img/asana_logo.png';
import atlassianLogo from '@assets/img/atlassian_logo.png';
import boxLogo from '@assets/img/box_logo.svg';
import centrifyLogo from '@assets/img/centrify_logo.png';
import idaptiveLogo from '@assets/img/idaptive_logo.png';
import concurLogo from '@assets/img/concur_logo.png';
import sapConcurLogo from '@assets/img/sapconcur_logo.png';
import sapConcurEndorsedLogo from '@assets/img/sapconcur_endorsed_logo.png';
import coupaLogo from '@assets/img/coupa_logo.png';
import docusignLogo from '@assets/img/docusign_logo.png';
import domoLogo from '@assets/img/domo_logo.png';
import dropboxBizLogo from '@assets/img/dropbox_business_logo.svg';
import expensifyLogo from '@assets/img/expensify_logo.png';
import githubLogo from '@assets/img/github_logo.png';
import azureadLogo from '@assets/img/azuread_logo.png';
import msDynamics365Logo from '@assets/img/msdynamics365_logo.png';
import s3SyncLogo from '@assets/img/s3-sync.png';
import zyloApiLogo from '@assets/img/zylo-api-logo.png';
import googleWorkspaceLogo from '@assets/img/google-workspace.png';
import zyloHrisLogo from '@assets/img/zylo_hris_logo.png';
import informaticaLogo from '@assets/img/informatica_logo.png';
import intacctLogo from '@assets/img/intacct_logo.png';
import jiraLogo from '@assets/img/jira_logo.png';
import lookerLogo from '@assets/img/looker_logo.png';
import miroLogo from '@assets/img/miro_logo.png';
import netsuiteLogo from '@assets/img/netsuite_logo.png';
import o365Logo from '@assets/img/office_365_logo.png';
import oktaLogo from '@assets/img/okta_logo.png';
import oneloginLogo from '@assets/img/onelogin_logo.png';
import outreachLogo from '@assets/img/outreach_logo.png';
import pagerdutyLogo from '@assets/img/pagerduty_logo.png';
import pingLogo from '@assets/img/ping_logo.png';
import salesforceLogo from '@assets/img/salesforce_logo.png';
import serviceNowLogo from '@assets/img/servicenow_logo.png';
import slackLogo from '@assets/img/slack_logo.png';
import smartsheetLogo from '@assets/img/smartsheet_logo.svg';
import veevaCrmLogo from '@assets/img/veeva_crm_logo.png';
import veevaVaultLogo from '@assets/img/veeva_vault_logo.png';
import workdayLogo from '@assets/img/workday_logo.png';
import wrikeLogo from '@assets/img/wrike_logo.png';
import launchDarklyLogo from '@assets/img/LaunchDarkly_logo.png';
import zendeskLogo from '@assets/img/zendesk_logo.svg';
import zoomLogo from '@assets/img/zoom_logo.png';
import figmaLogo from '@assets/img/figma_logo.png';
import calendlyLogo from '@assets/img/calendly_logo.png';
import googleSSOLogo from '@assets/img/google_sso_logo.png';
import { checkDomain } from './formUtil';

const {
  AHA_CLIENT_ID,
  ASANA_CLIENT_ID,
  AZURE_AD_CLIENT_ID,
  BOX_CLIENT_ID,
  CALENDLY_CLIENT_ID,
  CLIENT_URI,
  DOCUSIGN_CLIENT_ID,
  DOCUSIGN_SANDBOX_CLIENT_ID,
  DROPBOX_APP_KEY,
  GITHUB_CLIENT_ID,
  GOOGLE_SSO_CLIENT_ID,
  GSUITE_CLIENT_ID,
  JIRACLOUD_CLIENT_ID,
  MS_DYNAMICS_365_CLIENT_ID,
  OFFICE365_V2_CLIENT_ID,
  PAGERDUTY_CLIENT_ID,
  SLACK_CLIENT_ID,
  SLACK_UTILIZATION_CLIENT_ID,
  SMARTSHEET_CLIENT_ID,
  WRIKE_CLIENT_ID,
  ZENDESK_CLIENT_ID,
  ZOOM_CLIENT_ID,
  OUTREACH_CLIENT_ID,
} = process.env;

const REDIRECT_BASE = `${CLIENT_URI}/integrations/`;
const AZUREAD_SCOPES = [
  'User.Read',
  'Directory.Read.All',
  'offline_access',
  'email',
  'openid',
  'Reports.Read.All',
  'AuditLog.Read.All',
];
const OUTREACH_SCOPES = [
  'users.read',
  'users.all',
  'profiles.read',
  'roles.read',
  'teams.read',
  'events.read',
];
const SMARTSHEET_SCOPES = ['READ_USERS', 'READ_EVENTS'];
const WRIKE_SCOPES = ['wsReadOnly', 'amReadOnlyUser', 'amReadOnlyAuditLog'];
const MS_DYNAMICS_365_GRAPH_SCOPES = [
  'openid',
  'offline_access',
  'https://graph.microsoft.com/user.read',
];
const GSUITE_TOKEN_FIELDS = [
  {
    name: 'includeUtilizationData',
    label: 'Import Utilization Data?',
    type: 'checkbox',
    defaultValue: true,
    isBoolean: true,
  },
  {
    name: 'includeTokenData',
    label: 'Import Token Usage Data?',
    type: 'checkbox',
    isBoolean: true,
    defaultValue: true,
  },
];

export const GRAPH_API_DEFAULT_TENANT = 'common';

export default function integrationMasterList(
  companyId?: string,
  featureFlags: Flags = {},
): IIntegrationMasterListConfigurationObject[] {
  const hasTokenReportFeature = get(featureFlags, 'gsuite-token-report', false);

  return [
    {
      type: 'aha!',
      uriType: 'aha',
      displayName: 'Aha!',
      category: 'utilization',
      description:
        'The Zylo + Aha integration analyzes user activity data to provide insight into which employees are using Aha most.',
      logo: ahaLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360020340112-Aha-Integration',
      active: get(featureFlags, 'aha', false),
      isCustomOauth: true,
      formatCustomOauthURL: ({ subdomain }) => {
        return `https://${subdomain}.aha.io/oauth/authorize?client_id=${AHA_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}aha&response_type=code`;
      },
      formatOauthValidationData: ({ oauthToken, subdomain }) => {
        return {
          code: oauthToken,
          subdomain,
        };
      },
      fields: [
        {
          name: 'subdomain',
          label: 'Aha! Subdomain',
        },
      ],
      requiredFields: ['subdomain'],
      disabledReconnectingFields: ['subdomain'],
      multiOrgSupport: true,
    },
    {
      type: 'airtable',
      displayName: 'Airtable',
      category: 'utilization',
      description:
        'The Zylo + Airtable integration analyzes user activity data to provide insight into which employees are using Airtable the most.',
      logo: airtableLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/4677425153677-Airtable-Integration',
      active: get(featureFlags, 'airtable', false),
      fields: [
        {
          name: 'apikey',
          label: 'Airtable API Key',
          type: 'password',
        },
        {
          name: 'accountId',
          label: 'Airtable Enterprise Account ID',
        },
      ],
      requiredFields: ['accountId', 'apikey'],
      disabledReconnectingFields: ['accountId'],
      multiOrgSupport: true,
    },
    {
      type: 'asanav2',
      displayName: 'Asana',
      category: 'utilization',
      description:
        'The Zylo + Asana integration analyzes user activity data to provide insight into which employees are using Asana most. We are not yet reporting on individual user activity with this integration.',
      logo: asanaLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360038741652-Asana-Integration-',
      active: get(featureFlags, 'asanav2', false),
      oauthURL: `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${ASANA_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}asanav2&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken, workspaceId }) => {
        return {
          code: oauthToken,
          workspaceId,
          redirectUri: `${REDIRECT_BASE}asanav2`,
        };
      },
      hasCustomModal: true,
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      beta: false,
      fields: [
        {
          name: 'workspaceId',
          placeholder: '123456789',
          label: 'Workspace GID',
        },
      ],
      requiredFields: ['workspaceId'],
    },
    {
      type: 'atlassian',
      displayName: 'Atlassian',
      category: 'utilization',
      description:
        'The Zylo + Atlassian Suite integration analyzes user activity data to provide insight into which employees are using Atlassian provided applications most.',
      logo: atlassianLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360043858611-Atlassian-Suite-Integration',
      active: get(featureFlags, 'atlassian-suite', true),
      canBePrimaryUserStore: false,
      isAppProvider: !get(featureFlags, 'atlassian-auto-map-enabled', true),
      mappingType: 'suite',
      isSuiteIntegration: true,
      hasCustomModal: true,
      fields: [
        {
          name: 'organizationId',
          label: 'Organization ID',
        },
        {
          name: 'apikey',
          label: 'Organization API Key',
        },
      ],
      requiredFields: ['organizationId', 'apikey'],
      multiOrgSupport: true,
    },
    {
      type: 'box',
      displayName: 'Box',
      category: 'utilization',
      description:
        'The Zylo + Box integration analyzes users and activity data to provide insight into which employees are using Box most.',
      logo: boxLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360026830232-Box-Integration',
      isOauth: true,
      oauthURL: `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${BOX_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}box&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}box`,
        };
      },
      active: get(featureFlags, 'box', false),
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
    },
    {
      type: 'centrify',
      displayName: 'Centrify',
      category: 'sso',
      isAppProvider: true,
      mappingType: 'sso',
      canBePrimaryUserStore: true,
      description:
        "The Zylo + Centrify integration combines Centrify's utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.",
      logo: centrifyLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360021961931-Centrify-Integration-Guide',
      active: get(featureFlags, 'centrify', false),
      isCustomOauth: true,
      formatCustomOauthURL: ({ tenantId, clientAppInstanceId, clientSecret, scope, clientId }) => {
        return `https://${tenantId}.my.centrify.com/oauth2/authorize/${encodeURIComponent(
          clientAppInstanceId,
        )}?scope=${scope}&response_type=code&redirect_uri=${REDIRECT_BASE}centrify&client_id=${encodeURIComponent(
          clientId,
        )}&client_secret=${encodeURIComponent(clientSecret)}`;
      },
      formatOauthValidationData: ({
        oauthToken,
        tenantId,
        clientAppInstanceId,
        clientId,
        clientSecret,
        scope,
      }) => {
        return {
          code: oauthToken,
          tenantId,
          redirectUri: `${REDIRECT_BASE}centrify`,
          clientAppInstanceId,
          clientId,
          clientSecret,
          scope,
        };
      },
      fields: [
        {
          name: 'tenantId',
          label: 'Tenant ID',
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'clientAppInstanceId',
          label: 'Application ID',
          value: 'ZyloIntegration',
        },
        {
          name: 'scope',
          label: 'Client Scope',
          value: 'ZYLO',
        },
      ],
      requiredFields: ['tenantId', 'clientAppInstanceId', 'clientId', 'clientSecret', 'scope'],
      hasCustomModal: true,
    },
    {
      type: 'idaptive',
      displayName: 'Idaptive',
      category: 'sso',
      isAppProvider: true,
      mappingType: 'sso',
      canBePrimaryUserStore: true,
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      description:
        "The Zylo + Idaptive integration combines Idaptive's utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.",
      logo: idaptiveLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360043532811-Idaptive-Integration-Guide',
      active: get(featureFlags, 'idaptive', false),
      isCustomOauth: true,
      formatCustomOauthURL: ({ tenantId, clientAppInstanceId, clientSecret, scope, clientId }) => {
        return `https://${tenantId}.my.idaptive.app/oauth2/authorize/${encodeURIComponent(
          clientAppInstanceId,
        )}?scope=${scope}&response_type=code&redirect_uri=${REDIRECT_BASE}idaptive&client_id=${encodeURIComponent(
          clientId,
        )}&client_secret=${encodeURIComponent(clientSecret)}`;
      },
      formatOauthValidationData: ({
        oauthToken,
        tenantId,
        clientAppInstanceId,
        clientId,
        clientSecret,
        scope,
      }) => {
        return {
          code: oauthToken,
          tenantId,
          redirectUri: `${REDIRECT_BASE}idaptive`,
          clientAppInstanceId,
          clientId,
          clientSecret,
          scope,
        };
      },
      fields: [
        {
          name: 'tenantId',
          label: 'Tenant ID',
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'clientAppInstanceId',
          label: 'Application ID',
          value: 'ZyloIntegration',
        },
        {
          name: 'scope',
          label: 'Client Scope',
          value: 'ZYLO',
        },
      ],
      requiredFields: ['tenantId', 'clientAppInstanceId', 'clientId', 'clientSecret', 'scope'],
      hasCustomModal: true,
    },
    {
      type: 'concur',
      displayName: 'Concur',
      category: 'financial',
      description:
        'The Zylo + Concur integration gives teams visibility into expense data, helping to uncover purchasing trends within the Zylo platform.',
      logo: concurLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360042971431-SAP-Concur-App-Center-Zylo-Integration',
      active: get(featureFlags, 'concur', false),
      isCustomOauth: true,
      formatCustomOauthURL: ({ consumerKey }) => {
        return `https://www.concursolutions.com/net2/oauth2/Login.aspx?client_id=${consumerKey}&scope=EXPRPT&redirect_uri=${CLIENT_URI}/concur`;
      },
      formatOauthValidationData: ({ consumerKey, consumerSecret, oauthToken }) => {
        return {
          consumerKey,
          consumerSecret,
          requestToken: oauthToken,
        };
      },
      fields: [
        {
          name: 'consumerKey',
          label: 'Client Key',
        },
        {
          name: 'consumerSecret',
          label: 'Client Secret',
          type: 'password',
        },
      ],
      requiredFields: ['consumerKey', 'consumerSecret'],
    },
    {
      type: 'concurv2',
      displayName: 'Concur',
      category: 'financial',
      description:
        'The Zylo and Concur Expense integration gives teams visibility into expense data, helping to uncover purchasing trends within the Zylo platform.',
      logo: sapConcurLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360042971431-SAP-Concur-App-Center-Zylo-Integration',
      active: get(featureFlags, 'concur-v2', false),
      isCustomOauth: false,
      fields: [],
      requiredFields: [],
      hasCustomModal: true,
    },
    {
      type: 'concurendorsed',
      displayName: 'Concur',
      category: 'financial',
      description:
        'The Zylo and Concur Expense integration gives teams visibility into expense data, helping to uncover purchasing trends within the Zylo platform.',
      logo: sapConcurEndorsedLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360042971431-SAP-Concur-App-Center-Zylo-Integration',
      active: get(featureFlags, 'concur-endorsed', false),
      isCustomOauth: false,
      fields: [],
      requiredFields: [],
      hasCustomModal: true,
    },
    {
      active: get(featureFlags, 'coupa', false),
      category: 'financial',
      description:
        'The Zylo + Coupa integration helps to find, uncover, and classify cloud-related spend by analyzing invoice and expense data.',
      displayName: 'Coupa',
      hasCustomModal: true,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/4422768886669-Coupa',
      logo: coupaLogo,
      multiOrgSupport: true,
      type: 'coupa',
      fields: [
        {
          name: 'domain',
          label: 'Coupa Domain',
          type: 'domainInput',
          inputGroupPre: 'https://',
          validate: (value: string) => {
            const coupaDomainRe = /^.*coupa(host|cloud)\.(com).*?/;
            if (value && !checkDomain(value, coupaDomainRe, false)) {
              return 'Invalid domain. Example: company.coupacloud.com';
            }
            return undefined;
          },
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          label: 'Coupa Data Type to Import',
          name: 'financial_information',
          type: 'select',
          options: [
            {
              name: 'ap',
              label: (
                <Tooltip text="Import invoice payments through the Coupa Invoices API.">AP</Tooltip>
              ),
              value: 'ap',
            },
            {
              name: 'expense',
              label: (
                <Tooltip text="Import paid expenses through the Coupa Expense Reports API.">
                  Expense
                </Tooltip>
              ),
              value: 'expense',
            },
            ...(get(featureFlags, 'purchase-orders', false)
              ? [
                  {
                    name: 'purchaseOrder',
                    label: (
                      <Tooltip text="Import purchase orders through the Coupa Expense Reports API.">
                        Purchase Order
                      </Tooltip>
                    ),
                    value: 'purchaseOrder',
                  },
                ]
              : []),
          ],
        },
      ],
      formatIntegrationData: ({
        financial_information: financialInformation,
        ...restProps
      }: GenericIntegrationValues) => ({
        ...restProps,
        fetchAPData: financialInformation.includes('ap'),
        fetchExpenseData: financialInformation.includes('expense'),
        ...(get(featureFlags, 'purchase-orders', false)
          ? {
              fetchPOData: financialInformation.includes('purchaseOrder'),
            }
          : {}),
      }),
      requiredFields: ['domain', 'clientId', 'clientSecret', 'financial_information'],
      disabledReconnectingFields: ['domain', 'financial_information'],
    },
    {
      active: get(featureFlags, 'coupa-contracts', false),
      category: 'contracts',
      description:
        'The Zylo + Coupa integration helps to find, uncover, and classify cloud-related contracts by analyzing data directly from Coupa.',
      displayName: 'Coupa',
      hasCustomModal: true,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/4422768886669-Coupa',
      logo: coupaLogo,
      multiOrgSupport: false,
      type: 'coupacontract',
      fields: [
        {
          name: 'domain',
          label: 'Coupa Domain',
          type: 'domainInput',
          inputGroupPre: 'https://',
          validate: (value: string) => {
            const coupaDomainRe = /^.*coupa(host|cloud)\.(com).*?/;
            if (value && !checkDomain(value, coupaDomainRe, false)) {
              return 'Invalid domain. Example: company.coupacloud.com';
            }
            return undefined;
          },
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
      ],
      formatIntegrationData: ({ ...restProps }: GenericIntegrationValues) => restProps,
      requiredFields: ['domain', 'clientId', 'clientSecret'],
      disabledReconnectingFields: ['domain', 'financial_information'],
      validate: (value: string) => {
        const coupaDomainRe = /^.*coupa(host|cloud)\.(com).*?/;
        if (!checkDomain(value, coupaDomainRe, false)) {
          return false;
        } else {
          return true;
        }
      },
    },
    {
      type: 'docusign',
      displayName: 'DocuSign',
      category: 'utilization',
      description:
        'The Zylo + DocuSign integration analyzes user activity data to provide insight into which employees are using DocuSign most.',
      logo: docusignLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603731-Docusign-Integration',
      isOauth: true,
      oauthURL: `https://account.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=${DOCUSIGN_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}docusign&state=${companyId}&prompt=login`,
      sandboxURL: `https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=${DOCUSIGN_SANDBOX_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}docusign&state=${companyId}&prompt=login`,
      formatOauthValidationData: ({ oauthToken, isSandbox }) => {
        return {
          code: oauthToken,
          isSandbox,
        };
      },
      active: get(featureFlags, 'docusign', false),
      multiOrgSupport: true,
    },
    {
      type: 'domo',
      displayName: 'Domo',
      category: 'utilization',
      description:
        'The Zylo + Domo integration analyzes user activity data to provide insight into which employees are using Domo the most.',
      logo: domoLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360044864952-Domo-Integration',
      isOauth: false,
      active: get(featureFlags, 'domo', false),
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      fields: [
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
      ],
      requiredFields: ['clientId', 'clientSecret'],
    },
    {
      type: 'dropbox',
      displayName: 'Dropbox',
      category: 'utilization',
      description:
        'The Zylo + Dropbox integration analyzes user activity data to provide insights into which employees use Dropbox the most.',
      logo: dropboxBizLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360026049431-Dropbox-Integration',
      isOauth: true,
      oauthURL: `https://www.dropbox.com/oauth2/authorize?client_id=${DROPBOX_APP_KEY}&response_type=code&redirect_uri=${REDIRECT_BASE}dropbox&force_reapprove=true&token_access_type=offline&require_role=work&force_reauthentication=true&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}dropbox`,
        };
      },
      active: get(featureFlags, 'dropbox', false),
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
    },
    {
      type: 'expensify',
      displayName: 'Expensify',
      category: 'financial',
      description:
        'The Zylo + Expensify integration gives teams visibility into expense data, helping to uncover purchasing trends within the Zylo platform.',
      logo: expensifyLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603531-Expensify-Integration-Setup',
      active: get(featureFlags, 'expensify', false),
      fields: [
        {
          name: 'partnerUserId',
          label: 'User ID',
        },
        {
          name: 'partnerUserSecret',
          label: 'User Secret',
          type: 'password',
        },
      ],
      requiredFields: ['partnerUserId', 'partnerUserSecret'],
    },
    {
      type: 'figma',
      displayName: 'Figma',
      category: 'utilization',
      description:
        'The Zylo + Figma integration analyzes user activity data to provide insight into which employees are using Figma.',
      logo: figmaLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/12387538542221-Figma-Integration',
      active: get(featureFlags, 'figma', false),
      isCustomOauth: true,
      formatCustomOauthURL: ({ clientId }) => {
        return `https://www.figma.com/oauth?client_id=${clientId}&redirect_uri=${CLIENT_URI}/integrations/figma&scope=org:activity_log_read&state=${companyId}&response_type=code`;
      },
      formatOauthValidationData: ({ oauthToken, scimToken, tenantId, clientId, clientSecret }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}figma`,
          scimToken: scimToken,
          tenantId: tenantId,
          clientId: clientId,
          clientSecret: clientSecret,
        };
      },
      fields: [
        {
          name: 'tenantId',
          label: 'Tenant ID',
        },
        {
          name: 'scimToken',
          label: 'SCIM API Token',
          type: 'password',
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
      ],
      requiredFields: ['tenantId', 'scimToken', 'clientId', 'clientSecret'],
    },
    {
      type: 'github',
      displayName: 'GitHub',
      category: 'utilization',
      description:
        'The Zylo + Github integration analyzes user activity data to provide insight into Team and Repo relationships, when Teams and Repos were last active, and user license consumption.',
      logo: githubLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603571-Github-Integration',
      active: get(featureFlags, 'github', false),
      isOauth: true,
      oauthURL: `https://github.com/login/oauth/authorize?scope=admin:org_hook,user,read:org,repo&client_id=${GITHUB_CLIENT_ID}&redirect_uri=${CLIENT_URI}/github`,
      formatOauthValidationData: ({ oauthToken }) => {
        return { code: oauthToken };
      },
    },
    {
      type: 'azuread',
      displayName: 'Azure Active Directory',
      category: 'sso',
      isAppProvider: true,
      mappingType: 'sso',
      description:
        "The Zylo + Azure Active Directory integration combines Azure AD's utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.",
      logo: azureadLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360020282812-Azure-AD-SSO',
      active: get(featureFlags, 'azuread', false),
      isCustomOauth: true,
      formatCustomOauthURL: () => {
        return `https://login.microsoftonline.com/common/adminconsent?client_id=${AZURE_AD_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}azuread_adminconsent`;
      },
      secondOauthURL: ({ activeDirectoryId = GRAPH_API_DEFAULT_TENANT }) => {
        return `https://login.microsoftonline.com/${activeDirectoryId}/oauth2/v2.0/authorize?client_id=${AZURE_AD_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_BASE}azuread&response_mode=query&scope=${AZUREAD_SCOPES.join(
          ' ',
        )}&state=${companyId}`;
      },
      formatOauthValidationData: ({ oauthToken, activeDirectoryId = GRAPH_API_DEFAULT_TENANT }) => {
        return {
          code: oauthToken,
          activeDirectoryId,
          redirectUri: `${REDIRECT_BASE}azuread`,
        };
      },
      fields: [],
      requiredFields: [],
      hasCustomModal: true,
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      canBePrimaryUserStore: true,
    },
    {
      active: get(featureFlags, 'msdynamics365', false),
      beta: false,
      canBePrimaryUserStore: false,
      category: 'utilization',
      description:
        'The Zylo + Microsoft Dynamics 365 integration analyzes user activity data to provide insight into team CRM utilization.',
      displayName: 'Microsoft Dynamics 365',
      fields: [
        {
          name: 'tenantId',
          label: 'Tenant Id',
          type: 'hidden',
        },
        {
          name: 'dataverseApiCrmHost',
          label: 'Dataverse CRM URL',
          placeholder: 'https://orgXXXXXXXX.crm.dynamics.com',
          instructions: (
            <span>
              This field is only required if additional data
              <br />
              is sourced from Dataverse CRM.
            </span>
          ),
        },
      ],
      hasCustomModal: true,
      isCustomOauth: true,
      isWebhookIntegration: true,
      logo: msDynamics365Logo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360056953212-Microsoft-Dynamics-Integration',
      multiOrgSupport: true,
      requiredFields: ['tenantId'],
      type: 'msdynamics365',
      formatCustomOauthURL: ({ nonce }) => {
        return `https://login.microsoftonline.com/common/adminconsent?client_id=${MS_DYNAMICS_365_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}msdynamics365&state=${encodeURIComponent(
          nonce,
        )}`;
      },
      secondOauthURL: ({ nonce, tenantId }) => {
        return `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${MS_DYNAMICS_365_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_BASE}msdynamics365&response_mode=query&scope=${encodeURIComponent(
          MS_DYNAMICS_365_GRAPH_SCOPES.join(' '),
        )}&state=${encodeURIComponent(nonce)}`;
      },
      thirdOauthURL: ({ nonce, tenantId }) => {
        return `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${MS_DYNAMICS_365_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_BASE}msdynamics365&response_mode=query&scope=https://manage.office.com/.default&state=${encodeURIComponent(
          nonce,
        )}`;
      },
      fourthOauthURL: ({ nonce, crmHost }) => {
        return `https://login.microsoftonline.com/common/oauth2/authorize?resource=${crmHost}&response_type=code&client_id=${MS_DYNAMICS_365_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}msdynamics365&state=${encodeURIComponent(
          nonce,
        )}`;
      },
      formatOauthValidationData: ({
        crmHost,
        graphApiCode,
        managementApiCode,
        tenantId,
        dataverseApiCode,
      }) => ({
        ...(crmHost ? { crmHost } : {}),
        ...(dataverseApiCode ? { dataverseApiCode } : {}),
        tenantId,
        managementApiCode,
        graphApiCode,
        redirectUri: `${REDIRECT_BASE}msdynamics365`,
      }),
    },
    {
      type: 'genericusage',
      displayName: 'Third Party',
      category: 'utilization',
      description:
        'The Zylo + Amazon S3 integration provides an S3 Bucket where app and utilization data can be dropped from a third party application and then mapped to an application in Zylo to track user activity.',
      additionalDescription:
        'Zylo imports files dropped to your S3 location daily. Map applications in Zylo to the unique applications found in the data provided via your S3 location. To remove any mappings that have changed or were done in error, just click on the row and select "Unmap."',
      logo: s3SyncLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360057507951-Amazon-S3-Event-Log-Importer',
      active: process.env.NODE_ENV === 'development',
      fields: [],
      requiredFields: [],
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      isOauth: false,
      isAppProvider: true,
      mappingType: 'batch-generic-direct',
      hasCustomModal: true,
    },
    {
      type: 'apiusage',
      displayName: 'Zylo Import API Integration',
      category: 'utilization',
      description: (
        <span>
          The Zylo API allows you to import usage data to Zylo for any application. See more details
          on mapping and import at{' '}
          <a href="https://developer.zylo.com/" rel="noopener noreferrer" target="_blank">
            developer.zylo.com
          </a>{' '}
          .
        </span>
      ),
      additionalDescription: (
        <span>
          Map applications that you plan on providing data for via our{' '}
          <a href="https://developer.zylo.com/" rel="noopener noreferrer" target="_blank">
            import api
          </a>{' '}
          . Once mapped, follow the instructions in the link provided above to begin populating data
          for the mapped applications.
        </span>
      ),
      logo: zyloApiLogo,
      knowledgeBaseUrl: 'https://developer.zylo.com/',
      active: true,
      fields: [],
      requiredFields: [],
      canBePrimaryUserStore: false,
      multiOrgSupport: false,
      isOauth: false,
      isAppProvider: true,
      mappingType: 'import-api-usage',
      hasCustomModal: true,
    },
    {
      type: 'genericsso',
      displayName: 'Third Party',
      category: 'sso',
      description:
        'The Zylo + Amazon S3 integration provides an S3 Bucket where app and utilization data can be dropped from a third party application and then mapped to an application in Zylo to track user activity.',
      additionalDescription:
        'Zylo imports files dropped to your S3 location daily. Map applications in Zylo to the unique applications found in the data provided via your S3 location. To remove any mappings that have changed or were done in error, just click on the row and select "Unmap."',
      logo: s3SyncLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360057507951-Amazon-S3-Event-Log-Importer',
      active: process.env.NODE_ENV === 'development',
      fields: [],
      requiredFields: [],
      canBePrimaryUserStore: false,
      multiOrgSupport: false,
      isOauth: false,
      isAppProvider: true,
      mappingType: 'batch-generic-sso',
      hasCustomModal: true,
    },
    {
      type: 'gsuite',
      displayName: 'Google Workspace',
      appName: 'Google Workspace',
      category: 'utilization',
      description:
        'The Zylo + Google Workspace integration gives teams visibilitiy into plan-specific provisioning details and use, as well as user level consumption of Google Workspace services such as Gmail and Drive, right inside of the Zylo platform.',
      logo: googleWorkspaceLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020339932-G-Suite-Integration',
      active: get(featureFlags, 'gsuite', false),
      formatOauthValidationData: (options) => {
        const {
          oauthToken,
          domain,
          includeTokenData = false,
          includeUtilizationData = true,
        } = options;

        return {
          code: oauthToken,
          redirectUri: `${CLIENT_URI}/gsuite`,
          domain,
          includeTokenData,
          includeUtilizationData,
        };
      },
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      canBePrimaryUserStore: true,
      isCustomOauth: true,
      formatCustomOauthURL: function formatCustomOauthURL() {
        const scopes = [
          'https://www.googleapis.com/auth/admin.directory.user.readonly',
          'https://www.googleapis.com/auth/admin.directory.domain.readonly',
          'https://www.googleapis.com/auth/apps.licensing',
          'https://www.googleapis.com/auth/admin.reports.usage.readonly',
          'https://www.googleapis.com/auth/admin.reports.audit.readonly',
        ];
        const scope = encodeURIComponent(scopes.join(' '));
        return `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&client_id=${GSUITE_CLIENT_ID}&redirect_uri=${CLIENT_URI}/gsuite&prompt=consent`;
      },
      fields: hasTokenReportFeature ? GSUITE_TOKEN_FIELDS : [],
      requiredFields: [],
    },
    {
      type: 'googlesso',
      displayName: 'Google SSO',
      appName: 'Google SSO',
      category: 'sso',
      isAppProvider: true,
      hasCustomModal: true,
      mappingType: 'sso',
      description:
        'The Zylo + Google SSO integration combines Google SSO utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.',
      logo: googleSSOLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/12361025932813-Google-SSO-Integration',
      active: get(featureFlags, 'google-workspace-sso', false),
      formatOauthValidationData: (options) => {
        const { oauthToken, domain } = options;

        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}googlesso`,
          domain,
        };
      },
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      canBePrimaryUserStore: false,
      isCustomOauth: true,
      formatCustomOauthURL: function formatCustomOauthURL() {
        const scopes = [
          'https://www.googleapis.com/auth/admin.directory.user.readonly',
          'https://www.googleapis.com/auth/admin.directory.domain.readonly',
          'https://www.googleapis.com/auth/admin.reports.audit.readonly',
          'https://www.googleapis.com/auth/admin.reports.usage.readonly',
          'https://www.googleapis.com/auth/apps.licensing',
        ];
        const scope = encodeURIComponent(scopes.join(' '));
        return `https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=${scope}&response_type=code&client_id=${GOOGLE_SSO_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}googlesso&prompt=consent`;
      },
      fields: [],
      requiredFields: [],
    },
    {
      active: get(featureFlags, 'user-import', false),
      category: 'hr',
      canBePrimaryUserStore: true,
      displayName: 'HRIS Integration',
      description:
        'The Zylo HRIS Integration accepts automated file transfers and manual CSV uploads to reflect any HRIS or user data source of truth in Zylo.',
      hasCustomModal: true,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/4788046078733-Zylo-HRIS-Integration',
      logo: zyloHrisLogo,
      type: 'hrroster',
    },
    {
      type: 'informatica',
      displayName: 'Informatica Cloud',
      category: 'utilization',
      active: get(featureFlags, 'informatica', false),
      description:
        'The Zylo + Informatica integration gives teams powerful utilization insights into user accounts, organizations, connections, and usergroups.',
      logo: informaticaLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360024454852-Informatica-Integration',
      fields: [
        {
          name: 'username',
          label: 'Username',
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
        },
        {
          name: 'subdomain',
          label: 'Informatica Domain',
          type: 'subdomainInput',
          inputGroupPre: 'https://',
          inputGroupPost: '.informaticacloud.com',
          placeholder: 'dmr-us',
        },
      ],
      requiredFields: ['username', 'password'],
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      isOauth: false,
      disabledReconnectingFields: ['subdomain'],
    },
    {
      type: 'intacct',
      displayName: 'Intacct',
      category: 'financial',
      description:
        'The Zylo + Intacct integration gives teams the ability to visualize AP payment data within a broader context of cloud application purchases and utilization data.',
      logo: intacctLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603471-Intacct-Integration-Setup',
      active: get(featureFlags, 'intacct', false),
      fields: [
        {
          name: 'companyId',
          label: 'Company ID',
        },
        {
          name: 'userId',
          label: 'User ID',
        },
        {
          name: 'userPassword',
          label: 'User Password',
          type: 'password',
        },
      ],
      requiredFields: ['companyId', 'userId', 'userPassword'],
      disabledReconnectingFields: ['companyId'],
    },
    {
      type: 'jiracloud',
      displayName: 'Jira Cloud',
      appName: 'Jira Cloud',
      category: 'utilization',
      description:
        'The Zylo + Jira Cloud integration analyzes user activity data to provide insight into which employees are using JIRA most.',
      logo: jiraLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360029988572-Jira-Cloud-Integration',
      active: get(featureFlags, 'jiracloud', false),
      isCustomOauth: true,
      formatCustomOauthURL: () => {
        const scopes = ['read:jira-user', 'offline_access'];
        return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${JIRACLOUD_CLIENT_ID}&scope=${scopes.join(
          ' ',
        )}&redirect_uri=${REDIRECT_BASE}jiracloud&state=${companyId}&response_type=code&prompt=consent`;
      },
      formatOauthValidationData: ({ oauthToken, domain }) => {
        return {
          code: oauthToken,
          redirectUri: `${CLIENT_URI}/jiracloud`,
          domain,
        };
      },
      canBePrimaryUserStore: false,
      multiOrgSupport: false,
      fields: [
        {
          name: 'domain',
          label: 'Jira Cloud Domain',
          type: 'subdomainInput',
          inputGroupPre: 'https://',
          inputGroupPost: '.atlassian.net',
        },
      ],
      requiredFields: ['domain'],
      hasCustomModal: true,
      isWebhookIntegration: true,
      disabledReconnectingFields: ['domain'],
    },
    {
      type: 'looker',
      displayName: 'Looker',
      category: 'utilization',
      description:
        'The Zylo + Looker integration analyzes user activity data to provide insight into which employees are using Looker most.',
      logo: lookerLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360042055652-Looker-Integration',
      isOauth: false,
      active: get(featureFlags, 'looker', false),
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      fields: [
        {
          name: 'lookerClientId',
          label: 'Client ID',
        },
        {
          name: 'lookerSecretId',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'port',
          label: 'API Host URL Port Number',
          type: 'number',
          placeholder: 'Default is 443 or 19999',
          instructions: (
            <span>
              This field is required.
              <br />
              Use port 19999 if your instance has no specified port and was created before 07/2020.
              <br />
              Use port 443 if your instance has no specified port and was created after 07/2020.
            </span>
          ),
        },
        {
          name: 'domain',
          label: 'API Host URL',
          type: 'domainInput',
          inputGroupPre: 'https://',
          placeholder: 'dmr-us.looker.com',
          validate: (value: string) => {
            if (value) {
              if (value.includes('/')) return 'Value cannot include forward slashes.';
              // @ts-ignore src/utilities/formUtil.js should be typed to remove type error
              if (!checkDomain(value)) return 'Invalid domain. Example: dmr-us.looker.com';
            }
            return undefined;
          },
        },
      ],
      requiredFields: ['domain', 'lookerClientId', 'lookerSecretId', 'port'],
      disabledReconnectingFields: ['port', 'domain'],
    },
    {
      type: 'miro',
      displayName: 'Miro',
      category: 'utilization',
      description:
        'The Zylo + Miro integration analyzes user activity data to provide insight into which employees are using Miro the most.',
      logo: miroLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/4405327197325-Miro-Integration',
      active: get(featureFlags, 'miro', true),
      hasCustomModal: true,
      beta: false,
      multiOrgSupport: true,
      canBePrimaryUserStore: false,
      isOauth: false,
      fields: [
        {
          name: 'accessToken',
          label: 'Access Token',
          type: 'password',
        },
      ],
      requiredFields: ['accessToken'],
    },
    {
      type: 'netsuite',
      displayName: 'NetSuite',
      category: 'financial',
      description:
        'The Zylo + Netsuite integration helps to find, uncover, and classify cloud-related spend by analyzing bill and payment data.',
      logo: netsuiteLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020339852-Netsuite-Integration-Setup',
      active: get(featureFlags, 'netsuite', false),
      fields: [
        {
          name: 'accountId',
          label: 'Account ID',
        },
        {
          name: 'consumerKey',
          label: 'Consumer Key',
        },
        {
          name: 'consumerSecret',
          label: 'Consumer Secret',
          type: 'password',
        },
        {
          name: 'tokenId',
          label: 'Token ID',
        },
        {
          name: 'tokenSecret',
          label: 'Token Secret',
          type: 'password',
        },
        get(featureFlags, 'netsuiteSavedSearch', false)
          ? {
              name: 'useSavedSearch',
              label: 'Use Saved Search',
              type: 'checkbox',
            }
          : null,
      ],
      requiredFields: ['accountId', 'consumerKey', 'consumerSecret', 'tokenId', 'tokenSecret'],
      disabledReconnectingFields: ['accountId'],
      hasCustomModal: true,
    },
    {
      type: 'office365v2',
      displayName: 'Microsoft 365',
      appName: 'Microsoft 365',
      category: 'utilization',
      description:
        'The Zylo - Microsoft 365 integration enables Zylo users to view information about the plans and services that are enabled and used from the connected Microsoft Office tenet, including an enhanced view of Skype for Business use.',
      logo: o365Logo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020339952-Microsoft-Office-365-Integration',
      active: get(featureFlags, 'office365-v2', false),
      isOauth: true,
      oauthURL: `https://login.microsoftonline.com/common/adminconsent?client_id=${OFFICE365_V2_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}office365v2`,
      formatOauthValidationData: ({ tenant }) => {
        return {
          tenantId: tenant,
          redirectUri: `${REDIRECT_BASE}/office365v2`,
        };
      },
      canBePrimaryUserStore: true,
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      hasCustomModal: true,
    },
    {
      type: 'okta',
      displayName: 'Okta',
      appName: 'Okta',
      category: 'sso',
      isAppProvider: true,
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      mappingType: 'sso',
      description:
        'The Zylo + Okta integration combines Okta’s utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.',
      logo: oktaLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360020339892-Okta-Integration',
      active: get(featureFlags, 'okta', false),
      basicAuthFields: [
        {
          name: 'url',
          label: 'Okta URL',
          placeholder: 'ex: https://zylo.okta.com',
        },
        {
          name: 'apikey',
          label: 'API Key',
          type: 'password',
        },
        {
          name: 'backoffThreshold',
          label: 'API Backoff Threshold (%)',
          type: 'number',
          value: 80,
          validate: (value: number) => {
            const min = 20;
            const max = 80;
            const step = 1; // ie: HTML5 number input "step" attribute
            if (value < min) return `Minimum value allowed is ${min}`;
            if (value > max) return `Max value allowed is ${max}`;
            if (value % step !== 0) return `Value must be a number within an increment of ${step}`;
            return undefined;
          },
        },
      ],
      requiredBasicAuthFields: ['url', 'apikey'],
      oAuthFields: [
        {
          name: 'url',
          label: 'Okta URL',
          placeholder: 'ex: https://zylo.okta.com',
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'backoffThreshold',
          label: 'API Backoff Threshold (%)',
          type: 'number',
          value: 80,
          validate: (value: number) => {
            const min = 20;
            const max = 80;
            const step = 1; // ie: HTML5 number input "step" attribute
            if (value < min) return `Minimum value allowed is ${min}`;
            if (value > max) return `Max value allowed is ${max}`;
            if (value % step !== 0) return `Value must be a number within an increment of ${step}`;
            return undefined;
          },
        },
      ],
      oAuthRequiredFields: ['clientId', 'clientSecret', 'url'],
      disabledReconnectingFields: ['url'],
      canBePrimaryUserStore: true,
      hasCustomModal: true,
    },
    {
      type: 'onelogin',
      displayName: 'OneLogin',
      appName: 'OneLogin',
      category: 'sso',
      isAppProvider: true,
      mappingType: 'sso',
      description:
        'The Zylo + OneLogin integration combines OneLogin’s utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.',
      logo: oneloginLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603551-OneLogin-Integration',
      active: get(featureFlags, 'onelogin', false),
      fields: [
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'useWebhook',
          label: (
            <div className="sandbox-label">
              <Icon color="darkgray" icon="IconLink" size={1.5} relativeSize />
              Use Webhook?
            </div>
          ),
          type: 'checkbox',
          value: false,
        },
        {
          name: 'backoffThreshold',
          label: 'API Backoff Threshold (%)',
          type: 'number',
          value: 80,
          validate: (value: number) => {
            const min = 20;
            const max = 80;
            const step = 1; // ie: HTML5 number input "step" attribute
            if (value < min) return `Minimum value allowed is ${min}`;
            if (value > max) return `Max value allowed is ${max}`;
            if (value % step !== 0) return `Value must be a number within an increment of ${step}`;
            return undefined;
          },
        },
      ],
      requiredFields: ['clientId', 'clientSecret'],
      canBePrimaryUserStore: true,
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      hasCustomModal: true,
    },
    {
      type: 'outreachv2',
      displayName: 'Outreach',
      category: 'utilization',
      description:
        'The Zylo + Outreach integration analyzes user activity data to provide insight into which employees are using Outreach most.',
      logo: outreachLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360041547092-Outreach-io-Integration',
      active: get(featureFlags, 'outreach-native-21-1', true),
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      isOauth: true,
      oauthURL: `https://api.outreach.io/oauth/authorize?response_type=code&client_id=${OUTREACH_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}outreachv2&scope=${OUTREACH_SCOPES.join(
        '+',
      )}&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken }) => {
        return { code: oauthToken, redirectUri: `${REDIRECT_BASE}outreachv2` };
      },
    },
    {
      type: 'pagerdutyv2',
      displayName: 'PagerDuty',
      category: 'utilization',
      description:
        'The Zylo + PagerDuty integration analyzes user activity data to provide insight into which employees are using PagerDuty most.',
      logo: pagerdutyLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360040643452-PagerDuty-Integration',
      active: get(featureFlags, 'pagerduty-v2', true),
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      isOauth: true,
      oauthURL: `https://app.pagerduty.com/oauth/authorize?response_type=code&client_id=${PAGERDUTY_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}pagerdutyv2&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken, subdomain }) => {
        return { code: oauthToken, subdomain, redirectUri: `${REDIRECT_BASE}pagerdutyv2` };
      },
    },
    {
      type: 'pingplatform',
      displayName: 'Ping Platform',
      appName: 'Ping Platform',
      category: 'sso',
      isAppProvider: true,
      multiOrgSupport: get(featureFlags, 'multiple-user-stores', false),
      mappingType: 'sso',
      description:
        'The Zylo + PingOne Cloud Platform integration combines PingOne’s utilization and employee data with Zylo’s spend, licensing, and application data to give teams insight into their full cloud stack.',
      logo: pingLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/16283422451981-PingOne-Platform-SSO-Integration',
      active: get(featureFlags, 'ping-one-platform-sso', false),
      fields: [
        {
          name: 'clientId',
          label: 'Client Id',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'environmentId',
          label: 'Environment ID',
        },
      ],
      requiredFields: ['clientId', 'clientSecret', 'environmentId'],
      canBePrimaryUserStore: true,
      hasCustomModal: true,
    },
    {
      type: 'salesforce',
      displayName: 'Salesforce',
      category: 'utilization',
      description:
        'The Zylo + Salesforce integration combines user login data with employee & license data, linking employees to provisioned licenses and providing valuable insight into license utilization.',
      logo: salesforceLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603431-Salesforce-Integration',
      active: get(featureFlags, 'salesforce', false),
      isOauth: true,
      oauthURL: `https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9szVa2RxsqBbZfM8rwzp.jHvnu.HxsmXkXwJPWNgQxPKxxvpBNQbeo9BRbJL5X2SaTHrFv7_iRuMDZcYS&response_type=code&redirect_uri=${CLIENT_URI}/salesforce&scope=id+api+refresh_token&prompt=login%20consent`,
      formatOauthValidationData: ({ oauthToken, isSandbox }) => {
        return {
          code: oauthToken,
          redirectUri: `${CLIENT_URI}/salesforce${isSandbox ? 'sandbox' : ''}`,
          sandboxAccount: isSandbox,
        };
      },
      sandboxURL: `https://test.salesforce.com/services/oauth2/authorize?client_id=3MVG9szVa2RxsqBbZfM8rwzp.jHvnu.HxsmXkXwJPWNgQxPKxxvpBNQbeo9BRbJL5X2SaTHrFv7_iRuMDZcYS&response_type=code&redirect_uri=${CLIENT_URI}/salesforcesandbox&scope=id+api+refresh_token&prompt=login%20consent`,
      multiOrgSupport: true,
    },
    {
      type: 'calendly',
      displayName: 'Calendly',
      category: 'utilization',
      description:
        'The Zylo + Calendly integration collects user, scheduled events, and activity logs to show utilization within Calendly.',
      logo: calendlyLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/12571127777933-Calendly-Integration',
      active: get(featureFlags, 'calendly', false),
      isOauth: true,
      oauthURL: `https://auth.calendly.com/oauth/authorize?client_id=${CALENDLY_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_BASE}calendly`,
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}calendly`,
        };
      },
      multiOrgSupport: true,
    },
    {
      type: 'servicenowv2',
      displayName: 'ServiceNow',
      category: 'utilization',
      active: get(featureFlags, 'servicenow-native-21-1', false),
      description:
        'The Zylo + ServiceNow integration analyzes user activity data to provide insight into which employees are using ServiceNow most.',
      logo: serviceNowLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360038603152-ServiceNow-Integration',
      hasCustomModal: true,
      basicAuthFields: [
        {
          name: 'subdomain',
          label: 'ServiceNow Instance URL',
          type: 'subdomainInput',
          inputGroupPre: 'https://',
          inputGroupPost: '.service-now.com',
        },
        {
          name: 'username',
          label: 'User ID',
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
        },
      ],
      requiredBasicAuthFields: ['username', 'password', 'subdomain'],
      oAuthFields: [
        {
          name: 'subdomain',
          label: 'ServiceNow Instance URL',
          type: 'subdomainInput',
          inputGroupPre: 'https://',
          inputGroupPost: '.service-now.com',
        },
        {
          name: 'clientId',
          label: 'Client ID',
        },
        {
          name: 'clientSecret',
          label: 'Client Secret',
          type: 'password',
        },
        {
          name: 'emailAddress',
          label: 'E-Mail Address',
        },
        {
          name: 'keyId',
          label: 'Key ID',
        },
      ],
      oAuthRequiredFields: ['clientId', 'clientSecret', 'emailAddress', 'keyId'],
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      isOauth: false,
      disabledReconnectingFields: ['subdomain'],
    },
    {
      type: 'slack',
      displayName: 'Slack',
      category: 'communication',
      description:
        'The Zylo + Slack integration connects Zylo to your Slack team to power alerts and sentiment surveys from Zylo right where employees spend their time - in Slack.',
      logo: slackLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360020340032-Slack-Integration',
      active: get(featureFlags, 'slack', false),
      isOauth: true,
      oauthURL: `https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,team:read,users:read,users:read.email&user_scope=&client_id=${SLACK_CLIENT_ID}&redirect_uri=${CLIENT_URI}/slack&state=${companyId}`,
      customOauthConnectButton: (
        <a
          href={`https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,team:read,users:read,users:read.email&user_scope=&client_id=${SLACK_CLIENT_ID}&redirect_uri=${CLIENT_URI}/slack&state=${companyId}`}
        >
          <img
            alt="Add to Slack"
            height="40"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            width="139"
          />
        </a>
      ),
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${CLIENT_URI}/slack`,
        };
      },
    },
    {
      type: 'slackutilization',
      displayName: 'Slack',
      category: 'utilization',
      description:
        'The Zylo + Slack integration analyzes user activity data to provide insight into which employees are using Slack most.',
      logo: slackLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360045287351-Slack-Usage-Integration',
      active: get(featureFlags, 'slack-util', false),
      isOauth: true,
      oauthURL: `https://slack.com/oauth/authorize?scope=users:read users:read.email team:read admin&client_id=${SLACK_UTILIZATION_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}slackutilization&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}slackutilization`,
        };
      },
      multiOrgSupport: true,
    },
    {
      type: 'smartsheet',
      displayName: 'Smartsheet',
      category: 'utilization',
      description:
        'The Zylo + Smartsheet integration analyzes user activity data to provide insight into which employees are using Smartsheet most.',
      logo: smartsheetLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020340132-Smartsheet-Integration',
      active: get(featureFlags, 'smartsheet', false),
      isOauth: true,
      oauthURL: `https://app.smartsheet.com/b/authorize?response_type=code&client_id=${SMARTSHEET_CLIENT_ID}&scope=${encodeURIComponent(
        SMARTSHEET_SCOPES.join(' '),
      )}&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken }) => {
        return { code: oauthToken, scopes: SMARTSHEET_SCOPES };
      },
      multiOrgSupport: true,
    },
    {
      type: 'veevacrm',
      displayName: 'Veeva CRM',
      category: 'utilization',
      description:
        'The Zylo + Veeva CRM integration combines user login data with employee & license data, linking employees to provisioned licenses and providing valuable insight into license utilization.',
      logo: veevaCrmLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603631-Veeva-CRM-Integration',
      active: get(featureFlags, 'veevacrm', false),
      isOauth: true,
      oauthURL: `https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9szVa2RxsqBbZfM8rwzp.jHvnu.HxsmXkXwJPWNgQxPKxxvpBNQbeo9BRbJL5X2SaTHrFv7_iRuMDZcYS&response_type=code&redirect_uri=${CLIENT_URI}/veevacrm&scope=id+api+refresh_token&prompt=login%20consent`,
      formatOauthValidationData: ({ oauthToken, isSandbox }) => {
        return {
          code: oauthToken,
          redirectUri: `${CLIENT_URI}/veevacrm${isSandbox ? 'sandbox' : ''}`,
          sandboxAccount: isSandbox,
        };
      },
      sandboxURL: `https://test.salesforce.com/services/oauth2/authorize?client_id=3MVG9szVa2RxsqBbZfM8rwzp.jHvnu.HxsmXkXwJPWNgQxPKxxvpBNQbeo9BRbJL5X2SaTHrFv7_iRuMDZcYS&response_type=code&redirect_uri=${CLIENT_URI}/veevacrmsandbox&scope=id+api+refresh_token&prompt=login%20consent`,
      multiOrgSupport: true,
    },
    {
      type: 'veevavault',
      displayName: 'Veeva Vault',
      category: 'utilization',
      description:
        'The Zylo + Veeva Vault integration analyzes user activity data to provide insight into which employees are accessing and sharing approved company resources and when.',
      logo: veevaVaultLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603671-Veeva-Vault-Integration',
      active: get(featureFlags, 'veevavault', false),
      fields: [
        {
          name: 'username',
          label: 'Username',
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
        },
        {
          name: 'vaultName',
          label: 'Domain',
        },
      ],
      requiredFields: ['username', 'password', 'vaultName'],
      disabledReconnectingFields: ['vaultName'],
      multiOrgSupport: true,
    },
    {
      type: 'workdayfinancialexpense',
      displayName: 'Workday Financial Expense',
      subtitle: 'Expense',
      category: 'financial',
      description:
        'The Zylo + Workday integration helps to find, uncover, and classify cloud-related spend by analyzing bill and payment data.',
      logo: workdayLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/4409651615373-Workday-Financial-Management-Integration-Setup',
      active: get(featureFlags, 'workday-financial', false),
      fields: [
        {
          name: 'reportUrl',
          label: 'Report URL',
        },
        {
          name: 'username',
          label: 'Username',
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
        },
        {
          name: 'dateFilterLabel',
          label: 'Date Filter Label',
          instructions: (
            <span>
              This field is required.
              <br />
              This field is the date field you configured as your date filter when configuring
              <br />
              your Workday report. We will use this to fetch your report data on a daily basis.
              <br />
            </span>
          ),
        },
      ],
      requiredFields: ['reportUrl', 'username', 'password', 'dateFilterLabel'],
      hasCustomModal: true,
      validate: (value: string) => {
        const workdayDomainRe = /^.*workday\.(com).*?/;
        if (!checkDomain(value, workdayDomainRe, false)) {
          return false;
        } else {
          return true;
        }
      },
    },
    {
      type: 'workdayfinancialap',
      displayName: 'Workday Financial AP',
      subtitle: 'AP',
      category: 'financial',
      description:
        'The Zylo + Workday integration helps to find, uncover, and classify cloud-related spend by analyzing bill and payment data.',
      logo: workdayLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/4409651615373-Workday-Financial-Management-Integration-Setup',
      active: get(featureFlags, 'workday-financial', false),
      fields: [
        {
          name: 'reportUrl',
          label: 'Report URL',
        },
        {
          name: 'username',
          label: 'Username',
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
        },
        {
          name: 'dateFilterLabel',
          label: 'Date Filter Label',
          instructions: (
            <span>
              This field is required.
              <br />
              This field is the date field you configured as your date filter when configuring
              <br />
              your Workday report. We will use this to fetch your report data on a daily basis.
              <br />
            </span>
          ),
        },
      ],
      requiredFields: ['reportUrl', 'username', 'password', 'dateFilterLabel'],
      hasCustomModal: true,
      validate: (value: string) => {
        const workdayDomainRe = /^.*workday\.(com).*?/;
        if (!checkDomain(value, workdayDomainRe, false)) {
          return false;
        } else {
          return true;
        }
      },
    },
    {
      type: 'wrike',
      displayName: 'Wrike',
      appName: 'Wrike',
      category: 'utilization',
      description:
        'The Zylo + Wrike integration analyzes user activity data to provide insight into which employees are using Wrike most.',
      logo: wrikeLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360045687332-Wrike-Integration',
      active: get(featureFlags, 'wrike', false),
      isOauth: true,
      oauthURL: `https://login.wrike.com/oauth2/authorize/v4?response_type=code&scope=${encodeURIComponent(
        WRIKE_SCOPES.join(','),
      )}&client_id=${WRIKE_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}wrike&state=${companyId}`,
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}wrike`,
        };
      },
      multiOrgSupport: true,
    },
    {
      type: 'launchdarkly',
      displayName: 'LaunchDarkly',
      category: 'utilization',
      active: get(featureFlags, 'launch-darkly-21-2', false),
      description:
        'The Zylo + LaunchDarkly integration analyzes user activity data to provide insight into which employees are using LaunchDarkly most.',
      logo: launchDarklyLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360060879611-LaunchDarkly-Integration',
      hasCustomModal: true,
      fields: [
        {
          name: 'apikey',
          label: 'API Access Token',
          type: 'password',
        },
      ],
      requiredFields: ['apikey'],
      canBePrimaryUserStore: false,
      multiOrgSupport: true,
      isOauth: false,
      beta: false,
    },
    {
      type: 'zendesk',
      displayName: 'Zendesk',
      category: 'utilization',
      description:
        'The Zylo + Zendesk integration analyzes user activity data to provide insight into which employees are using Zendesk most.',
      logo: zendeskLogo,
      knowledgeBaseUrl:
        'https://zylo.zendesk.com/hc/en-us/articles/360020603751-Zendesk-Integration',
      active: get(featureFlags, 'zendesk', false),
      isCustomOauth: true,
      formatCustomOauthURL: ({ subdomain }) => {
        return `https://${subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${REDIRECT_BASE}zendesk&client_id=${ZENDESK_CLIENT_ID}&scope=read`;
      },
      formatOauthValidationData: ({ oauthToken, subdomain }) => {
        return {
          code: oauthToken,
          subdomain,
          redirectUri: `${REDIRECT_BASE}zendesk`,
        };
      },
      fields: [
        {
          name: 'subdomain',
          label: 'Zendesk Subdomain',
        },
      ],
      requiredFields: ['subdomain'],
      disabledReconnectingFields: ['subdomain'],
      multiOrgSupport: true,
    },
    {
      type: 'zoom',
      displayName: 'Zoom',
      appName: 'Zoom',
      category: 'utilization',
      description:
        'The Zylo + Zoom integration analyzes user activity data to provide insight into which employees are using Zoom most.',
      logo: zoomLogo,
      knowledgeBaseUrl: 'https://zylo.zendesk.com/hc/en-us/articles/360030115831-Zoom-Integration',
      active: get(featureFlags, 'zoom', false),
      isOauth: true,
      oauthURL: `https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${REDIRECT_BASE}zoom`,
      formatOauthValidationData: ({ oauthToken }) => {
        return {
          code: oauthToken,
          redirectUri: `${REDIRECT_BASE}zoom`,
        };
      },
      multiOrgSupport: true,
    },
  ];
}

/** A utility to create a dictionary of user friendly integration display names */
export const integrationDisplayNames: IGenericMapObject<string> = integrationMasterList(
  'unusedParam',
).reduce((ttl: IGenericMapObject<string>, val) => {
  ttl[val.type] = val.displayName;
  return ttl;
}, {});
