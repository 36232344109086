const primaryFirstFields = ['firstName', 'lastName', 'department', 'inPrimaryStore'];

export function parseOldColumnsOrder(columnsOrder, primaryUserStoreIntegrationId) {
  return columnsOrder.map((column) => {
    if (column.includes('_')) {
      return column;
    }

    if (primaryFirstFields.includes(column) && primaryUserStoreIntegrationId) {
      return `primary_${column}`;
    }

    return `direct_${column}`;
  });
}

export function parseOldFilters(displayFilters, primaryUserStoreIntegrationId) {
  const newDisplayFilters = {};
  const parsedFilters = JSON.parse(displayFilters);
  Object.keys(parsedFilters).forEach((filter) => {
    if (filter.includes('_')) {
      return (newDisplayFilters[filter] = parsedFilters[filter]);
    }

    if (primaryFirstFields.includes(filter) && primaryUserStoreIntegrationId) {
      return (newDisplayFilters[`primary_${filter}`] = parsedFilters[filter]);
    }

    return (newDisplayFilters[`direct_${filter}`] = parsedFilters[filter]);
  });

  return newDisplayFilters;
}

export function parseOldTableSort(tableSort, primaryUserStoreIntegrationId) {
  if (tableSort.includes('_')) {
    return tableSort;
  }

  const sortOrder = tableSort.charAt(0);
  const sortField = tableSort.substring(1);

  return [
    sortOrder,
    primaryFirstFields.includes(sortField) && primaryUserStoreIntegrationId
      ? 'primary_'
      : 'direct_',
    sortField,
  ].join('');
}

/**
 * The User Apps grid had to have changes made to the "key" field since it would
 * no longer be considered unique. For example, the "email" field could now be either
 * "primary_email" or "direct_email".
 *
 * To address this change, old saved/insight filters need to have their keys to match the new
 * format. The best way to approach this was to modify the response from old saved/insight filters
 * to have the new keys. This prevents downstream changes that can get confusing.
 *
 * This function goes through each column returned from the saved/insight filter that uses the old
 * key naming format (columnsOrder, displayFilters, filters, tableSort).
 */
export function handleOldSavedFilterKeys(fetchedFilters, primaryUserStoreIntegrationId) {
  // Loops through each filter
  return fetchedFilters.map((filter) => {
    const { columnsOrder, displayFilters, tableSort } = filter;

    // Changes columnOrder keys to new format
    filter.columnsOrder = parseOldColumnsOrder(columnsOrder, primaryUserStoreIntegrationId);

    // Changes filters to new format
    filter.filters = parseOldFilters(displayFilters, primaryUserStoreIntegrationId);

    // Changes tableSort to new format
    filter.tableSort = parseOldTableSort(tableSort, primaryUserStoreIntegrationId);

    return filter;
  });
}
