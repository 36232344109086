import { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Loader } from '@zylo/orchestra';
import { AppQueryClient } from '@lib/components/AppQueryClient';
import BrowserWarningPage from './pages/BrowserWarningPage';

import './configureDayjs';
import './styles/main.scss';

const Root = lazy(() => import('./Root'));

// Check if IE
if (
  window.navigator.userAgent.indexOf('MSIE') !== -1 ||
  window.navigator.appVersion.indexOf('Trident/') > 0
) {
  render(<BrowserWarningPage />, document.getElementById('app'));
} else {
  render(
    <Suspense fallback={<Loader />}>
      <AppQueryClient>
        <Root />
      </AppQueryClient>
    </Suspense>,
    document.getElementById('app'),
  );
}
