import styled from 'styled-components/macro';
import { PortalModal, defaultborder, FontStyles } from '@zylo/orchestra';
import CancelAndConfirmButtons from '@components/buttons/CancelAndConfirmButtons';
import { usePortalTarget } from '@hooks';

const WarningModal = styled(PortalModal)`
  padding: 20px !important;

  .warning-modal-content {
    max-width: 500px;
    font-size: 16px;
    font-weight: ${FontStyles.weight[400]};

    h5 {
      padding-bottom: 20px;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: ${FontStyles.weight[400]};
      border-bottom: ${defaultborder};
    }

    h6 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 32px;
    }

    .cancel-and-confirm-buttons {
      margin-top: 12px;
    }
  }
`;

type AssignmentChangeWarningProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
};

export default function ApplicationAssignmentChangeWarning(props: AssignmentChangeWarningProps) {
  const { handleCancel, handleConfirm } = props;
  const portalTarget = usePortalTarget();

  return (
    <WarningModal handleClose={handleCancel} portalTarget={portalTarget} isOpen>
      <div className="flex-container vertical warning-modal-content">
        <h5>Warning</h5>
        <p>
          Changing the assignment of this application will result in the paired Purchase Order being
          cleared, do you wish to continue?
        </p>
        <CancelAndConfirmButtons
          cancelButtonText="No, Cancel"
          confirmButtonText="Yes, Change assignment"
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      </div>
    </WarningModal>
  );
}
