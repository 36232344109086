import { useEffect, useRef, useState } from 'react';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import { Checkbox, FormField, FormFieldTextarea, Icon, PortalModal } from '@zylo/orchestra';
import { useActiveSavedFilterState } from '@contexts/savedFilterContexts/activeSavedFilterContext';
import { useSavedFiltersListState } from '@contexts/savedFilterContexts/savedFiltersListContext';
import { useSavedFiltersTypeState } from '@contexts/savedFilterContexts/savedFiltersTypeContext';
import { usePermissions } from '@hooks';
import AppSelectorInput from '@components/forms/inputs/AppSelectorInput';
import CancelAndConfirmButtons from '../../buttons/CancelAndConfirmButtons';
import { SAVED_FILTERS_TYPES } from '../utilities/savedFiltersConstants';
import { useCreateInsightFilter, useCreateSavedFilter, useUpdateInsightFilter } from '../hooks';

export default function SavedFiltersForm({ handleClose, isInsight }) {
  const dataLayer = useDataLayer(handleClose, isInsight);
  const { closeModal } = dataLayer;

  return (
    <PortalModal
      className="saved-filters-create-modal"
      handleClose={closeModal}
      isOpen={true}
      clickOutsideToClose
    >
      <SavedFiltersForm_DisplayLayer {...dataLayer} />
    </PortalModal>
  );
}

export function SavedFiltersForm_DisplayLayer({
  authCanAccessAdmin,
  authCanAccessProductAdmin,
  closeModal,
  createInsightFilter,
  createSavedFilter,
  createSavedFilterError,
  insightFilterToUpdate,
  insightFilterTypes = {},
  modalIsActive,
  savedFiltersType,
  updateInsightFilter,
}) {
  const [modalHeader, setModalHeader] = useState('Create a Saved Filter');
  const [filterName, setFilterName] = useState(get(insightFilterToUpdate, 'filterName', ''));
  const [description, setDescription] = useState(get(insightFilterToUpdate, 'description', ''));
  const [betaFilter, setBetaFilter] = useState(get(insightFilterToUpdate, 'betaFilter', ''));
  const [displayForAppId, setDisplayForAppId] = useState(
    get(insightFilterToUpdate, 'displayForAppId', ''),
  );
  const [displayAppSelected, setDisplayAppSelected] = useState(
    get(insightFilterToUpdate, 'displayForAppId', false),
  );
  const [teamFilter, setTeamFilter] = useState(false);
  const [insightFilter, setInsightFilter] = useState(!!insightFilterToUpdate);
  const focusRef = useRef();
  const { integrationType, mappingType } = insightFilterTypes;
  const isSaveDisabled = insightFilter
    ? description.length === 0 ||
      filterName.length === 0 ||
      (displayAppSelected && displayForAppId === '')
    : filterName.length === 0;

  function handleCreateSavedFilter() {
    createSavedFilter({ filterName, teamFilter });
  }

  function handleCreateInsightFilter() {
    createInsightFilter({
      betaFilter,
      description,
      filterName,
      integrationType,
      mappingType,
      displayForAppId,
    });
  }

  function handleUpdateInsightFilter() {
    updateInsightFilter({ betaFilter, description, filterName, displayForAppId });
  }

  function handleAppSelection(option) {
    setDisplayForAppId(option);
  }

  function handleSetCompanyAppSelection(checked) {
    if (!checked) {
      setDisplayForAppId('');
    }
    setDisplayAppSelected(checked);
  }

  function handleSave(e) {
    e.preventDefault();

    if (insightFilterToUpdate) {
      handleUpdateInsightFilter();

      return;
    }

    if (insightFilter) {
      handleCreateInsightFilter();

      return;
    }

    handleCreateSavedFilter();
  }

  useEffect(() => {
    if (insightFilter && insightFilterToUpdate) {
      setModalHeader('Update Insight Filter');
    } else if (insightFilter) {
      setModalHeader('Create Insight Filter');
    }
  }, [insightFilter, insightFilterToUpdate]);

  useEffect(() => {
    if (insightFilter) {
      setBetaFilter(true);
    }
  }, [insightFilter]);

  useEffect(() => {
    if (modalIsActive) {
      focusRef.current.focus();
    }
  }, [modalIsActive]);

  return (
    <form className="create-saved-filter" onSubmit={handleSave}>
      <Icon
        className="create-saved-filter__close-button "
        icon="IconX"
        onClick={closeModal}
        testId="saved-filter-form-close"
      />
      <header>
        <h2 className="saved-filters__heading">{modalHeader}</h2>
      </header>

      <FormField
        className="create-saved-filter__form-field"
        errors={createSavedFilterError}
        instructions="This field is required."
        label={insightFilter ? 'Insight Filter Name' : 'Saved Filter Name'}
        name="filterName"
        onChange={({ target: { value } }) => setFilterName(value)}
        ref={focusRef}
        value={filterName}
      />

      {authCanAccessProductAdmin && insightFilter && (
        <>
          <FormFieldTextarea
            className="create-saved-filter__form-field"
            instructions="This field is required."
            label="Insight Filter Description"
            name="description"
            onChange={({ target: { value } }) => setDescription(value)}
            ref={focusRef}
            value={description}
          />
          {savedFiltersType === SAVED_FILTERS_TYPES.APP_USERS && (
            <>
              <FormField
                className="create-saved-filter__form-field"
                defaultValue={integrationType}
                instructions="Confirm this is the correct integration type."
                label="Insight Filter Integration Type"
                name="integrationType"
                ref={focusRef}
                disabled
              />
              <FormField
                className="create-saved-filter__form-field"
                defaultValue={mappingType}
                instructions="Confirm this is the correct mapping type."
                label="Insight Filter Mapping Type"
                name="mappingType"
                ref={focusRef}
                disabled
              />
            </>
          )}
          <Checkbox
            checked={betaFilter}
            className="create-saved-filter__checkbox"
            label="Distribute this filter to Product Admins only"
            name="betaFilter"
            onChange={({ target: { checked } }) => setBetaFilter(checked)}
            value={betaFilter}
          />
        </>
      )}

      {authCanAccessAdmin && !insightFilter && (
        <div>
          <Checkbox
            className="create-saved-filter__checkbox"
            label="Share this filter for your team members to use"
            name="teamFilter"
            onChange={({ target: { checked } }) => setTeamFilter(checked)}
            value={teamFilter}
          />
        </div>
      )}

      {authCanAccessProductAdmin && !insightFilterToUpdate && (
        <div>
          <Checkbox
            className="create-saved-filter__checkbox"
            errors={createSavedFilterError}
            label="Save as Zylo Insight"
            name="zyloInsight"
            onChange={({ target: { checked } }) => setInsightFilter(checked)}
            value={insightFilter}
          />
        </div>
      )}

      {insightFilter &&
        authCanAccessProductAdmin &&
        savedFiltersType === SAVED_FILTERS_TYPES.COMPANY_APPS && (
          <div>
            <Checkbox
              checked={displayAppSelected}
              className="create-saved-filter__checkbox"
              label="Display only for clients with selected application"
              name="displayAppSelected"
              onChange={({ target: { checked } }) => handleSetCompanyAppSelection(checked)}
              value={displayForAppId}
            />
            {displayAppSelected && (
              <AppSelectorInput data={displayForAppId} onChange={handleAppSelection} />
            )}
          </div>
        )}

      <CancelAndConfirmButtons
        confirmIsDisabled={isSaveDisabled}
        containerClass="create-saved-filter__buttons-container"
        handleCancel={closeModal}
      />
    </form>
  );
}

SavedFiltersForm_DisplayLayer.propTypes = {
  authCanAccessAdmin: PropTypes.bool,
  authCanAccessProductAdmin: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  createInsightFilter: PropTypes.func.isRequired,
  createSavedFilter: PropTypes.func.isRequired,
  createSavedFilterError: PropTypes.string,
  insightFilterToUpdate: PropTypes.shape({
    betaFilter: PropTypes.bool,
    description: PropTypes.string,
    filterName: PropTypes.string,
  }),
  insightFilterTypes: PropTypes.shape({
    integrationType: PropTypes.string,
    mappingType: PropTypes.string,
  }),
  modalIsActive: PropTypes.bool,
  savedFiltersType: PropTypes.string.isRequired,
};

function useDataLayer(handleClose, isInsight) {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { insightFilterTypes } = useSavedFiltersListState();
  const savedFiltersType = useSavedFiltersTypeState();
  const [createSavedFilterError, setSavedFiltersFormError] = useState(null);
  const createInsightFilter = useCreateInsightFilter();
  const createSavedFilter = useCreateSavedFilter();
  const updateInsightFilter = useUpdateInsightFilter();
  const { authCanAccessAdmin, authCanAccessProductAdmin } = usePermissions('admin', 'productAdmin');

  function handleInsightFilterCreation(props) {
    createInsightFilter({ ...props, onError: handleFilterActionError, onSuccess: closeModal });
  }

  function handleInsightFilterUpdate(props) {
    updateInsightFilter({ ...props, onError: handleFilterActionError, onSuccess: closeModal });
  }

  function handleSavedFilterCreation(props) {
    createSavedFilter({ ...props, onError: handleFilterActionError, onSuccess: closeModal });
  }

  function handleFilterActionError(errorMessage) {
    if (errorMessage.includes('Conflict')) {
      setSavedFiltersFormError('Please choose a unique name');
    }
  }

  function closeModal() {
    handleClose();
    setSavedFiltersFormError(null);
  }

  return {
    authCanAccessAdmin,
    authCanAccessProductAdmin,
    closeModal,
    createInsightFilter: handleInsightFilterCreation,
    createSavedFilter: handleSavedFilterCreation,
    createSavedFilterError,
    insightFilterToUpdate: isInsight ? activeSavedFilter : null,
    insightFilterTypes,
    savedFiltersType,
    updateInsightFilter: handleInsightFilterUpdate,
  };
}
