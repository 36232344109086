import Raven from 'raven-js';
import { createAction, handleActions } from 'redux-actions';
import { navigate } from '@reach/router';
import { parse } from 'query-string';
import { get } from 'lodash-es';
import dayjs from 'dayjs';
import { formatLocation, wrapAsync, handleNetworkError } from '@utilities/util';
import { mgQueryClient } from '@lib/components/AppQueryClient';
import { fetchCompanyData } from '@hooks/company';
import { clearCurrentSessionData } from '@lib/components/AppQueryClient';
import { LS_PROFILE_KEY } from '../config/config';
import WaterWorksAPI from '../WaterWorksAPI';
import { actions as zyloUserActions, FETCH_CURRENT_USER_PROFILE_SUCCESS } from './zyloUsers';
import { actions as zyloFormActions } from './zyloForm';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants & Private Methods
// ------------------------------------
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS';
export const REFRESHED_TOKEN = 'REFRESHED_TOKEN';
export const EXPIRED_TOKEN = 'EXPIRED_TOKEN';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_ERROR = 'CONFIRM_ACCOUNT_ERROR';
export const UPDATING_PASSWORD = 'UPDATING_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const FORGOT_PASSWORD_COMPLETE = 'FORGOT_PASSWORD_COMPLETE';
export const SET_USES_PASSWORD = 'SET_USES_PASSWORD';
export const STASH_AUTH_UNTIL_COMPANY_SELECTION = 'STASH_AUTH_UNTIL_COMPANY_SELECTION';
export const COMPANY_SWITCHED = 'COMPANY_SWITCHED';

function _saveAuthToken(token) {
  const storedProfile = localStorage[LS_PROFILE_KEY];
  const profile = storedProfile ? JSON.parse(storedProfile) : {};
  profile.token = token;
  profile.tokenTimestamp = dayjs();
  localStorage[LS_PROFILE_KEY] = JSON.stringify(profile);
}

export function _saveProfile(params) {
  const storedProfile = localStorage[LS_PROFILE_KEY];
  const profile = storedProfile ? JSON.parse(storedProfile) : {};
  profile.tokenTimestamp = dayjs();

  localStorage[LS_PROFILE_KEY] = JSON.stringify({
    ...profile,
    ...params,
  });
}

function _sortCompanies(companies) {
  return companies.sort((a, b) => {
    const companyA = a.companyName.toLowerCase();
    const companyB = b.companyName.toLowerCase();

    if (companyA < companyB) {
      return -1;
    }
    if (companyA > companyB) {
      return 1;
    }
    return 0;
  });
}

// converts roles into feature-specific permissions
function _calculatePermissions({ roles, authUsesViews }) {
  const permissions = { haveLoaded: true };

  const authHasAppsRole = roles.includes('apps');
  const authHasAppsReadOnlyRole = roles.includes('apps-read-only');
  const authCanAccessApps = authHasAppsRole || authHasAppsReadOnlyRole;

  const authHasAutomationRole = roles.includes('automation');
  const authHasAutomationReadOnlyRole = roles.includes('automation-read-only');
  const authCanAccessAutomation = authHasAutomationRole || authHasAutomationReadOnlyRole;

  const authHasChargesRoles = roles.includes('charges');
  const authHasChargesReadOnlyRole = roles.includes('charges-read-only');
  const authCanAccessCharges = authHasChargesRoles || authHasChargesReadOnlyRole;

  const authHasUsersRole = roles.includes('users');
  const authHasUsersReadOnlyRole = roles.includes('users-read-only');
  const authCanAccessUsers = authHasUsersRole || authHasUsersReadOnlyRole;

  const authHasIntegrationsRole = roles.includes('integrations');
  const authHasIntegrationsReadOnlyRole = roles.includes('integrations-read-only');
  const authCanAccessIntegrations = authHasIntegrationsRole || authHasIntegrationsReadOnlyRole;

  const authHasContractsRole = roles.includes('contracts');
  const authHasContractsReadOnlyRole = roles.includes('contracts-read-only');
  const authCanAccessContracts = authHasContractsRole || authHasContractsReadOnlyRole;

  const authHasUploadsRole = roles.includes('uploads');

  // admin permissions
  const authHasAdminRole = roles.includes('admin-panel');
  const authHasSuperAdminRole = roles.includes('super-admin');
  const authHasProductAdminRole = roles.includes('product-admin');
  const authHasAppCatalogAdminRole = roles.includes('app-catalog-admin');

  const authHasMinimumCoreAccess = authCanAccessApps && authCanAccessCharges && authCanAccessUsers;

  // core access permissions
  permissions.apps = {
    canAccess: authCanAccessApps,
    readOnly: authHasAppsReadOnlyRole,
  };
  permissions.charges = {
    canAccess: authCanAccessCharges,
    readOnly: authHasChargesReadOnlyRole,
  };
  permissions.users = {
    canAccess: authCanAccessUsers,
    readOnly: authHasUsersReadOnlyRole,
  };

  // automation permission
  permissions.automation = {
    canAccess: authCanAccessAutomation,
    readOnly: authHasAutomationReadOnlyRole,
  };

  // integrations permission
  permissions.integrations = {
    canAccess: authCanAccessIntegrations,
    readOnly: authHasIntegrationsReadOnlyRole,
  };

  // contracts permission
  permissions.contracts = {
    canAccess: authCanAccessContracts,
    readOnly: authHasContractsReadOnlyRole,
  };

  // admin permission
  permissions.admin = {
    canAccess: authHasAdminRole,
  };

  // super-admin permission
  permissions.superAdmin = { canAccess: authHasSuperAdminRole };

  // product-admin permission
  permissions.productAdmin = { canAccess: authHasProductAdminRole };

  // app-catalog-admin permission
  permissions.appCatalogAdmin = { canAccess: authHasAppCatalogAdminRole };

  // feature-specific permissions
  permissions.addApps = {
    canAccess: authHasAppsRole && !authUsesViews,
  };
  permissions.dashboard = {
    canAccess: authCanAccessApps && !authUsesViews,
  };
  permissions.docs = {
    canAccess: authCanAccessApps,
  };
  permissions.glimpse = {
    canAccess: authHasMinimumCoreAccess,
  };
  permissions.insightsWorkbench = {
    canAccess: authCanAccessApps && authCanAccessUsers,
  };
  permissions.profileAlerts = {
    canAccess: authCanAccessApps && !authUsesViews,
  };
  permissions.recentActivity = {
    canAccess: authCanAccessApps && authCanAccessCharges && !authUsesViews,
  };
  permissions.reporting = {
    canAccess: authCanAccessApps && !authUsesViews,
  };
  permissions.suppliers = {
    canAccess: authCanAccessApps && !authUsesViews,
  };
  permissions.uploadHistory = {
    canAccess: authHasMinimumCoreAccess,
  };
  permissions.uploads = {
    canAccess: authHasUploadsRole,
  };
  permissions.workflows = {
    canAccess: authHasAppsRole && authHasUsersRole && authHasIntegrationsRole && !authUsesViews,
  };

  return permissions;
}

const DEFAULT_PROFILE_FIELDS = {
  token: null,
  zyloAuthId: null,
  zyloUserAccountId: null,
  companyId: null,
  companies: [],
  roles: [],
  usesVues: null,
  vues: [],
  authIsVueless: false,
};

function _getProfile() {
  try {
    return JSON.parse(localStorage[LS_PROFILE_KEY]);
  } catch (ignore) {
    return { ...DEFAULT_PROFILE_FIELDS };
  }
}

function _clearProfile() {
  clearCurrentSessionData();
  delete localStorage[LS_PROFILE_KEY];
}

const DEFAULT_AUTH_STATE = {
  permissions: {
    haveLoaded: false,

    // core access permissions
    apps: { canAccess: false, readOnly: false },
    users: { canAccess: false, readOnly: false },
    charges: { canAccess: false, readOnly: false },
    integrations: { canAccess: false, readOnly: false },

    // admin permissions
    admin: { canAccess: false },
    productAdmin: { canAccess: false },
    superAdmin: { canAccess: false },
    appCatalogAdmin: { canAccess: false },

    // feature-specific permissions
    addApps: { canAccess: false },
    dashboard: { canAccess: false },
    docs: { canAccess: false, readOnly: false },
    glimpse: { canAccess: false, readOnly: false },
    import: { canAccess: false },
    insightsWorkbench: { canAccess: false },
    profileAlerts: { canAccess: false },
    recentActivity: { canAccess: false },
    reporting: { canAccess: false },
    suppliers: { canAccess: false },
    uploadHistory: { canAccess: false },
    uploads: { canAccess: false },
    workflows: { canAccess: false },
  },
  ..._getProfile(),
  stashedAuth: null,
  loginError: null,
  isLoading: false,
  isUpdatingPassword: false,
  updatePasswordSuccess: null,
  updatePasswordError: null,
  usesPassword: false,
  resetPasswordSuccess: null,
  resetPasswordError: null,
  forgotPasswordMessage: null,
};

// ------------------------------------
// Actions
// ------------------------------------

export const authLoading = createAction(AUTH_LOADING);
export const loggedIn = createAction(LOGGED_IN);
export const loggedOut = createAction(LOGGED_OUT);
export const loginError = createAction(LOGIN_ERROR);
export const ssoLoginSuccess = createAction(SSO_LOGIN_SUCCESS);
export const refreshedToken = createAction(REFRESHED_TOKEN);
export const expiredToken = createAction(EXPIRED_TOKEN);
export const confirmAccountSuccess = createAction(CONFIRM_ACCOUNT_SUCCESS);
export const confirmAccountError = createAction(CONFIRM_ACCOUNT_ERROR);
export const updatingPassword = createAction(UPDATING_PASSWORD);
export const updatePasswordSuccess = createAction(UPDATE_PASSWORD_SUCCESS);
export const updatePasswordError = createAction(UPDATE_PASSWORD_ERROR);
export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);
export const resetPasswordError = createAction(RESET_PASSWORD_ERROR);
export const forgotPasswordComplete = createAction(FORGOT_PASSWORD_COMPLETE);
export const settingUsesPassword = createAction(SET_USES_PASSWORD);
export const stashAuthUntilCompanySelection = createAction(STASH_AUTH_UNTIL_COMPANY_SELECTION);
export const companySwitched = createAction(COMPANY_SWITCHED);

function _dispatchSwitchCompanyActions({ authProfile, pathname }, dispatch) {
  const { companyId } = authProfile;
  _saveProfile(authProfile);
  dispatch(companySwitched(authProfile));
  mgQueryClient.fetchQuery(['companyData'], fetchCompanyData);
  dispatch(zyloUserActions.fetchCurrentZyloUserProfile({ companyId, isLogin: true }));
  navigate(pathname || '/');
}

function setUsesPassword(bool, dispatch) {
  dispatch(settingUsesPassword(bool));
}

async function preauthenticate({ data, location }, dispatch) {
  const { email } = data;
  let result;

  try {
    result = await WaterWorksAPI.preauth({
      email,
      RelayState: `loc=${formatLocation(location)}`,
    });
    const { path } = result;

    if (!path.includes('credentials')) {
      window.location.assign(path);
    } else {
      dispatch(settingUsesPassword(true));
    }
  } catch (e) {
    dispatch(loginError(get(e, 'response.body.message', 'An unexpected error occurred.')));
    dispatch(zyloFormActions.saveZyloFormError());
  }
}

// checks if redirect is valid URI routing outside app
function isSafeUri(location) {
  const { loc: redirect } = parse(location.search);

  function validateRedirect() {
    const { CLIENT_URI } = process.env;
    let isValidRedirect = true;

    try {
      const { origin } = new URL(redirect, CLIENT_URI);

      isValidRedirect = origin === CLIENT_URI;
    } catch (e) {
      isValidRedirect = false;
    }

    return isValidRedirect;
  }

  return redirect === undefined || validateRedirect();
}

async function authenticate({ data, location }, dispatch) {
  dispatch(authLoading());
  const { email, password } = data;
  let result;

  try {
    result = await WaterWorksAPI.auth(null, { email, password });
  } catch (e) {
    dispatch(loginError(get(e, 'response.body.message', 'An unexpected error occurred.')));
    dispatch(saveZyloFormError());
    return;
  }
  const { companies } = result;

  dispatch(loggedIn(result));
  dispatch(zyloUserActions.fetchCurrentZyloUserProfile({ isLogin: true }));
  _saveProfile(result);

  if (companies.length === 1) {
    mgQueryClient.fetchQuery(['companyData'], fetchCompanyData);
    // redirecting to '/' if isSafeUri returns false
    const redirectURL = isSafeUri(location) ? formatLocation(location) : '/';
    navigate(redirectURL);
  } else {
    dispatch(stashAuthUntilCompanySelection(result));
  }
}

async function logout(params = {}, dispatch, getState) {
  const { auth } = getState();
  const { token } = auth;
  try {
    await WaterWorksAPI.logout(token, params);
  } catch (e) {
    handleNetworkError('logout', e, dispatch, null, {});
    return;
  }
  dispatch(loggedOut());
  navigate('/login');
}

// generates a new auth with most current zylo user account info
async function refreshCurrentZyloUserAuth(params = {}, dispatch, getState) {
  const { auth } = getState();
  const { token, zyloUserAccountId } = auth;
  const companyId = params.companyId || auth.companyId;
  let result;

  try {
    result = await WaterWorksAPI.auth(token, { companyId });
  } catch (e) {
    handleNetworkError('refreshCurrentZyloUserAuth', e, dispatch, null, {
      companyId,
      zyloUserAccountId,
    });
    return;
  }

  _saveProfile(result);
  dispatch(refreshedToken(result));
  dispatch(zyloUserActions.fetchCurrentZyloUserProfile({ companyId, isLogin: false }));
}

async function refreshZyloAuthAndFetchCompany({ companyId }, dispatch, getState) {
  mgQueryClient.fetchQuery(['companyData'], fetchCompanyData);
  await refreshCurrentZyloUserAuth({ companyId }, dispatch, getState);
}

async function switchCompany({ companyId, location = {} }, dispatch, getState) {
  const { auth } = getState();
  const { token, stashedAuth } = auth;
  let authProfile;

  if (companyId === get(auth, 'stashedAuth.companyId', '')) {
    authProfile = { ...stashedAuth };
  } else {
    try {
      authProfile = await WaterWorksAPI.auth(token || stashedAuth.token, { companyId });
    } catch (e) {
      dispatch(loginError(get(e, 'response.body.message', 'An unexpected error occurred.')));

      return;
    }
  }
  _dispatchSwitchCompanyActions({ authProfile, pathname: formatLocation(location) }, dispatch);
}

async function confirmAccount({ data }, dispatch) {
  const { firstName, lastName, password, token } = data;

  try {
    await WaterWorksAPI.postZyloUserAccountData('reset', { token, firstName, lastName, password });
  } catch (e) {
    dispatch(
      confirmAccountError(
        'Your request has expired. Please have your Zylo administrator resend your confirmation email.',
      ),
    );
    handleNetworkError('confirmAccount', e, dispatch, saveZyloFormError, {});

    return;
  }

  dispatch(saveZyloFormSuccess('Account successfully confirmed'));
  dispatch(confirmAccountSuccess());
}

async function forgotPassword({ data }, dispatch) {
  const { email } = data;

  try {
    await WaterWorksAPI.postZyloUserAccountData('forgot', { email });
  } catch (e) {
    dispatch(forgotPasswordComplete(email));
    handleNetworkError('forgotPassword', e, dispatch, saveZyloFormError, { email });

    return;
  }

  dispatch(forgotPasswordComplete(email));
}

async function resetPassword({ data }, dispatch) {
  const { password, token } = data;

  try {
    await WaterWorksAPI.postZyloUserAccountData('reset', { password, token });
  } catch (e) {
    dispatch(resetPasswordError('Your request has expired. Please reset your password again.'));
    handleNetworkError('resetPassword', e, dispatch, saveZyloFormError, {});

    return;
  }

  dispatch(saveZyloFormSuccess('Password successfully reset'));
  dispatch(resetPasswordSuccess());
}

async function refreshToken({ location }, dispatch, getState) {
  const { auth, zyloUsers } = getState();
  const { pathname } = location || '/';
  const localStorageProfile = _getProfile();
  const timeSinceLastTokenUpdate = dayjs().diff(localStorageProfile.tokenTimestamp, 'minutes');

  if (localStorageProfile.token === null) {
    dispatch(expiredToken());
    return;
  }

  if (
    auth.companyId !== localStorageProfile.companyId ||
    auth.usesVues !== localStorageProfile.usesVues
  ) {
    _dispatchSwitchCompanyActions({ authProfile: localStorageProfile, pathname }, dispatch);
    return;
  }

  if (auth.token !== localStorageProfile.token) {
    dispatch(refreshedToken(localStorageProfile));
  }

  if (timeSinceLastTokenUpdate <= Number(process.env.REFRESH_TOKEN_THRESHOLD)) {
    return;
  }

  let authProfile;

  try {
    authProfile = await WaterWorksAPI.refreshAuth(localStorageProfile.token);
  } catch (e) {
    handleNetworkError('refreshToken', e, dispatch, null, {});
    return;
  }

  _saveAuthToken(authProfile.token);
  dispatch(refreshedToken(authProfile));

  if (!zyloUsers.profileData.id) {
    dispatch(zyloUserActions.fetchCurrentZyloUserProfile({ isLogin: false }));
  }
}

async function updatePassword({ data }, dispatch, getState) {
  dispatch(updatingPassword(true));

  const { token, zyloUserAccountId } = getState().auth;
  const { oldPassword, newPassword } = data;

  try {
    await WaterWorksAPI.updatePassword(token, {
      zyloUserAccountId,
      oldPassword,
      password: newPassword,
    });
  } catch (e) {
    handleNetworkError('updatePassword', e, dispatch, saveZyloFormError, { zyloUserAccountId });
    dispatch(updatingPassword(false));

    return;
  }

  dispatch(updatingPassword(false));
  dispatch(saveZyloFormSuccess('Password updated successfully.'));
}

async function initSSOLoginSuccess({ token, location }, dispatch) {
  dispatch(authLoading());
  let result;
  const params = {
    isLogin: true,
  };

  try {
    result = await WaterWorksAPI.auth(token, params);
  } catch (e) {
    handleNetworkError('initSSOLoginSuccess', e, dispatch, loginError, {});
    navigate('/sso-failure');
    return;
  }

  _saveProfile(result);
  dispatch(ssoLoginSuccess(result));
  navigate(isSafeUri(location) ? location : '/');
}

export const actions = {
  preauthenticate: wrapAsync(preauthenticate),
  isSafeUri,
  authenticate: wrapAsync(authenticate),
  refreshToken: wrapAsync(refreshToken),
  refreshCurrentZyloUserAuth: wrapAsync(refreshCurrentZyloUserAuth),
  logout: wrapAsync(logout),
  refreshZyloAuthAndFetchCompany: wrapAsync(refreshZyloAuthAndFetchCompany),
  switchCompany: wrapAsync(switchCompany),
  confirmAccount: wrapAsync(confirmAccount),
  forgotPassword: wrapAsync(forgotPassword),
  resetPassword: wrapAsync(resetPassword),
  updatePassword: wrapAsync(updatePassword),
  setUsesPassword: wrapAsync(setUsesPassword),
  initSSOLoginSuccess: wrapAsync(initSSOLoginSuccess),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [AUTH_LOADING]: (state) => {
      return { ...state, isLoading: true, loginError: null, forgotPasswordMessage: null };
    },
    [LOGGED_IN]: (state, { payload }) => {
      const companies = _sortCompanies(payload.companies);
      Raven.setUserContext({ email: payload.email });

      return {
        ...state,
        ...payload,
        companies,
        loginError: null,
        isLoading: false,
        updatePasswordSuccess: null,
        updatePasswordError: null,
      };
    },
    [LOGGED_OUT]: () => {
      Raven.setUserContext();
      _clearProfile();
      return { ...DEFAULT_AUTH_STATE, ...DEFAULT_PROFILE_FIELDS };
    },
    [LOGIN_ERROR]: (state, { payload }) => {
      return { ...state, loginError: payload };
    },
    [SSO_LOGIN_SUCCESS]: (state, { payload }) => {
      return { ...state, ...payload, isLoading: false };
    },
    [REFRESHED_TOKEN]: (state, { payload }) => {
      const companies = payload.companies ? _sortCompanies(payload.companies) : state.companies;

      return { ...state, ...payload, companies };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [EXPIRED_TOKEN]: (state, { payload }) => {
      Raven.setUserContext();
      _clearProfile();
      return { ...DEFAULT_AUTH_STATE, ...DEFAULT_PROFILE_FIELDS, loginError: payload };
    },
    [CONFIRM_ACCOUNT_SUCCESS]: (state) => {
      return { ...state, confirmAccountSuccess: true, confirmAccountError: null };
    },
    [CONFIRM_ACCOUNT_ERROR]: (state, { payload }) => {
      return { ...state, confirmAccountSuccess: null, confirmAccountError: payload };
    },
    [UPDATING_PASSWORD]: (state, { payload }) => {
      return { ...state, isUpdatingPassword: payload };
    },
    [UPDATE_PASSWORD_SUCCESS]: (state) => {
      return {
        ...state,
        updatePasswordSuccess: true,
        forgotPasswordError: null,
        updatingPassword: false,
      };
    },
    [UPDATE_PASSWORD_ERROR]: (state, { payload }) => {
      return {
        ...state,
        updatePasswordSuccess: null,
        forgotPasswordError: payload,
        updatingPassword: false,
      };
    },
    [FORGOT_PASSWORD_COMPLETE]: (state) => {
      return {
        ...state,
        forgotPasswordMessage:
          'If the email provided is associated with a valid Zylo account, you will recieve an email with password reset instructions. If you have SSO, please access Zylo via SSO.',
      };
    },
    [RESET_PASSWORD_SUCCESS]: (state) => {
      return { ...state, resetPasswordSuccess: true, resetPasswordError: null };
    },
    [RESET_PASSWORD_ERROR]: (state, { payload }) => {
      return { ...state, resetPasswordError: payload, resetPasswordSuccess: null };
    },
    [SET_USES_PASSWORD]: (state, { payload }) => {
      return { ...state, usesPassword: payload };
    },
    [STASH_AUTH_UNTIL_COMPANY_SELECTION]: (state, { payload }) => {
      return { ...state, stashedAuth: payload, companies: payload.companies };
    },
    [COMPANY_SWITCHED]: (state, { payload }) => {
      const companies = _sortCompanies(payload.companies);
      Raven.setUserContext({ email: payload.email });

      return {
        ...state,
        ...payload,
        companies,
        stashedAuth: null,
      };
    },
    [FETCH_CURRENT_USER_PROFILE_SUCCESS]: (state, { payload }) => {
      const { id, usesVues, vues, roles } = payload.currentZyloAuth;

      return {
        ...state,
        zyloAuthId: id,
        usesVues,
        vues,
        authIsViewless: usesVues && vues.length === 0,
        permissions: _calculatePermissions({
          roles,
          authUsesViews: usesVues,
        }),
      };
    },
  },
  { ...DEFAULT_AUTH_STATE },
);
