import { FormValues } from '@frameworks/form-fns';
import { IMetadataField } from '@typings';
import { BulkEditApiChanges, BulkEditOperation } from './types';
import {
  extractApiValueFromFormValue,
  extractDisplayValueFromFormValue,
  formValueHasChange,
  getMatchingOperationValue,
  isFormValueBlank,
} from './helpers';

export function mapFormValuesToApiChanges(
  formValues: FormValues,
  fields: IMetadataField[],
): BulkEditApiChanges[] {
  return Object.entries(formValues)
    .filter(([key]) => !key.endsWith('ZyloOperation'))
    .filter(([key, value]) => formValueHasChange(key, value, formValues))
    .map(([key, value]) => {
      const fieldInfo = fields.find((field: IMetadataField) => field.key === key);
      const apiFormValue = extractApiValueFromFormValue(value);
      const operationValue: BulkEditOperation | undefined = getMatchingOperationValue(
        key,
        formValues,
      );
      // There is a non-edit operation selected and the form field has a value
      if (operationValue && operationValue !== 'replace') {
        return {
          field: key,
          fieldLabel: fieldInfo?.displayKey || key,
          operation: operationValue,
          value: operationValue === 'remove' ? '' : apiFormValue,
          displayValue: extractDisplayValueFromFormValue(value),
        };
      } else {
        return {
          field: key,
          fieldLabel: fieldInfo?.displayKey || key,
          operation: isFormValueBlank(value) ? 'remove' : 'replace',
          value: apiFormValue,
          displayValue: extractDisplayValueFromFormValue(value),
        };
      }
    });
}
