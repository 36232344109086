import PropTypes from 'prop-types';
import { createRef, cloneElement, Children, PureComponent } from 'react';
import classNames from 'clsx';
import { get, throttle } from 'lodash-es';
import { Popover } from '@zylo/orchestra';

/** This component only receiving minor updates, will be moved to the component library with the next major orchestra update */
class PopoverMenu extends PureComponent {
  static propTypes = {
    bodyStyle: PropTypes.shape({}),
    children: (props, propName, componentName) => {
      const children = props[propName];
      return Array.isArray(children) && children[0]
        ? children[0].componentName === 'PopoverMenuItem'
        : componentName === 'PopoverMenuItem';
    },
    className: PropTypes.string,
    // unable to update eslint pattern to recognize these unused vars without errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    menuButton: (_props, _propName, componentName) => {
      return componentName === 'ActionButton' || componentName === 'Icon';
    },
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
  };

  constructor() {
    super();

    this.handlePopoverToggle = throttle(this.handlePopoverToggle, 200);

    this.button = createRef();

    this.state = {
      isMenuOpen: false,
      isConfirmingAction: false,
      itemConfirming: -1,
    };
  }

  handlePopoverToggle = () => {
    const { onOpen, onClose } = this.props;
    this.setState(({ isMenuOpen }) => {
      if (!isMenuOpen && typeof onOpen === 'function') {
        onOpen();
      }
      if (isMenuOpen && typeof onClose === 'function') {
        onClose();
      }
      return { isMenuOpen: !isMenuOpen, isConfirmingAction: false };
    });
  };

  handlePopoverClose = () => {
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
    this.setState({ isMenuOpen: false, isConfirmingAction: false });
  };

  handleConfirmingToggle = (itemConfirming) => {
    this.setState({ itemConfirming, isConfirmingAction: true });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { children, menuButton, className, bodyStyle } = this.props;
    const { isConfirmingAction, itemConfirming } = this.state;

    return (
      children && (
        <div className={classNames('popover-menu', className)} ref={this.button}>
          {typeof get(menuButton, 'type') === 'function'
            ? cloneElement(menuButton, {
                handleClick: this.handlePopoverToggle,
                handleButtonClick: this.handlePopoverToggle,
                onClick: this.handlePopoverToggle,
                stopPropagation: this.stopPropagation,
              })
            : cloneElement(menuButton, {
                onClick: this.handlePopoverToggle,
                handleClick: this.handlePopoverToggle,
              })}
          <Popover
            closeAction={this.handlePopoverClose}
            positionRef={this.button.current}
            verticalDisplay="bottom"
            visible={this.state.isMenuOpen}
            clickToOpen
          >
            <div className="popover-menu-body" style={bodyStyle}>
              {Children.map(children, (menuItem, index) => {
                let itemMarkup = menuItem;

                if (typeof get(menuItem, 'type') === 'function') {
                  itemMarkup = cloneElement(menuItem, {
                    index,
                    closeMenu: this.handlePopoverClose,
                    isConfirmingAction,
                    setIsConfirming: this.handleConfirmingToggle,
                    key: `menu-item-${index}`,
                  });
                }

                if (isConfirmingAction && index !== itemConfirming) {
                  itemMarkup = null;
                }

                return itemMarkup;
              })}
            </div>
          </Popover>
        </div>
      )
    );
  }
}

export default PopoverMenu;
