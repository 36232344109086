import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { checkForProvider } from '@zylo/orchestra';

const SessionCacheStateContext = createContext();
const SessionCacheUpdateContext = createContext();

/* sessionCacheContext provides the ability to cache information that will persist throughout a
   user's session. Information in this cache will persist across router changes. This cache will
   reset whenever the user's session expires, or when the browser is refreshed / closed.  */

function SessionCacheProvider({ children }) {
  const [sessionCache, setSessionCache] = useState({});

  const updateSessionCache = useCallback((key, update) => {
    setSessionCache((currentVal) => ({ ...currentVal, [key]: update }));
  }, []);

  return (
    <SessionCacheStateContext.Provider value={sessionCache}>
      <SessionCacheUpdateContext.Provider value={updateSessionCache}>
        {children}
      </SessionCacheUpdateContext.Provider>
    </SessionCacheStateContext.Provider>
  );
}

SessionCacheProvider.propTypes = {
  children: PropTypes.node,
};

function useSessionCacheState() {
  const context = useContext(SessionCacheStateContext);

  checkForProvider(context, 'useSessionCacheState', 'SessionCacheProvider');

  return context;
}

function useSessionCacheUpdate() {
  const context = useContext(SessionCacheUpdateContext);

  checkForProvider(context, 'useSessionCacheUpdate', 'SessionCacheProvider');

  return context;
}

export { SessionCacheProvider, useSessionCacheState, useSessionCacheUpdate };
