import { useCallback } from 'react';
import {
  useActiveSavedFilterState,
  useActiveSavedFilterUpdate,
} from '../../../contexts/savedFilterContexts/activeSavedFilterContext';
import { useSavedFiltersListUpdate } from '../../../contexts/savedFilterContexts/savedFiltersListContext';
import { useAppMessaging } from '../../../hooks';
import { deleteData } from '../../../utilities/xhr';

export default function useDeleteSavedFilter() {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { clearActiveSavedFilter } = useActiveSavedFilterUpdate();
  const { fetchSavedFilters } = useSavedFiltersListUpdate();
  const showMessage = useAppMessaging();

  const deleteSavedFilter = useCallback(
    ({ filterName, id }) => {
      deleteData({
        path: `companies/$companyId/savedFilter/${id}`,
      })
        .then(() => {
          if (activeSavedFilter && filterName === activeSavedFilter.filterName) {
            clearActiveSavedFilter();
          }

          showMessage({ message: `${filterName} has been deleted` });
          fetchSavedFilters();
        })
        .catch(({ message }) => {
          showMessage({ message, isError: true });
        });
    },
    [activeSavedFilter, clearActiveSavedFilter, fetchSavedFilters, showMessage],
  );

  return deleteSavedFilter;
}
