import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Colors } from '@zylo/orchestra';
import classNames from 'clsx';
import AppLogo from '@components/AppLogo';
import OscillatingText from '@components/OscillatingText';

const HighlightText = styled.span`
  color: ${Colors.teal5};
`;

function SubscriptionOption({ children, inputValue, onSelect = () => {}, option }) {
  function handleMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();

    if (option.disabled) return;

    onSelect(option, e);
  }

  function highlightSearchTerm(appLabel) {
    const start = appLabel.toLowerCase().indexOf(inputValue.toLowerCase());
    let result;

    if (start > -1) {
      const { length } = inputValue;
      const end = start + length;

      result = (
        <span>
          {appLabel.substr(0, start)}
          <HighlightText>{appLabel.substr(start, length)}</HighlightText>
          {appLabel.substr(end, appLabel.length)}
        </span>
      );
    }

    return result || children;
  }

  const { appDomain, companyAppDomain, disabled, domain, launchUrl, tooltips, supplierDomain } =
    option;
  let domainInfo = domain || appDomain || companyAppDomain || launchUrl || supplierDomain;

  return (
    <div
      aria-selected={false}
      className={classNames(
        'flex-container align-center subscription-option Select-option',
        disabled && 'disabled',
      )}
      data-testid="subscription-option"
      disabled={disabled}
      onMouseDown={handleMouseDown}
      role="option"
    >
      {domainInfo && <AppLogo filename={Array.isArray(domainInfo) ? domainInfo[0] : domainInfo} />}

      <div className="app-label">
        <OscillatingText text={inputValue ? highlightSearchTerm(children) : children} />
      </div>

      {tooltips && <div className="flex-container tooltip-container">{tooltips}</div>}
    </div>
  );
}

SubscriptionOption.propTypes = {
  children: PropTypes.node,
  inputValue: PropTypes.string,
  onSelect: PropTypes.func,
  option: PropTypes.shape({
    appDomain: PropTypes.string,
    companyAppDomain: PropTypes.string,
    disabled: PropTypes.bool,
    domain: PropTypes.string,
    launchUrl: PropTypes.string,
    tooltips: PropTypes.arrayOf(PropTypes.node),
  }).isRequired,
};

export default SubscriptionOption;
