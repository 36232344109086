import styled from 'styled-components/macro';
import { Borders, FieldWrapper, ZyloSkeleton } from '@zylo/orchestra';
import type { Field, FieldConfig } from '../types';

const FieldWrapperPlaceholder = styled(FieldWrapper)<{ field: Field }>`
  .zylo-skeleton-field {
    height: 2.25rem;

    ${({ field }) => {
      switch (field) {
        case 'Checkbox':
        case 'Switch':
          return 'min-height: 2rem;';
        case 'FormFieldTextarea':
          return 'height: 7rem;';
        case 'ItemListField':
          return `height: 3rem; width: 75%; border-radius: ${Borders.radius.large};`;
        case 'MultiCombobox':
          return 'height: 4rem;';
        default:
          return null;
      }
    }}
  }
`;

export type FormFieldPlaceholderProps = {
  error?: boolean;
  fieldConfig: FieldConfig;
};

export function FormFieldPlaceholder({ error, fieldConfig }: FormFieldPlaceholderProps) {
  const [field, { className, label, name }, options] = fieldConfig;
  const { placeholder } = options ?? {};

  if (placeholder) {
    return <>{placeholder}</>;
  }

  return (
    <FieldWrapperPlaceholder
      className={className}
      field={field}
      key={`form-placeholder__form-field-${name}`}
      label={field === 'Checkbox' || field === 'Switch' ? null : label}
      name={name}
    >
      {!error ? <ZyloSkeleton animation="pulse" className="zylo-skeleton-field" /> : null}
    </FieldWrapperPlaceholder>
  );
}
