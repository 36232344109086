import { useCallback, useState } from 'react';

export default function useComponentStateObserver(initialStateValue = {}) {
  const [componentState, setComponentState] = useState<Record<string, any>>(initialStateValue);

  const componentStateObserver = useCallback((stateUpdate = {}) => {
    setComponentState((currentState) => ({ ...currentState, ...stateUpdate }));
  }, []);

  return { componentState, componentStateObserver };
}
