import classNames from 'clsx';
import { useActiveSavedFilterState } from '../../contexts/savedFilterContexts/activeSavedFilterContext';
import FiltersDisplay from '../filters/FiltersDisplay';
import SavedFiltersControls from './SavedFiltersControls';

type SavedFilterDisplayProps = {
  frozenKeys?: string[];
  includeSavedFilters?: boolean;
};

export default function SavedFiltersDisplay(props: SavedFilterDisplayProps) {
  return <SavedFiltersDisplay_DisplayLayer {...props} {...useDataLayer()} />;
}

type SavedFilterDisplayDisplayLayerProps = SavedFilterDisplayProps & {
  savedFilterIsActive: boolean;
};

export function SavedFiltersDisplay_DisplayLayer({
  frozenKeys,
  includeSavedFilters = true,
  savedFilterIsActive,
}: SavedFilterDisplayDisplayLayerProps) {
  return (
    <div
      className={classNames(
        'flex-container justify-between row-reversed saved-filters-display',
        savedFilterIsActive && 'show-saved-filter-name',
      )}
    >
      {includeSavedFilters && <SavedFiltersControls />}
      <FiltersDisplay frozenKeys={frozenKeys} />
    </div>
  );
}

function useDataLayer() {
  const { activeSavedFilter } = useActiveSavedFilterState();

  return {
    savedFilterIsActive: !!activeSavedFilter,
  };
}
