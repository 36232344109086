import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useInvalidateZapQueries } from '@frameworks/zap';
import { actions as authActions } from '@modules/auth';
import { ResponseError, Role, Store } from '@typings';
import { putData } from '@utilities/xhr';
import { useCompanyData } from '@hooks/company';

export type ZyloAuthParams = {
  email?: string;
  firstName?: string;
  lastName?: string;
  roles?: Role[];
  timezone?: string;
  usesVues?: boolean;
  vues?: string[];
};

type ZyloAuthVariables = {
  id: string;
  params: ZyloAuthParams;
};

export function useUpdateZyloAuth() {
  const companyId = useCompanyData().companyData.id;
  const dispatch = useDispatch();
  const invalidateZapQueries = useInvalidateZapQueries();
  const loggedInUserZuaId = useSelector(({ auth }: Store) => auth.zyloUserAccountId);
  const queryClient = useQueryClient();

  return useMutation<{ body: string }, ResponseError, ZyloAuthVariables, unknown>(
    async ({ id, params }) => await updateZyloAuth(id, params),
    {
      onSuccess: ({ body }) => {
        if (body.includes(loggedInUserZuaId)) {
          dispatch(authActions.refreshZyloAuthAndFetchCompany({ companyId }));
        }

        invalidateZapQueries('zyloAuth');
        queryClient.invalidateQueries(['userProfile']);
      },
    },
  );
}

/**
 * Updates properties associated with a user's zylo auth connected to the current company.
 * **Note**
 * `id`: `string` is the `id` found on the user's zylo auth.
 * */
export async function updateZyloAuth(id: string, params: ZyloAuthParams) {
  return await putData({ path: `companies/$companyId/zyloAuths/${id}`, params });
}
