import { useState } from 'react';
import { Icon, ConfirmationModal } from '@zylo/orchestra';
import { useDispatch } from 'react-redux';
import PopoverMenu from '@components/PopoverMenu';
import PopoverMenuItem from '@components/PopoverMenuItem';
import { actions as alertActions } from '@modules/alerts';
import { RowData } from '@typings';

export type AlertActionCellProps = {
  handleDeleteAlert: (a: RowData) => void;
  handleEditAlert: () => void;
  rowData: RowData;
};

export function AlertActionCell({
  handleDeleteAlert,
  handleEditAlert,
  rowData = {},
}: AlertActionCellProps) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  function handleShowModal() {
    setShowModal(!showModal);
  }

  function handleDeleteClick() {
    handleDeleteAlert(rowData);
    handleShowModal();
  }

  function handleEditClick() {
    dispatch(alertActions.selectAlertToEdit({ ...rowData }));
    handleEditAlert();
  }

  return (
    <div className="action-cell">
      <PopoverMenu menuButton={<Icon hoverColor="blue" icon="IconDots" size={1} relativeSize />}>
        <PopoverMenuItem action={handleEditClick} icon="IconEdit" optionText="Edit" />
        <PopoverMenuItem action={handleShowModal} icon="IconTrash" optionText="Delete" />
      </PopoverMenu>

      <ConfirmationModal
        contentText="Deleting this alert from Zylo is a permanent action and cannot be reversed."
        handleDismissClick={handleShowModal}
        handlePrimaryClick={handleDeleteClick}
        isOpen={showModal}
        primaryButtonColor="red"
        primaryButtonText="Delete"
      />
    </div>
  );
}
