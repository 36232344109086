import { createAction, handleActions } from 'redux-actions';
import WaterWorksAPI from '../WaterWorksAPI';
import { wrapAsync, handleNetworkError } from '../utilities/util';
import { calculateHasMore } from '../utilities/moduleUtils';
import { actions as zyloFormActions } from './zyloForm';
import { actions as authActions } from './auth';
import { actions as featureFlagActions } from './featureFlags';

const { saveZyloFormSuccess, saveZyloFormError } = zyloFormActions;

// ------------------------------------
// Constants
// ------------------------------------
const TEMPLATE = {
  spend: undefined,
  apps: [],
  utilization: undefined,
  teamRoles: undefined,
};
const DEFAULT_SORT_COLUMN = 'firstName';
const DEFAULT_SORT = `+${DEFAULT_SORT_COLUMN}`;
const mapData = (data) => {
  return data.map((listObj) => {
    return {
      ...TEMPLATE,
      ...listObj,
    };
  });
};

export const DEFAULT_ZYLO_USERS_STATE = {
  error: null,
  hasMoreZyloUsers: true,
  isLoadingZyloUsers: false,
  isSavingZyloAuth: false,
  saveZyloAuthResult: null,
  list: [],
  listSort: [DEFAULT_SORT],
  loadingError: null,
  profileData: {
    settings: {},
    subscriptionsColumnsOrder: [
      'appLabel',
      'spend',
      'apSpend',
      'expenseSpend',
      'purchasedLicenseCount',
      'userCount',
      'utilization',
      'uniqueUserCount',
      'uniqueActiveUserCount',
      'category',
      'subcategory',
      'renewalDate',
      'createdAt',
      'lastTransactionDate',
      'percentActiveLicenses',
      'percentUtilizedLicenses',
      'ssoEnforced',
      'holdsPii',
      'itSupported',
    ],
  },
  zyloUserAuthToEdit: null,
  isFetchingZyloUserAuthtoEdit: false,
};

export const FETCH_ZYLO_USERS_START = 'FETCH_ZYLO_USERS_START';
export const FETCH_ZYLO_USERS_SUCCESS = 'FETCH_ZYLO_USERS_SUCCESS';
export const FETCH_ZYLO_USERS_FAIL = 'FETCH_ZYLO_USERS_FAIL';
export const SORT_ZYLO_USERS_LIST = 'SORT_ZYLO_USERS_LIST';

export const FETCH_CURRENT_USER_PROFILE_START = 'FETCH_CURRENT_USER_PROFILE_START';
export const FETCH_CURRENT_USER_PROFILE_SUCCESS = 'FETCH_CURRENT_USER_PROFILE_SUCCESS';
export const FETCH_CURRENT_USER_PROFILE_FAIL = 'FETCH_CURRENT_USER_PROFILE_FAIL';

export const IS_FETCHING_ZYLO_USER_AUTH_TO_EDIT = 'IS_FETCHING_ZYLO_USER_AUTH_TO_EDIT';
export const FETCH_ZYLO_USER_AUTH_TO_EDIT_SUCCESS = 'FETCH_ZYLO_USER_AUTH_TO_EDIT_SUCCESS';

export const ADD_ZYLO_USER_AUTH_SUCCESS = 'ADD_ZYLO_USER_AUTH_SUCCESS';
export const EDIT_ZYLO_USER_AUTH_SUCCESS = 'EDIT_ZYLO_USER_AUTH_SUCCESS';
export const DELETE_ZYLO_USER_AUTH_SUCCESS = 'DELETE_ZYLO_USER_AUTH_SUCCESS';

export const UPDATE_CURRENT_USER_PROFILE_SUCCESS = 'UPDATE_CURRENT_USER_PROFILE_SUCCESS';
export const SAVE_ZYLO_AUTH_RESULT = 'SAVE_ZYLO_AUTH_RESULT';

export const RESET_ZYLO_USERS_STATE = 'RESET_ZYLO_USERS_STATE';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchZyloUsersStart = createAction(FETCH_ZYLO_USERS_START);
export const fetchZyloUsersSuccess = createAction(FETCH_ZYLO_USERS_SUCCESS);
export const fetchZyloUsersFail = createAction(FETCH_ZYLO_USERS_FAIL);
export const sortZyloUsersList = createAction(SORT_ZYLO_USERS_LIST);

export const fetchCurrentUserProfileStart = createAction(FETCH_CURRENT_USER_PROFILE_START);
export const fetchCurrentUserProfileSuccess = createAction(FETCH_CURRENT_USER_PROFILE_SUCCESS);
export const fetchCurrentUserProfileFail = createAction(FETCH_CURRENT_USER_PROFILE_FAIL);

export const isFetchingZyloUserAuthtoEdit = createAction(IS_FETCHING_ZYLO_USER_AUTH_TO_EDIT);
export const fetchZyloUserAuthToEditSuccess = createAction(FETCH_ZYLO_USER_AUTH_TO_EDIT_SUCCESS);

export const addZyloUserAuthSuccess = createAction(ADD_ZYLO_USER_AUTH_SUCCESS);
export const editZyloUserAuthSuccess = createAction(EDIT_ZYLO_USER_AUTH_SUCCESS);
export const deleteZyloUserAuthSuccess = createAction(DELETE_ZYLO_USER_AUTH_SUCCESS);

export const updateCurrentZyloUserSuccess = createAction(UPDATE_CURRENT_USER_PROFILE_SUCCESS);
export const saveZyloAuthResult = createAction(SAVE_ZYLO_AUTH_RESULT);

export const resetZyloUsersState = createAction(RESET_ZYLO_USERS_STATE);

async function fetchZyloUsers({ offset }, dispatch, getState) {
  const { auth, zyloUsers } = getState();
  const { token, companyId } = auth;
  const { list, listSort, hasMoreZyloUsers } = zyloUsers;
  const calculatedOffset = hasMoreZyloUsers ? list.length : 0;
  const offsetValue = Number.isInteger(offset) ? offset : calculatedOffset;

  dispatch(fetchZyloUsersStart(true));
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, ['zyloUserAccounts'], {
      offset: offsetValue,
      sort: listSort,
    });
  } catch (e) {
    handleNetworkError('fetchZyloUsers', e, dispatch, fetchZyloUsersFail, { companyId });

    return;
  }

  dispatch(fetchZyloUsersSuccess({ data: result, offset: offsetValue }));
}

function sortZyloUsers({ sortBy }, dispatch, getState) {
  dispatch(sortZyloUsersList(sortBy));
  fetchZyloUsers({ offset: 0 }, dispatch, getState);
}

async function fetchCurrentZyloUserProfile(params = {}, dispatch, getState) {
  const { auth } = getState();
  const { token, zyloUserAccountId } = auth;
  const companyId = params.companyId || auth.companyId;

  if (companyId) {
    dispatch(fetchCurrentUserProfileStart(true));
    let result;

    try {
      result = await WaterWorksAPI.getCompanyZyloUserAccount(
        token,
        companyId,
        zyloUserAccountId,
        params,
      );
    } catch (e) {
      handleNetworkError('fetchCurrentUserProfile', e, dispatch, fetchCurrentUserProfileFail, {
        companyId,
        zyloUserAccountId,
      });

      return;
    }

    const currentZyloAuth = result.zyloAuths.find((zyloAuth) => {
      return zyloAuth.companyId === companyId;
    });

    dispatch(featureFlagActions.initializeLaunchDarklyClient({ zyloAuthId: currentZyloAuth.id }));
    dispatch(
      fetchCurrentUserProfileSuccess({
        data: result,
        currentZyloAuth: {
          ...currentZyloAuth,
          authIsViewless: currentZyloAuth.usesVues && currentZyloAuth.vues.length === 0,
        },
      }),
    );
  }
}

async function updateCurrentZyloUser(props, dispatch, getState) {
  const { data = {}, showMessage = true } = props;
  const { auth, zyloUsers } = getState();
  const { token, companyId, zyloUserAccountId } = auth;
  const { profileData } = zyloUsers;
  const { settings = {} } = profileData;

  const payload = { ...data };
  delete payload.password;
  if (Object.prototype.hasOwnProperty.call(data, 'settings')) {
    payload.settings = { ...settings, ...data.settings };
  }

  try {
    await WaterWorksAPI.updateZyloUser(token, companyId, zyloUserAccountId, payload);
  } catch (e) {
    handleNetworkError(
      'updateCurrentZyloUser',
      e,
      dispatch,
      showMessage ? saveZyloFormError : null,
      { payload, showMessage },
    );

    return;
  }

  if (showMessage) {
    dispatch(saveZyloFormSuccess('Updated Zylo user.'));
  }

  dispatch(updateCurrentZyloUserSuccess(payload));
}

async function fetchZyloUserAuthToEdit({ id, includeSettings }, dispatch, getState) {
  dispatch(isFetchingZyloUserAuthtoEdit(true));

  const { token, companyId } = getState().auth;

  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(
      token,
      companyId,
      ['zyloUserAccounts', id],
      includeSettings ? { include: ['user.settings'] } : null,
    );
  } catch (e) {
    handleNetworkError('fetchZyloUserAuthToEdit', e, dispatch, saveZyloFormError, {
      companyId,
      id,
    });
    dispatch(isFetchingZyloUserAuthtoEdit(false));

    return;
  }

  const currentZyloAuth = result.zyloAuths.find((auth) => {
    return auth.companyId === companyId;
  });

  dispatch(
    fetchZyloUserAuthToEditSuccess({
      ...result,
      ...currentZyloAuth,
      id,
      zyloAuthId: currentZyloAuth.id,
      vues: currentZyloAuth.vues
        .filter(({ type }) => {
          return type !== 'appOwner';
        })
        .map((vue) => {
          return vue.id;
        }),
    }),
  );
}

function clearZyloUserAuthToEdit() {
  return (dispatch) => {
    dispatch(fetchZyloUserAuthToEditSuccess(null));
    dispatch(saveZyloAuthResult('error'));
  };
}

async function addZyloUserAuth({ data }, dispatch, getState) {
  const { token, companyId } = getState().auth;
  const { roles, vues = [], usesVues } = data;
  let result;

  try {
    result = await WaterWorksAPI.postCompanyData(
      token,
      companyId,
      ['zyloUserAccountsOrZyloAuths'],
      {
        ...data,
        usesVues: !!usesVues,
        vues: usesVues ? vues : [],
        roles,
      },
    );
  } catch (e) {
    handleNetworkError('addZyloUserAuth', e, dispatch, saveZyloFormError, { companyId, data });
    dispatch(saveZyloAuthResult('error'));

    return;
  }

  if (result.created) {
    dispatch(addZyloUserAuthSuccess({ ...data, zyloAuthId: result.zyloAuthId }));
    dispatch(
      saveZyloFormSuccess(`Zylo user successfully created and email sent to ${data.email}.`),
    );
    dispatch(saveZyloAuthResult('success'));
    fetchZyloUsers({ offset: 0 }, dispatch, getState);
  } else {
    dispatch(saveZyloAuthResult('error'));
    dispatch(saveZyloFormError(`${data.email} is already a user for this company.`));
  }
}

async function editZyloUserAuth({ data }, dispatch, getState) {
  const { token, companyId } = getState().auth;
  const {
    zyloAuthId,
    zyloUserAccountId,
    firstName,
    lastName,
    email,
    roles,
    usesVues,
    vues = [],
  } = data;
  const payloadData = {
    firstName,
    lastName,
    email,
    usesVues,
    vues: usesVues ? vues : [],
    roles,
  };

  try {
    await WaterWorksAPI.updateCompanyData(token, companyId, ['zyloAuths', zyloAuthId], payloadData);
  } catch (e) {
    handleNetworkError('editZyloUserAuth', e, dispatch, saveZyloFormError, {
      companyId,
      zyloAuthId,
      payloadData,
    });
    dispatch(saveZyloAuthResult('error'));

    return;
  }

  dispatch(editZyloUserAuthSuccess(data));
  dispatch(saveZyloFormSuccess(`${data.firstName} ${data.lastName} successfully updated.`));
  dispatch(saveZyloAuthResult('success'));
  fetchZyloUsers({ offset: 0 }, dispatch, getState);
  if (zyloUserAccountId === getState().zyloUsers.profileData.id) {
    dispatch(authActions.refreshZyloAuthAndFetchCompany({ companyId }, dispatch, getState));
  }
}

async function deleteZyloUserAuth(zyloAuthId, dispatch, getState) {
  const { token, companyId } = getState().auth;

  try {
    await WaterWorksAPI.deleteCompanyData(token, companyId, ['zyloUserAccounts', zyloAuthId], {});
  } catch (e) {
    handleNetworkError('deleteZyloUserAuth', e, dispatch, saveZyloFormError, { zyloAuthId });

    return;
  }

  fetchZyloUsers({ offset: 0 }, dispatch, getState);
  dispatch(deleteZyloUserAuthSuccess({ zyloAuthId }));
  dispatch(saveZyloFormSuccess('Successfully deleted Zylo user'));
}

async function resendZyloUserCreationEmail({ zyloUserId, zyloUserEmail }, dispatch, getState) {
  const { token, companyId } = getState().auth;

  try {
    await WaterWorksAPI.postCompanyData(
      token,
      companyId,
      ['zyloUserAccounts', zyloUserId, 'resendNewUserEmail'],
      {},
    );
  } catch (e) {
    handleNetworkError('resendZyloUserConfirmationEmail', e, dispatch, saveZyloFormError, {
      companyId,
      zyloUserId,
    });
  }

  dispatch(saveZyloFormSuccess(`Account creation email resent to ${zyloUserEmail}`));
}

function resetZyloUsers() {
  return (dispatch) => {
    dispatch(resetZyloUsersState());
  };
}

export const actions = {
  fetchZyloUsers: wrapAsync(fetchZyloUsers),
  sortZyloUsers: wrapAsync(sortZyloUsers),
  fetchCurrentZyloUserProfile: wrapAsync(fetchCurrentZyloUserProfile),
  updateCurrentZyloUser: wrapAsync(updateCurrentZyloUser),
  fetchZyloUserAuthToEdit: wrapAsync(fetchZyloUserAuthToEdit),
  clearZyloUserAuthToEdit,
  addZyloUserAuth: wrapAsync(addZyloUserAuth),
  editZyloUserAuth: wrapAsync(editZyloUserAuth),
  deleteZyloUserAuth: wrapAsync(deleteZyloUserAuth),
  resendZyloUserCreationEmail: wrapAsync(resendZyloUserCreationEmail),
  resetZyloUsers,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [FETCH_ZYLO_USERS_START]: (state, { payload }) => {
      return { ...state, isLoadingZyloUsers: payload };
    },
    [FETCH_ZYLO_USERS_SUCCESS]: (state, { payload }) => {
      const { data, offset } = payload;
      const list = offset === 0 ? mapData(data) : state.list.concat(mapData(data));

      return {
        ...state,
        list,
        error: null,
        isLoadingZyloUsers: false,
        hasMoreZyloUsers: calculateHasMore(data.length),
      };
    },
    [FETCH_ZYLO_USERS_FAIL]: (state, { payload }) => {
      return { ...state, error: payload, isLoadingZyloUsers: false, hasMoreZyloUsers: false };
    },
    [SORT_ZYLO_USERS_LIST]: (state, { payload }) => {
      return { ...state, listSort: [payload] };
    },
    [FETCH_CURRENT_USER_PROFILE_START]: (state, { payload }) => {
      return { ...state, loadingError: payload };
    },
    [FETCH_CURRENT_USER_PROFILE_SUCCESS]: (state, { payload }) => {
      const { profileData } = state;
      const { data } = payload;
      if (data.subscriptionsColumnsOrder === '' || data.subscriptionsColumnsOrder === null) {
        delete data.subscriptionsColumnsOrder; // don't merge over default
      }
      return { ...state, profileData: { ...profileData, ...data }, error: null };
    },
    [FETCH_CURRENT_USER_PROFILE_FAIL]: (state, { payload }) => {
      return { ...state, loadingError: payload };
    },

    [IS_FETCHING_ZYLO_USER_AUTH_TO_EDIT]: (state, { payload }) => {
      return { ...state, isFetchingZyloUserAuthtoEdit: payload };
    },
    [FETCH_ZYLO_USER_AUTH_TO_EDIT_SUCCESS]: (state, { payload }) => {
      return { ...state, zyloUserAuthToEdit: payload, isFetchingZyloUserAuthtoEdit: false };
    },
    [UPDATE_CURRENT_USER_PROFILE_SUCCESS]: (state, { payload }) => {
      const profileData = {
        ...state.profileData,
        ...payload,
      };
      return { ...state, profileData };
    },

    [SAVE_ZYLO_AUTH_RESULT]: (state, { payload }) => {
      return {
        ...state,
        saveZyloAuthResult: payload,
      };
    },

    [RESET_ZYLO_USERS_STATE]: (state) => {
      const { profileData } = state;
      return { ...DEFAULT_ZYLO_USERS_STATE, profileData };
    },
  },
  DEFAULT_ZYLO_USERS_STATE,
);
