import { Tooltip } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { MultiValueCell } from '@components/tables/cells';

export const DefaultCellContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export type DefaultCellProps = {
  data?: string | string[];
  truncate?: number;
};

export function DefaultCell({ data, truncate }: DefaultCellProps) {
  let cellValue: DefaultCellProps['data'] | JSX.Element = '-';

  if (data && data.length) {
    cellValue = data;

    if (Array.isArray(data)) {
      return <MultiValueCell data={data} />;
    }

    if (truncate && cellValue.length > truncate) {
      cellValue = <Tooltip text={data}> {data.substring(0, truncate)}...</Tooltip>;
    }
  }
  return <DefaultCellContainer>{cellValue}</DefaultCellContainer>;
}
