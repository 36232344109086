import { MutableRefObject, ReactNode } from 'react';
import { ColumnsProvider } from '@contexts/columnsContext';
import { FiltersProvider } from '@contexts/filtersContext';
import SavedFiltersProviders from '@components/savedFilters/SavedFiltersProviders';
import { SearchProvider } from '@contexts/searchContext';
import { SortProvider } from '@contexts/sortContext';
import { IJsonFilters } from '@src/typings';

export type BaseTableProviderProps = {
  children: ReactNode;
  columnsUrlParam?: string;
  defaultSort?: string;
  searchUrlParam?: string;
  sortUrlParam?: string;
};

export type MetadataTableProvidersProps = BaseTableProviderProps & {
  companyAppId?: string;
  defaultSelectedFilters?: string;
  filtersUrlParam?: string;
  frozenKeys?: string[];
  hiddenFilters?: IJsonFilters;
  savedFilterStateRef?: MutableRefObject<{}> | (() => void);
  savedFiltersType: string;
};

/** For use with tables that are API driven (provides metadata for contructing filters and column fields) and utilize Saved Filters. */
export function MetadataTableProviders({
  children,
  columnsUrlParam,
  companyAppId,
  defaultSelectedFilters,
  filtersUrlParam,
  frozenKeys,
  hiddenFilters,
  searchUrlParam,
  defaultSort,
  sortUrlParam,
  savedFilterStateRef,
  savedFiltersType,
}: MetadataTableProvidersProps) {
  return (
    <BaseTableProviders
      columnsUrlParam={columnsUrlParam}
      defaultSort={defaultSort}
      searchUrlParam={searchUrlParam}
      sortUrlParam={sortUrlParam}
    >
      <FiltersProvider
        defaultSelectedFilters={defaultSelectedFilters}
        filtersUrlParam={filtersUrlParam}
        frozenKeys={frozenKeys}
        hiddenFilters={hiddenFilters}
      >
        <SavedFiltersProviders
          companyAppId={companyAppId}
          savedFilterStateRef={savedFilterStateRef}
          savedFiltersType={savedFiltersType}
        >
          {children}
        </SavedFiltersProviders>
      </FiltersProvider>
    </BaseTableProviders>
  );
}

/** Generally for use with tables that are **not** API driven (receiving metadata for contructing filters and column fields) and do **not** utilize Saved Filters.
 *  If this is needed use `MetadataTableProviders` */
export function BaseTableProviders({
  children,
  columnsUrlParam,
  searchUrlParam,
  defaultSort,
  sortUrlParam,
}: BaseTableProviderProps) {
  return (
    <ColumnsProvider columnsUrlParam={columnsUrlParam}>
      <SearchProvider searchUrlParam={searchUrlParam}>
        <SortProvider defaultSort={defaultSort} sortUrlParam={sortUrlParam}>
          {children}
        </SortProvider>
      </SearchProvider>
    </ColumnsProvider>
  );
}
