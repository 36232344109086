import reduceReducers from 'reduce-reducers';
import { createAction, handleActions } from 'redux-actions';
import { get } from 'lodash-es';
import { wrapAsync, handleNetworkError } from '../utilities/util';
import WaterWorksAPI from '../WaterWorksAPI';

import makeListModule from './list';

// ------------------------------------
// Constants
// ------------------------------------
export const { actionTypes, actionCreators, defaultState, reducer } = makeListModule(
  'DOCUMENTS',
  {},
  { DEFAULT_SORT_COLUMN: 'createdAt' },
);

export const DELETE_DOCUMENTS_START = 'DELETE_DOCUMENTS_START';
export const DELETE_DOCUMENTS_SUCCESS = 'DELETE_DOCUMENTS_SUCCESS';
export const DELETE_DOCUMENTS_FAIL = 'DELETE_DOCUMENTS_FAIL';

// ------------------------------------
// Actions
// ------------------------------------
export const deleteDocumentsStart = createAction(DELETE_DOCUMENTS_START);
export const deleteDocumentsSuccess = createAction(DELETE_DOCUMENTS_SUCCESS);
export const deleteDocumentsFail = createAction(DELETE_DOCUMENTS_FAIL);

async function fetchDocuments({ offset, type, companyAppId, contractId }, dispatch, getState) {
  dispatch(actionCreators.fetchStart());
  const { auth, documents, suppliers } = getState();
  const { token, companyId } = auth;
  const { list, listSort, hasMore } = documents;
  let subId;
  if (type === 'contracts') {
    subId = contractId;
  } else {
    subId = type === 'apps' ? companyAppId : get(suppliers, 'details.id');
  }
  const calculatedOffset = hasMore ? list.length : 0;
  const offsetValue = Number.isInteger(offset) ? offset : calculatedOffset;
  let result;

  try {
    result = await WaterWorksAPI.fetchCompanyData(token, companyId, [type, subId, 'documents'], {
      offset: offsetValue,
      sort: listSort,
    });
  } catch (e) {
    handleNetworkError('fetchDocuments', e, dispatch, actionCreators.fetchFail, {
      companyId,
      type,
      subId,
    });

    return;
  }

  dispatch(actionCreators.fetchSuccess({ data: result, offset: offsetValue }));
}

async function deleteDocument({ documentId, subId, type }, dispatch, getState) {
  dispatch(deleteDocumentsStart(true));
  const { token, companyId } = getState().auth;
  let result;
  let payload = { offset: 0, type };

  try {
    if (type === 'contracts') {
      payload.contractId = subId;
      result = await WaterWorksAPI.deleteCompanyData(
        token,
        companyId,
        ['documents', documentId],
        {},
        {
          entityId: subId,
          entityType: type,
        },
      );
    } else {
      payload.companyAppId = subId;
      result = await WaterWorksAPI.deleteCompanyData(
        token,
        companyId,
        ['apps', subId, 'documents', documentId],
        {},
      );
    }
  } catch (e) {
    handleNetworkError('deleteDocument', e, dispatch, deleteDocumentsFail, {
      companyId,
      documentId,
    });

    return;
  }

  await fetchDocuments(payload, dispatch, getState);
  dispatch(deleteDocumentsSuccess(result));
}

function sortDocs({ sortBy, type, companyAppId, contractId }) {
  return (dispatch, getState) => {
    dispatch(actionCreators.sort(sortBy));
    fetchDocuments({ offset: 0, type, companyAppId, contractId }, dispatch, getState);
  };
}

function resetDocuments() {
  return (dispatch) => {
    dispatch(actionCreators.reset());
  };
}

export const actions = {
  fetchDocuments: wrapAsync(fetchDocuments),
  deleteDocument: wrapAsync(deleteDocument),
  sortDocuments: sortDocs,
  resetDocuments,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default reduceReducers(
  reducer,
  handleActions(
    {
      [DELETE_DOCUMENTS_START]: (state, { payload }) => {
        return { ...state, isDeleting: payload };
      },
      [DELETE_DOCUMENTS_SUCCESS]: (state, { payload }) => {
        return { ...state, deleteResponse: payload, error: null, isDeleting: false };
      },
      [DELETE_DOCUMENTS_FAIL]: (state, { payload }) => {
        return { ...state, error: payload, isDeleting: false };
      },
    },
    defaultState,
  ),
);
