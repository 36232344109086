import { capitalize } from 'lodash-es';
import styled from 'styled-components/macro';
import { Icon, Colors, defaultborder, FontStyles } from '@zylo/orchestra';
import { Draggable } from 'react-beautiful-dnd';
import { getDraggableStyle } from '@utilities/dndUtil';

const Field = styled.div`
  display: flex;
  justify-content: space-between;
  height: 31px;
  width: 100%;
  padding: 0.25rem;
  font-size: ${FontStyles.size['14px']};
  font-weight: ${FontStyles.weight[400]};
  color: ${Colors.grey9};
  cursor: pointer;
  border-bottom: ${defaultborder};
  position: relative;

  span:nth-of-type(1) {
    display: flex;
    align-items: center;
  }

  span:nth-of-type(2) {
    margin-right: 26px;
    color: ${Colors.grey4};
  }

  .draggable-icon {
    margin-right: 0.25rem;
  }
`;

type DraggbleSortFieldProps = {
  category: string;
  dataSource?: string;
  disabled?: boolean;
  id: string;
  index: number;
  name: string;
  sourceKey?: string;
};

export default function DraggbleSortField({
  category,
  dataSource,
  disabled,
  id,
  index,
  name,
  sourceKey,
}: DraggbleSortFieldProps) {
  const sourceType = sourceKey ? capitalize(sourceKey.replace(/(\.|_)\w+/, '')) : undefined;
  const sourceDetail =
    sourceType === 'Direct' ? `(${sourceType} Integration)` : `(${sourceType} User Store)`;

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={disabled} type={category}>
      {(provided, snapshot) => (
        <Field
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getDraggableStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <span>
            <Icon
              className="draggable-icon"
              color="darkgray"
              icon="IconGripVertical"
              size={14}
              testId="sort-fields-move-icon"
            />
            {name}
          </span>

          {dataSource && sourceType ? (
            <span>
              {capitalize(dataSource)} {sourceDetail}
            </span>
          ) : null}
        </Field>
      )}
    </Draggable>
  );
}
