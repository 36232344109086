import { useEffect, useState } from 'react';
import { getValue, updateValue } from '@utilities/localStorageHelpers';

/**
 * Used to persist values in `localStorage` for later retrieval. Ideal for storing user preferences.
 * Should be used with care as we should be mindful of how much we store in the user's browser.
 * There is a limit as to how much data can be stored with localStorage.
 * */
export function useLocalStorage<TLocalStorage>(key: string, defaultValue?: TLocalStorage) {
  const [storedValue, setValue] = useState(() => getValue(key, defaultValue));

  useEffect(() => {
    updateValue(key, storedValue);
  }, [key, storedValue]);

  return [storedValue, setValue] as const;
}
