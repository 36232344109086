import { useEffect } from 'react';
import { uniqWith, isEqual } from 'lodash-es';
import Select from 'react-select-plus';
import classNames from 'clsx';
import styled from 'styled-components/macro';
import { defaultborder, ZyloTag } from '@zylo/orchestra';
import { FilterOption } from '@typings/filters';
import useDebouncedState from '@hooks/useDebouncedState';
import { humanizeFilterValue, STATIC_FILTER_OPTIONS } from '@utilities/filterUtils';

/**
 * Most of the styling here is to match the current `Typeahead` styling exactly, so they appear visually identical
 */
const DynamicComboboxContainer = styled.div`
  border-top: ${defaultborder};
  .pill-container {
    flex-wrap: wrap;
    width: 100%;
    padding: 8px 8px 0 8px;
    border-bottom: ${defaultborder};
  }
  .dynamic-combobox-refine-helper-text {
    width: 100%;
    padding: 0.5rem;
    border-bottom: ${defaultborder};
  }
  .dropdown-container {
    width: 100%;
  }
  .Select {
    border: none;
    width: 100%;
    .Select-control {
      border-bottom: ${defaultborder};
    }
    .Select-input {
      height: 40px;
      max-width: 91%;
      position: absolute;
      overflow: auto;
    }
    .Select-menu-outer {
      position: relative;
      top: 0;
      font-size: 0.875rem;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
    .Select-option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
  .filter-tag-container {
    border-bottom: ${defaultborder};
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: auto;
    padding: 2px 8px;

    .MuiChip-root {
      margin: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: fit-content;

      .MuiChip-label {
        display: inline-block;
        margin: 1px;
      }
    }
  }
`;

export type DynamicComboboxProps = {
  options?: FilterOption[];
  needMoreInput?: boolean;
  hasMore?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  updateSearchString: (newSearch: string) => void;
  handleSelection: (values: string[]) => void;
  selectedValues: string[];
  className?: string;
  allowMultiple?: boolean;
};

function getNoResultsText(needMoreInput: boolean, isError: boolean, isLoading: boolean): string {
  if (isLoading) {
    return `Loading...`;
  }

  if (needMoreInput) {
    return `Input a longer search string to begin searching.`;
  }

  if (isError) {
    return `Something went wrong, no results could be returned.`;
  }

  return `No results found...`;
}

/**
 * Input Combobox component that supports asynchronous searching + loading of options rather than local matching.
 * This is meant to be used with `useDynamicSearchOption` along with an API that returns `hasMore: true` when more than
 * a maximum number of results match the search. However, it should be flexible enough to use with other APIs by manually
 * calculating if `hasMore` is true or false.
 */
function DynamicCombobox({
  options = [],
  needMoreInput = false,
  hasMore = false,
  isError = false,
  isLoading = false,
  allowMultiple = true,
  updateSearchString,
  selectedValues = [],
  handleSelection,
  className,
}: DynamicComboboxProps) {
  const [searchString, setSearchString] = useDebouncedState('');
  useEffect(() => updateSearchString(searchString), [updateSearchString, searchString]);
  const updateSelectedFilters = (option: FilterOption) => {
    if (allowMultiple) {
      handleSelection(selectedValues.concat(option.value));
    } else {
      handleSelection([option.value]);
    }
  };

  if (!needMoreInput) {
    options = [...STATIC_FILTER_OPTIONS.nullOptions, ...options];
    options = uniqWith(options, isEqual);
  }

  const tagRemoveClick = (labelKey: string) => {
    handleSelection(selectedValues.filter((value: string) => value !== labelKey));
  };

  const filterOutSelectedOption = (option: FilterOption) => !selectedValues.includes(option.value);

  return (
    <DynamicComboboxContainer className={classNames('dynamic-combobox', className)}>
      {selectedValues.length > 0 && (
        <div className="flex-container filter-tag-container">
          {selectedValues.map((selectedValue) => (
            <ZyloTag
              color="primary"
              key={selectedValue}
              label={humanizeFilterValue('string', null, selectedValue)}
              onDelete={() => tagRemoveClick(selectedValue)}
            />
          ))}
        </div>
      )}
      {!isLoading && hasMore && (
        <div className="dynamic-combobox-refine-helper-text">
          Too many results matched your search. Please refine the search further.
        </div>
      )}
      <div className="flex-container dropdown-container">
        <Select
          autoFocus={true}
          closeOnBlur={false}
          closeOnSelect={!allowMultiple}
          filterOption={filterOutSelectedOption}
          isLoading={isLoading}
          noResultsText={getNoResultsText(needMoreInput, isError, isLoading)}
          onBlurResetsInput={false}
          onChange={updateSelectedFilters}
          onInputChange={setSearchString}
          onSelectResetsInput={false}
          openOnFocus={true}
          options={options}
          placeholder="Search..."
          value={searchString}
        />
      </div>
    </DynamicComboboxContainer>
  );
}

export default DynamicCombobox;
