import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Icon, Popover, ZyloButton } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { useFiltersState, useFiltersUpdate } from '@contexts/filtersContext';
import CancelAndConfirmButtons from '@components/buttons/CancelAndConfirmButtons';
import SearchBar from '@components/forms/SearchBar';
import { useEventTracking } from '@hooks';
import './FiltersMenu.scss';

const StyledPopover = styled(Popover)`
  &.MuiPaper-root {
    overflow: visible;
  }
`;

export default function FiltersMenu(props) {
  return <FiltersMenu_DisplayLayer {...props} {...useDataLayer()} />;
}

FiltersMenu.propTypes = {
  children: PropTypes.func.isRequired,
  filterApplyEventName: PropTypes.string,
  handleFilterTypeChange: PropTypes.func,
  headerTextNode: PropTypes.node,
  onFilterApply: PropTypes.func,
};

function FiltersMenu_DisplayLayer({
  children,
  filterApplyEventName,
  filterGroups,
  filters,
  handleFilterChange,
  handleFilterTypeChange,
  headerTextNode = <></>,
  onFilterApply,
  selectedFilters,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [filterTypePool, setFilterTypePool] = useState([]);
  const [filterTypeSearchValue, setFilterTypeSearchValue] = useState('');
  const [filterListMarkup, setFilterListMarkup] = useState([]);
  const [selectedFilterType, setSelectedFilterType] = useState({});
  const [stagedFilterValue, setStagedFilterValue] = useState(null);
  const [menuIsAnimating, setMenuIsAnimating] = useState(false);
  const menuPositionRef = useRef();
  const sendEvent = useEventTracking();

  const handleFilterTypeClick = useCallback(
    (e) => {
      const filterType = filters.find(
        ({ key }) => key === e.target.getAttribute('data-filter-key'),
      );

      setSelectedFilterType(filterType);

      if (handleFilterTypeChange) {
        handleFilterTypeChange(filterType);
      }
    },
    [filters, handleFilterTypeChange],
  );

  useEffect(() => {
    function renderFilterType({ key, displayKey, freezeFilterValue }) {
      if (freezeFilterValue) {
        return null;
      }

      return (
        <div
          className={classNames(
            'flex-container justify-between align-center filters-menu-type',
            selectedFilters[key] && 'active',
          )}
          data-filter-key={key}
          key={key}
          onClick={handleFilterTypeClick}
        >
          {displayKey}
          <Icon icon="IconChevronRight" size={1.4} relativeSize />
        </div>
      );
    }

    if (filterGroups) {
      setFilterListMarkup(
        filterGroups.map(({ groupName, groupFunction }) => {
          return (
            <div key={groupName}>
              <div className="filter-menu-group-title">{groupName}</div>
              {filterTypePool.filter(groupFunction).map(renderFilterType)}
            </div>
          );
        }),
      );
    } else {
      setFilterListMarkup(filterTypePool.map(renderFilterType));
    }
  }, [filterGroups, filterTypePool, handleFilterTypeClick, selectedFilters]);

  const applyFilterTypeSearch = useCallback(
    (search) => {
      if (filters) {
        setFilterTypePool(
          filters.filter(({ displayKey }) => displayKey.toLowerCase().includes(search)),
        );
      }
    },
    [filters],
  );

  useEffect(() => {
    applyFilterTypeSearch(filterTypeSearchValue.toLowerCase().trim());
  }, [applyFilterTypeSearch, filterTypeSearchValue]);

  useEffect(() => {
    if (!selectedFilterType.key) {
      setStagedFilterValue(null);
    }

    setMenuIsAnimating(true);
    setTimeout(() => setMenuIsAnimating(false), 250);
  }, [selectedFilterType.key]);

  function handleFilterTypeSearch(search) {
    setFilterTypeSearchValue(search);
  }

  function clearFilterTypeSearch() {
    setFilterTypeSearchValue('');
  }

  function clearSelectedFilterType() {
    setSelectedFilterType({});
    setFilterTypeSearchValue('');
    setMenuIsAnimating(true);
  }

  function updateStagedFilter(value) {
    const appliedFilterValue = JSON.stringify(selectedFilters[selectedFilterType.key]);
    const updatedFilterValue = typeof value === 'string' ? value : JSON.stringify(value);
    const emptyFilterValues = ['null', '[]', ':::'];

    if (
      appliedFilterValue === updatedFilterValue ||
      (!appliedFilterValue && emptyFilterValues.includes(updatedFilterValue))
    ) {
      setStagedFilterValue(null);
    } else {
      setStagedFilterValue(value);
    }
  }

  function applyStagedFilter() {
    if (onFilterApply) {
      onFilterApply(selectedFilterType.key, stagedFilterValue);
    }
    handleFilterChange(selectedFilterType.key, stagedFilterValue);
    if (filterApplyEventName) {
      const filtersApplied = { [selectedFilterType.key]: stagedFilterValue };
      sendEvent({
        eventName: filterApplyEventName,
        data: { selectedFilters: filtersApplied },
      });
    }
    setStagedFilterValue(null);
  }

  function hideMenuDropdown() {
    clearSelectedFilterType();
    setShowMenu(false);
  }

  function preventPopoverClick(e) {
    e.stopPropagation();
  }

  return (
    <>
      <ZyloButton
        buttonType="button"
        collapseMediaQueryWidth={1100}
        handleClick={() => setShowMenu(true)}
        icon="IconFilter"
        ref={menuPositionRef}
        variant="outlined"
      >
        Filters
      </ZyloButton>

      <StyledPopover
        className={classNames(
          'filters-menu-popover',
          !showMenu && 'hidden',
          menuIsAnimating && 'menu-is-animating',
        )}
        closeAction={hideMenuDropdown}
        enableVerticalRepositioning={false}
        positionRef={menuPositionRef.current}
        verticalDisplay="bottom"
        visible={showMenu}
        clickToOpen
      >
        <div
          className={classNames(
            'flex-container filters-menu-dropdown',
            selectedFilterType.key && 'filter-type-selected',
          )}
          onClick={preventPopoverClick}
        >
          <div className="flex-container vertical filters-menu-types">
            <div className="filter-types-header">
              <div className="flex-container justify-between align-baseline">
                <div className="filters-menu-title">Filters</div>
                {Object.keys(selectedFilters).length ? (
                  <div className="flex-container align-center active-filters-indicator">
                    Active Filters
                  </div>
                ) : null}
              </div>
              {headerTextNode}
              {filters && filters.length > 6 && (
                <SearchBar
                  clearSearch={clearFilterTypeSearch}
                  handleSearch={handleFilterTypeSearch}
                  placeholder="Search Filters..."
                  searchValue={filterTypeSearchValue}
                  searchOnChange
                />
              )}
            </div>

            <div
              className="flex-container vertical flex-1 filter-type-list"
              data-disable-scroll-listener
            >
              {filterListMarkup}
            </div>
          </div>

          <div className="flex-container vertical filter-type-options">
            <div className="flex-container vertical filters-options-header">
              <div
                className="flex-container align-center back-to-menu"
                onClick={clearSelectedFilterType}
              >
                <Icon color="blue" icon="IconChevronLeft" size={1.4} relativeSize />
                Back to Menu
              </div>
              <div className="filter-options-title">{selectedFilterType.displayKey}</div>
            </div>
            <div className="filter-options-main-content">
              {children({
                reportFilterChange: updateStagedFilter,
                selectedFilterType,
                stagedFilterValue,
              })}
            </div>
            <CancelAndConfirmButtons
              cancelButtonText="Dismiss"
              confirmButtonText="Apply"
              confirmIsDisabled={!stagedFilterValue}
              containerClass="filter-options-footer"
              handleCancel={hideMenuDropdown}
              handleConfirm={applyStagedFilter}
            />
          </div>
        </div>
      </StyledPopover>
    </>
  );
}

FiltersMenu_DisplayLayer.propTypes = {
  children: PropTypes.func.isRequired,
  filterGroups: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      groupFunction: PropTypes.func.isRequired,
    }),
  ),
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  handleFilterChange: PropTypes.func,
  handleFilterTypeChange: PropTypes.func,
  selectedFilters: PropTypes.shape({}),
};

function useDataLayer() {
  const { filterGroups, filters, selectedFilters = {} } = useFiltersState();
  const { handleFilterChange } = useFiltersUpdate();

  return { filterGroups, filters, handleFilterChange, selectedFilters };
}
