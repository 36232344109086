import { createContextHelper, Loader } from '@zylo/orchestra';
import { ReactNode, useMemo } from 'react';
import { buildZAPEndpoints, customCellMapping } from '@frameworks/zap/helpers';
import { ZAPProps } from '@frameworks/zap/ZAP';
import { useInvalidateZapQueries } from '@frameworks/zap/hooks/useInvalidateZapQueries';
import { MetadataResponse, useTableMetadata } from '@hooks';

export type ZAPContextShape<T extends object> = Pick<
  ZAPProps<T>,
  'categoriesInfo' | 'customBulkEditForm' | 'gridName' | 'ZAPName'
> & {
  children: ReactNode;
  eventLocation?: string;
  tableProps: Pick<
    ZAPProps<T>,
    | 'cellMappings'
    | 'includeFieldPicker'
    | 'includeTableExport'
    | 'noDataText'
    | 'rowSelectionKey'
    | 'tableHeaderContent'
    | `rowActionsComponent`
  >;

  /** Unique key for this ZAP */
  ZAPName: string;

  /** Object to override default ZAP endpoints */
  zapEndpointsOverride?: ZAPEndpointsOverrideType;

  /** company app id used to filter down this ZAP */
  zapId?: string;
};

export type ZAPStateType<T extends object> = Pick<
  ZAPContextShape<T>,
  'categoriesInfo' | 'customBulkEditForm' | 'gridName' | 'tableProps' | 'ZAPName'
> & {
  /**
   * Readonly endpoints associated with ⚡️ZAP™. The endpoints are also the
   * `queryKeys` for the API request for each endpoint. Useful for retrieving
   * query data or invalidating a query.
   */
  endpoints: {
    /** Endpoint for filter options and the `queryKey` for the request. */
    completions: string;
    /** Endpoint for metadata and the `queryKey` for the request. */
    metadata: string;
    /** Endpoint for stats and the `queryKey` for the request. */
    stats: string;
    /** Endpoint for table data and the `queryKey` for the request. */
    tableData: string;
    /** Endpoint for bulk editing the data behind the ZAP table. */
    update: string;
  };
  /** String that can be added to data property when sending an event to indicate event location */
  eventLocation?: string;
  /** Readonly value to indicate to component that it exists in a ⚡️ZAP™. */
  existsInZAP: boolean;
  ZAPMetadata: MetadataResponse['body'];
  /** Readonly value to indicate ⚡️ZAP™ name */
  ZAPName?: string;
};

export type ZAPEndpointsOverrideType = {
  completions?: string;
  metadata?: string;
  stats?: string;
  tableData?: string;
  update?: string;
};

type ZAPUpdateType = {
  /** Invalidates ZAP Queries for this ZAP */
  invalidateQueries: () => Promise<void>;
};

const [useZAPState, ZAPStateContext] = createContextHelper<ZAPStateType<any>>(
  'useZAPState',
  'ZAPProvider',
);

const [useZAPUpdate, ZAPUpdateContext] = createContextHelper<ZAPUpdateType>(
  'useZAPUpdate',
  'ZAPProvider',
);

function ZAPProvider<T extends object>({
  categoriesInfo,
  children,
  customBulkEditForm,
  eventLocation,
  gridName,
  tableProps,
  ZAPName,
  zapEndpointsOverride,
  zapId,
}: ZAPContextShape<T>) {
  const invalidateZapQueries = useInvalidateZapQueries();
  const endpoints = useMemo(
    () => buildZAPEndpoints(ZAPName, zapId, zapEndpointsOverride),
    [ZAPName, zapId, zapEndpointsOverride],
  );
  const { cellMappings } = tableProps;

  const { zapMetadata } = useTableMetadata({
    fieldToColumnMappers: useMemo(
      () => (cellMappings ? [customCellMapping(cellMappings)] : undefined),
      [cellMappings],
    ),
    path: endpoints.metadata,
  });

  return zapMetadata ? (
    <ZAPStateContext
      value={{
        categoriesInfo,
        customBulkEditForm,
        endpoints,
        eventLocation,
        existsInZAP: true,
        gridName,
        tableProps,
        ZAPName,
        ZAPMetadata: zapMetadata,
      }}
    >
      <ZAPUpdateContext
        value={{
          invalidateQueries: () => invalidateZapQueries(ZAPName),
        }}
      >
        {children}
      </ZAPUpdateContext>
    </ZAPStateContext>
  ) : (
    <Loader />
  );
}

export { ZAPProvider, useZAPState, useZAPUpdate };
