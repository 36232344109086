import { cloneElement, MouseEvent, useState } from 'react';
import classNames from 'clsx';
import { ZyloButton } from '@zylo/orchestra';
import { RowData } from '@typings';

export interface Confirmation {
  id?: string;
  isVisible?: boolean;
  index?: number;
}

export type ActionConfirmationCellProps = {
  className?: string;
  confirmationButtonText: string[];
  elements: JSX.Element[];
  handleConfirmation?: (rowData?: RowData) => void;
  rowData: RowData;
};

export function ActionConfirmationCell({
  className,
  confirmationButtonText = [],
  elements = [],
  handleConfirmation = () => {},
  rowData,
}: ActionConfirmationCellProps) {
  const [confirmation, setConfirmation] = useState<Confirmation>({});

  return (
    <div className={classNames('action-confirmation-cell', className)}>
      {rowData.id === confirmation.id &&
      confirmation.isVisible &&
      confirmationButtonText[confirmation.index as number] ? (
        <div>
          <ZyloButton
            buttonType="button"
            onClick={(e?: MouseEvent<HTMLButtonElement>) => {
              e && e.stopPropagation();
              setConfirmation({ isVisible: false });
            }}
            variant="text"
          >
            Cancel
          </ZyloButton>
          <ZyloButton
            buttonType="button"
            onClick={(e?: MouseEvent<HTMLButtonElement>) => {
              e && e.stopPropagation();
              handleConfirmation(rowData);
              setConfirmation({ isVisible: false });
            }}
            variant="text"
          >
            {confirmationButtonText[confirmation.index as number]}
          </ZyloButton>
        </div>
      ) : (
        <div className="flex-container justify-end">
          {elements.map((element, index) => {
            const { icon: key, onClick: additionalOnClickhandler = () => {} } = element.props;
            const { id } = rowData;

            function onClick(e: MouseEvent<HTMLElement>) {
              e.stopPropagation();
              setConfirmation({ id, index, isVisible: true });
              additionalOnClickhandler(rowData);
            }

            return cloneElement(element, { key, onClick });
          })}
        </div>
      )}
    </div>
  );
}
