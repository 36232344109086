import { useState } from 'react';
import { debounce } from 'lodash-es';
import AssignmentCell, { AssignmentCellOption } from '@components/tables/cells/AssignmentCell';
import SubscriptionOption from '@components/forms/inputs/SubscriptionOption';
import TypeAhead from '@components/forms/inputs/TypeAhead';
import { SendEventFunction, useCompanyAppsSearch, useEventTracking } from '@hooks';
import { CellProps, CompanyApp } from '@typings';
import { OptionData } from '@components/PopoverMenuItem';

export type PurchaseOrderAppSelectorCellProps = {
  handleSearchChange: (search: string) => void;
  companyApps: Array<CompanyApp>;
  onChange: (ids: PurchaseOrderReassignementProps, rowData: CellProps['rowData']) => void;
  data: string;
  rowData: CellProps['rowData'];
  sendEvent: SendEventFunction;
};

export type PurchaseOrderReassignementProps = {
  purchaseOrderId: string;
  companyAppId: string | null;
};

export type PurchaseOrderAppSelectorCellDisplayProps = PurchaseOrderAppSelectorCellProps;

export default function PurchaseOrderAppSelectorCell(props: PurchaseOrderAppSelectorCellProps) {
  return <PurchaseOrderAppSelectorCell_DisplayLayer {...props} {...useDataLayer()} />;
}

export function PurchaseOrderAppSelectorCell_DisplayLayer({
  handleSearchChange,
  companyApps,
  onChange,
  data,
  rowData,
  sendEvent,
}: PurchaseOrderAppSelectorCellDisplayProps) {
  const [reassigning, setReassigning] = useState(false);

  function getPurchaseOrderAppCellOptions(): Array<AssignmentCellOption> {
    const purchaseOrderAppAssignmentCellOptions = Array<AssignmentCellOption>();

    if (rowData.companyAppLabel) {
      purchaseOrderAppAssignmentCellOptions.push({
        icon: 'IconSwitch3',
        isDisabled: false,
        optionText: 'Reassign',
        value: true,
      });
      purchaseOrderAppAssignmentCellOptions.push({
        icon: 'IconUnlink',
        isDisabled: false,
        optionText: 'Unassign',
        value: false,
      });
    } else {
      purchaseOrderAppAssignmentCellOptions.push({
        icon: 'IconSwitch3',
        isDisabled: false,
        optionText: 'Assign',
        value: true,
      });
    }
    return purchaseOrderAppAssignmentCellOptions;
  }

  function handleAssignment(item: OptionData) {
    const isReassign = !!(item as AssignmentCellOption).value;
    if (isReassign) {
      setReassigning(isReassign);
    } else {
      handleUnassign();
    }
  }

  function handleBlur() {
    setReassigning(false);
  }

  function handleReassign(_: never, option: CompanyApp) {
    const { purchaseOrderId } = rowData;

    if (!option) {
      return;
    } else {
      const reassignObj: PurchaseOrderReassignementProps = {
        purchaseOrderId: purchaseOrderId,
        companyAppId: option.id,
      };
      onChange(reassignObj, rowData);
      setReassigning(false);
      sendEvent({
        eventName: 'PURCHASEORDERS_APPLICATION_ASSIGN',
        data: {
          'Purchase Order Id': reassignObj.purchaseOrderId,
          'Company App Id': reassignObj.companyAppId,
        },
      });
    }
  }

  function handleUnassign() {
    const { purchaseOrderId } = rowData;
    const reassignObj: PurchaseOrderReassignementProps = {
      purchaseOrderId: purchaseOrderId,
      companyAppId: null,
    };
    onChange(reassignObj, rowData);
  }

  function renderSelectedApp() {
    const options = rowData;
    options.domain = options.domain ?? rowData.appDomain;
    return <SubscriptionOption option={options}>{rowData.companyAppLabel}</SubscriptionOption>;
  }

  return (
    <div className="flex-container align-center justify-end condensed-company-app-selector-cell">
      {reassigning && (
        <TypeAhead
          allowCustomValue={false}
          allowMultiple={false}
          customOption={SubscriptionOption}
          customValueRenderer={renderSelectedApp}
          handleSearchChange={debounce(handleSearchChange, 300)}
          labelKey="appLabel"
          matchPos="any"
          name="companyAppLabel"
          onBlur={handleBlur}
          onChange={handleReassign}
          options={companyApps}
          value={data}
          valueKey="id"
          showMenuByDefault
        />
      )}
      {!reassigning && (
        <AssignmentCell
          customValue={data ? renderSelectedApp() : 'Unassigned'}
          data={''}
          onChange={(item: OptionData) => {
            handleAssignment(item);
          }}
          options={getPurchaseOrderAppCellOptions()}
        />
      )}
    </div>
  );
}

function useDataLayer() {
  const sendEvent = useEventTracking();
  const { searchCompanyApps, queryData } = useCompanyAppsSearch({});
  const { data } = queryData;

  return {
    handleSearchChange: searchCompanyApps,
    companyApps: data,
    sendEvent,
  };
}
