import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { attemptMessageHide, showErrorMessage, showSuccessMessage } from '@modules/statusBar';

export type AppMessagingParams = {
  isError?: boolean;
  message: string;
};

export type ShowMessageFunction = (params: AppMessagingParams) => void;

export function useAppMessaging(): ShowMessageFunction {
  const dispatch = useDispatch();

  return useCallback(
    ({ message, isError }) => {
      if (isError) {
        dispatch(showErrorMessage(message));
      } else {
        dispatch(showSuccessMessage(message));
      }

      setTimeout(() => dispatch(attemptMessageHide({ override: false })), 3000);
    },
    [dispatch],
  );
}
