import { ReactNode, useState } from 'react';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { get } from 'lodash-es';
import classNames from 'clsx';
import TabLabel from './TabLabel';
import TopBar from './TopBar';
import { TabPaneElement } from './ZyloTabs';

export type TabsProps = {
  children: TabPaneElement | TabPaneElement[] | undefined;
  className?: string;
  contentClass?: string;
  isDetails?: boolean;
  leftContent?: ReactNode;
  location?: { search: string };
  onTabChange?: (tabId: string) => void;
  rightContent?: ReactNode;
  selected: string;
  showLabels?: boolean;
};

export default function Tabs({
  children,
  className,
  contentClass,
  isDetails = false,
  leftContent,
  onTabChange = () => {},
  rightContent,
  showLabels = true,
  selected,
}: TabsProps) {
  const location = useLocation();
  const { tab } = parse(location.search);
  const childs = (Array.isArray(children) ? children : [children]).filter(Boolean);
  const [selectedTab, setSelectedTab] = useState(
    confirmTabIdExistence((tab as string) ?? selected),
  );

  function confirmTabIdExistence(tabId: string) {
    if (childs !== undefined) {
      const tabIds = childs.filter(Boolean).map((c) => {
        return c?.props.tabId.toLowerCase();
      });

      return tabIds.indexOf(tabId.toLowerCase()) > -1
        ? tabId.toLowerCase()
        : childs.filter(Boolean)[0]?.props.tabId.toLowerCase();
    }
  }

  function handleLabelClick(tabId: string) {
    const selectedTabId = confirmTabIdExistence(tabId);

    setSelectedTab(selectedTabId);
    onTabChange(selectedTabId);
  }

  function renderContent() {
    const tabToDisplay = showLabels ? selectedTab : selected;
    const content = Array.isArray(childs)
      ? childs.find((child) => {
          return get(child, 'props.tabId', '').toLowerCase() === tabToDisplay.toLowerCase();
        })
      : childs;

    return <div className={classNames('tabs-content', contentClass)}>{content}</div>;
  }

  const tabs = (
    <ul className="tabs-labels">
      {childs.map((l, index) => {
        const { tabId, label } = l?.props;

        return (
          <TabLabel
            handleClick={handleLabelClick}
            isActive={selectedTab === tabId.toLowerCase()}
            key={tabId}
            label={label}
            tabId={tabId}
            tabIndex={index + 1}
          />
        );
      })}
    </ul>
  );

  return (
    <div className={classNames('tabs', isDetails ? 'details-tabs' : 'standard-tabs', className)}>
      {leftContent || showLabels || rightContent ? (
        <TopBar
          centerContent={showLabels && !isDetails ? tabs : null}
          leftContent={isDetails ? tabs : leftContent}
          rightContent={rightContent}
        />
      ) : null}

      {renderContent()}
    </div>
  );
}
