import styled from 'styled-components/macro';
import { Icon, Icons, Tooltip, defaultborder, FontStyles } from '@zylo/orchestra';
import { ReactNode } from 'react';

const Title = styled.div`
  align-items: center;
  border-bottom: ${defaultborder};
  display: flex;
  font-size: ${FontStyles.size['16px']};
  font-weight: ${FontStyles.weight[500]};
  padding: 0 1.5rem 0.25rem;

  .choose-fields-icon {
    margin-left: 0.5rem;
  }
`;

type ChooseFieldsCategoryTitleProps = {
  icon?: Icons;
  category: string;
  tooltipText?: ReactNode;
  testId?: string;
};

export default function ChooseFieldsCategoryTitle({
  icon,
  category,
  tooltipText,
  testId,
}: ChooseFieldsCategoryTitleProps) {
  const allowBodyHover = typeof tooltipText === 'object';

  return (
    <Title>
      <Tooltip allowBodyHover={allowBodyHover} disabled={!tooltipText} text={tooltipText}>
        {category}
      </Tooltip>
      {icon ? <Icon className="choose-fields-icon" icon={icon} testId={testId} /> : null}
    </Title>
  );
}
