import { useEffect, useRef, useState } from 'react';
import { useSidebarState } from '@contexts/sidebarContext';

type VisibilitySensorParams = {
  animationDuration?: number;
  sidebarName: string;
};

export function useSidebarVisibilitySensor({
  animationDuration = 300,
  sidebarName,
}: VisibilitySensorParams) {
  const [sidebarIsVisible, setSidebarIsVisible] = useState(false);
  const activeSidebars: Array<string | undefined> = useSidebarState();
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (activeSidebars.includes(sidebarName)) {
      clearTimeout(timeoutRef.current);
      setSidebarIsVisible(true);
    } else {
      timeoutRef.current = setTimeout(() => {
        setSidebarIsVisible(false);
      }, animationDuration) as unknown as number;
    }
  }, [activeSidebars, animationDuration, sidebarName]);

  return sidebarIsVisible;
}
