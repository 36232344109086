import { useRef } from 'react';
import PropTypes from 'prop-types';
import { createNumberMask } from 'redux-form-input-masks';
import Input from './Input';

function NumberInput({
  value = '',
  onChange,
  numberMask = { locale: 'en-US', stringValue: true },
  ...props
}) {
  function cleanseValue(val) {
    onChange(Number(val));
  }

  const numberMaskRef = useRef(
    createNumberMask({
      ...numberMask,
      onChange: cleanseValue,
    }),
  );
  const { normalize, format } = numberMaskRef.current;

  function handleChange(nextValue) {
    if (nextValue !== '') {
      normalize(nextValue);
    } else {
      onChange(nextValue);
    }
  }

  return (
    <div className="flex-container align-center number-input-field">
      <Input {...props} onChange={handleChange} value={value ? format(value) : value} />
    </div>
  );
}

NumberInput.propTypes = {
  name: PropTypes.string,
  numberMask: PropTypes.shape({
    decimalPlaces: PropTypes.number,
    locale: PropTypes.string,
    multiplier: PropTypes.number,
    stringValue: PropTypes.bool,
    suffix: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NumberInput;
