import { Icon, Tooltip } from '@zylo/orchestra';
import { useDispatch, useSelector } from 'react-redux';
import { useEventTracking } from '@hooks';
import { actions as filterActions } from '@modules/filters';
import { useCompanyData } from '@hooks/company';
import { Store } from '@typings';

type ReportsDownloadCellProps = {
  rowData: { filterName: string; id: string; reportStatus: string };
};

export function ReportsDownloadCell(props: ReportsDownloadCellProps) {
  const { id: reportId, reportStatus, filterName: reportName } = props.rowData;
  const canDownload = reportStatus === 'A' && !!reportId;
  const reportErred = reportStatus === 'E';
  const displayProps = { canDownload, reportErred, reportId, reportName };

  return (
    <ReportsDownloadCell_DisplayLayer
      {...props}
      {...displayProps}
      {...useDataLayer({ reportStatus })}
    />
  );
}

type ReportsDownloadCellDisplayProps = {
  canDownload?: boolean;
  companyName?: string;
  fetchReportForDownload: (props: { filterId: string }) => any;
  reportErred?: boolean;
  reportForDownload?: string;
  reportId: string;
  reportName: string;
  sendEvent: (props: { eventName: string; data: Record<string, any> }) => void;
};

export function ReportsDownloadCell_DisplayLayer({
  canDownload,
  companyName,
  fetchReportForDownload,
  reportErred,
  reportForDownload,
  reportId,
  reportName,
  sendEvent,
}: ReportsDownloadCellDisplayProps) {
  function handleDownloadHover() {
    if (canDownload) {
      fetchReportForDownload({ filterId: reportId });
    }
  }

  function handleDownloadClick() {
    sendEvent({
      eventName: 'DOWNLOAD_REPORT',
      data: {
        'Report Name': reportName,
      },
    });
  }

  return (
    <div className="reporting-action-cell" onMouseEnter={handleDownloadHover}>
      <a
        className="popover-menu-action"
        download={`${companyName}_${reportName}.csv`}
        href={reportForDownload}
      >
        {reportErred && (
          <Tooltip text="Report unsuccessful, please try again.">
            <Icon color="red" icon="IconDownload" size={1.5} relativeSize />
          </Tooltip>
        )}
        {!reportErred && (
          <Icon
            color="navy"
            disabled={!canDownload}
            hoverColor={canDownload ? 'blue' : undefined}
            icon="IconDownload"
            onClick={canDownload ? handleDownloadClick : undefined}
            size={1.5}
            relativeSize
          />
        )}
      </a>
    </div>
  );
}

function useDataLayer({ reportStatus }: { reportStatus: string }) {
  const { companyName } = useCompanyData().companyData;
  const { reportForDownload } = useSelector(({ filters }: Store) => filters);
  const sendEvent = useEventTracking();
  const dispatch = useDispatch();

  return {
    companyName,
    fetchReportForDownload: (props: { filterId: string }) =>
      dispatch(filterActions.fetchFilterForDownload(props)),
    reportForDownload: reportStatus === 'A' ? reportForDownload : undefined,
    sendEvent,
  };
}
