import { useCallback } from 'react';
import {
  useActiveSavedFilterState,
  useActiveSavedFilterUpdate,
} from '../../../contexts/savedFilterContexts/activeSavedFilterContext';
import { useSavedFiltersListUpdate } from '../../../contexts/savedFilterContexts/savedFiltersListContext';
import { useAppMessaging } from '../../../hooks';
import { deleteData } from '../../../utilities/xhr';

export default function useDeleteInsightFilter() {
  const { activeSavedFilter } = useActiveSavedFilterState();
  const { clearActiveSavedFilter } = useActiveSavedFilterUpdate();
  const { fetchInsightFilters } = useSavedFiltersListUpdate();
  const showMessage = useAppMessaging();

  const deleteInsightFilter = useCallback(
    ({ id, filterName }) => {
      deleteData({
        path: `insightFilters/${id}`,
      })
        .then(() => {
          if (activeSavedFilter && filterName === activeSavedFilter.filterName) {
            clearActiveSavedFilter();
          }

          showMessage({ message: `${filterName} has been deleted` });
          fetchInsightFilters();
        })
        .catch(({ message }) => {
          showMessage({ message, isError: true });
        });
    },
    [activeSavedFilter, clearActiveSavedFilter, fetchInsightFilters, showMessage],
  );

  return deleteInsightFilter;
}
