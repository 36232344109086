import { useMutation } from 'react-query';
import { useInvalidateZapQueries } from '@frameworks/zap';
import { HttpResponse, ResponseError } from '@typings';
import { postData } from '@utilities/xhr';
import { ZyloAuthParams } from './useUpdateZyloAuth';

type CreateZyloAuthParams = Required<Omit<ZyloAuthParams, 'timezone'>>;
type CreateZyloAuthResponse = HttpResponse<{
  created: boolean;
  zyloUserAccount: {
    firstName: string;
    lastName: string;
    email: string;
    companyFamily: string;
    id: string;
    setupComplete: false;
  };
  zyloAuthId: string;
  message: string;
}>;

export function useCreateZyloAuth() {
  const invalidateZapQueries = useInvalidateZapQueries();

  return useMutation<CreateZyloAuthResponse, ResponseError, CreateZyloAuthParams, unknown>(
    async (params) =>
      await postData({ path: 'companies/$companyId/zyloUserAccountsOrZyloAuths', params }),
    {
      onSuccess: () => invalidateZapQueries('zyloAuth'),
    },
  );
}
