import { useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import { useHandleClickOutside } from '@hooks';
import { convertDateStringToMoment } from '@utilities/util';
import DatePickerInput from './DatePickerInput';

const CURRENT_DAY = moment.utc();

export type DatePickerProps = {
  className?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  formatToISOString?: boolean;
  name?: string;
  onChange(d: string): void;
  outsideRange?: string;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
};

export default function DatePicker({
  className,
  disabled,
  disableFuture,
  disablePast,
  name,
  onChange,
  outsideRange,
  placeholder,
  readOnly,
  formatToISOString = false,
  value = '',
}: DatePickerProps) {
  const [date, setDate] = useState(convertDateStringToMoment(value));
  const [focused, setFocused] = useState(false);
  const clickOutsideRef = useRef<HTMLDivElement>(null);
  const isDisabled = disabled || readOnly;

  useEffect(() => {
    setDate(convertDateStringToMoment(value));
  }, [value]);

  function handleClickOutside(e: Event) {
    if (focused) {
      e.stopPropagation();
    }

    setFocused(false);
  }

  useHandleClickOutside(clickOutsideRef, handleClickOutside);

  function checkForDisabledDate(day: moment.Moment) {
    let result = false;

    if (disableFuture) {
      result = moment(day, 'L').isAfter(outsideRange || CURRENT_DAY);
    } else if (disablePast) {
      result = moment(day, 'L').isBefore(outsideRange || CURRENT_DAY);
    }

    return result;
  }

  // ISO string is required for ElasticSearch upload
  function formatDateValue(dateValue: moment.Moment) {
    return formatToISOString ? dateValue.toISOString() : dateValue.format('L');
  }

  function handleValueChange(newValue: moment.Moment) {
    onChange(newValue ? formatDateValue(newValue) : '');
  }

  function handleDateChange(selectedDate: moment.Moment | null) {
    const utcDate = moment.utc(selectedDate);

    handleValueChange(utcDate);
    setDate(utcDate);
    setFocused(false);
  }

  function handleInputFocus() {
    if (!isDisabled) {
      setFocused(true);
      const els = document.getElementsByClassName('DayPicker');
      if (els && els[0]) {
        els[0].scrollIntoView(false);
      }
    }
  }

  function handleInputChange(newDateValue: moment.Moment) {
    setDate(newDateValue);

    if (!checkForDisabledDate(newDateValue)) {
      handleValueChange(newDateValue);
    }

    if (newDateValue) {
      setFocused(false);
      setTimeout(() => setFocused(true), 100);
    } else {
      setFocused(true);
    }
  }

  return (
    <div
      className={classNames(
        'date-picker',
        isDisabled && 'date-picker__read-only',
        focused && 'date-picker__focused',
        className,
      )}
      ref={clickOutsideRef}
    >
      <DatePickerInput
        checkForDisabledDate={checkForDisabledDate}
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled}
        focused={focused}
        name={name}
        onBlur={() => setFocused(false)}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        value={date ? date.format('L') : ''}
      />

      {focused && (
        <DayPickerSingleDateController
          date={date}
          initialVisibleMonth={date ? () => date : null}
          isOutsideRange={checkForDisabledDate}
          onDateChange={handleDateChange}
          onFocusChange={() => {}}
          focused
        />
      )}
    </div>
  );
}
