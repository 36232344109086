import { useCallback } from 'react';

export type SendEventFunction = (e: { eventName: string; data?: Record<string, unknown> }) => void;
/**
 * Hook to send a segment.io event in a functional component
 * @returns {func} - sendEvent
 * @example
 *
 *  const sendEvent = useEventTracking();
 *  sendEvent({ eventName: 'FOOBAR', data: { zap: 'foobar' }})
 *
 */
export function useEventTracking(): SendEventFunction {
  return useCallback(({ eventName, data }) => {
    window.analytics?.track(eventName, data);
  }, []);
}
