import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';

export class ObjectCell extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({}),
    dataKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data, dataKey } = this.props;
    return <div>{get(data, dataKey, '-')}</div>;
  }
}
