import { PartialPick } from '@typings';
import { FormElementConfig, ObserverValues, FormContentCommonProps } from '../types';
import { Field } from './Field';
import { FieldSet } from './Fieldset';

type FieldReturnProps = PartialPick<FormContentCommonProps, 'editing'> & {
  formElementConfig: Exclude<FormElementConfig, null | undefined>;
  obeserverValues?: ObserverValues;
};

export function FieldReturn({
  editing = false,
  formElementConfig,
  methods,
  readOnly,
  status,
}: FieldReturnProps) {
  const [field] = formElementConfig;

  switch (field) {
    case 'CustomElement': {
      const [, , { customElementComponent: CustomElement }] = formElementConfig;
      return <CustomElement />;
    }

    case 'Fieldset': {
      return (
        <FieldSet
          editing={editing}
          fieldsetConfig={formElementConfig}
          methods={methods}
          readOnly={readOnly}
          status={status}
        />
      );
    }

    default: {
      return (
        <Field
          editing={editing}
          fieldConfig={formElementConfig}
          methods={methods}
          readOnly={readOnly}
          status={status}
        />
      );
    }
  }
}
