import { useMutation, useQueryClient } from 'react-query';
import { putData } from '@utilities/xhr';
import { useAppMessaging } from '@hooks';

type UpdatePasswordArgs = {
  oldPassword: string;
  newPassword: string;
  zyloUserAccountId: string;
};

export async function updatePassword({
  oldPassword,
  newPassword,
  zyloUserAccountId,
}: UpdatePasswordArgs) {
  const response = await putData({
    path: `zyloUserAccounts/password`,
    params: { oldPassword, password: newPassword, zyloUserAccountId },
  });
  return response;
}

export function useUpdatePassword() {
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();

  return useMutation(
    ({ oldPassword, newPassword, zyloUserAccountId }: UpdatePasswordArgs) =>
      updatePassword({ oldPassword, newPassword, zyloUserAccountId }),
    {
      onSuccess: async ({ body }) => {
        showMessage({ message: body.message });
        await queryClient.invalidateQueries(['userProfile']);
      },
      onError: () =>
        showMessage({
          message: 'There was an error updating your password. Please try again.',
          isError: true,
        }),
    },
  );
}
