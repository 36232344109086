import { LOGO_URI } from '../../config/config';

/**
 * [getAWSCompanyLogoPath description]
 *
 * @param   {string}  filename   name of file, including extension
 * @param   {string}  companyId  id of company
 *
 * @return  {string}             url/src for logo in aws
 */
export default function getAWSCompanyLogoPath(filename, companyId) {
  return `${LOGO_URI}${companyId}/${filename}`;
}
