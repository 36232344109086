import { createAction, handleActions } from 'redux-actions';
import { calculateHasMore, buildList } from '../utilities/moduleUtils';

/**
 * Helper for List/Grid/Tabular data in Redux Modules (use reduce-reducers pkg to combine)
 * @param {String} [name] unique identifier or pre/post-fix for ACTION_NAMES
 * @param {Object} [initialState] additional state to be initially set aka DEFAULT_STATE
 * @param {Object} [options] further configuration for module
 * @param {String} [options.DEFAULT_SORT_COLUMN] columnName/property to sort by initially
 * @returns {Object}
 */

const makeListModule = (name, initialState, options = {}, resultsArePaginated = true) => {
  const { DEFAULT_SORT_COLUMN = 'name', DEFAULT_SORT_ORDER = '-' } = options;
  const DEFAULT_SORT = `${DEFAULT_SORT_ORDER}${DEFAULT_SORT_COLUMN}`;
  const defaultState = {
    list: [],
    hasMore: true,
    listSort: [DEFAULT_SORT],
    isLoading: false,
    error: null,
    ...initialState,
  };
  const actionTypes = {
    fetchStart: `FETCH_${name}_START`,
    fetchSuccess: `FETCH_${name}_SUCCESS`,
    fetchFail: `FETCH_${name}_FAIL`,
    search: `SEARCH_${name}`,
    clearSearch: `SEARCH_${name}_CLEAR`,
    sort: `SORT_${name}`,
    reset: `RESET_${name}`,
  };
  const actionCreators = {
    fetchStart: createAction(actionTypes.fetchStart),
    fetchSuccess: createAction(actionTypes.fetchSuccess),
    fetchFail: createAction(actionTypes.fetchFail),
    search: createAction(actionTypes.search),
    clearSearch: createAction(actionTypes.clearSearch),
    sort: createAction(actionTypes.sort),
    reset: createAction(actionTypes.reset),
  };
  const reducer = handleActions(
    {
      [actionTypes.fetchStart]: (state) => {
        return { ...state, isLoading: true };
      },
      [actionTypes.fetchSuccess]: (state, { payload }) => {
        const { data, offset } = payload;

        const fetchPayload = resultsArePaginated
          ? {
              hasMore: calculateHasMore(data.length),
              list: buildList(offset, data, state.list),
            }
          : {
              hasMore: false,
              list: data,
            };

        return {
          ...state,
          isLoading: false,
          error: null,
          ...fetchPayload,
        };
      },
      [actionTypes.fetchFail]: (state, { payload }) => {
        return { ...state, isLoading: false, error: payload };
      },
      [actionTypes.search]: (state, { payload }) => {
        return { ...state, searchTerm: payload, isSearching: true };
      },
      [actionTypes.clearSearch]: (state) => {
        return { ...state, searchTerm: '', isSearching: false };
      },
      [actionTypes.sort]: (state, { payload }) => {
        const listSort =
          payload.indexOf(DEFAULT_SORT_COLUMN) > -1 ? [payload] : [payload, DEFAULT_SORT];
        return { ...state, listSort };
      },
      [actionTypes.reset]: (state) => {
        return { ...state, ...defaultState };
      },
    },
    defaultState,
  );
  return { actionTypes, actionCreators, reducer, defaultState };
};

export default makeListModule;
