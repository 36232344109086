import { useQuery } from 'react-query';
import { getData } from '../utilities/xhr';

type PurchaseOrderWithLinesParams = {
  search?: string;
};

async function fetchPurchaseOrdersWithLines(params: PurchaseOrderWithLinesParams) {
  const { body: purchaseOrdersWithLines } = await getData({
    path: `companies/$companyId/purchaseOrdersWithLines`,
    params,
  });
  return purchaseOrdersWithLines;
}

export function usePurchaseOrderLinesData(searchValue: string) {
  return useQuery(['purchaseOrdersWithLines', searchValue], () =>
    fetchPurchaseOrdersWithLines({ search: searchValue || '' }),
  );
}
