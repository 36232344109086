import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { checkForProvider } from '@zylo/orchestra';

const SavedFiltersTypeStateContext = createContext();

function SavedFiltersTypeProvider({ children, savedFiltersType }) {
  return (
    <SavedFiltersTypeStateContext.Provider value={savedFiltersType}>
      {children}
    </SavedFiltersTypeStateContext.Provider>
  );
}

SavedFiltersTypeProvider.propTypes = {
  children: PropTypes.node,
  savedFiltersType: PropTypes.string.isRequired,
};

function useSavedFiltersTypeState() {
  const context = useContext(SavedFiltersTypeStateContext);

  checkForProvider(context, 'useSavedFiltersTypeState', 'SavedFiltersTypeProvider');

  return context;
}

export { SavedFiltersTypeProvider, useSavedFiltersTypeState };
