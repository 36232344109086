import PropTypes from 'prop-types';

export function UserCountCell({ data }) {
  const userCount = data.length;

  return <div>{`${userCount} User${userCount === 1 ? '' : 's'}`}</div>;
}

UserCountCell.propTypes = { data: PropTypes.array };
UserCountCell.defaultProps = { data: [] };
