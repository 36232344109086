import { useMutation } from 'react-query';
import { useAppMessaging } from '@hooks';
import { postData } from '@src/utilities/xhr';
import { useInvalidateZapQueries } from '@frameworks/zap';
import { NewOrUpdatedSavingsEvent } from '@pages/insightsWorkbench/savingsEvents/types';

async function createSavingsEvent(event: NewOrUpdatedSavingsEvent) {
  const response = await postData({
    path: `savingsCenter/companies/$companyId/events`,
    params: event,
  });
  return response;
}

export function useCreateSavingsEvent(setNewId?: (id?: string) => void) {
  const showMessage = useAppMessaging();
  const invalidateZapQueries = useInvalidateZapQueries();

  return useMutation((event: NewOrUpdatedSavingsEvent) => createSavingsEvent(event), {
    onSuccess: ({ body }) => {
      if (setNewId) {
        setNewId(body.id);
        showMessage({ message: 'Duplicate event created' });
      } else {
        showMessage({ message: body.message });
      }

      invalidateZapQueries('savingsCenter');
    },
    onError: () =>
      showMessage({
        message: 'There was an error creating the event. Please try again.',
        isError: true,
      }),
  });
}
