import { useMutation } from 'react-query';
import { useAppMessaging } from '@hooks';
import { deleteData } from '@src/utilities/xhr';
import { useInvalidateZapQueries } from '@frameworks/zap';

async function deleteSavingsEvent(eventId?: string) {
  const response = await deleteData({
    path: `savingsCenter/companies/$companyId/events/${eventId}`,
  });
  return response;
}

export function useDeleteSavingsEvent() {
  const showMessage = useAppMessaging();
  const invalidateZapQueries = useInvalidateZapQueries();

  return useMutation((eventId?: string) => deleteSavingsEvent(eventId), {
    onSuccess: ({ body }) => {
      invalidateZapQueries('savingsCenter');
      showMessage({ message: body.message });
    },
    onError: () =>
      showMessage({
        message: 'There was an error deleting the event. Please try again.',
        isError: true,
      }),
  });
}
