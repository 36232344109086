import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { checkForProvider } from '@zylo/orchestra';

const SidebarStateContext = createContext();
const SidebarUpdateContext = createContext();

function SidebarProvider({ children }) {
  const location = useLocation();
  const [activeSidebars, setActiveSidebars] = useState([]);
  const sidebarActivityIsLocked = useRef(false);

  // clear activeSidebars whenever url changes
  useEffect(() => {
    setActiveSidebars([]);
  }, [location]);

  const lockSidebarActivity = useCallback(() => {
    sidebarActivityIsLocked.current = true;
    setTimeout(() => {
      sidebarActivityIsLocked.current = false;
    }, 300);
  }, []);

  const showSidebar = useCallback(
    (sidebar) => {
      if (!sidebarActivityIsLocked.current) {
        setActiveSidebars((currentVal) => [...currentVal, sidebar]);
        lockSidebarActivity();
      }
    },
    [lockSidebarActivity],
  );

  const hideSidebar = useCallback(
    (sidebar) => {
      if (!sidebarActivityIsLocked.current) {
        setActiveSidebars((currentVal) => currentVal.filter((item) => item !== sidebar));
        lockSidebarActivity();
      }
    },
    [lockSidebarActivity],
  );

  return (
    <SidebarStateContext.Provider value={activeSidebars}>
      <SidebarUpdateContext.Provider value={{ hideSidebar, showSidebar }}>
        {children}
      </SidebarUpdateContext.Provider>
    </SidebarStateContext.Provider>
  );
}

SidebarProvider.propTypes = {
  children: PropTypes.any,
};

function useSidebarState() {
  const context = useContext(SidebarStateContext);

  checkForProvider(context, 'useSidebarState', 'SidebarProvider');

  return context;
}

function useSidebarUpdate() {
  const context = useContext(SidebarUpdateContext);

  checkForProvider(context, 'useSidebarUpdate', 'SidebarProvider');

  return context;
}

export { SidebarProvider, useSidebarState, useSidebarUpdate };
