import { useMutation, UseMutationResult } from 'react-query';
import { putData } from '@utilities/xhr';
import { ResponseError } from '@typings';

export type UpdateUploadIdStatusArgs = {
  uploadId: string;
  status: string;
};

export function useUpdateUploadIdStatus(): UseMutationResult<
  string,
  ResponseError,
  UpdateUploadIdStatusArgs
> {
  return useMutation(async ({ uploadId, status }) => {
    const { body } = await putData({
      path: `companies/$companyId/manualUpload/id/${uploadId}`,
      params: { status },
    });
    return body;
  });
}
