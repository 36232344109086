import classNames from 'clsx';
import { Button, ButtonCategory, ButtonColor, ButtonProps } from '@zylo/orchestra';

type CancelAndConfirmButtonsProps = {
  buttonSize?: ButtonProps['size'];
  cancelButtonColor?: ButtonColor;
  cancelButtonText?: string;
  cancelButtonType?: ButtonCategory;
  cancelIsDisabled?: boolean;
  confirmButtonColor?: ButtonColor;
  confirmButtonText?: string;
  confirmButtonType?: ButtonCategory;
  confirmIsDisabled?: boolean;
  containerClass?: string;
  handleCancel(): void;
  handleConfirm?(): void;
};

export default function CancelAndConfirmButtons({
  buttonSize = 'medium',
  cancelButtonColor,
  cancelButtonText = 'Cancel',
  cancelButtonType = 'dismiss',
  cancelIsDisabled = false,
  confirmButtonColor,
  confirmButtonText = 'Save',
  confirmButtonType = 'primary',
  confirmIsDisabled = false,
  containerClass,
  handleCancel,
  handleConfirm,
}: CancelAndConfirmButtonsProps) {
  return (
    <div
      className={classNames(
        'flex-container justify-end align-center cancel-and-confirm-buttons',
        containerClass,
      )}
    >
      <Button
        buttonColor={cancelButtonColor}
        disabled={cancelIsDisabled}
        handleClick={handleCancel}
        size={buttonSize}
        text={cancelButtonText}
        type={cancelButtonType}
      />
      <Button
        buttonColor={confirmButtonColor}
        disabled={confirmIsDisabled}
        handleClick={handleConfirm}
        isTypeSubmit={!handleConfirm}
        size={buttonSize}
        text={confirmButtonText}
        type={confirmButtonType}
      />
    </div>
  );
}
