import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash-es';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { checkForProvider } from '@zylo/orchestra';
import { IMetadataField } from '../typings';

export type SortOption = {
  sortDescription: string;
  sortDirection: string;
  sortLabel: string;
  sortName: string;
  sortType: string;
  sortValueKey: string;
};

/** Prebuilt sort parameter for user_app_v3 powered tables. */
export type SortApiRequestParam = string | undefined;
/** Can be combined with `currentSort` in order to build filterd request for user_app_v3 powered tables. */
export type SortMap = { [key: string]: string };
/** Typically for use with tables to provide their sort. */
export type SortType = string | SortOption | undefined;

type SortStateContextType = {
  currentSort: SortType;
  sortApiRequestParam: SortApiRequestParam;
  defaultSort: SortType;
  hasPreselectedSort: boolean;
  sortMap: SortMap | undefined;
  sortOptions: SortOption[] | undefined;
};

type SortUpdateContextType = {
  buildSortMap(fields: IMetadataField[] | SortMap): void;
  handleSort(sortValue: string): void;
};

const SortStateContext = createContext<SortStateContextType>(undefined!);
const SortUpdateContext = createContext<SortUpdateContextType>(undefined!);

export type SortProviderProps = {
  children: ReactNode;
  defaultSort: SortType;
  sortOptions?: SortOption[];
  sortUrlParam?: string;
};

function SortProvider({
  children,
  defaultSort,
  sortOptions,
  sortUrlParam = 'sort',
}: SortProviderProps) {
  const location = useLocation();
  const [currentSort, setCurrentSort] = useState<SortType>();
  const [sortApiRequestParam, setSortApiRequestParam] = useState<SortApiRequestParam>();
  const [sortMap, setSortMap] = useState<SortMap>();
  const preselectedSort = useRef<SortType>();

  useEffect(() => {
    if (currentSort) return;

    const urlSort = parse(location.search)[sortUrlParam] as string | undefined;
    const urlSortValue = sortOptions
      ? sortOptions.find(({ sortValueKey }) => sortValueKey === urlSort)
      : urlSort;

    preselectedSort.current = urlSortValue;

    setCurrentSort((currentVal) => urlSortValue || (currentVal ?? defaultSort));
  }, [currentSort, defaultSort, location.search, sortOptions, sortUrlParam]);

  useEffect(() => {
    if (!sortMap || typeof currentSort !== 'string') return;

    setSortApiRequestParam(`${currentSort.substring(0, 1)}${sortMap![currentSort.substring(1)]}`);
  }, [currentSort, sortMap]);

  const handleSort = useCallback(
    (sortValue: SortType) => {
      setCurrentSort(sortValue || defaultSort);
    },
    [defaultSort],
  );

  /**
   * When provided `IMetadataField[]` this function can build a sortMap for sorting tables powered by user_apps_v3.
   * Optionally if you the metadata field have a different type signature that `IMetadataField[]`
   * (see `src/typings/filters.ts` for more information), you may provide your own `sortMap` to the function and it will
   * be stored in the `sortContext`. Example:
   *
   * ```
   * buildSortMap(fields)
   * ```
   *
   * Alternatively:
   *
   * ```
   * buildSortMap(fields.reduce((sortMap, { sourecKey, sortOn }) => ({ ...sortMap, [sourecKey]: sortOn }), {}))
   * ```
   */
  const buildSortMap = useCallback((fields: IMetadataField[] | SortMap) => {
    Array.isArray(fields)
      ? setSortMap(fields.reduce((sortMap, { key, sortOn }) => ({ ...sortMap, [key]: sortOn }), {}))
      : setSortMap(fields);
  }, []);

  return (
    <SortStateContext.Provider
      value={{
        currentSort,
        sortApiRequestParam,
        defaultSort,
        hasPreselectedSort: !!preselectedSort.current,
        sortMap,
        sortOptions,
      }}
    >
      <SortUpdateContext.Provider value={{ buildSortMap, handleSort }}>
        {children}
      </SortUpdateContext.Provider>
    </SortStateContext.Provider>
  );
}

const debouncedProviderCheck = debounce(checkForProvider, 500);

function useSortState() {
  const context = useContext(SortStateContext);

  debouncedProviderCheck(context, 'useSortState', 'SortProvider');

  return context;
}

function useSortUpdate() {
  const context = useContext(SortUpdateContext);

  checkForProvider(context, 'useSortUpdate', 'SortProvider');

  return context;
}

export { SortProvider, useSortState, useSortUpdate };
