import { useState, useCallback } from 'react';
import { useEventTracking } from '@hooks/useEventTracking';

/**
 * Basic state helper for open/closed logic. Accepts Mixpanel event strings that
 * will only be sent if the state actually changes (open twice in a row and the event only fires once).
 * @param openEventName - event to send to Mixpanel when `open` is called
 * @param closeEventName - event to send to Mixpanel when `close` is called
 * @return [currentOpenState, openFunction, closeFunction]
 */

export function useOpenCloseState(
  openEventName?: string,
  closeEventName?: string,
): [boolean, () => void, () => void] {
  const sendEvent = useEventTracking();
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen((prevValue: boolean) => {
      if (!prevValue && openEventName) {
        sendEvent({ eventName: openEventName });
      }
      return true;
    });
  }, [setIsOpen, sendEvent, openEventName]);

  const close = useCallback(() => {
    setIsOpen((prevValue: boolean) => {
      if (prevValue && closeEventName) {
        sendEvent({ eventName: closeEventName });
      }
      return false;
    });
  }, [setIsOpen, sendEvent, closeEventName]);

  return [isOpen, open, close];
}
