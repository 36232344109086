import styled from 'styled-components/macro';
import { useMemo } from 'react';
import { Borders, BarSegment, ColorsUnion, Colors, formatAbbreviateNumber } from '@zylo/orchestra';
import {
  getBarValues,
  getTextColor,
  PercentVisualizationCellCustomizations,
  PercentVisualizationCellProps,
  PercentVisualizationColorMap,
  StaticColorMap,
} from './PercentVisualizationCell';

type PercentVizualizationContainerProps = {
  percent?: number;
};

const PercentVisualizationContainer = styled.div<PercentVizualizationContainerProps>`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 250px;

  .segments-container {
    display: flex;
    height: 1.25rem;
    position: relative;
    width: calc(200px - 0.5rem);
  }
`;

const Segment = styled.div<BarSegment>`
  background: ${({ color }) => (color ? Colors[color] : Colors.grey3)};
  height: 100%;
  left: 0;
  margin-right: ${({ value }) => (value && value >= 100 ? 0 : '3px')};
  position: relative;
  width: ${({ value }) => (value ? `${value > 100 ? 100 : value < 8 ? 8 : value}%` : 0)};

  &:first-child {
    border-radius: ${({ value }) =>
      value && value >= 100
        ? Borders.radius.medium
        : `${Borders.radius.medium} 0 0 ${Borders.radius.medium}`};
  }

  &:last-child {
    border-radius: ${({ value }) =>
      value && value >= 100
        ? Borders.radius.medium
        : `0 ${Borders.radius.medium} ${Borders.radius.medium} 0`};
  }
`;

const PercentVisualizationText = styled.div<{ textColor?: ColorsUnion }>`
  color: ${({ textColor }: { textColor?: ColorsUnion }) =>
    textColor ? Colors[textColor] : 'inherit'};
  display: inline-block;
  flex: 1 0 50px;
  margin-left: 0.5rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50px;
`;

export const defaultNumberColorMap: PercentVisualizationColorMap = {
  empty: 'grey2',
  primary: 'teal4',
  secondary: 'sienna4',
  overflow: 'sienna4',
};

export function PercentVisualizationCellLightweight({
  data,
  colorMap = StaticColorMap,
  numberColorMap = defaultNumberColorMap,
  isPercent = true,
  multipleThresholdsColorMap = undefined,
  showOverflow = false,
  primaryColorThreshold = 80,
  overflowThreshold = 100,
}: PercentVisualizationCellProps) {
  const parsedData: number = isPercent ? parseFloat(data) : parseFloat(data) * 10;

  const percentText = !!parsedData ? `${parsedData}%` : 'N/A';
  const numberText =
    !!parsedData && !isNaN(parsedData)
      ? formatAbbreviateNumber({
          decimals: 1,
          value: parseFloat(data),
        }).formattedNumber
      : 'N/A';

  const finalNumberColorMap = useMemo(() => {
    const result = { ...numberColorMap };

    if (multipleThresholdsColorMap) {
      multipleThresholdsColorMap.forEach((th) => {
        if (th.operatorFn(parsedData / 10)) {
          result.primary = th.color;
        }
      });
    }

    return result;
  }, [multipleThresholdsColorMap, numberColorMap, parsedData]);

  const cellCustomizations: PercentVisualizationCellCustomizations = {
    colorMap: isPercent ? colorMap : finalNumberColorMap,
    showOverflow,
    primaryColorThreshold,
    overflowThreshold,
  };
  const bars = getBarValues(parsedData || 0, cellCustomizations);

  return (
    <PercentVisualizationContainer percent={!!parsedData ? parsedData : 0}>
      <div className="segments-container">
        {bars.map((b) => {
          return <Segment color={b.color} id={b.id as string} key={b.id} value={b.value}></Segment>;
        })}
      </div>
      <PercentVisualizationText
        textColor={getTextColor(
          parsedData,
          cellCustomizations,
          isPercent,
          parsedData,
          finalNumberColorMap.primary,
        )}
      >
        {isPercent ? percentText : numberText}
      </PercentVisualizationText>
    </PercentVisualizationContainer>
  );
}
