import { Colors, FontStyles, ZyloTableHeader } from '@zylo/orchestra';
import styled from 'styled-components/macro';
import { ZAPBulkEditButton } from '@frameworks/zap/bulkEditDrawer/ZAPBulkEditButton';
import { useZAPState } from '@frameworks/zap/ZAPContext';
import ZAPTableExportButton from '@frameworks/zap/table/ZAPTableExportButton';
import ZAPCompareButton from '@frameworks/zap/compare/ZAPCompareButton';
import ZAPColumnPickerButton from './ZAPColumnPickerButton';

export const HeaderStyles = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 30px;
    }
  }

  & > *:last-child {
    margin-left: auto;
  }
`;

export const ZAPTableHeaderTitle = styled.h5`
  margin: 0;
  font-size: ${FontStyles.size['16px']};
  color: ${Colors.teal5};
`;

export default function ZAPTableHeader({
  disableBulkEdit = false,
  includeCompare = false,
}: {
  disableBulkEdit: boolean;
  includeCompare: boolean;
}) {
  const { tableProps, ZAPMetadata } = useZAPState();
  const { tableHeaderContent, includeFieldPicker, includeTableExport } = tableProps;
  const showBulkEditButton = ZAPMetadata?.settings?.bulkEditingEnabled ?? false;

  return showBulkEditButton ||
    includeCompare ||
    tableHeaderContent ||
    includeFieldPicker ||
    includeTableExport ? (
    <ZyloTableHeader>
      <HeaderStyles>
        <div className="flex-container zap__table-header__button-container">
          {showBulkEditButton && !disableBulkEdit && <ZAPBulkEditButton />}
          {includeCompare && <ZAPCompareButton />}
        </div>
        {tableHeaderContent}
        <div className="flex-container">
          {includeFieldPicker && <ZAPColumnPickerButton />}
          {includeTableExport && <ZAPTableExportButton />}
        </div>
      </HeaderStyles>
    </ZyloTableHeader>
  ) : (
    <></>
  );
}
