import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { HttpResponse, ContractDocument } from '@typings';

export function useContractsDocumentData(contractId: string) {
  return useQuery(
    ['contractsDocumentData', contractId],
    async () => {
      const response: HttpResponse<ContractDocument[]> = await getData({
        path: `companies/$companyId/contracts/${contractId}/documents`,
      });

      return response.body;
    },
    { refetchInterval: 5000 },
  );
}
