import { useQuery } from 'react-query';
import { CostCenter, HttpResponse } from '@typings';
import { getData } from '@utilities/xhr';
import { usePermissions } from './usePermissions';

export function useGetAppCostCenters(companyAppId: string | undefined) {
  const { usesVues } = usePermissions();

  return useQuery(
    ['appCostCenters', companyAppId],
    async () => {
      const response: HttpResponse<CostCenter[]> = await getData({
        path: `companies/$companyId/reporting/apps/${companyAppId}/costCenters`,
      });

      return response.body;
    },
    {
      enabled: Boolean(companyAppId) && !usesVues,
      keepPreviousData: true,
    },
  );
}
