export function BooleanCell({
  data,
  dataIsString,
  falseText = 'No',
  trueText = 'Yes',
  trueValue = 'Yes',
  unspecifiedValue,
}: {
  data: boolean | string;
  dataIsString?: boolean;
  falseText?: string;
  trueText?: string;
  trueValue?: string;
  unspecifiedValue?: string;
}): JSX.Element {
  let cellMarkup = '-';
  let additionalClass = '';
  if (data !== undefined && data !== '' && data !== unspecifiedValue) {
    if (data === true || (dataIsString && data === trueValue)) {
      cellMarkup = trueText;
      additionalClass = ' color-green';
    } else {
      cellMarkup = falseText;
      additionalClass = ' color-red';
    }
  }
  return (
    <div className={`boolean-cell${additionalClass}`}>
      <span>{cellMarkup}</span>
    </div>
  );
}
