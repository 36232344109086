import { useInfiniteQuery } from 'react-query';
import { useFiltersState } from '@contexts/filtersContext';
import { useSearchState } from '@contexts/searchContext';
import { useSortState } from '@contexts/sortContext';
import type { List } from '@typings';
import { getData } from '@utilities/xhr';

export type GetTableDataArgs<Response, TableData> = {
  enabled?: boolean;
  fetchLimit?: number;
  handleResponse: (data: Response) => TableData;
  path: string;
};
/** Fetches table data when provided a `path` (the URL to the resource) and a `handleResponse` function.
 * The `handleResponse` function is responsible for handling the raw response from the API and returning
 * an array of table table data that will then be managed by the hook.
 *
 *  @example```
 * type GetTableDataResponse = {
 *   body: { userList: IUser[] };
 * };
 *
 * const { data, fetchNextPage, hasNextPage, isFetching } = useGetTableData<GetTableDataResponse, IUser[]>({
 *  enabled: isFeatureFlagEnabled,
 *  handleResponse: (data: GetTableDataResponse) => data.body.userList,
 *  path: `companies/$companyId/users/`,
 * });
 *
 * const tableData = data?.pages.flat()
 *
 * <DynamicTable
 *  data={tableData}
 *  hasMore={hasNextPage}
 *  isLoading={isFetching}
 *  loadMore={fetchNextPage}
 *  />
 * ```
 */
export function useGetTableData<Response = unknown, TableData = List>({
  enabled = true,
  fetchLimit = 500,
  handleResponse,
  path,
}: GetTableDataArgs<Response, TableData>) {
  const { apiRequestFilters = '[]' } = useFiltersState();
  const { sortApiRequestParam } = useSortState();
  const searchValue = useSearchState() ?? '';

  return useInfiniteQuery(
    [path, apiRequestFilters, sortApiRequestParam, searchValue],
    async ({ pageParam = 0 }) => {
      const response: Response = await getData({
        params: {
          filters: apiRequestFilters,
          limit: fetchLimit,
          offset: pageParam,
          search: searchValue,
          sort: sortApiRequestParam!,
        },
        path,
      });

      return handleResponse(response);
    },
    {
      enabled: enabled && Boolean(sortApiRequestParam),
      getNextPageParam: (_, allPages) => {
        const pages = allPages.flat();
        return pages.length !== 0 && pages.length % fetchLimit === 0 ? pages.length : undefined;
      },
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60 * 12, // 12 hours
      refetchOnWindowFocus: false,
    },
  );
}
