import { useQuery } from 'react-query';
import { getData } from '@utilities/xhr';
import { CompanyBranding } from '@typings';

export type CompanyBrandResponse = {
  body: Array<CompanyBranding>;
};

export type GetCompanyBrandingVariables = {
  enabled?: boolean;
  showDefaults?: boolean;
};

export function useGetCompanyBranding({
  enabled = true,
  showDefaults = true,
}: GetCompanyBrandingVariables = {}) {
  return useQuery(
    ['companyBrand', { showDefaults }],
    async () => {
      const response: CompanyBrandResponse = await getData({
        path: 'companies/$companyId/companyBranding',
        params: { showDefaults },
      });

      return response.body;
    },
    {
      enabled,
    },
  );
}
