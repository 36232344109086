import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { postData, putData } from '@utilities/xhr';
import { useAppMessaging, useFeatureFlags } from '@hooks';
import { useCompanyData } from '@hooks/company';
import { UpdateProfileInformationArgs } from '@pages/profile/ProfileInformationForm';
import { refreshedToken, _saveProfile } from '@modules/auth';
import { actions as zyloUserActions } from '@modules/zyloUsers';
import { updateZyloAuth } from '@pages/admin/zyloUser/hooks';

type UpdateProfileInformationBody = {
  firstName: string;
  lastName: string;
};

export async function updateProfileInformation(
  body: UpdateProfileInformationBody,
  zyloUserID: string,
) {
  const response = await putData({
    path: `companies/$companyId/zyloUserAccounts/${zyloUserID}`,
    params: { ...body },
  });
  return response;
}

export async function refreshCurrentZyloUserAuth(companyId: string) {
  const response = await postData({
    path: `zyloUserAccounts/auth`,
    params: { companyId },
  });
  return response.body;
}

export function useUpdateProfileInformation() {
  const dispatch = useDispatch();
  const showMessage = useAppMessaging();
  const queryClient = useQueryClient();
  const { id } = useCompanyData().companyData;
  const { isAutomationCenterEnabled } = useFeatureFlags('automation-center');

  return useMutation(
    async ({
      body,
      zyloUserId,
      timeZone,
      zyloAuthId,
      authCanAccessAutomation,
    }: UpdateProfileInformationArgs) => {
      const requests = [updateProfileInformation(body, zyloUserId)];
      if (isAutomationCenterEnabled && authCanAccessAutomation) {
        requests.push(updateZyloAuth(zyloAuthId, { timezone: timeZone }));
      }
      await Promise.all(requests);
      return await refreshCurrentZyloUserAuth(id);
    },
    {
      onSuccess: (result) => {
        showMessage({
          message: `Your profile settings have been successfully updated.`,
        });
        _saveProfile(result);
        dispatch(refreshedToken(result));
        dispatch(zyloUserActions.fetchCurrentZyloUserProfile({ id, isLogin: false }));
        queryClient.invalidateQueries(['userProfile']);
      },
      onError: () => {
        showMessage({
          message: 'There was an error updating your profile. Please try again.',
          isError: true,
        });
      },
    },
  );
}
