import styled, { css } from 'styled-components/macro';
import { FormFieldWrapper, Input, SelectButton, Textarea } from '@zylo/orchestra';
import { DisabledStyle, FormLayout } from './types';

const FormFieldWrapperStyles = css`
  ${FormFieldWrapper} {
    min-width: unset;
    max-width: unset;
    width: 100%;
    /* It may be preferrable to change this property on FormFieldInstructions in pennybags instead of here. */
    div:last-of-type {
      white-space: unset;
    }
  }
`;

const twoColumnLayout = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 100%));
  gap: 1.25rem;

  ${FormFieldWrapperStyles}
`;

/**
 * Form element with defualt styling for zylo forms. Accepts all props/attributes associated with a
 * [form element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) with the
 * addition of a `layout` prop. `layout` determines how the fields will appear in the form.
 * As a single column ('column') or as a two column grid ('grid').
 * */
export const Form = styled.form<{ disabledStyle?: DisabledStyle; layout?: FormLayout }>`
  & > div {
    ${({ layout }) => {
      switch (layout) {
        case 'oneColumn':
          return FormFieldWrapperStyles;
        case 'twoColumn':
          return twoColumnLayout;
        case 'responsive':
          return 'display: flex; flex-flow: row wrap;';
        default:
          return undefined;
      }
    }}

    ${({ disabledStyle }) =>
      disabledStyle === 'widget'
        ? css`
            ${Input}, ${SelectButton}, ${Textarea} {
              &:disabled {
                background-color: unset;
                border: none;
                padding-left: 0;
              }
            }
          `
        : undefined}
  }

  input[type='number'] {
    appearance: unset;
  }
`;
