import { useQuery } from 'react-query';
import moment from 'moment';
import { orderBy } from 'lodash-es';
import { getData } from '@utilities/xhr';
import { definitions } from '@typings/api';
import { RenewalItemValues } from '@pages/dashboard/cards/renewals/RenewalItem';
import { useCompanyData } from '@hooks/company';
import { useFeatureFlags } from './useFeatureFlags';

export type ContractRenewalsReport = Required<definitions['ContractRenewalsReport']> & {
  contractName: string;
  billingFrequency: string;
  totalLicenses: number;
};

export type ContractRenewalsResponse = {
  body: ContractRenewalsReport[];
};

function formatRenewalData(
  data: ContractRenewalsReport[] = [],
  todaysDate: string,
): RenewalItemValues[][] {
  // eslint-disable-next-line prefer-spread
  const renewalList: RenewalItemValues[][] = Array.apply(null, Array(14)).map(() => []);
  const currentDateYear = moment.utc(todaysDate).year();
  const currentDateMonth = moment.utc(todaysDate).month() + 1;

  data = orderBy(
    data,
    (renewal) => {
      return moment(renewal.renewalDate);
    },
    ['asc'],
  );

  data.forEach((app) => {
    const genericRenewalItem: RenewalItemValues = {
      open: false,
      id: app.contractId,
      category: app.category,
      name: app.supplierName,
      renewalDate: app.renewalDate,
      spend: app.totalContractValue,
      domain: app.domain,
      contractName: app.contractName,
      billingFrequency: app.billingFrequency,
      totalLicenses: app.totalLicenses,
      type: 'contract',
    };

    const month = moment.utc(genericRenewalItem.renewalDate).month() + 1;
    const year = moment.utc(genericRenewalItem.renewalDate).year();

    const monthIndex =
      moment(`${month}/1/${year}`).diff(
        moment(`${currentDateMonth}/1/${currentDateYear}`),
        'months',
      ) + 1;

    if (monthIndex >= 1 && monthIndex <= 13) {
      renewalList[monthIndex].push(genericRenewalItem);
    }
  });
  return renewalList;
}

export function useContractRenewals() {
  const { isContractManagementEnabled } = useFeatureFlags('contract-management');
  const { timeMachineDate } = useCompanyData().companyData;
  const todaysDate =
    timeMachineDate && timeMachineDate.length > 0 ? timeMachineDate : new Date().toString();
  return useQuery<RenewalItemValues[][]>(
    ['contractRenewals'],
    async () => {
      const contractRenewals: ContractRenewalsResponse = await getData({
        path: `companies/$companyId/reporting/renewals/contracts`,
      });
      return formatRenewalData(contractRenewals.body, todaysDate);
    },
    {
      enabled: isContractManagementEnabled,
      staleTime: 1000 * 60 * 60 * 12, // 12 hours
    },
  );
}
