import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { Button } from '@zylo/orchestra';
import NotFoundImage from '@assets/img/404.svg';
import '@styles/pages/error-landing-page.scss';

class PageNotFound extends PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
  };

  componentDidMount() {
    document.title = 'Zylo | Page Not Found';
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      navigate('/login');
    }
  }

  back = () => {
    window.history.back();
  };

  render() {
    return (
      <div className="error-landing-page" role="main">
        <div className="center-image">
          <div className="error-svg">
            <img alt="Not-Found" src={NotFoundImage} />
          </div>
          <p className="color-darkgray subtext">
            We were unable to find the page you were looking for.
          </p>
          <Button
            buttonColor="navy"
            handleClick={this.back}
            icon="IconArrowNarrowLeft"
            text="Go Back"
            type="dismiss"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;

  return {
    isAuthenticated: !!token,
  };
};

export default connect(mapStateToProps)(PageNotFound);
