import { capitalize, get } from 'lodash-es';
import { RowData } from '@typings';

export type AlertTypeCellProps = {
  data?: string;
  rowData: RowData;
};

export function AlertTypeCell({ data = 'default', rowData }: AlertTypeCellProps) {
  const chargeType = get(rowData, 'settings.transaction.chargeType', '');
  const textLookup: { [key: string]: string } = {
    renewal: 'Renewal',
    'contract-renewal': 'Contract Renewal',
    transaction: `${capitalize(chargeType)} Payments`,
    default: '-',
  };

  return (
    <div className="alert-type-cell">
      <div className={`alert-type ${data}`} />
      {textLookup[data]}
    </div>
  );
}
